import { useEffect } from "react";
import { MultiSelectDropdown } from "@hopper-b2b/ui";
import { trackEvent } from "@hopper-b2b/api";
import { useApiConfigSelector, useUberBridge } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  APPLIED_FILTER,
  AppliedFilterTrackingProperties,
} from "@hopper-b2b/types";
import { generateTrackingEvent } from "../../../../../../actions/actions";
import { IAirportOptions } from "../../../../../../../shop/reducer";
import { flightApiConfigStoreKey } from "../../../../../../../../reducers/types";
import { AirportFilterProps } from "./container";

interface IAirportFilterProps extends AirportFilterProps {
  allAirports: IAirportOptions[];
  showDropdownContentOnly?: boolean;
  trackingProperties?: AppliedFilterTrackingProperties;
}

export const AirportFilterSelection = (props: IAirportFilterProps) => {
  const {
    airportFilter = [],
    setAirportFilter,
    allAirports,
    showDropdownContentOnly,
    trackingProperties,
  } = props;
  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);
  const { t } = useI18nContext();
  const { postEvent } = useUberBridge();

  useEffect(() => {
    if (airportFilter.length > 0) {
      postEvent(APPLIED_FILTER, trackingProperties);
      trackEvent(generateTrackingEvent("airport"), apiConfig);
    }
  }, [airportFilter]);

  return (
    <MultiSelectDropdown
      currentValue={airportFilter}
      dropdownLabel={t("searchControl.airport")}
      options={allAirports}
      setValue={setAirportFilter}
      showDropdownContentOnly={showDropdownContentOnly}
      className="b2b-shop-filter"
      popoverClassName="b2b"
    />
  );
};
