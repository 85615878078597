export enum TimeUnitEnum {
  MILLISECONDS = "milliseconds",
  SECONDS = "seconds",
  MINUTES = "minutes",
  HOURS = "hours",
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months",
  YEARS = "years",
}

type TimeUnit = {
  value: number;
  unit: TimeUnitEnum;
};

export const convertTimeUnit = ({
  value,
  from,
  to,
}: {
  value: number;
  from: TimeUnitEnum;
  to: TimeUnitEnum;
}) => {
  const timeInMilliseconds = toMillis({
    value: value,
    unit: from,
  });
  return fromMillis(timeInMilliseconds, to);
};

const fromMillis = (time: number, unit: TimeUnitEnum) => {
  switch (unit) {
    case TimeUnitEnum.MILLISECONDS:
      return { value: time, unit };
    case TimeUnitEnum.SECONDS:
      return { value: time / 1000, unit };
    case TimeUnitEnum.MINUTES:
      return { value: time / 1000 / 60, unit };
    case TimeUnitEnum.HOURS:
      return { value: time / 1000 / 60 / 60, unit };
    case TimeUnitEnum.DAYS:
      return { value: time / 1000 / 60 / 60 / 24, unit };
    case TimeUnitEnum.WEEKS:
      return { value: time / 1000 / 60 / 60 / 24 / 7, unit };
    case TimeUnitEnum.MONTHS:
      return { value: time / 1000 / 60 / 60 / 24 / 30, unit };
    case TimeUnitEnum.YEARS:
      return { value: time / 1000 / 60 / 60 / 24 / 365, unit };
  }
};

const toMillis = (time: TimeUnit) => {
  switch (time.unit) {
    case TimeUnitEnum.MILLISECONDS:
      return time.value;
    case TimeUnitEnum.SECONDS:
      return time.value * 1000;
    case TimeUnitEnum.MINUTES:
      return time.value * 1000 * 60;
    case TimeUnitEnum.HOURS:
      return time.value * 1000 * 60 * 60;
    case TimeUnitEnum.DAYS:
      return time.value * 1000 * 60 * 60 * 24;
    case TimeUnitEnum.WEEKS:
      return time.value * 1000 * 60 * 60 * 24 * 7;
    case TimeUnitEnum.MONTHS:
      return time.value * 1000 * 60 * 60 * 24 * 30;
    case TimeUnitEnum.YEARS:
      return time.value * 1000 * 60 * 60 * 24 * 365;
  }
};
