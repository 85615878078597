import { TripDetailsV2, TripSlice } from "@b2bportal/air-shopping-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { removeTimezone } from "@hopper-b2b/utilities";
import dayjs from "dayjs";
import defaultStyles from "./FlightDetails.module.scss";
import clsx from "clsx";
import { FlightDetailsSegment } from "@components/flights";

export interface FlightDetailsComponentProps extends PlatformComponentProps {
  flightDetails?: TripDetailsV2;
  flightSlice: TripSlice;
  cabinClass?: string;
  planeInfo?: string;
  plusDays?: string;
  isOutgoing: boolean;
  classNames?: string;
}

export const FlightDetails = ({
  flightSlice,
  cabinClass,
  planeInfo,
  plusDays,
  isOutgoing = true,
  classNames,
  children,
}: FlightDetailsComponentProps) => {
  const COMPONENT_KEY = CoreFlightsComponent.FlightDetails;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t, formatDateTime, DateTimeFormatStyle } = useI18nContext();

  if (flightSlice == null) {
    return null;
  }

  return (
    <div className={clsx(block, classNames)}>
      <div className={cn("title")}>
        <span className={cn("title-text")}>
          <I18nMarkup
            tKey={
              isOutgoing
                ? "flightsPageTitles.detailsOutbound"
                : "flightsPageTitles.detailsReturn"
            }
            replacements={
              isOutgoing
                ? { outboundCity: flightSlice.destinationName }
                : { returnCity: flightSlice.destinationName }
            }
          />
        </span>{" "}
        <span className={cn("title-date")}>
          {t("departingOnWithDate", {
            date: formatDateTime(
              dayjs(removeTimezone(flightSlice.departureTime)).toDate(),
              DateTimeFormatStyle.ShortMonthDayShortWeekday
            ),
          })}
        </span>
      </div>
      <div className={cn("slice")}>
        {flightSlice.segmentDetails.map((segment, index) => {
          return (
            <FlightDetailsSegment
              key={`segment-${index}`}
              segment={segment}
              cabinClass={cabinClass}
              planeInfo={planeInfo}
            />
          );
        })}

        {plusDays != null ? (
          <div>
            <div className={cn("plus-days")}>{t("flightNextDay")}</div>
          </div>
        ) : null}
      </div>
      {children}
    </div>
  );
};
