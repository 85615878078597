import { ReactNode } from "react";
import DisruptionExerciseFooter from "../DisruptionExerciseFooter/DisruptionExerciseFooter";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExercisePopup.module.scss";
import { useDeviceType } from "@b2bportal/core-utilities";
import { Dialog, DialogContent, Icon } from "@b2bportal/core-ui";

export interface DisruptionExercisePopupProps {
  open: boolean;
  onClose?: () => void;
  headerImage: IconNameEnum;
  title: string;
  subtitle: string;
  content: ReactNode;
  onSupportClick?: () => void;
  onRefundClick?: () => void;
  width: string;
  hideFooter: boolean;
}

export const DisruptionExercisePopup = ({
  open,
  onClose,
  headerImage,
  title,
  subtitle,
  content,
  onSupportClick,
  onRefundClick,
  width = "634px",
  hideFooter = false,
}: DisruptionExercisePopupProps) => {
  const image = <Icon iconName={headerImage} />;
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExercisePopup;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { isDesktopAndUp } = useDeviceType();

  const popupContent = !isDesktopAndUp ? (
    <div className={cn("half-sheet-content-mobile")}>
      <div className={cn("header-image-container-mobile")}>{image}</div>
      <div className={cn("half-sheet-title-mobile")}>{title}</div>
      <div className={cn("half-sheet-subtitle-mobile")}>{subtitle}</div>
      <div className={cn("content-mobile")}>{content}</div>
      {!hideFooter && (
        <DisruptionExerciseFooter
          onSupportClick={onSupportClick}
          onRefundClick={onRefundClick}
        ></DisruptionExerciseFooter>
      )}
    </div>
  ) : (
    <div className={cn("desktop-popup-container")} style={{ width: width }}>
      <div className={cn("header-image-container")}>{image}</div>
      <div className={cn("half-sheet-content")}>
        <div className={cn("popup-title")}>{title}</div>
        <div className={cn("popup-subtitle")}>{subtitle}</div>
        <div className={cn("content")}>{content}</div>
      </div>

      {onSupportClick && (
        <div className={cn("popup-terms-container")}>
          <DisruptionExerciseFooter
            onSupportClick={onSupportClick}
            onRefundClick={onRefundClick}
          ></DisruptionExerciseFooter>
        </div>
      )}
    </div>
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>{popupContent}</DialogContent>
    </Dialog>
  );
};

export default DisruptionExercisePopup;
