import { useStyles } from "../context";
import {
  CORE_FINTECH_UI_NAME,
  CoreFintechUiComponents,
} from "@b2bportal/core-types";

export const useFintechUiStyles = (
  componentName: keyof typeof CoreFintechUiComponents,
  defaultStyles?: object
) => {
  return useStyles(CORE_FINTECH_UI_NAME, componentName, defaultStyles);
};
