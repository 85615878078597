import { useDispatch } from "react-redux";
import { useI18nContext } from "@hopper-b2b/i18n";
import defaultStyles from "./DisruptionExerciseRefundConfirmationPage.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
  useTenantIcons,
} from "@b2bportal/core-themes";
import {
  DisruptionExerciseProgress,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import { Icon } from "@b2bportal/core-ui";
import { DisruptionExerciseButton } from "../../components/DisruptionExerciseButton";
import { DisruptionExerciseButtonType } from "../../components/DisruptionExerciseButton/DisruptionExerciseButton";

export interface DisruptionExerciseRefundConfirmationPageProps {
  email: string;
  productName: string;
  handleExerciseCompleted?: () => void;
}

export const DisruptionExerciseRefundConfirmationPage = ({
  email,
  productName,
  handleExerciseCompleted,
}: DisruptionExerciseRefundConfirmationPageProps) => {
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseRefundConfirmationPage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t } = useI18nContext();
  const dispatch = useDispatch();
  const icons = useTenantIcons();

  const handleCta = () => {
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.NotStarted)
    );
    handleExerciseCompleted?.();
  };

  const confirmationIcon =
    icons.disruptionConfirmationIcon != null ? (
      <Icon
        className={cn("icon-container")}
        iconName={IconNameEnum.disruptionConfirmationIcon}
        useCurrentColor={false}
      />
    ) : (
      <Icon
        className={cn("icon-container")}
        iconName={IconNameEnum.checkFilled}
      />
    );

  return (
    <div className={cn("page-container")}>
      <div className={cn("container")}>
        {confirmationIcon}
        <span className={cn("title")}>
          {t(
            "core-disruption.disruptionUniversalExercise.refundConfirmationPage.title"
          )}
        </span>
        <span className={cn("subtitle")}>
          {t(
            "core-disruption.disruptionUniversalExercise.refundConfirmationPage.subtitle",
            {
              productName,
              email,
            }
          )}
        </span>
        <div className={cn("button-container")}>
          <DisruptionExerciseButton
            type={DisruptionExerciseButtonType.Primary}
            title={t(
              "core-disruption.disruptionUniversalExercise.refundConfirmationPage.confirmCta"
            )}
            onClick={handleCta}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};

export default DisruptionExerciseRefundConfirmationPage;
