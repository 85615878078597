import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  airCfarApi,
  GetAirCfarOfferRequest,
  GetAirCfarOfferResponseEnum,
  GetAirCfarOfferResponse,
} from "@b2bportal/air-cfar-api";

import { FlightsCfarTrackingEvent } from "@b2bportal/core-types";

// eslint-disable-next-line @nx/enforce-module-boundaries
import { trackEvent } from "@hopper-b2b/api";

export const fetchCfarOffers = createAsyncThunk<
  GetAirCfarOfferResponse,
  GetAirCfarOfferRequest
>("cfar/fetchCfarOffers", async (request: GetAirCfarOfferRequest, thunkAPI) => {
  try {
    const res = await airCfarApi(axios).apiV0CfarAirOfferGetPost(request);
    if (
      res.data.GetAirCfarOfferResponse === GetAirCfarOfferResponseEnum.Available
    ) {
      return res.data;
    } else {
      if (
        res.data.GetAirCfarOfferResponse ===
        GetAirCfarOfferResponseEnum.NotAvailable
      ) {
        const trackingProperties = res.data.trackingProperties;
        trackEvent({
          eventName: FlightsCfarTrackingEvent.SKIP_CFAR,
          properties: trackingProperties?.properties ?? {},
          encryptedProperties: trackingProperties?.encryptedProperties
            ? [trackingProperties.encryptedProperties]
            : [],
        });
      }
      return res.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
