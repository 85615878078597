import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreDisruptionComponent,
  FlightFareSelectionFunction,
  IFlightListData,
} from "@b2bportal/core-types";
import {
  FlightCardDetailsLoading,
  FlightCardFareList,
  FlightCardSummary,
  FlightCardWrapper,
  FlightDetails,
  FlightDetailsDialog,
  FlightFareGrid,
} from "@components/flights";
import { Icon } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import React from "react";
import defaultStyles from "./DisruptionExerciseFlightCard.module.scss";
import {
  Fare,
  FareSliceOutbound,
  Flights,
} from "@b2bportal/air-disruption-api";
import { useDisruptionFlightCard } from "../../utils/useDisruptionFlightCard";
import { DisruptionExerciseButton } from "@b2bportal/core-disruption";

export interface DisruptionExerciseFlightCardProps {
  flight: IFlightListData<Fare | FareSliceOutbound>;
  isSelected: boolean;
  sliceIndex: number;
  onClick?: () => void;
  onFareSelect: FlightFareSelectionFunction;
  goToNextStep: () => void;
  className?: string;
  flights: Flights;
}

export const DisruptionExerciseFlightCard = React.forwardRef<
  HTMLDivElement,
  DisruptionExerciseFlightCardProps
>((props, forwardedRef) => {
  const {
    flight,
    isSelected,
    sliceIndex = 0,
    onFareSelect,
    goToNextStep,
    className,
    flights,
  } = props;

  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseFlightCard;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t } = useI18nContext();

  const {
    context: {
      isFlightDetailsLoading,
      open,
      flightCardSummary,
      fareData,
      tripCategory,
      fareShelfFilter,
      flightDetails,
      flightSlice,
      fareShelfFilterWithUpsell,
      openFlightDetailsDialog,
    },
    handlers: {
      handleFlightCardClick,
      setOpenFlightDetailsDialog,
      handleFareGridSelection,
    },
  } = useDisruptionFlightCard(props);

  return (
    <div className={clsx(block, className)} ref={forwardedRef}>
      <FlightCardWrapper
        className={cn("button")}
        onClick={handleFlightCardClick}
      >
        {flightCardSummary == null ? null : (
          <div className={cn("summary")}>
            <FlightCardSummary {...flightCardSummary} />
          </div>
        )}
        <div className={cn("fare-grid")}>
          <FlightFareGrid
            fares={fareData}
            tripType={tripCategory}
            onFareGridClick={handleFareGridSelection}
            fareShelfFilter={fareShelfFilter}
          />
        </div>
        <Icon
          iconName={IconNameEnum.dropdownTriangle}
          className={clsx(
            cn("icon", {
              expanded: isSelected,
            })
          )}
        />
      </FlightCardWrapper>

      {open && (
        <>
          <div
            className={cn("flight-card-details", {
              open: open,
              loading: isFlightDetailsLoading,
            })}
          >
            {/* Conditional rendering for flight details based on loading state */}
            {isFlightDetailsLoading ||
            flightDetails == null ||
            flightSlice == null ? (
              <FlightCardDetailsLoading />
            ) : (
              <>
                <div className={cn("flight-details")}>
                  <FlightDetails
                    flightDetails={flightDetails}
                    flightSlice={flightSlice}
                    planeInfo=""
                    isOutgoing={sliceIndex === 0}
                    classNames=""
                  />
                </div>
                <div className={cn("fare-details")}>
                  <FlightCardFareList
                    fareIdList={flight.fares.map((f) => {
                      return "example" in f ? f.fareSlice : f.id;
                    })}
                    fareDetails={flightDetails.fareDetails}
                    sliceIndex={sliceIndex}
                    fareShelfFilter={fareShelfFilterWithUpsell}
                    sliceId={flight.sliceId}
                    tripId={flightDetails.id}
                    onFareSelect={onFareSelect}
                    goToNextStep={goToNextStep}
                  />
                </div>
              </>
            )}
          </div>
          <div className={cn("bottom-floating-root", { fixed: true })}>
            <DisruptionExerciseButton
              title={t("continue")}
              onClick={() => {
                setOpenFlightDetailsDialog(true);
              }}
              disabled={isFlightDetailsLoading}
            />
          </div>
        </>
      )}
      <FlightDetailsDialog
        open={openFlightDetailsDialog}
        onClose={() => setOpenFlightDetailsDialog(false)}
        onClick={() => {
          goToNextStep();
        }}
        flightSlice={flightSlice}
        isOutbound={sliceIndex === 0}
      />
    </div>
  );
});
