import {
  HowItWorksDialog as CoreHowItWorksDialog,
  HowItWorksDialogProps,
} from "@b2bportal/core-disruption";
import { useModuleBEM } from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import defaultStyles from "./HowItWorksDialog.module.scss";
import clsx from "clsx";
import { DialogClose } from "@b2bportal/core-ui";
import { useI18nContext } from "@hopper-b2b/i18n";

export const HowItWorksDialog = (props: HowItWorksDialogProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.HowItWorksDialog;
  const [block, cn] = useModuleBEM(defaultStyles, COMPONENT_KEY);
  const { t } = useI18nContext();

  const secondaryContent = (
    <div className={cn("secondary-content")}>
      <DialogClose asChild>
        <button className={cn("continue-button")}>
          {t("core-disruption.howItWorksDialog.close")}
        </button>
      </DialogClose>
    </div>
  );

  return (
    <div className={clsx(block)}>
      <CoreHowItWorksDialog {...props}></CoreHowItWorksDialog>

      {secondaryContent}
    </div>
  );
};
