/* eslint-disable prefer-const */
import { call, put, putResolve, select } from "redux-saga/effects";

import {
  CalendarBuckets,
  LocationQuery,
  LocationQueryEnum,
  LocationResponse,
  PredictionResultEnum,
  PriceFreezeResultEnum,
  RegionType,
  ShopSummaryResponseV4Response,
  TripFilterEnum,
  TripType,
} from "@b2bportal/air-shopping-api";
import {
  fetchCalendar,
  fetchChfarFlights,
  FetchIataCodeLookupResponse,
  fetchIataLocation,
  fetchLocationAutocomplete,
  trackEvent,
} from "@hopper-b2b/api";
import {
  AIR_ENTRY,
  AIR_PRICE_PREDICTION_RECOMMENDATION,
  AirEntryProperties,
  ClientName,
  FLIGHT_LIST_UPDATED,
  FlightShopType,
  IDepartureCalendarReport,
  IIdFlight,
  OfferWithSuggested,
  SliceStopCountFilter,
  TripCategory,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import { fetchFlights } from "../../../api/v4/shop/fetchFlights";
import Logger from "../../../helpers/Logger";
import { flightApiConfigStoreKey, IStoreState } from "../../../reducers/types";
import { PATH_HOME } from "../../../utils/urlPaths";
import { actions as searchActions } from "../../search/actions";
import { setMobileSearchProgress } from "../../search/actions/actions";
import { MobileFlightSearchStep } from "../../search/reducer";
import { transformDateBuckets } from "../../search/sagas/fetchDepartureCalendarSaga";
import { actions } from "../actions";
import { IShopParams } from "../actions/actions";
import {
  getAirEntryProperties,
  getPriceFreezeOfferWithSuggested,
  getTrackingProperties,
  maxFlightPriceSelectorV2,
} from "../reducer/selectors";
import { IFlightShopParsedQuery } from "../utils/parseQueryString";
import {
  getExistingStateVariables,
  populateFlightShopQueryParametersFromState,
} from "./populateShopQueryParamsSaga";
import { getEnvVariables } from "@hopper-b2b/utilities";

const shouldRedirect = ({
  origin,
  destination,
  departureDate,
  tripCategory,
  returnDate,
  stopsOption,
}: any) => {
  return (
    !origin ||
    !destination ||
    !departureDate ||
    !stopsOption ||
    (tripCategory === TripCategory.ROUND_TRIP && !returnDate)
  );
};

export function* fetchFlightsV4(
  action: actions.IFetchFlightsV4
): Generator<any, any, any> {
  try {
    const flightShopParams = yield call(setUpFlightShopParams, action);
    const {
      departureDate,
      returnDate,
      origin,
      destination,
      infantsInSeatCount,
      infantsOnLapCount,
    } = flightShopParams;

    const adultsCount = flightShopParams.adultsCount || 1;
    const childrenCount = flightShopParams.childrenCount || 0;
    const stopsOption =
      flightShopParams.stopsOption || SliceStopCountFilter.DEFAULT;
    const noLCC = flightShopParams.noLCC || false;

    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const requestBody: IShopParams = {
      origin: { ...origin.id.code },
      destination: { ...destination.id.code },
      originType: { ...origin.id.regionType },
      destinationType: { ...destination.id.regionType },
      ...(returnDate && {
        returnDate: dayjs(returnDate).format("YYYY-MM-DD"),
      }),
      departureDate: departureDate
        ? dayjs(departureDate).format("YYYY-MM-DD")
        : undefined,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter: {
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      },
    };

    const chfarId = state.flightShop.chfarId;
    const isChfarFlightShop =
      state.flightShop.flightShopType === FlightShopType.CHFAR_EXERCISE &&
      chfarId;
    const response: ShopSummaryResponseV4Response = isChfarFlightShop
      ? yield fetchChfarFlights(
          requestBody,
          chfarId,
          action.isMobile,
          apiConfig
        )
      : yield fetchFlights(
          requestBody,
          action.isMobile,
          apiConfig,
          action?.controller
        );

    if (getEnvVariables("clientName") !== ClientName.NUBANK) {
      const properties: AirEntryProperties | null = yield select(
        getAirEntryProperties
      );
      yield trackEvent(
        {
          eventName: AIR_ENTRY,
          properties,
        },
        apiConfig
      );
    }

    if (response.Response === "Failure") {
      yield put(actions.setTripSummariesError());
      yield put(actions.setPredictionError());
    } else {
      // yield select() is the new state, we need to use the previous state for passing the max price filter
      // note: the order does matter here! having redux set priceFreezeOffer first will allow it to update priceFreezeOffer
      // related selectors prior to that of the prediction
      const trackingProps = response.value?.trackingProperties;
      yield putResolve(actions.setFlights(response.value.flights));
      const priceFreezeOffer =
        response.value.priceFreezeOffer?.PriceFreezeResult ===
        PriceFreezeResultEnum.PriceFreezeAvailable
          ? response.value.priceFreezeOffer.offer
          : null;

      yield putResolve(actions.setPriceFreezeOffer(priceFreezeOffer));

      if (
        response.value?.prediction?.PredictionResult ===
        PredictionResultEnum.Prediction
      ) {
        const prediction = response.value.prediction;
        trackEvent({
          eventName: AIR_PRICE_PREDICTION_RECOMMENDATION,
          properties: {
            recommendation: prediction.recommendationV2,
            recommendation_before_confidence: prediction.recommendation,
            ...(prediction.trackingProperties?.properties || {}),
          },
        });
      }
      yield putResolve(actions.setPrediction(response.value.prediction));

      yield putResolve(actions.setFlightShopOffers(response.value.offers));

      const priceFreeze: OfferWithSuggested | null = yield select(
        getPriceFreezeOfferWithSuggested
      );
      if (priceFreeze) {
        yield put(actions.fetchTripDetails(priceFreeze.cheapestTrip.tripId));
      }
      const maxPriceFlight: number = yield select(maxFlightPriceSelectorV2);
      yield putResolve(searchActions.setMaxPriceFilter(maxPriceFlight));

      yield put(searchActions.setAwaitingRefetch(false));

      yield put(actions.setShopTrackingProperties(trackingProps));

      trackEvent({
        eventName: FLIGHT_LIST_UPDATED,
        properties: getTrackingProperties(state),
      });
    }
  } catch (e) {
    yield put(actions.setTripSummariesError());
    yield put(actions.setPredictionError());
    Logger.debug(e);
  }
}

function* setUpFlightShopParams({
  history,
  isIataEnabled,
}: actions.IFetchFlightsV4) {
  const state: IStoreState = yield select();

  let {
    departureDate,
    returnDate,
    origin,
    destination,
    originType,
    destinationType,
    tripCategory,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  } = getExistingStateVariables(state);

  const parsedQueryString: IFlightShopParsedQuery =
    yield populateFlightShopQueryParametersFromState({
      state,
      history,
      isV2: true,
    });

  if (
    !origin ||
    !destination ||
    !departureDate ||
    (tripCategory === TripCategory.ROUND_TRIP && !returnDate) ||
    // note: when it's from FlightWatch, it should treat parsedQueryString as the source of truth.
    parsedQueryString.isFromFlightWatch
  ) {
    const { correspondingDestination, correspondingOrigin } =
      yield fetchOriginDestination(parsedQueryString, isIataEnabled);
    [
      origin,
      destination,
      departureDate,
      returnDate,
      tripCategory,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      noLCC,
    ] = [
      correspondingOrigin,
      correspondingDestination,
      parsedQueryString.departureDate,
      parsedQueryString.returnDate,
      parsedQueryString.tripCategory,
      parsedQueryString.adultsCount,
      parsedQueryString.childrenCount,
      parsedQueryString.infantsInSeatCount,
      parsedQueryString.infantsOnLapCount,
      (parsedQueryString.stopsOption as SliceStopCountFilter) ||
        SliceStopCountFilter.DEFAULT,
      parsedQueryString.noLCC,
    ];

    // If we are missing the data we need from both the state and the query params
    // we should redirect the user home.
    if (
      shouldRedirect({
        departureDate,
        origin,
        destination,
        returnDate,
        tripCategory,
        stopsOption,
      })
    ) {
      yield putResolve(
        setMobileSearchProgress(MobileFlightSearchStep.LocationSearch)
      );
      history.push(PATH_HOME);
      return;
    }

    const { monthBuckets, priceBuckets } = yield fetchCalendarBuckets(
      origin,
      destination,
      tripCategory
    );
    // Order here matters because of the reducer structure.
    // Set trip category resets the returnDate field.
    yield putResolve(searchActions.setTripCategory(tripCategory));
    yield putResolve(searchActions.setOrigin(origin));
    yield putResolve(searchActions.setDestination(destination));
    yield putResolve(searchActions.setOriginType(originType));
    yield putResolve(searchActions.setDestinationType(destinationType));
    yield putResolve(searchActions.setDepartureDate(departureDate));
    yield putResolve(searchActions.setReturnDate(returnDate));
    yield putResolve(searchActions.setStopsOption(stopsOption));
    yield putResolve(actions.setCalendarBuckets(monthBuckets, priceBuckets));
    yield putResolve(
      searchActions.setPassengerCounts({
        adultsCount,
        childrenCount,
        infantsInSeatCount,
        infantsOnLapCount,
      })
    );
    yield putResolve(
      searchActions.setFareclassOptionFilter({
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      })
    );
  }

  return {
    departureDate,
    returnDate,
    origin,
    destination,
    originType,
    destinationType,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  };
}

function* fetchOriginDestination(
  parsedQueryString: IFlightShopParsedQuery,
  isIataEnabled?: boolean
) {
  const correspondingOrigin = yield fetchLocation(
    parsedQueryString.origin,
    Boolean(isIataEnabled),
    parsedQueryString?.originType as RegionType
  );
  const correspondingDestination = yield fetchLocation(
    parsedQueryString.destination,
    Boolean(isIataEnabled),
    parsedQueryString?.destinationType as RegionType
  );

  return { correspondingDestination, correspondingOrigin };
}

function* fetchLocation(
  locationQuery: string,
  isIataCodeLookup: boolean,
  regionType?: RegionType
) {
  if (isIataCodeLookup) {
    const locationResult: FetchIataCodeLookupResponse = yield fetchIataLocation(
      {
        iataCode: locationQuery,
      }
    );
    return regionType
      ? locationResult[regionType]
      : locationResult.airport || locationResult.city;
  } else {
    const requestBody: LocationQuery = {
      l: locationQuery,
      LocationQuery: LocationQueryEnum.Label,
    };
    const autocompleteResults: LocationResponse =
      yield fetchLocationAutocomplete(requestBody);
    const locationMatch =
      autocompleteResults.categories[0].results.find(
        (o) => (o.id as IIdFlight).code.code === locationQuery
      ) ||
      autocompleteResults.categories[1].results.find(
        (o) => (o.id as IIdFlight).code.code === locationQuery
      );
    return locationMatch;
  }
}

function* fetchCalendarBuckets(origin, destination, tripCategory) {
  const req: CalendarBuckets = {
    route: {
      origin: origin?.id.code,
      destination: destination?.id.code,
    },
    tripType:
      tripCategory === TripCategory.ONE_WAY
        ? TripType.one_way
        : TripType.round_trip,
    filter: {
      TripFilter: TripFilterEnum.NoFilter,
    },
  };
  const calendarReport: IDepartureCalendarReport = yield fetchCalendar(req);

  const monthBuckets = transformDateBuckets(
    calendarReport.departureDateBuckets
  );
  const priceBuckets = calendarReport.departureDateBuckets.reduce(
    (acc, bucket) => {
      acc.push(bucket.legend);
      return acc;
    },
    []
  );

  return { monthBuckets, priceBuckets };
}
