import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import defaultStyles from "./FlightDisruptionAlertBanner.module.scss";
import { Icon } from "@b2bportal/core-ui";
import { ensureExhaustive } from "@b2bportal/core-utilities";
import { useCallback, useMemo } from "react";

export enum DisruptionAlertBannerType {
  DISRUPTED = "disrupted",
  BOOK_RETURN = "bookReturn",
  ENTRY_POINT = "entryPoint",
}

export interface FlightDisruptionAlertBannerProps {
  onClick: () => void;
  type: DisruptionAlertBannerType;
  productName?: string;
  destination?: string;
}

export const FlightDisruptionAlertBanner = ({
  onClick,
  type,
  productName,
  destination,
}: FlightDisruptionAlertBannerProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.FlightDisruptionAlertBanner;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t } = useI18nContext();

  const content = useCallback(() => {
    switch (type) {
      case DisruptionAlertBannerType.DISRUPTED:
        return (
          <div className={cn("text-container")}>
            <span className={cn("textFirstPart")}>
              {t(
                "core-disruption.disruptionUniversalExercise.flightDisruptionAlertBanner.flightDisruptedTitle"
              )}
            </span>
            <div className={cn("actionContainer")}>
              <span className={cn("textActionPart")}>
                {t(
                  "core-disruption.disruptionUniversalExercise.flightDisruptionAlertBanner.flightDisruptedAction",
                  {
                    productName: productName,
                  }
                )}
              </span>
              <Icon
                iconName={IconNameEnum.rightArrow}
                className={cn("arrow")}
              />
            </div>
          </div>
        );
      case DisruptionAlertBannerType.BOOK_RETURN:
        return (
          <Trans
            i18nKey="core-disruption.disruptionUniversalExercise.flightDisruptionAlertBanner.bookReturn"
            components={[
              <span className={cn("textFirstPart")} />,
              <span className={cn("textSecondPart")} />,
              <span className={cn("textActionPart")} />,
            ]}
            values={{
              destination: destination,
              productName: productName,
            }}
            className={cn("text-container")}
          />
        );
      case DisruptionAlertBannerType.ENTRY_POINT:
        return (
          <div className={cn("text-container")}>
            <span className={cn("textFirstPart")}>
              {t(
                "core-disruption.disruptionUniversalExercise.flightDisruptionAlertBanner.entryPointTitle",
                {
                  productName: productName,
                }
              )}
            </span>
            <div className={cn("actionContainer")}>
              <span className={cn("textActionPart")}>
                {t(
                  "core-disruption.disruptionUniversalExercise.flightDisruptionAlertBanner.entryPointAction",
                  {
                    productName: productName,
                  }
                )}
              </span>
              <Icon
                iconName={IconNameEnum.rightArrow}
                className={cn("arrow")}
              />
            </div>
          </div>
        );
    }
  }, [cn, destination, productName, type]);

  const iconName = useCallback(() => {
    switch (type) {
      case DisruptionAlertBannerType.DISRUPTED:
        return IconNameEnum.warningTriangle;
      case DisruptionAlertBannerType.BOOK_RETURN:
        return IconNameEnum.info;
      case DisruptionAlertBannerType.ENTRY_POINT:
        return IconNameEnum.info;
      default:
        return ensureExhaustive(type, null);
    }
  }, [type]);

  const iconContainer = useCallback(() => {
    const icon = iconName();
    const iconStyleClass =
      type === DisruptionAlertBannerType.DISRUPTED
        ? cn("disrupted-icon")
        : cn("book-return-icon");

    return icon != null ? (
      <div className={clsx(cn("icon"), iconStyleClass)}>
        {<Icon iconName={icon} />}
      </div>
    ) : null;
  }, [cn, iconName, type]);

  return (
    <button
      className={cn("button-container")}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <div className={clsx(cn("container"), cn(type))}>
        <div className={cn("content")}>
          {iconContainer()}
          {content()}
        </div>
      </div>
    </button>
  );
};

export default FlightDisruptionAlertBanner;
