import { Airport } from "@b2bportal/air-booking-api";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import defaultStyles from "./DisruptionExerciseFlightInfoRow.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import dayjs from "dayjs";

export interface DisruptionExerciseFlightInfoRowProps {
  originCode?: string;
  destinationCode?: string;
  fareName: string;
  numberOfTravelers: number;
  date?: string;
  airports: { [key: string]: Airport };
}

export const DisruptionExerciseFlightInfoRow = (
  props: DisruptionExerciseFlightInfoRowProps
) => {
  const { t, language } = useI18nContext();
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseFlightInfoRow;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const TRANS_PATH =
    "core-disruption.disruptionUniversalExercise.rebook.segmentSelectionPage";

  const getCityName = (code?: string) => {
    return code ? props.airports[code]?.cityName || "" : "";
  };
  const origin = getCityName(props.originCode);
  const destination = getCityName(props.destinationCode);

  const formattedDate = formatDateTime(
    dayjs(props.date).toDate(),
    language,
    DateTimeFormatStyle.MediumDate
  );

  const travelerInfo =
    props.numberOfTravelers > 1 ? (
      <Trans
        i18nKey={`${TRANS_PATH}.travelersInfo`}
        components={[<span className={cn("travelersInfo")} />]}
        values={{
          numberOfTravelers: props.numberOfTravelers,
          fareName: props.fareName,
        }}
      />
    ) : (
      <Trans
        i18nKey={`${TRANS_PATH}.travelersInfoSingle`}
        components={[<span className={cn("travelersInfoSingle")} />]}
        values={{
          numberOfTravelers: props.numberOfTravelers,
          fareName: props.fareName,
        }}
      />
    );

  return (
    <div className={cn("container")}>
      <Trans
        i18nKey={`${TRANS_PATH}.originDestinationInfo`}
        components={[<span className={cn("destination")} />]}
        values={{ origin: origin, destination: destination }}
      />
      <br></br>
      {travelerInfo}
      <p>{formattedDate}</p>
    </div>
  );
};

export default DisruptionExerciseFlightInfoRow;
