import { lazy, Suspense, useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import type { Theme } from "@material-ui/core";
import { Navigate, Route, Routes } from "react-router-dom-v5-compat";
import clsx from "clsx";

import {
  useExperiment,
  useGetExperimentVariant,
  useTrackingProperties,
} from "@hopper-b2b/experiments";
import type { Slots } from "@hopper-b2b/ui";
import type {
  LaunchedApplicationProperties,
  RenderLodgingShopValuePropsVariant,
} from "@hopper-b2b/types";
import { logger, trackEvent } from "@hopper-b2b/api";
import { TripsList as UberTripsList } from "@hopper-b2b/trips/src/pages/UberTripsList";
import {
  type FeatureFlagsContextProps,
  getEnvVariables,
  getLandingScreen,
  useLaunchedApplicationEvent,
} from "@hopper-b2b/utilities";
import type { ChatPropertiesType } from "@b2bportal/chat-api";
import { BASE_PATH_DISRUPTION } from "@b2bportal/core-utilities";

import { EXPERIMENTS, FEATURE_FLAGS } from "../../App";
import { useDarkModePreferred } from "../../utils/colors";
import config from "../../utils/config";
import {
  PATH_HOME,
  PATH_HOTELS_SEARCH,
  PATH_LANDING,
  PATH_TRIPS,
  ROUTE_FLIGHTS,
  ROUTE_HOTELS,
} from "../../utils/urlPaths";
import { ReactComponent as ChevronDown } from "../../assets/client/chevron-down.svg";
import Maintenance from "../Maintenance/maintenance";
import {
  AddTravelerFormSelect,
  ContactInfoForm,
  ContactSupportModalContent,
  ExchangeLandingScreen,
  FareDetailsSliceHeader,
  FiltersModal,
  FilterTitle,
  FlightCardExpandedSection,
  FlightCheckoutCtaSection,
  FlightNumberFilterSelection,
  FlightPriceDropProtectionBanner,
  FlightSearchMobileHeaderLeftContent,
  FlightShopLocationDestinationAutocomplete,
  FlightShopLocationOriginAutocomplete,
  FlightShopReviewMultiticketBanner,
  FlightShopSummaryPanel,
  FrequentFlyerDropdown,
  GalleryGrid,
  GalleryHeader,
  HotelCancellationConfirmationScreen,
  HotelCancellationSummaryPanel,
  HotelCheckoutCtaSection,
  HotelDetailsPolicies,
  HotelDetailsReview,
  HotelHeader,
  HotelListMapCard,
  HotelShopMap,
  HotelVoucherOfferBanner,
  InputAdornmentLearnMore,
  Loader,
  LoadingScreen,
  LodgingCardCashBack,
  LodgingSearch,
  LodgingSearchMobileGuestModal,
  MixedCabinHalfsheet,
  MobileActionLinks,
  MobileFlightPricePredictionContent,
  NoResults,
  NubankFlightCard,
  PassportCountrySelect,
  PriceBreakdownAdditionalInfo,
  RequiredAssistanceSelect,
  RadioDropdown,
  RoomDetailsCta,
  RoomDetailsHeader,
  RoomDetailsModalHeader,
  RoomDetailsSelect,
  RoomGallery,
  SearchModal,
  SelfServeFlightInfo,
  ShopHeader,
  TripDetailsSummary,
  TripsCard,
  TripsConfirmationCopyAndGoButton,
  TripsErrorModalContent,
  TripsModalCloseIcon,
  TravelerSelectRowButtonWrap,
  HotelSearchAutocomplete,
  InstallmentsBanner,
  PassengerFormMobileTopSaveRightButton,
  PassengerSelectButton,
  FlightShopReviewInsuranceSection,
  FlightConfirmationTravelInsuranceIncluded,
  LodgingCardOriginalPrice,
  TripFlightsDetailsInsurance,
  LodgingMapBackButton,
} from "../Slots";
import { BestPriceGuaranteeBanner } from "../BestPriceGuaranteeBanner";
import { SharedFlightCtaSection } from "../SharedFlightCtaSection";
import { ConfirmationScreen } from "../ConfirmationScreen";
import { ErrorScreen } from "../ErrorScreen";
import LodgingSearchRoute from "./LodgingSearchRoute";
import HomeRouteComponent from "./HomeRouteComponent";
import useStyles from "./styles";
import "./styles.scss";

const FlightsRouteComponent = lazy(() => import("./FlightsRouteComponent"));
const LodgingRouteComponent = lazy(() => import("./LodgingRouteComponent"));
const TripRoute = lazy(() => import("./TripRouteComponent"));
const DisruptionExerciseRoute = lazy(() => import("./DisruptionExerciseRoute"));

export enum MaintenanceFlagVariants {
  "control" = "control",
  "disable-everything" = "disable-everything",
  "flights" = "flights",
  "hotels" = "hotels",
  "trips" = "trips",
  "exclude-trips" = "exclude-trips",
}

const slots: Slots = {
  "flight-shop-loading-screen": LoadingScreen,
  "trips-list-loading-screen": Loader,
  "mobile-flight-price-prediction-content": MobileFlightPricePredictionContent,
  "flight-shop-list-mobile-card": NubankFlightCard,
  "flight-shop-list-expanded-section": FlightCardExpandedSection,
  "fare-details-slice-header": FareDetailsSliceHeader,
  "flight-shop-cta-section": SharedFlightCtaSection,
  "mobile-flight-number-filter-selection": FlightNumberFilterSelection,
  "mobile-flight-filter-title": FilterTitle,
  "add-traveler-form-select": AddTravelerFormSelect,
  "checkout-contact-info-form": ContactInfoForm,
  "passenger-frequent-flyer-select": FrequentFlyerDropdown,
  "passenger-required-assistance-select": RequiredAssistanceSelect,
  "passenger-passport-country-select": PassportCountrySelect,
  "passenger-input-field-learn-more": InputAdornmentLearnMore,
  "flight-review-mixed-cabin-tooltip": MixedCabinHalfsheet,
  "flight-shop-summary-panel": FlightShopSummaryPanel,
  "trip-details-links": MobileActionLinks,
  "trip-details-summary": TripDetailsSummary,
  "empty-trips-list": NoResults,
  "flight-price-drop-protection-banner": FlightPriceDropProtectionBanner,
  "flight-shop-location-search-destination-autocomplete":
    FlightShopLocationDestinationAutocomplete,
  "flight-shop-location-search-origin-autocomplete":
    FlightShopLocationOriginAutocomplete,
  "flight-search-mobile-header-left-content":
    FlightSearchMobileHeaderLeftContent,
  "price-breakdown-additional-info": PriceBreakdownAdditionalInfo,
  "flight-shop-review-multiticket-banner": FlightShopReviewMultiticketBanner,
  "flight-shop-review-insurance-section": FlightShopReviewInsuranceSection,
  "dropdown-icon": ChevronDown,
  "radio-dropdown": RadioDropdown,
  "traveler-select-row-button-wrap": TravelerSelectRowButtonWrap,
  "passenger-form-mobile-top-save-right-button":
    PassengerFormMobileTopSaveRightButton,
  "passenger-select-button": PassengerSelectButton,
  "flight-confirmation-more-information-container":
    FlightConfirmationTravelInsuranceIncluded,

  // Self-serve
  "self-serve-cancellation-flight-info": SelfServeFlightInfo,
  "self-serve-cancellation-error-screen": ErrorScreen,
  "exchange-loader": Loader,
  "exchange-error-screen": ErrorScreen,
  "exchange-contact-airline-screen": SelfServeFlightInfo,
  "exchange-landing-screen": ExchangeLandingScreen,
  "exchange-request-confirmation-screen": ConfirmationScreen,
  "trips-confirmation-modal-copy-and-go-button":
    TripsConfirmationCopyAndGoButton,
  "trips-error-modal-content": TripsErrorModalContent,
  "hotel-cancellation-confirmation-screen": HotelCancellationConfirmationScreen,

  // Hotels
  "hotel-shop-filters-modal": FiltersModal,
  "lodging-details-screen-header": HotelHeader,
  "hotel-shop-header": ShopHeader,
  "hotel-shop-search-modal": SearchModal,
  "lodging-availability-search": LodgingSearch,
  "hotel-details-gallery-header": GalleryHeader,
  "hotel-details-gallery-grid": GalleryGrid,
  "hotel-details-policies": HotelDetailsPolicies,
  "hotel-details-review": HotelDetailsReview,
  "hotel-shop-map": HotelShopMap,
  "hotel-room-details-header": RoomDetailsHeader,
  "hotel-room-details-select": RoomDetailsSelect,
  "hotel-room-details-modal-header": RoomDetailsModalHeader,
  "hotel-room-details-gallery": RoomGallery,
  "hotel-room-details-cta": RoomDetailsCta,
  "lodging-search-mobile-guest-modal": LodgingSearchMobileGuestModal,
  "lodging-card-cash-back": LodgingCardCashBack,
  "lodging-card-original-price": LodgingCardOriginalPrice,
  "hotel-list-map-card": HotelListMapCard,
  "hotel-voucher-offer-banner": HotelVoucherOfferBanner,
  "hotel-cancellation-summary-panel": HotelCancellationSummaryPanel,
  "hotel-cancellation-loader": LoadingScreen,
  "hotel-search-autocomplete": HotelSearchAutocomplete,
  "best-price-guarantee-banner": BestPriceGuaranteeBanner,
  "installments-banner": InstallmentsBanner,
  "lodging-map-back-button": LodgingMapBackButton,

  // Trips
  "mytrips-list": UberTripsList,
  "mytrips-trip-card": TripsCard,
  "contact-support-modal-content": ContactSupportModalContent,
  "mytrips-modal-close-icon": TripsModalCloseIcon,
  "trip-flights-details-insurance": TripFlightsDetailsInsurance,
};

interface IBodyProps {
  theme: Theme;
  className?: string;
  isFirstSession: boolean;
  launchEventSent: boolean;
  setLaunchEventSent: (val: boolean) => void;
  openSupportChat: (
    productId?: string,
    productType?: ChatPropertiesType,
    requestType?: string
  ) => void;
  onDarkModeChange: (val: boolean) => void;
}

const Body = (props: IBodyProps) => {
  const {
    theme,
    className,
    openSupportChat,
    launchEventSent,
    setLaunchEventSent,
    onDarkModeChange,
  } = props;
  const classes = useStyles();

  const appMaintenance = useExperiment(
    FEATURE_FLAGS.MAINTENANCE,
    MaintenanceFlagVariants["disable-everything"]
  );
  const appExcludingTripsMaintenance = useExperiment(
    FEATURE_FLAGS.MAINTENANCE,
    MaintenanceFlagVariants["exclude-trips"]
  );
  const flightsMaintenance = useExperiment(
    FEATURE_FLAGS.MAINTENANCE,
    MaintenanceFlagVariants.flights
  );
  const hotelsMaintenance = useExperiment(
    FEATURE_FLAGS.MAINTENANCE,
    MaintenanceFlagVariants.hotels
  );
  const tripsMaintenance = useExperiment(
    FEATURE_FLAGS.MAINTENANCE,
    MaintenanceFlagVariants.trips
  );
  const isFlightsEnabled = !appExcludingTripsMaintenance && !flightsMaintenance;
  const isHotelsEnabled = !appExcludingTripsMaintenance && !hotelsMaintenance;

  // Fintech Flags
  const enablePriceWatch = useExperiment(FEATURE_FLAGS.AIR_PRICE_WATCH);
  const enableDisruptionExerciseRebook = useExperiment(
    FEATURE_FLAGS.ENABLE_DISRUPTION_EXERCISE_REBOOK
  );

  const enableCfar = useExperiment(FEATURE_FLAGS.AIR_CFAR);
  const enableChfar = useExperiment(FEATURE_FLAGS.AIR_CHFAR);
  const enableVipSupport = useExperiment(FEATURE_FLAGS.VIP_SUPPORT);
  const enableDisruptionExerciseRebooking = useExperiment(
    FEATURE_FLAGS.AIR_DISRUPTION_EXERCISE_REBOOKING
  );
  const enablePricePrediction = useExperiment(FEATURE_FLAGS.PRICE_PREDICTION);
  const enablePriceDropProtection = useExperiment(
    FEATURE_FLAGS.PRICE_DROP_PROTECTION
  );

  // Features
  const enableAirExchange = useExperiment(FEATURE_FLAGS.AIR_EXCHANGE);
  const enableDarkMode = useExperiment(FEATURE_FLAGS.APP_DARKMODE);
  const enableAirShopV4 = true; //Default should use v4
  const enableLoadingSteps = useExperiment(FEATURE_FLAGS.LOADING_STEPS);
  const enableLodging = useExperiment(FEATURE_FLAGS.LODGING);
  const enableHFv2 = useExperiment(FEATURE_FLAGS.HFv2);
  const enableWalletOffers = useExperiment(FEATURE_FLAGS.WALLET_OFFERS);
  const enableWalletCredits = useExperiment(FEATURE_FLAGS.WALLET_CREDITS);
  const enableLodgingMap = useExperiment(FEATURE_FLAGS.SHOW_LODGING_MAP);

  // Experiments
  const hideAirItineraryReview = useExperiment(
    EXPERIMENTS.HIDE_AIR_ITINERARY_REVIEW
  );
  const hideFareDetails = useExperiment(EXPERIMENTS.HIDE_FARE_DETAILS);
  const enableFintechSelection = useExperiment(
    EXPERIMENTS.AIR_FINTECH_SELECTION
  );
  const hideSeats = useExperiment(EXPERIMENTS.HIDE_SEATS);
  const enableVouchers10 = useExperiment(EXPERIMENTS.VOUCHERS_10);
  const enableVouchers20 = useExperiment(EXPERIMENTS.VOUCHERS_20);
  const replaceMaterialUI = useExperiment(EXPERIMENTS.REPLACE_MATERIAL_UI);
  const renderLodgingStrikethroughPrice = useExperiment(
    EXPERIMENTS.RENDER_LODGING_STRIKETHROUGH_PRICE
  );
  const renderHotelMapEntryPoint = useExperiment(
    EXPERIMENTS.RENDER_HOTEL_MAP_ENTRY_POINT
  );
  const nubankMaxInstallments = useGetExperimentVariant(
    EXPERIMENTS.MAX_INSTALLMENTS
  );
  const enableBlackFridayExperiment = useExperiment(
    EXPERIMENTS.NUBANK_BLACK_FRIDAY
  );
  const renderLodgingShopValueProps = useGetExperimentVariant(
    EXPERIMENTS.RENDER_LODGING_SHOP_VALUE_PROPS
  ) as RenderLodgingShopValuePropsVariant;
  const renderHotelMapAsDefaultView = useExperiment(
    EXPERIMENTS.RENDER_HOTEL_MAP_AS_DEFAULT
  );
  const renderExtraHotelAmenities = useGetExperimentVariant(
    EXPERIMENTS.RENDER_EXTRA_HOTEL_AMENITIES
  );
  const enableTravelInsurance = useExperiment(
    EXPERIMENTS.NUBANK_TRAVEL_INSURANCE
  );

  const trackingProperties: LaunchedApplicationProperties =
    useTrackingProperties({
      landing_screen: getLandingScreen(),
      url: document.location.pathname,
    });

  const launchSent = useLaunchedApplicationEvent(
    launchEventSent,
    trackingProperties,
    trackEvent
  );

  useEffect(() => {
    if (launchSent) {
      setLaunchEventSent(true);
    }
  }, [launchSent, setLaunchEventSent]);

  const isDarkMode = useDarkModePreferred();

  const featureFlags: FeatureFlagsContextProps = useMemo(
    () => ({
      enableAirExchange,
      enableCfar,
      enableChfar,
      enableDarkMode: enableDarkMode && isDarkMode,
      enableFintechSelection,
      enablePriceWatch,
      enableVipSupport,
      enableDisruptionExerciseRebooking,
      hideAirItineraryReview,
      hideFareDetails,
      hideSeats,
      enableAirShopV4,
      enableLoadingSteps,
      enablePricePrediction,
      enableLodging,
      enablePriceDropProtection,
      enableHFv2,
      enableWallet: enableWalletOffers, // use offers to control generic wallet features
      enableWalletOffers, // offer specific features
      enableWalletCredits, // credit specific features
      hideLodgingMap: enableLodgingMap !== true,
      enableVouchers10,
      enableVouchers20,
      replaceMaterialUI,
      renderLodgingStrikethroughPrice,
      renderHotelMapEntryPoint,
      enableBlackFridayExperiment,
      nubankMaxInstallments,
      renderLodgingShopValueProps,
      renderExtraHotelAmenities,
      enableTravelInsurance,
      enableDisruptionExerciseRebook,
      renderHotelMapAsDefaultView,
    }),
    [
      enableAirExchange,
      enableCfar,
      enableChfar,
      enableDarkMode,
      isDarkMode,
      enableFintechSelection,
      enablePriceWatch,
      enableVipSupport,
      enableDisruptionExerciseRebooking,
      hideAirItineraryReview,
      hideFareDetails,
      hideSeats,
      enableAirShopV4,
      enableLoadingSteps,
      enablePricePrediction,
      enableLodging,
      enablePriceDropProtection,
      enableHFv2,
      enableWalletOffers,
      enableWalletCredits,
      enableLodgingMap,
      enableVouchers10,
      enableVouchers20,
      replaceMaterialUI,
      renderLodgingStrikethroughPrice,
      renderHotelMapEntryPoint,
      enableBlackFridayExperiment,
      nubankMaxInstallments,
      renderLodgingShopValueProps,
      renderExtraHotelAmenities,
      enableTravelInsurance,
      enableDisruptionExerciseRebook,
      renderHotelMapAsDefaultView,
    ]
  );

  useEffect(() => {
    if (enableDarkMode && isDarkMode) {
      onDarkModeChange(true);
    }
  }, [enableDarkMode, isDarkMode, onDarkModeChange]);

  // Adding DD RUM tracking for non-prod environments ONLY

  useEffect(() => {
    if (config.ENV !== "production") {
      const promise = document?.hasStorageAccess?.();
      promise?.then(
        function (hasAccess) {
          logger().info(`hasStorageAccess - success: ${hasAccess}`);
        },
        function (reason) {
          logger().info(`hasStorageAccess - failed: ${reason}`);
        }
      );
      if (!promise) {
        logger().info("hasStorageAccess does not exist on document");
      }
    }
  }, []);

  return (
    <main
      className={clsx(
        "main-body",
        classes.main,
        className,
        getEnvVariables("clientName")
      )}
    >
      {appMaintenance ? (
        <Maintenance />
      ) : (
        <Grid className={classes.content}>
          <Routes>
            {/* TODO: Add landing page */}
            <Route
              path={"/*"}
              element={<Navigate to={PATH_LANDING} replace={true} />}
            />
            <Route
              path={PATH_HOME}
              element={<HomeRouteComponent featureFlags={featureFlags} />}
            />
            {isFlightsEnabled ? (
              <Route
                path={ROUTE_FLIGHTS}
                element={
                  <Suspense>
                    <FlightsRouteComponent
                      featureFlags={featureFlags}
                      theme={theme}
                      slots={{
                        ...slots,
                        "mobile-checkout-cta-section": FlightCheckoutCtaSection,
                      }}
                    />
                  </Suspense>
                }
              />
            ) : null}
            {isHotelsEnabled ? (
              <Route
                path={PATH_HOTELS_SEARCH}
                element={
                  <Suspense>
                    <LodgingSearchRoute />
                  </Suspense>
                }
              />
            ) : null}
            {isHotelsEnabled ? (
              <Route
                path={ROUTE_HOTELS}
                element={
                  <Suspense>
                    {/* TODO: nubank loading screen */}
                    <LodgingRouteComponent
                      //Do not use client assets, use TenantContext for icons and images
                      featureFlags={{
                        ...featureFlags,
                        showHorizontalScrollListInMobileMap: true,
                      }}
                      slots={{
                        ...slots,
                        "mobile-checkout-cta-section": HotelCheckoutCtaSection,
                      }}
                    />
                  </Suspense>
                }
              />
            ) : null}

            {!tripsMaintenance ? (
              <Route
                path={PATH_TRIPS}
                element={
                  <Suspense>
                    <TripRoute
                      featureFlags={featureFlags}
                      openSupportChat={openSupportChat}
                      theme={theme}
                      slots={slots}
                    />
                  </Suspense>
                }
              />
            ) : null}
            <Route
              path={`${BASE_PATH_DISRUPTION}*`}
              element={
                <Suspense>
                  <DisruptionExerciseRoute
                    openSupportChat={openSupportChat}
                    slots={slots}
                  />
                </Suspense>
              }
            />
          </Routes>
        </Grid>
      )}
    </main>
  );
};

export default Body;
