import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreFlightsComponent } from "@b2bportal/core-types";
import { Loading } from "@b2bportal/core-ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import defaultStyles from "./FlightCardDetailsLoading.module.scss";

export const FlightCardDetailsLoading = () => {
  const styles = useFlightStyles(
    CoreFlightsComponent.FlightCardDetailsLoading,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightCardDetailsLoading
  );

  const { t } = useI18nContext();

  return (
    <div className={block}>
      <Loading className={cn("loading")} />
      <div className={cn("text")}>{t("loadingFlightDetails")}</div>
    </div>
  );
};
