import { useCallback, useState } from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ActionLink,
  ActionLinkKey,
  ButtonWrap,
  IActionLink,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { ReactComponent as Pencil } from "../../../assets/client/pencil.svg";
import { ReactComponent as Mail } from "../../../assets/client/mail.svg";
import { ReactComponent as Headset } from "../../../assets/client/headset.svg";
import { ReactComponent as Cancel } from "../../../assets/client/cancel.svg";
import { ContactSupportModalContent } from "../ContactSupportModalContent";
import "./styles.scss";

interface IMobileActionLinksProps {
  actions: IActionLink[];
}

export const MobileActionLinks = ({ actions }: IMobileActionLinksProps) => {
  const { t } = useI18nContext();

  const [openContactSupportModal, setOpenContactSupportModal] = useState(false);
  const getIcon = useCallback((key: ActionLinkKey) => {
    switch (key) {
      case ActionLinkKey.EXCHANGE:
        return <Pencil className="mobile-action-link-icon exchange" />;
      case ActionLinkKey.CANCELLATION:
        return <Cancel className="mobile-action-link-icon cancellation" />;
      case ActionLinkKey.RESEND_CONFIRMATION:
        return <Mail className="mobile-action-link-icon resend-confirmation" />;
      case ActionLinkKey.HELP:
        return <Headset className="mobile-action-link-icon help" />;
    }
  }, []);

  const fullWidthButtonKeys = [ActionLinkKey.CHECK_IN, ActionLinkKey.HELP];

  const handleCloseContactSupportModal = () =>
    setOpenContactSupportModal(false);

  const handleOnClick = useCallback((action: IActionLink) => {
    switch (action.key) {
      case ActionLinkKey.HELP:
        setOpenContactSupportModal(true);
        return;
      default:
        action.onClick();
        return;
    }
  }, []);

  const handleOpenChat = useCallback(() => {
    handleCloseContactSupportModal();
    actions.find((a) => a.key === ActionLinkKey.HELP).onClick();
  }, [actions]);

  return (
    <div className="mobile-action-link-root">
      <div className="mobile-action-links-container slot">
        {actions.map((action, index) =>
          fullWidthButtonKeys.includes(action.key) ? null : (
            <div
              className="mobile-action-link-button-container"
              style={{ width: `${100 / (actions.length - 1)}%` }}
              key={`${action.key}-link-button-${index}`}
            >
              <ButtonWrap
                key={`mobile-action-link-${index}`}
                className={clsx("mobile-action-link", {
                  "link-separator": index !== actions.length - 1,
                })}
                onClick={action.onClick}
              >
                {getIcon(action.key)}
              </ButtonWrap>
              <Typography
                className={clsx(
                  "mobile-action-link-text",
                  action.linkClassName
                )}
              >
                {action.content}
              </Typography>
            </div>
          )
        )}
      </div>
      <div className="mobile-action-links-container slot help">
        {actions.map((action, index) =>
          fullWidthButtonKeys.includes(action.key) ? (
            <div
              className="mobile-action-link-button-container"
              key={`${action.key}-link-button-${index}`}
            >
              <button
                className={clsx("mobile-action-link", {
                  "link-separator": index !== actions.length - 1,
                })}
                onClick={() => handleOnClick(action)}
              >
                <span className="mobile-action-link-icon">
                  {getIcon(action.key)}
                </span>
                <span className="mobile-action-link-text">
                  {action.content}
                </span>
              </button>
            </div>
          ) : null
        )}
      </div>

      <MobilePopoverCard
        open={openContactSupportModal}
        className="contact-support-modal"
        maxWidth={"sm"}
        scroll="paper"
        fullWidth
        fullScreen
        topRightButton={
          <ActionLink
            className="introduction-modal-close-button"
            content={
              <IconComponent
                ariaLabel={t("modalClose.ariaLabel")}
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            }
            label={t("modalClose.label")}
            onClick={handleCloseContactSupportModal}
          />
        }
        onClose={handleCloseContactSupportModal}
      >
        <ContactSupportModalContent
          onClose={handleCloseContactSupportModal}
          onContactSupport={handleOpenChat}
        />
      </MobilePopoverCard>
    </div>
  );
};
