import { PropsWithChildren, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { EditTripButtons } from "@hopper-b2b/ui";
import { LodgingSearchProps } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import Calendar from "../../../assets/client/calendar.svg";
import CalendarWhite from "../../../assets/client/darkMode/calendar-white.svg";
import LocationMarker from "../../../assets/client/location-marker.svg";
import LocationMarkerWhite from "../../../assets/client/darkMode/location-marker-white.svg";
import { useDarkModePreferred } from "../../../utils/colors";
import { GenericHeader } from "../../GenericHeader";
import { dateStringToNewDate } from "@hopper-b2b/utilities";

export const LodgingSearch = ({
  initialDestination,
  initialCheckinDate,
  initialCheckoutDate,
  openFiltersModal,
  openLocationModal,
  openCalendarModal,
  setOpenLocationModal,
  setOpenCalendarModal,
}: PropsWithChildren<LodgingSearchProps>) => {
  const { t } = useI18nContext();
  const history = useHistory();
  const handleOpenLocationModal = useCallback(() => {
    setOpenLocationModal(true);
  }, [setOpenLocationModal]);

  const handleOpenCalendarModal = useCallback(() => {
    setOpenCalendarModal(true);
  }, [setOpenCalendarModal]);

  const isDarkMode = useDarkModePreferred();

  const locationLabel = useMemo(() => {
    return initialDestination
      ? initialDestination?.label +
          // In some cases, we don't have a subLabel, like when we refresh after a map search
          (initialDestination?.subLabel
            ? `, ${initialDestination.subLabel}`
            : "")
      : t("hotelsOnMap");
  }, [initialDestination, t]);

  const assets = useMemo(
    () => ({
      locationMarker: isDarkMode ? LocationMarkerWhite : LocationMarker,
      calendar: isDarkMode ? CalendarWhite : Calendar,
    }),
    [isDarkMode]
  );

  const handleBack = useCallback(() => history.goBack(), [history]);

  // Temporary redirect to search onClick of shop location pill until new autocomplete component is built since shop modal is creating major scroll issues on Nubank iOS
  const redirectToSearch = useCallback(() => {
    history.replace("/hotels/search");
  }, [history]);

  return (
    <div className="lodging-shop-edit-section">
      <GenericHeader
        title={t("hotelSearchPage.selectHotels")}
        onBack={handleBack}
      />
      <EditTripButtons
        departureDate={dateStringToNewDate(initialCheckinDate)}
        returnDate={dateStringToNewDate(initialCheckoutDate)}
        openCalendarModal={openCalendarModal}
        setOpenCalendarModal={handleOpenCalendarModal}
        openLocationModal={openLocationModal}
        setOpenLocationModal={handleOpenLocationModal}
        assets={assets}
        onFiltersClick={openFiltersModal}
        locationLabel={locationLabel}
        customEditLocation={redirectToSearch}
      />
    </div>
  );
};
