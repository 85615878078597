import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  DisruptionProductType,
  DisruptionTrackingEvents,
} from "@hopper-b2b/types";
import {
  useEnableDisruptionHackerFare,
  useEnableScheduleChange,
} from "@hopper-b2b/utilities";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedScheduleChangeOffer } from "../../../../disruption/actions/actions";
import {
  getScheduleChangeOffer,
  getSelectedScheduleChangeOffer,
  isScheduleChangeValid,
} from "../../../../disruption/reducer";
import { SingleOptionFintechCard } from "../SingleOptionFintechCard";
import { FintechOptionCardProps } from "../types";
import { isReturnFareHackerFareSelector } from "../../../reducer";

export type ScheduleChangeFintechOptionCard = {
  onChange: () => void;
} & FintechOptionCardProps;

export const ScheduleChangeFintechOptionCard = ({ onChange, disabled }) => {
  const { t, formatCurrency } = useI18nContext();

  const dispatch = useDispatch();

  const enableScheduleChange = useEnableScheduleChange();
  const hasValidScheduleChangeOffers = useSelector(
    isScheduleChangeValid(false)
  );

  const isReturnFareHackerFare = useSelector(isReturnFareHackerFareSelector);
  const hideFlightDisruptionHackerFare = useEnableDisruptionHackerFare();
  const isHackerFareEnabledWithDisruption = isReturnFareHackerFare
    ? hideFlightDisruptionHackerFare
    : true; //If HackerFare is disabled/not selected, always return true

  const showScheduleChangeFintechOptionCard =
    enableScheduleChange &&
    hasValidScheduleChangeOffers &&
    isHackerFareEnabledWithDisruption;

  const scheduleChangeOffer = useSelector(getScheduleChangeOffer);
  const selectedScheduleChangeOffer = useSelector(
    getSelectedScheduleChangeOffer
  );

  const scheduleChangeOfferQuote = useMemo(
    () => scheduleChangeOffer?.quotes?.[0],
    [scheduleChangeOffer?.quotes]
  );

  const pricePerPax = useMemo(
    () =>
      formatCurrency(scheduleChangeOfferQuote?.pricePerPax?.fiat?.value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }),
    [formatCurrency, scheduleChangeOfferQuote]
  );

  const onSelect = useCallback(
    (showScheduleChange: boolean) => {
      if (showScheduleChange) {
        trackEvent({
          eventName: DisruptionTrackingEvents.ADDED_DELAY_GUARANTEE,
          properties: scheduleChangeOfferQuote?.trackingProperties,
        });
      }
      onChange();
      dispatch(
        setSelectedScheduleChangeOffer(
          showScheduleChange ? scheduleChangeOffer : undefined
        )
      );
    },
    [scheduleChangeOffer, scheduleChangeOfferQuote, dispatch, onChange]
  );

  const onShow = useCallback((show: boolean) => {
    if (show) {
      trackEvent({
        eventName: DisruptionTrackingEvents.VIEWED_DELAY_GUARANTEE,
        properties: undefined,
      });
    }
  }, []);

  return showScheduleChangeFintechOptionCard ? (
    <SingleOptionFintechCard
      product={DisruptionProductType.ScheduleChange}
      title={t?.("disruptionPurchase.header")}
      description={t?.("fintechSelection.disruptionDescription")}
      checked={!!selectedScheduleChangeOffer}
      setChecked={onSelect}
      iconName="shieldDisruption"
      pricePerPax={pricePerPax}
      icons={["refresh", "ticket"]}
      disabled={disabled}
      onShow={onShow}
    />
  ) : null;
};
