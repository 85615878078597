import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { airDisruptionApi } from "@b2bportal/air-disruption-api";
import {
  DisruptionRebookShopRequest,
  DisruptionRebookShopResponse,
  DisruptionRebookShopResponseEnum,
} from "@b2bportal/air-disruption-api/lib/api";

export const disruptionRebookShop = createAsyncThunk<
  DisruptionRebookShopResponse | undefined,
  DisruptionRebookShopRequest
>(
  "disruption/exercise/rebook/shop",
  async (request: DisruptionRebookShopRequest, thunkAPI) => {
    try {
      const res = await airDisruptionApi(
        axios
      ).apiV0DisruptionExerciseRebookShopPost(request);
      if (
        res.data.DisruptionRebookShopResponse ===
        DisruptionRebookShopResponseEnum.RebookShopSuccess
      ) {
        return res.data;
      } else {
        throw new Error("disruptionRebookShop: " + res.data.reason);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
