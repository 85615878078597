import { useI18nContext } from "@hopper-b2b/i18n";
import { ReactComponent as Insurance } from "../../../assets/client/uv/insurance-with-background.svg";
import styles from "./styles.module.scss";
import { useExperiment } from "@hopper-b2b/experiments";
import { EXPERIMENTS } from "../../../App";

const FlightConfirmationTravelInsuranceIncluded = () => {
  const { t } = useI18nContext();

  const enableTravelInsurance = useExperiment(
    EXPERIMENTS.NUBANK_TRAVEL_INSURANCE
  );

  return enableTravelInsurance ? (
    <div className={styles.FlightConfirmationTravelInsuranceIncluded}>
      <div className="content">
        <h4 className="title">{t("ultravioletaTravelInsurance.title")}</h4>
        <p>{t("ultravioletaTravelInsurance.rightToInsuranceEmail")}</p>
      </div>
      <Insurance className="icon" />
    </div>
  ) : null;
};

export default FlightConfirmationTravelInsuranceIncluded;
