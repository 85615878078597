import { DisruptionExerciseState } from "./slice";
import { DisruptionState } from "../../../store";
import {
  DisruptionContractStatusEnum,
  DisruptionProtectionAncillaryContract,
} from "@b2bportal/air-booking-api";

export const getDisruptionExerciseProgress = (state: DisruptionState) => {
  return state.disruption.disruptionExerciseState.disruptionExerciseProgress;
};

export const getDisruptionExerciseToken = (state: DisruptionExerciseState) =>
  state.token;

export const isDisruptionProtectionExercisable = (
  contract: DisruptionProtectionAncillaryContract
) => {
  const isEligible =
    contract.status.DisruptionContractStatus ===
    DisruptionContractStatusEnum.AnyExerciseEligible;
  const isEligibleToBookReturn =
    contract.status.DisruptionContractStatus ===
    DisruptionContractStatusEnum.RebookOnlyEligible;
  return isEligible || isEligibleToBookReturn;
};
