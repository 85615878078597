import {
  useDisruptionStyles,
  useModuleBEM,
  useTenantImages,
} from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseLoading.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { PageLoadingScreen } from "@b2bportal/core-ui";

export interface DisruptionExerciseLoadingProps {
  isLoading: boolean;
  message?: string;
}

export const DisruptionExerciseLoading = ({
  isLoading,
  message,
}: DisruptionExerciseLoadingProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseLoading;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t } = useI18nContext();
  const images = useTenantImages();

  return (
    <div className={cn("overlay", { loading: isLoading })}>
      <PageLoadingScreen
        isLoading={isLoading}
        backgroundImage={images?.flightLoadingImage}
        className={cn("loadingScreen")}
        text={
          message != null
            ? message
            : t(
                "core-disruption.disruptionUniversalExercise.defaultLoadingMessage"
              )
        }
      />
    </div>
  );
};

export default DisruptionExerciseLoading;
