import { useFintechUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFintechUiComponents,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import clsx from "clsx";
import { RadioGroup } from "@components/ui";
import { SelectionOfferItemProp } from "@b2bportal/core-fintech-ui";
import { useDeviceType } from "@b2bportal/core-utilities";
import defaultStyles from "./FintechProductOptionsSection.module.scss";
import { useI18nContext } from "@hopper-b2b/i18n";

export interface FintechProductOptionsSectionProps
  extends PlatformComponentProps {
  selectionOffers: {
    options: SelectionOfferItemProp[];
  };
  showTitle?: boolean;
  setShowSelectionRow: (isSelected: boolean) => void;
  radioButtonPosition: "start" | "end";
}

export const FintechProductOptionsSection = ({
  selectionOffers,
  showTitle,
  setShowSelectionRow,
  className,
  radioButtonPosition,
}: FintechProductOptionsSectionProps) => {
  const COMPONENT_KEY = CoreFintechUiComponents.FintechProductOptionsSection;
  const styles = useFintechUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t } = useI18nContext();

  const { isDesktopAndUp } = useDeviceType();

  const title = showTitle ? (
    <span className={cn("title")}>
      {t("core-disruption.optionsSectionTitle")}
    </span>
  ) : (
    <></>
  );

  const displayRadioButtonStart = radioButtonPosition === "start";
  const displayRadioButtonEnd = radioButtonPosition === "end";

  return (
    <div className={clsx(block, className)}>
      {title}

      {selectionOffers.options.map((offer, index) => {
        const onClick = () => {
          offer.onClick();
          if (isDesktopAndUp) setShowSelectionRow(true);
        };

        return (
          <div
            onClick={onClick}
            key={index}
            className={cn("option-container", {
              selected: offer.selected,
              "display-radio-button-end": displayRadioButtonEnd,
            })}
          >
            {displayRadioButtonStart ? (
              <RadioGroup
                options={[{ label: undefined, value: String(offer.selected) }]}
                selectedOption={String(true)}
                onSelect={onClick}
                onSelectValue={onClick}
                aria-label={t("fintechProduct")}
              />
            ) : null}

            <div
              className={cn("option-content", {
                "display-radio-button-end": displayRadioButtonEnd,
              })}
            >
              <span className={cn("option-title")}>{offer.title}</span>
              <span className={cn("option-description")}>
                {offer.description}
              </span>
            </div>
            {displayRadioButtonEnd ? (
              <RadioGroup
                options={[{ label: undefined, value: String(offer.selected) }]}
                selectedOption={String(true)}
                onSelect={onClick}
                onSelectValue={onClick}
                aria-label={t("fintechProduct")}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
