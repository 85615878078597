import defaultStyles from "./DisruptionExerciseCheckbox.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import { Checkbox } from "@components/ui";

export interface DisruptionExerciseCheckboxProps {
  onChange?: (boolean) => void;
  label: string;
  selected: boolean;
}
// TODO: extend the functionality of the Checkbox component to accept a label
export const DisruptionExerciseCheckbox = ({
  onChange,
  label,
  selected,
}: DisruptionExerciseCheckboxProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseCheckbox;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <div className={block}>
      <div className={cn("container")}>
        <Checkbox checked={selected} onCheckedChange={onChange} />
        <span className={cn("lable")}>{label}</span>
      </div>
    </div>
  );
};

export default DisruptionExerciseCheckbox;
