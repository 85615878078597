import { createSelector } from "reselect";
import { FlightShopStep } from "../types";
import { IStoreState } from "../../../../reducers/types";

export const flightShopProgressSelector = (state: IStoreState) =>
  state.flightShop.progress;

export const selectedTripPriceFreezeOfferSelector = (state: IStoreState) =>
  state.flightShop.selectedTripPriceFreezeOffer;

export const isInChooseDepartureStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.ChooseDeparture
);
export const isInChooseReturnStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.ChooseReturn
);
export const isInReviewStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.ReviewItinerary
);
export const isInPredictionStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.PricePrediction
);
export const isInFareDetailsStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.FareDetails
);
export const isInFintechSelectionStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.FintechSelection
);
export const isInCfarOffersStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.CfarOffers
);
export const isInScheduleChangeStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.ScheduleChange
);
export const isInMissedConnectionStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.MissedConnection
);
export const isInDisruptionProtectionOfferStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.DisruptionProtectionOffer
);
export const isInChfarOfferStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.ChfarOffer
);

export const renderLoadingStepsSelector = (state: IStoreState) =>
  state.flightShop.renderLoadingSteps;

export const isInWalletOfferStepSelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress: FlightShopStep) =>
    flightShopProgress === FlightShopStep.WalletOffers
);
