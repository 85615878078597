import { useDeviceType } from "@b2bportal/core-utilities";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./styles.module.scss";

interface DisruptionExercisePageContentProps {
  topContent?: JSX.Element | JSX.Element[];
  leftContent: JSX.Element | JSX.Element[];
  rightConent?: JSX.Element | JSX.Element[];
}

export const DisruptionExercisePageContent = (
  props: DisruptionExercisePageContentProps
) => {
  const { isDesktopAndUp } = useDeviceType();
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExercisePageContent;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const leftConent = (
    <div className={cn("leftScreenContent")}>
      <div className={cn("leftContainer")}>{props.leftContent}</div>
    </div>
  );

  const desktopContent = (
    <div className={cn("desktopContent")}>
      {props.topContent && props.topContent}
      <div className={cn("rowContent")}>
        {leftConent}
        {props.rightConent && (
          <div className={cn("rightScreenContent")}>{props.rightConent}</div>
        )}
      </div>
    </div>
  );

  const mobileContent = (
    <div className={cn("mobileContent")}>
      {props.topContent && props.topContent}
      {leftConent}
      {props.rightConent && (
        <div className={cn("rightScreenContent")}>{props.rightConent}</div>
      )}
    </div>
  );

  return (
    <div
      className={cn("disruptionExercisePageContainer", {
        mobile: !isDesktopAndUp,
      })}
    >
      {isDesktopAndUp ? desktopContent : mobileContent}
    </div>
  );
};

export default DisruptionExercisePageContent;
