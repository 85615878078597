import { useDispatch } from "react-redux";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySegment,
} from "@b2bportal/air-booking-api";
import { useCallback, useEffect, useState } from "react";
import defaultStyles from "./DisruptionExerciseRebookOnboardingPage.module.scss";
import { useDeviceType } from "@b2bportal/core-utilities";
import {
  DisruptionRebookPlanResponse,
  DisruptionRebookPlanResponseEnum,
  DisruptionRefundPlanRequest,
  RebookPlanSuccess,
  TripSlice,
} from "@b2bportal/air-disruption-api";
import {
  DisruptionExerciseProgress,
  resetDisruptionExerciseState,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import {
  DisruptionExerciseInformationCard,
  DisruptionExerciseMoreInfo,
  DisruptionExercisePage,
  DisruptionExercisePageContent,
  DisruptionExerciseSummaryCard,
} from "@b2bportal/core-disruption";
import { DisruptionExercisePageWidth } from "../../components/DisruptionExercisePage/DisruptionExercisePage";
import { unwrapResult } from "@reduxjs/toolkit";
import { disruptionRebookPlan } from "../../../../features/exercise/thunks/disruptionRebookPlan";
import { AppDispatch, CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import { DisruptionExerciseMoreInfoType } from "../../components/DisruptionExerciseMoreInfo/DisruptionExerciseMoreInfo";

export interface DisruptionExerciseRebookOnboardingPageProps {
  disruptionProductName: string;
  disruptionProductLogo: string;
  disruptedFlight: BookedFlightItineraryWithDepartureTime;
  rebookingLimit?: string;
  contractId?: string;
  bookingReturn: boolean;
  termsLink: string;
  displayExternalLinkAsModal: boolean;
  delayHours: string;
  hoursString: string;
  onFlightSelectContinue: (props: {
    origin: string;
    destination: string;
    departure: string;
    tripSlice?: TripSlice;
  }) => void;
  onContactSupport: () => void;
  handleRefundClick: () => void;
  setRebookPlanResponse: (DisruptionRebookPlanResponse) => void;
  setSelectedSlice: (slice: {
    segments: Array<FlightItinerarySegment>;
  }) => void;
}

export const DisruptionExerciseRebookOnboardingPage = ({
  disruptionProductName,
  disruptionProductLogo,
  disruptedFlight,
  rebookingLimit,
  contractId,
  bookingReturn = false,
  termsLink,
  displayExternalLinkAsModal,
  delayHours,
  hoursString,
  onFlightSelectContinue,
  onContactSupport,
  handleRefundClick,
  setRebookPlanResponse,
  setSelectedSlice,
}: DisruptionExerciseRebookOnboardingPageProps) => {
  const { t } = useI18nContext();
  const { isDesktopAndUp } = useDeviceType();
  const [openBagsLearnMore, setOpenBagsLearnMore] = useState(false);
  const [openReturnLearnMore, setOpenReturnLearnMore] = useState(false);
  const [rebookPlanError, setRebookPlanError] = useState<boolean>(false);
  const [openMoreHelp, setOpenMoreHelp] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false);
  const TRANS_PATH =
    "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage";
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseNotEligiblePage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const handlePlanResponse = useCallback(
    (response: RebookPlanSuccess) => {
      setRebookPlanResponse(response);
      // if more than one slice, the user need to select which slice to rebook
      if (response.slices.length > 1) {
        dispatch(
          setDisruptionExerciseProgress(DisruptionExerciseProgress.FlightSelect)
        );
      } else if (response.slices.length === 1) {
        const flightSlice = response.slices[0];
        setSelectedSlice(flightSlice);
        if (flightSlice.segments.length === 1) {
          // if 1 segment (no connections) -> search flights
          const origin = flightSlice.segments[0].origin.locationCode;
          const departure =
            flightSlice.segments[0].updatedDeparture ??
            flightSlice.segments[0].scheduledDeparture;
          const destination = flightSlice.segments[0].destination.locationCode;

          onFlightSelectContinue({
            origin: origin,
            destination: destination,
            departure: departure,
            tripSlice: undefined,
          });

          dispatch(
            setDisruptionExerciseProgress(
              DisruptionExerciseProgress.FlightSearch
            )
          );
        } else if (flightSlice.segments.length > 1) {
          // if more than 1 segment (connections) -> select segment
          dispatch(
            setDisruptionExerciseProgress(
              DisruptionExerciseProgress.SegmentSelect
            )
          );
        } else {
          setOpenMoreHelp(true);
        }
      } else {
        setOpenMoreHelp(true);
      }
    },
    [dispatch, setRebookPlanResponse, setSelectedSlice, onFlightSelectContinue]
  );

  const fetchDisruptionRebookPlan = useCallback(() => {
    if (contractId) {
      setLoading(true);
      const req: DisruptionRefundPlanRequest = {
        contractId,
        itineraryId: disruptedFlight.bookedItinerary.id,
      };

      return dispatch(disruptionRebookPlan(req))
        .then((payload) => {
          const response = unwrapResult(payload);
          setLoading(false);
          if (
            response?.DisruptionRebookPlanResponse ===
            DisruptionRebookPlanResponseEnum.RebookPlanSuccess
          ) {
            handlePlanResponse(response);
          } else {
            setRebookPlanError(true);
            setLoading(false);
          }
        })
        .catch((e) => {
          setRebookPlanError(true);
          setLoading(false);
        });
    }
  }, [contractId, dispatch, disruptedFlight]);

  const handleCta = () => {
    fetchDisruptionRebookPlan();
  };

  const handleGoBack = () => {
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.LandingPage)
    );
  };

  const onCloseBagsLearnMore = useCallback(
    () => setOpenBagsLearnMore(false),
    [setOpenBagsLearnMore]
  );

  const onCloseReturnLearnMore = useCallback(
    () => setOpenReturnLearnMore(false),
    [setOpenReturnLearnMore]
  );

  const onOpenReturnLearnMore = useCallback(
    () => setOpenReturnLearnMore(true),
    [setOpenReturnLearnMore]
  );

  const onOpenBagsLearnMore = useCallback(
    () => setOpenBagsLearnMore(true),
    [setOpenBagsLearnMore]
  );

  const onCloseMoreHelp = useCallback(
    () => setOpenMoreHelp(false),
    [setOpenMoreHelp]
  );

  const bullets = [
    {
      text: t(`${TRANS_PATH}.importantInfo.bulletOne`, {
        maxPerPax: rebookingLimit,
      }),
      index: 1,
    },
    {
      text: t(`${TRANS_PATH}.importantInfo.bulletTwo`),
      index: 2,
    },
    {
      text: t(`${TRANS_PATH}.importantInfo.bulletThree`),
      learnMore: {
        title: t("core-disruption.disruptionUniversalExercise.learnMore"),
        onClick: onOpenBagsLearnMore,
      },
      index: 3,
    },
    {
      text: t(`${TRANS_PATH}.importantInfo.bulletFourWithRebookLimit`, {
        rebookLimit: rebookingLimit,
      }),
      learnMore: {
        title: t("core-disruption.disruptionUniversalExercise.learnMore"),
        onClick: onOpenReturnLearnMore,
      },
      index: 4,
    },
  ];

  const bagsLearnMore = (
    <DisruptionExerciseMoreInfo
      open={openBagsLearnMore}
      onClose={onCloseBagsLearnMore}
      type={DisruptionExerciseMoreInfoType.Baggage}
      onSupportClick={onContactSupport}
      handleRefundClick={handleRefundClick}
      rebookingLimit={rebookingLimit}
      disruptionProductName={disruptionProductName}
      delayHours={delayHours}
      numberOfHours={hoursString}
      termsLink={termsLink}
    ></DisruptionExerciseMoreInfo>
  );

  const returnLearnMore = (
    <DisruptionExerciseMoreInfo
      open={openReturnLearnMore}
      onClose={onCloseReturnLearnMore}
      type={DisruptionExerciseMoreInfoType.Return}
      onSupportClick={onContactSupport}
      handleRefundClick={handleRefundClick}
      rebookingLimit={rebookingLimit}
      disruptionProductName={disruptionProductName}
      delayHours={delayHours}
      numberOfHours={hoursString}
      termsLink={termsLink}
    ></DisruptionExerciseMoreInfo>
  );

  const moreHelpPopup = (
    <DisruptionExerciseMoreInfo
      open={openMoreHelp}
      onClose={onCloseMoreHelp}
      type={DisruptionExerciseMoreInfoType.MoreHelp}
      onSupportClick={onContactSupport}
      handleRefundClick={handleRefundClick}
      rebookingLimit={rebookingLimit}
      disruptionProductName={disruptionProductName}
      delayHours={delayHours}
      numberOfHours={hoursString}
      termsLink={termsLink}
    ></DisruptionExerciseMoreInfo>
  );

  useEffect(() => {
    if (bookingReturn) {
      fetchDisruptionRebookPlan();
    }
  }, [fetchDisruptionRebookPlan, bookingReturn]);

  return (
    <DisruptionExercisePage
      className={cn("DisruptionExerciseRebookOnboardingPage")}
      pageWidth={DisruptionExercisePageWidth.Regular}
      title={disruptionProductName}
      disruptionProductLogo={disruptionProductLogo}
      onBack={handleGoBack}
      openErrorModal={rebookPlanError}
      onCloseErrorModal={() => {
        setRebookPlanError(false);
        dispatch(resetDisruptionExerciseState());
      }}
      onSupportClick={onContactSupport}
      handleRefundClick={handleRefundClick}
      submitButtonTitle={t(
        "core-disruption.disruptionUniversalExercise.continue"
      )}
      submitButtonAction={handleCta}
      submitButtonDisabled={false}
      isLoading={loading}
      loadingMessage={t(`${TRANS_PATH}.loadingMessage`)}
      delayHours={delayHours}
      numberOfHours={hoursString}
      termsLink={termsLink}
      content={
        <DisruptionExercisePageContent
          leftContent={[
            <DisruptionExerciseSummaryCard
              title={t(`${TRANS_PATH}.title`)}
              description={t(`${TRANS_PATH}.subtitle`)}
            ></DisruptionExerciseSummaryCard>,
            !isDesktopAndUp ? <hr></hr> : <></>,
            <DisruptionExerciseInformationCard
              onSupportClick={onContactSupport}
              bulletPoints={bullets}
              onRefundClick={handleRefundClick}
            ></DisruptionExerciseInformationCard>,
            bagsLearnMore,
            returnLearnMore,
            moreHelpPopup,
          ]}
        />
      }
    ></DisruptionExercisePage>
  );
};

export default DisruptionExerciseRebookOnboardingPage;
