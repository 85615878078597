import {
  en_defaultTranslations,
  en_nubankProvidedTranslations,
  en_nubankTranslations,
  pt_defaultTranslations,
  pt_nubankProvidedTranslations,
  pt_nubankTranslations,
} from "@hopper-b2b/i18n";
import { Translation, TranslationLanguage } from "@hopper-b2b/types";
import { merge } from "lodash-es";

const translation: Translation = {
  [TranslationLanguage.en]: merge(
    en_defaultTranslations,
    en_nubankTranslations,
    en_nubankProvidedTranslations
  ),
  [TranslationLanguage.pt]: merge(
    pt_defaultTranslations,
    pt_nubankTranslations,
    pt_nubankProvidedTranslations
  ),
};

export default translation;
