import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseBulletPoint.module.scss";

export interface DisruptionExerciseBulletPointProps {
  title?: string;
  text: string;
  learnMore?: { title: string; onClick: () => void };
  index: number;
}

export const DisruptionExerciseBulletPoint = (
  props: DisruptionExerciseBulletPointProps
) => {
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseBulletPoint;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <div className={cn("disruptionBulletRoot")}>
      <div className={cn("bulletPointContainer")} key={props.index}>
        <div className={cn("bulletPointIcon")}>
          <span className={cn("bulletPointNumber")}>{props.index}</span>
        </div>
        <div className={cn("bulletPointTextContainer")}>
          {props.title == null ? null : (
            <span className={cn("bulletPointTitle")}>{props.title}</span>
          )}
          <span
            className={cn("bulletPointText")}
            dangerouslySetInnerHTML={{ __html: props.text }}
          />
          {props.learnMore == null ? null : (
            <div onClick={props.learnMore.onClick}>
              <span className={cn("bulletPointAction")}>
                {props.learnMore.title}
              </span>
            </div>
          )}
        </div>
      </div>
      <hr></hr>
    </div>
  );
};

export default DisruptionExerciseBulletPoint;
