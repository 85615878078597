import { connect, ConnectedProps } from "react-redux";
import { setRerunPrediction } from "../../../../../shop/actions/actions";
import { IStoreState } from "../../../../../../reducers/types";
import {
  allFlightShopFilterSelector,
  getViewedForecastPropertiesForV2,
} from "../../../../../shop/reducer/selectors";
import { FlightShopSearchFilter } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    flightShopFilters: allFlightShopFilterSelector(state),
    viewedForecastProperties: getViewedForecastPropertiesForV2(state),
  };
};

const mapDispatchToProps = {
  setRerunPrediction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopSearchFilterConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopSearchFilter = connector(
  FlightShopSearchFilter
);
