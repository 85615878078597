import { I18nMarkup } from "@hopper-b2b/i18n";
import { useGetNubankMaxInstallments } from "@hopper-b2b/utilities";
import InstallmentsIcon from "../../../assets/client/installments.svg";
import InstallmentsIconDark from "../../../assets/client/darkMode/installments.svg";
import { useDarkModePreferred } from "../../../utils/colors";
import { FintechBanner } from "../../FintechBanner";
import styles from "./styles.module.scss";

const InstallmentsBanner = () => {
  const isDarkMode = useDarkModePreferred();
  const nubankMaxInstallments = useGetNubankMaxInstallments();

  return (
    <div className={styles.installmentsBanner}>
      <FintechBanner
        fintechIcon={isDarkMode ? InstallmentsIconDark : InstallmentsIcon}
        hideBrand
        title={
          <I18nMarkup
            className="default-text"
            tKey={"interestFreeInstallments"}
            replacements={{ count: parseInt(nubankMaxInstallments, 10) }}
          />
        }
      />
    </div>
  );
};

export default InstallmentsBanner;
