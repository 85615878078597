import { ExperimentVariant } from "@hopper-b2b/types";
import { useExperiments } from "./useExperiments";

/**
 * @description Returns variant for an experiment
 * @param {string} expName
 * @param {string} [variant=ExperimentVariant.Available]
 */
export const useGetExperimentVariant = (expName: string) => {
  const exps = useExperiments();
  const expVariant = exps?.experiments?.[expName];

  return expVariant ?? ExperimentVariant.Control;
};
