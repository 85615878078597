import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RouteComponentProps } from "react-router-dom";
import clsx from "clsx";

import { useI18nContext } from "@hopper-b2b/i18n";
import {
  HotelItineraryState,
  LodgingAddressExact,
  MyTripsFilter,
  MyTripsModalTypes,
  PortalItineraryStatusEnum,
  TagColors,
  TagInfo,
} from "@hopper-b2b/types";
import {
  ActionLinkKey,
  Divider,
  IActionLink,
  Icon,
  IconName,
  PassengerSummaryRow,
  Slot,
  UberPriceSummaryRow,
} from "@hopper-b2b/ui";
import {
  getGoogleStaticMapSrc,
  getHotelReservationDetails,
  getTwoLineAddresses,
  useEnableChfar,
} from "@hopper-b2b/utilities";
import { addHotelType } from "../../../../../TripsList/components/HotelCard/components/DesktopHotelCard/component";
import { MobileHotelItineraryDetailsConnectorProps } from "./container";

import styles from "./styles.module.scss";
import { ClientContext } from "../../../../../..//App";
import { MobileActionLinks } from "../../../../../TripsList/components/ItineraryList/components/MobileActionLinks";

import { ChatPropertiesType } from "@b2bportal/chat-api";

export interface IMobileHotelItineraryDetailsProps
  extends RouteComponentProps,
    MobileHotelItineraryDetailsConnectorProps {
  onSupportClick?: (
    productId?: string,
    productType?: ChatPropertiesType,
    requestType?: string
  ) => void;
}

export const MobileHotelItineraryDetails = ({
  hotel,
  history,
  setOpenModal,
  setSelectedHotel,
  populateTripQueryParams,
  tripsFilter,
  onSupportClick,
}: IMobileHotelItineraryDetailsProps) => {
  const refundPopoverRef = useRef<HTMLButtonElement>(null);
  const [refundPopoverOpen, setRefundPopoverOpen] = useState(false);
  const { t, formatFiatCurrency, language: locale, brand } = useI18nContext();
  const clientContext = useContext(ClientContext);

  const coordinates = useMemo(() => {
    const lat = hotel?.reservation?.lodgingData?.location?.coordinates?.lat;
    const lon = hotel?.reservation?.lodgingData?.location?.coordinates?.lon;
    return `${lat},${lon}`;
  }, [hotel]);

  const resState = useMemo(
    () => hotel.reservation?.state?.ReservationState,
    [hotel.reservation?.state?.ReservationState]
  );
  const isCancelled = useMemo(
    () =>
      [
        HotelItineraryState.Canceled,
        HotelItineraryState.CancelationFailure,
        HotelItineraryState.Canceled,
        "Cancelled",
      ].includes(resState),
    [resState]
  );

  const onOpenModal = useCallback(
    (type: MyTripsModalTypes) =>
      setOpenModal({
        type,
        selectedItinerary: addHotelType(hotel),
      }),
    [hotel, setOpenModal]
  );

  const { reservation } = hotel;
  // const { cfar } = ancillaries;

  const { startDate, endDate } = getHotelReservationDetails(
    hotel,
    locale,
    brand
  );

  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;

  const actions: IActionLink[] = [];
  const topActions: IActionLink[] = []; // render at the top of the page

  const closeRefundPopover = () => setRefundPopoverOpen(false);
  const openRefundPopover = () => setRefundPopoverOpen(true);

  const isChfarEnabled = useEnableChfar();

  if (!isCancelled && !isPastTrips) {
    if (isChfarEnabled) {
      actions.push({
        content: t("hotelTripsLinks.changeReservation"),
        onClick: () => onOpenModal(MyTripsModalTypes.ChangeHotel),
        key: ActionLinkKey.EXCHANGE,
      });
    }
    actions.push({
      content: t("hotelTripsLinks.cancelReservation"),
      onClick: () => onOpenModal(MyTripsModalTypes.CancelHotel),
      key: ActionLinkKey.CANCELLATION,
    });
  } else if (isCancelled) {
    topActions.push({
      content: (
        <>
          {t("howRefundsWorkTitle")}
          <Icon className="info-icon" name={IconName.InfoCircle} />
        </>
      ),
      linkClassName: "how-refunds-work-link",
      linkRef: refundPopoverRef,
      onClick: openRefundPopover,
    });
  }

  actions.push(
    {
      content: t("tripReviewLinks.resendEmail"),
      onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
      key: ActionLinkKey.RESEND_CONFIRMATION,
    },
    {
      content: t("tripReviewLinks.contactSupport"),
      onClick: () =>
        onSupportClick(
          hotel.reservation.reservationBookingId,
          ChatPropertiesType.Hotel,
          "General"
        ),
      key: ActionLinkKey.HELP,
    }
  );

  const hotelAddress = useMemo(
    () => reservation.lodgingData?.address as LodgingAddressExact | undefined,
    [reservation.lodgingData?.address]
  );

  const hotelStreet = getTwoLineAddresses(hotelAddress).line1;

  const goBack = useCallback(() => {
    history.goBack();
    setSelectedHotel(null);
  }, [history, setSelectedHotel]);

  const openPaymentBreakdownModal = useCallback(() => {
    onOpenModal(MyTripsModalTypes.PaymentModal);
  }, [onOpenModal]);

  useEffect(() => {
    if (hotel?.reservation) {
      populateTripQueryParams(history, {
        tripId: hotel?.reservation?.reservationBookingId,
      });
    }
  }, [history, hotel?.reservation, populateTripQueryParams]);

  const titleTag = useMemo((): TagInfo | undefined => {
    if (hotel?.reservation.state.ReservationState) {
      switch (hotel.reservation.state.ReservationState) {
        case PortalItineraryStatusEnum.Confirmed:
          return {
            label: t("confirmed"),
            type: TagColors.GREEN,
          };
        case PortalItineraryStatusEnum.Pending:
          return {
            label: t("pending"),
            type: TagColors.GREY,
          };
        case PortalItineraryStatusEnum.Canceled:
          return {
            label: t("cancelled"),
            type: TagColors.RED,
          };
        case PortalItineraryStatusEnum.Modified:
          return {
            label: t("itineraryModified"),
            type: TagColors.YELLOW,
          };
        default:
          return undefined;
      }
    }
    return undefined;
  }, [hotel.reservation.state.ReservationState, t]);

  if (!hotel) {
    return null;
  }

  // TODO: Move marker color to branding
  const markerColor = "0x2A1050";

  const roomTypeInfoCopy = hotel.reservation.roomInfo.maxAdults
    ? `${t("roomCapacityGuest", {
        count: hotel.reservation.roomInfo.maxAdults,
      })}, ${hotel.reservation.roomInfo.beds.description}`
    : hotel.reservation.roomInfo.beds.description;

  return (
    <div className={styles.mobileHotelItineraryDetails}>
      <div className="mobile-trip-details-header">
        <div className="mobile-trip-details-header-button" onClick={goBack}>
          <img src={clientContext.assets.back} className="back-arrow" alt="" />
        </div>
        <div className="mobile-trip-details-header-title">
          {t("tripSummary")}
        </div>
      </div>
      <div className="mobile-trip-details-icon-container">
        <img
          alt=""
          src={clientContext.assets.hotelTrips}
          className="mobile-trip-details-icon"
        />
      </div>
      <Slot
        id="trip-details-summary"
        hotelDetails={hotel.reservation}
        title={hotel.reservation.lodgingData.name}
        titleTag={titleTag}
        confirmationNumbers={[
          {},
          { locator: hotel?.reservation.reservationId },
        ]}
      />

      <div className="mobile-location-details-container">
        <Divider className="divider" />
        <div className="hotel-dates">
          <div className="check-in">
            <p className="date-label">{t("checkIn")}</p>
            <p className="date">{startDate}</p>
          </div>
          <Divider className="divider" />
          <div className="check-out">
            <p className="date-label">{t("checkOut")}</p>
            <p className="date">{endDate}</p>
          </div>
          <Divider className="divider" />
        </div>

        <div className="mobile-hotel-address">
          <p className="address">{t("address")}</p>
          <p className="hotel-address-line">{hotelStreet}</p>
          <p className="address-details">{`${
            reservation.lodgingData?.city
              ? `${reservation.lodgingData?.city}, `
              : ""
          }${reservation.lodgingData?.country || ""}`}</p>
          {reservation.lodgingData.phone ? (
            <p className="hotel-phone">{reservation.lodgingData.phone}</p>
          ) : null}
        </div>
        {coordinates ? (
          <img
            className="hotel-static-map"
            src={getGoogleStaticMapSrc(
              coordinates,
              "16",
              "600x300",
              "2",
              true,
              markerColor
            )}
            alt=""
          />
        ) : null}

        <Divider className="divider" />
        <div className="mobile-trip-room-type">
          <p className="room-type">{t("hotelCard.roomType")}</p>
          <p className="room-type-info">{roomTypeInfoCopy}</p>
        </div>

        <Divider className="divider" />
        <div
          className={clsx("mobile-trip-details-passenger-info-container", {
            "no-padding": !hotel.paymentBreakdown,
          })}
        >
          <PassengerSummaryRow
            passengers={hotel.reservation.guests}
            iconSrc={clientContext?.assets?.passenger}
            review
          />
        </div>
        <Divider className="divider" />
        <div className="mobile-trip-details-total-price">
          <UberPriceSummaryRow
            priceString={formatFiatCurrency(hotel.productTotal.fiat, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            iconSrc={clientContext?.assets?.card}
            onClick={openPaymentBreakdownModal}
            roundTrip
            customExpandIcon={
              <img src={clientContext?.assets?.chevron} alt="" />
            }
          />
        </div>
        <Divider className="divider" />
      </div>
      <div className="mobile-trip-details-hotel">
        <div className="mobile-trip-details-manage">
          <Slot
            id="trip-details-links"
            actions={actions}
            component={<MobileActionLinks actions={actions} />}
          />
        </div>
      </div>
      {/* {hasAddOn ? (
        <SummaryCard className="mobile-trip-card add-ons-summary">
          <AddOnsSummary
            addOns={(() => {
              const addOnItems: AddOn[] = [];
              if (hasCfar) {
                addOnItems.push({
                  expiryDate: cfar?.expired ?? "",
                  expiredTz: cfar?.expiredTz ?? "America/New_York", // TODO: Fall back to EST / EDT for now
                  onClick: () => onOpenModal(MyTripsModalTypes.CfarHotel),
                  isEnabled: !hasCfarExpired(cfar) && !isCancelled,
                  type: AddOnEnum.Cfar,
                });
              }
              return addOnItems;
            })()}
          />
        </SummaryCard>
      ) : null} */}
      {/* <SummaryCard
        className="mobile-payment-summary mobile-trip-card"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={openPaymentBreakdownModal}
            icon={faChevronRight}
          />
        }
      >
        <PaymentSummary
          className="mobile-hotel-details-payment-summary"
          tripTotalAmount={formatFiatCurrency(calculatedTripTotal)}
          paymentLineItems={paymentLineItems}
        />
      </SummaryCard>
      <SummaryCard className="mobile-trip-card confirmation-summary">
        <ConfirmationSummary confirmationCode={confirmationCode} />
      </SummaryCard> */}
      {/* <MobileActionLinks actions={actions} /> */}
      {/* {isCancelled ? (
        <Popover
          anchorEl={refundPopoverRef.current}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          className="how-refunds-work-popover"
          onClose={closeRefundPopover}
          open={refundPopoverOpen}
        >
          <Typography className="subtitle">
            {t("howRefundsWorkSubtitle")}
          </Typography>
        </Popover>
      ) : null} */}
    </div>
  );
};
