import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import defaultStyles from "./TextWithExternalLink.module.scss";
import { Dialog, DialogTrigger, IframeDialogContent } from "@b2bportal/core-ui";
import { Trans } from "@hopper-b2b/i18n";

export interface TextWithExternalLinkProps {
  transI18nKey: string;
  transValues: { [key: string]: string };
  transComponents: JSX.Element[];
  linkUrl: string;
  linkClassName: string;
  displayInModal?: boolean;
}

export const TextWithExternalLink = ({
  transI18nKey,
  transValues,
  transComponents,
  linkUrl,
  linkClassName,
  displayInModal,
}: TextWithExternalLinkProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.TextWithExternalLink;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <Dialog>
      <Trans
        i18nKey={transI18nKey}
        values={transValues}
        components={[
          ...transComponents,
          displayInModal ? (
            <DialogTrigger className={linkClassName}></DialogTrigger>
          ) : (
            <a href={linkUrl} target="_blank" className={linkClassName} />
          ),
        ]}
      />
      <IframeDialogContent showHeaderBottomBorder={false}>
        <iframe src={linkUrl} />
      </IframeDialogContent>
    </Dialog>
  );
};

export default TextWithExternalLink;
