import { ClientName } from "@hopper-b2b/types";
import { useMemo } from "react";

interface TenantEnvConfig {
  /**
   * @deprecated use `kustomerApiKey` instead
   */
  KUSTOMER_API_KEY?: string;
  /**
   * @deprecated use `kustomerBrandId` instead
   */
  KUSTOMER_BRAND_ID?: string;
  /**
   * @deprecated use `kustomerChatSupport` instead
   */
  KUSTOMER_CHAT_SUPPORT?: string;
  kustomerApiKey?: string;
  kustomerBrandId?: string;
  kustomerChatSupport?: boolean;
}

export function useKustomerConfig<T extends TenantEnvConfig>(
  tenant: ClientName,
  tenantEnvConfig: T
) {
  const {
    KUSTOMER_API_KEY,
    KUSTOMER_BRAND_ID,
    KUSTOMER_CHAT_SUPPORT,
    kustomerApiKey,
    kustomerBrandId,
    kustomerChatSupport,
  } = tenantEnvConfig;
  const apiKey = KUSTOMER_API_KEY || kustomerApiKey;
  const brandId = KUSTOMER_BRAND_ID || kustomerBrandId;
  const chatSupportEnabled = KUSTOMER_CHAT_SUPPORT || kustomerChatSupport;

  return useMemo(
    () => ({
      apiKey,
      brandId,
      enabled: chatSupportEnabled === "enabled",
      tenant,
    }),
    [apiKey, brandId, chatSupportEnabled, tenant]
  );
}
