import styles from "./DisruptionNotEligible.module.scss";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { DisruptionInfoBanner } from "@components/disruption";

export const DisruptionNotEligible = () => {
  const { t } = useI18nContext();

  return (
    <div className={styles.disruptionNotEligible}>
      <span color="textSecondary" className={styles.title}>
        {t("disruptionExercise.ineligible.title")}
      </span>
      <div className={styles.content}>
        <span color="textSecondary" className={styles.subtitle}>
          {t("disruptionExercise.ineligible.subtitle")}
        </span>
        <DisruptionInfoBanner
          icon=""
          text={<I18nMarkup tKey="disruptionExercise.ineligible.infoText" />}
        />
      </div>
    </div>
  );
};
