import { useCallback } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import defaultStyles from "./DisruptionExerciseLearnMoreContent.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import DisruptionExpandableContainer from "../DisruptionExpandableContainer/DisruptionExpandableContainer";
import {
  DisruptionExerciseButton,
  DisruptionExerciseButtonType,
} from "@b2bportal/core-disruption";

export interface DisruptionExerciseLearnMoreContentProps {
  learnMoreType: DisruptionExerciseLearnMoreType;
  maxPerPax?: string;
  productName?: string;
  contactSupportAction?: () => void;
  termsLink?: string;
  delayHours: string;
  numberOfHours: string;
}

export enum DisruptionExerciseLearnMoreType {
  Baggage = "baggage",
  BookReturn = "bookReturn",
  FAQ = "faq",
}

export const DisruptionExerciseLearnMoreContent = ({
  learnMoreType,
  maxPerPax,
  productName,
  contactSupportAction,
  termsLink,
  delayHours,
  numberOfHours,
}: DisruptionExerciseLearnMoreContentProps) => {
  const { t } = useI18nContext();
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseLearnMoreContent;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const getLearnMoreQuestions = useCallback(() => {
    switch (learnMoreType) {
      case DisruptionExerciseLearnMoreType.Baggage:
        return [
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBaggage.question1"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBaggage.answer1"
            ),
          },
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBaggage.question2"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBaggage.answer2"
            ),
          },
        ];
      case DisruptionExerciseLearnMoreType.BookReturn:
        return [
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBookReturn.question1"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBookReturn.answer1"
            ),
          },
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBookReturn.question2"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBookReturn.answer2"
            ),
          },
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBookReturn.question3"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBookReturn.answer3",
              {
                maxPerPax,
                productName,
              }
            ),
          },
        ];
      case DisruptionExerciseLearnMoreType.FAQ:
        return [
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.question1"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.answer1",
              {
                delayHours,
                numberOfHours,
              }
            ),
          },
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.question2"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.answer2"
            ),
            contactSupportFooter: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.footer2"
            ),
          },
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.question3"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.answer3"
            ),
            contactSupportFooter: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.footer3"
            ),
          },
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.question4"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.answer4"
            ),
            contactSupportFooter: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.footer4"
            ),
          },
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.question5"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.answer5"
            ),
            contactSupportFooter: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.footer5"
            ),
          },
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.question6"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.answer6"
            ),
            contactSupportFooter: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.footer6"
            ),
          },
          {
            question: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.question7"
            ),
            answer: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.answer7"
            ),
            contactSupportFooter: t(
              "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.footer7"
            ),
          },
        ];
    }
  }, [t, learnMoreType, maxPerPax, productName]);

  const questions = getLearnMoreQuestions();

  const termsRow = termsLink != null && (
    <p
      className={cn("moreDetailsTerms")}
      dangerouslySetInnerHTML={{
        __html: t(
          "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.additionalDetails",
          {
            termsUrl: termsLink,
          }
        ),
      }}
    ></p>
  );

  return (
    <div>
      {questions.map((question) => {
        return (
          <DisruptionExpandableContainer
            title={
              <div className={cn("learnMoreExpandTitle")}>
                {question.question}
              </div>
            }
            content={
              <div>
                <span
                  className={cn("learnMoreExpandDescribtion")}
                  dangerouslySetInnerHTML={{
                    __html: question.answer,
                  }}
                />
                {question.contactSupportFooter != null &&
                  contactSupportAction && (
                    <div>
                      <br />
                      <div
                        onClick={contactSupportAction}
                        className={cn("learnMoreFooterContainer")}
                      >
                        <span
                          className={cn("learnMoreExpandFooter")}
                          dangerouslySetInnerHTML={{
                            __html: question.contactSupportFooter,
                          }}
                        />
                        <DisruptionExerciseButton
                          onClick={contactSupportAction}
                          type={DisruptionExerciseButtonType.Clear}
                          disabled={false}
                          title={t(
                            "core-disruption.disruptionUniversalExercise.contactSupport"
                          )}
                        />
                      </div>
                    </div>
                  )}
              </div>
            }
          ></DisruptionExpandableContainer>
        );
      })}
      {termsRow}
    </div>
  );
};

export default DisruptionExerciseLearnMoreContent;
