import type { TFunction } from "i18next";
import type { Amenities, UtaCategory } from "@b2bportal/air-shopping-api";
import { ensureExhaustive } from "@b2bportal/core-utilities";

export const localizedAmenityLabel = (
  key: keyof Amenities,
  t: TFunction
): string => {
  // Explicitly use static keys instead of t(`amenitiesCategories.${key}`)
  // to help localization tools extract these keys for translation.
  switch (key) {
    case "seat":
      return t("amenitiesCategories.seat");
    case "aircraft":
      return t("amenitiesCategories.aircraft");
    case "entertainment":
      return t("amenitiesCategories.entertainment");
    case "beverage":
      return t("amenitiesCategories.beverage");
    case "wifi":
      return t("amenitiesCategories.wifi");
    case "layout":
      return t("amenitiesCategories.layout");
    case "power":
      return t("amenitiesCategories.power");
    case "freshFood":
      return t("amenitiesCategories.freshFood");
    default:
      ensureExhaustive(key);
  }
};

export const localizedUtaCategoryLabel = (
  category: UtaCategory,
  t: TFunction
): string => {
  // Explicitly use static keys instead of t(`amenityUtaCategories.${category}`)
  // to help localization tools extract these keys for translation.
  switch (category) {
    case "advance-change":
      return t("amenityUtaCategories.advance-change");
    case "boarding-priority":
      return t("amenityUtaCategories.boarding-priority");
    case "cancellation":
      return t("amenityUtaCategories.cancellation");
    case "carry-on-allowance":
      return t("amenityUtaCategories.carry-on-allowance");
    case "check-in-priority":
      return t("amenityUtaCategories.check-in-priority");
    case "checked-bag-allowance":
      return t("amenityUtaCategories.checked-bag-allowance");
    case "lounge-access":
      return t("amenityUtaCategories.lounge-access");
    case "same-day-change":
      return t("amenityUtaCategories.same-day-change");
    case "seat-selection":
      return t("amenityUtaCategories.seat-selection");
    case "upgrade-eligibility":
      return t("amenityUtaCategories.upgrade-eligibility");
    case "personal-item":
      return t("amenityUtaCategories.personal-item");
    case "refundable-fare":
      return t("amenityUtaCategories.refundable-fare");
    default:
      ensureExhaustive(category);
  }
};
