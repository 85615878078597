import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash-es";
import {
  tenantFlagsEnabled,
  useApiConfigSelector,
  useEnableSetMaxPriceWhenChoosingReturn,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { SliderDropdown } from "@hopper-b2b/ui";
import { trackEvent } from "@hopper-b2b/api";
import {
  APPLIED_FILTER,
  type AppliedFilterTrackingProperties,
  type FiatPrice,
} from "@hopper-b2b/types";
import type { MaxPriceFilterSelectionProps } from "./container";
import { generateTrackingEvent } from "../../../../../../actions/actions";
import {
  flightApiConfigStoreKey,
  FlightShopStep,
} from "../../../../../../../../reducers/types";
import { flightShopProgressSelector } from "../../../../../../../../modules/shop/reducer";

interface IMaxPriceFilterSelectionProps extends MaxPriceFilterSelectionProps {
  showDropdownContentOnly?: boolean;
  maximumPrice: Omit<FiatPrice, "currencySymbol">;
  minimumPrice: Omit<FiatPrice, "currencySymbol">;
  trackingProperties?: AppliedFilterTrackingProperties;
}

export const MaxPriceFilterSelection = (
  props: IMaxPriceFilterSelectionProps
) => {
  const flightShopProgress = useSelector(flightShopProgressSelector);
  const enableSetMaxPriceWhenChoosingReturn =
    useEnableSetMaxPriceWhenChoosingReturn();
  const isReturnStep = flightShopProgress === FlightShopStep.ChooseReturn;
  const {
    maxPriceFilter,
    setMaxPriceFilter,
    maximumPrice,
    minimumPrice,
    showDropdownContentOnly,
    trackingProperties,
  } = props;
  useEffect(() => {
    if (
      enableSetMaxPriceWhenChoosingReturn &&
      (isReturnStep || maximumPrice.value)
    ) {
      // if we are in return, is not enought to resetAllFilters from FlightShopV3
      // we need to set the actual maxprice from the return flight list
      // most likely this issue will affect VI itineraries
      // if we go back to change our flight, we should also set the maximum price
      setMaxPriceFilter(maximumPrice.value);
    }
  }, [
    isReturnStep,
    maximumPrice.value,
    setMaxPriceFilter,
    enableSetMaxPriceWhenChoosingReturn,
  ]);
  const { t, formatFiatCurrency } = useI18nContext();
  const { postEvent } = useUberBridge();
  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);

  const onValueChange = (maxPriceFilter: number) => {
    postEvent(APPLIED_FILTER, trackingProperties);
    trackEvent(generateTrackingEvent("price"), apiConfig);
    setMaxPriceFilter(maxPriceFilter);
  };

  const debouncedAction = debounce(onValueChange, 300);
  const [stateDebounceDispatchAction] = useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const handleChange = (newValue: number) => {
    stateDebounceDispatchAction(newValue);
  };

  return (
    <SliderDropdown
      dropdownLabel={t("searchFilter.price")}
      sliderHeader={t("searchFilter.maxPrice")}
      showPriceStationary={
        tenantFlagsEnabled.FlightShopShowFilterPriceStationary
      }
      maxValue={maximumPrice.value}
      minValue={
        maximumPrice.value === minimumPrice.value ? 0 : minimumPrice.value
      }
      value={maxPriceFilter}
      setValue={handleChange}
      sliderLabelFormat={(value) =>
        formatFiatCurrency(
          { ...maximumPrice, value },
          {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }
        )
      }
      showDropdownContentOnly={showDropdownContentOnly}
      className="b2b-shop-filter"
      popoverClassName="b2b"
    />
  );
};
