import { Slice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Fare, FareDetails, FlightShopCardType } from "@hopper-b2b/types";
import { B2BSpinner, LoadingIndicator } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { get } from "lodash-es";
import { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";

import {
  setChosenOutgoingSlice,
  setChosenReturnSlice,
} from "../../../../../../actions/actions";
import { IFlightListData } from "../../../component";
import { FlightDetails } from "../../FlightDetails";
import { FlightListInfo } from "../../FlightListInfo";
import { FlightCardType } from "../../FlightListInfo/component";
import { IInfiniteFlightsProps } from "../index";

const MOBILE_OFFSET_SCROLL = 80;
const DESKTOP_OFFSET_SCROLL = 250;

export const FlightComponent: FC<
  IInfiniteFlightsProps<{
    flight: IFlightListData;
    slice: Slice | undefined;
    index: number;
    selectedFare: Fare;
    openFlightDetailModal?: boolean;
    setOpenFlightDetailModal?: (open: boolean) => void;
    mobileSelectedFareId?: string;
    setMobileSelectedFareId?: (fareId: string) => void;
  }>
> = (props) => {
  const {
    departureDate,
    expandedFareDetails,
    expandedFlight,
    fareClassFilter,
    flight,
    flights,
    isInChooseReturnStep,
    isRoundTrip,
    maxFlightPrice,
    returnDate,
    rewardsKey,
    outgoingFareRating,
    slice,
    selectedFare: chosenFare, // This is the fare that was submitted when the user clicked Continue
    openFlightDetailModal,
    setOpenFlightDetailModal,
    onAlgomerchClick,
    onFareSubmit,
    onSliceSelect,
    mobileSelectedFareId,
    setMobileSelectedFareId,
    setExpandedFlight,
  } = props;

  const { t, brand } = useI18nContext();
  const dispatch = useDispatch();

  const { matchesMobile } = useDeviceTypes();

  // This is the clicked fare on desktop grid view
  const [previewedFareId, setPreviewedFareId] = useState("");

  const sliceId = flight.slice;

  const history = useHistory();
  const location = useLocation();

  const handleMobileSelectedFareChange = (fareId: string) => {
    setMobileSelectedFareId(fareId);
    if (isInChooseReturnStep) {
      dispatch(
        setChosenReturnSlice({
          returnFareId: fareId,
          returnSliceId: sliceId,
          returnFareRating: get(
            flights,
            `fareSlices[${fareId}].fareShelf.value`,
            0
          ),
          tripId: get(flights, `fares[${fareId}]tripId`, ""),
        })
      );
    } else {
      dispatch(
        setChosenOutgoingSlice({
          outgoingFareId: fareId,
          outgoingSliceId: sliceId,
          outgoingFareRating: get(
            flights,
            `fareSlices[${fareId}].fareShelf.value`,
            0
          ),
          tripId: get(flight, `fares[${fareId}].tripId`, ""),
          // whenever selecting a different departure flight, reset return ids
          resetReturnIds: true,
        })
      );
    }
  };

  const fareId = chosenFare.example?.fare || chosenFare.id;

  /**
   * Desktop fare submission handler. Mobile skips this step. Both eventually are passed up to Flight Shop V3 component
   */
  const handleFareSubmit = useCallback(
    (_sliceId: string, fare: FareDetails) => {
      history.replace(`${location.pathname}${location.search}`);
      onFareSubmit(flight, fare!.id);
    },
    [flight, history, location.pathname, location.search, onFareSubmit]
  );

  const handleOnClick = useCallback(
    (selectedFareRating: number) => {
      // For Uber: select trip and first fare id
      // TODO: Test other tenants' flights flows and add in if needed
      if (isInChooseReturnStep) {
        dispatch(
          setChosenReturnSlice({
            returnFareId: fareId,
            returnSliceId: sliceId,
            returnFareRating: selectedFareRating,
            tripId: get(flights, `fares[${fareId}].tripId`, ""),
          })
        );
      } else {
        dispatch(
          setChosenOutgoingSlice({
            outgoingFareId: fareId,
            outgoingSliceId: sliceId,
            outgoingFareRating: get(
              flights,
              `fareSlices[${fareId}].fareShelf.value`,
              0
            ),
            tripId: get(flight, `fares[${previewedFareId}].tripId`, ""),
            // whenever selecting a different departure flight, reset return ids
            resetReturnIds: true,
          })
        );
      }

      onSliceSelect(fareId, selectedFareRating, flight);
      setTimeout(() => {
        const OFFSET = matchesMobile
          ? MOBILE_OFFSET_SCROLL
          : DESKTOP_OFFSET_SCROLL;
        const cardTop =
          document?.getElementById(fareId)?.getBoundingClientRect().top || 0;
        window.scrollBy({
          top: (cardTop as number) - OFFSET,
          behavior: "smooth",
        });
        // Wait 0.5s to let the smooth animation complete
        setTimeout(() => {
          // Force a rescroll
          window.scrollBy({ top: 1 });
        }, 500);
      }, 100);
    },
    [
      isInChooseReturnStep,
      onSliceSelect,
      fareId,
      flight,
      dispatch,
      sliceId,
      flights,
      previewedFareId,
      matchesMobile,
    ]
  );

  return (
    <div
      id={fareId}
      className={clsx(
        "flight-list-item",
        "flight-row",
        {
          "row-view-desktop": !matchesMobile,
          "row-view-mobile": matchesMobile,
          selected: fareId === expandedFlight,
          mini: brand.flightShopCardType === FlightShopCardType.MINI,
        },
        "b2b"
      )}
    >
      <FlightListInfo
        selectedFare={chosenFare}
        slice={slice}
        flights={flights}
        flight={flight}
        rewardsKey={rewardsKey}
        fareClassFilter={fareClassFilter}
        maxFlightPrice={maxFlightPrice}
        isRoundTrip={isRoundTrip}
        type={FlightCardType.content}
        isExpanded={fareId === expandedFlight}
        onClick={handleOnClick}
        onAlgomerchClick={onAlgomerchClick}
        onFareClick={setPreviewedFareId}
        previewedFareId={previewedFareId}
        onFareSubmit={handleFareSubmit}
      />
      {fareId === expandedFlight && expandedFareDetails ? (
        <FlightDetails
          isOutgoing={!isInChooseReturnStep}
          onAlgomerchClick={onAlgomerchClick}
          tripDetails={expandedFareDetails}
          airports={flights!.airports}
          rewardsKey={rewardsKey}
          departureDate={departureDate}
          returnDate={returnDate}
          outgoingFareRating={outgoingFareRating}
          openFlightDetailModal={openFlightDetailModal}
          setOpenFlightDetailModal={setOpenFlightDetailModal}
          selectedFareId={previewedFareId}
          mobileSelectedFareId={mobileSelectedFareId}
          onFareSubmit={handleFareSubmit}
          onMobileSelectedFareChange={handleMobileSelectedFareChange}
          setExpandedFlight={setExpandedFlight}
        />
      ) : null}
      {sliceId === expandedFlight && !expandedFareDetails ? (
        <LoadingIndicator
          className="flight-shop-details-loading-indicator"
          indicatorSize={"small"}
          indicator={B2BSpinner}
          message={t("loadingFlightDetails")}
        />
      ) : null}
    </div>
  );
};
