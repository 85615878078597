import { useCallback, useState } from "react";
import { ITimeRange } from "@b2bportal/core-types";
import { Slider } from "@components/ui";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseTimeWindowSlider.module.scss";

interface DisruptionExerciseTimeWindowSliderProps {
  onChange: (min: number, max: number) => void;
  timeRange?: ITimeRange;
  label?: string;
  type: "singleThumb" | "doubleThumb" | "normal";
  sliderMax: number;
  sliderMin: number;
  getLabel?: (value: number) => string;
}

export const DisruptionExerciseTimeWindowSlider = (
  props: DisruptionExerciseTimeWindowSliderProps
) => {
  const { onChange, timeRange, label, type, sliderMax, sliderMin, getLabel } =
    props;
  const [value, setValue] = useState(timeRange);
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseSummaryCard;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const handleChange = useCallback(
    (min: number, max: number) => {
      setValue({ min: min, max: max });
      onChange(min, max);
    },
    [onChange]
  );

  return (
    <div className={cn("disruptionSliderContainer")}>
      <span className={cn("sliderTitle")}>{label}</span>
      <Slider
        min={sliderMin}
        max={sliderMax}
        ariaLabel=""
        value={[value?.min ?? 0, value?.max ?? sliderMax]}
        step={1}
        onValueChange={(val) => handleChange(val[0], val[1])}
        minStepsBetweenThumbs={1}
        inverted={type === "singleThumb"}
        className={cn("timeWindowSliderContainer")}
      />
      {/* <Slider
        className={styles.timeWindowSliderContainer}
        onChange={handleChange}
        sliderType={type}
        step={1}
        chosenMin={value.min}
        chosenMax={value.max}
        sliderMin={sliderMin}
        sliderMax={sliderMax}
        getLabel={getLabel}
        hideMarks={true}
      /> */}
    </div>
  );
};

export default DisruptionExerciseTimeWindowSlider;
