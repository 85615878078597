import { trackEvent } from "@hopper-b2b/api";
import {
  EntryButtonEnum,
  FreezePriceClickProperties,
  PreviousFlightEnum,
  PriceFreezeEntryEnum,
  PriceFreezeHistoryState,
  PriceFreezeTrackingEvents,
} from "@hopper-b2b/fintech";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  FlightShopType,
  HalfSheetHashType,
  TripCategory,
} from "@hopper-b2b/types";
import { B2BSpinner, MobileFloatingSection, Slot } from "@hopper-b2b/ui";
import {
  TenantFlag,
  getEnvVariables,
  getIsMixedClass,
  tenantFlagsEnabled,
  useEnablePriceDropProtection,
  useEnableWallet,
  useHideAirPriceFreezeHackerFare,
  useSessionContext,
} from "@hopper-b2b/utilities";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { PATH_PRICE_FREEZE_PURCHASE } from "../../../../../../../../../utils/urlPaths";
import {
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getTripCategory,
} from "../../../../../../../../search/reducer/selectors";
import { MobileItineraryDetailsModal } from "../../../../../../../components/FlightShopReviewItinerary";
import {
  fetchSelectedTripPriceFreezeOffer,
  setChosenOutgoingSlice,
  setChosenReturnSlice,
} from "../../../../../../../actions/actions";
import { DesktopInfiniteFlightsProps } from "../DesktopInfiniteFlights";
import { FlightComponent } from "../FlightComponent";
import {
  flightShopTypeSelector,
  getFareDetails,
  getFareMultiTicketType,
  getSelectedTripAirports,
  getTripDetails,
  predictionSelector,
  selectedTripPriceFreezeOfferSelector,
} from "./../../../../../../../../../../src/modules/shop/reducer";

import {
  MultiTicketType,
  PriceDropProtectionEnum,
} from "@b2bportal/air-shopping-api";
import { SignupBanner } from "@hopper-b2b/wallet";
import "./styles.scss";
import { get } from "lodash-es";

type MobileInfiniteFlightsProps = DesktopInfiniteFlightsProps;

export const MobileInfiniteFlights = (props: MobileInfiniteFlightsProps) => {
  const {
    disablePriceFreeze,
    expandedFareDetails,
    faresToShow,
    flights,
    isInChooseReturnStep,
    expandedFlight,
    setFetchMoreData,
    setExpandedFlight,
    hasMore,
    onFareSubmit,
  } = props;

  const { t, formatFiatCurrency } = useI18nContext();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSessionContext();

  const hasVouchersEnabled = useEnableWallet();

  const [openFlightDetailModal, setOpenFlightDetailModal] = useState(false);
  const [selectedFareId, setSelectedFareId] = useState(expandedFlight);

  const tripCategory = useSelector(getTripCategory);
  const isOneWay = tripCategory === TripCategory.ONE_WAY;
  const prediction = useSelector(predictionSelector);
  const enabledPDP = useEnablePriceDropProtection();

  const adultsCount = useSelector(getAdultsCount);
  const childrenCount = useSelector(getChildrenCount);
  const infantsInSeatCount = useSelector(getInfantsInSeatCount);
  const infantsOnLapCount = useSelector(getInfantsOnLapCount);
  const selectedTripPriceFreezeOffer = useSelector(
    selectedTripPriceFreezeOfferSelector
  );
  const departureDate = useSelector(getDepartureDate);
  const flightShopType = useSelector(flightShopTypeSelector);
  const fareMultiTicketType = useSelector(getFareMultiTicketType);
  const hidePriceFreezeHackerFare = useHideAirPriceFreezeHackerFare();
  const showFlightShopModalItinerary =
    tenantFlagsEnabled[TenantFlag.FlightMobileShopItineraryModal];

  useEffect(() => {
    if (location.hash === `#${HalfSheetHashType.FLIGHT_DETAILS}`) {
      setOpenFlightDetailModal(true);
    } else {
      setOpenFlightDetailModal(false);
    }
  }, [location]);

  useEffect(() => {
    const fareId = selectedFareId || expandedFlight;
    // fareId may not be updated yet, don't fetch PF until the fare selected is part of the trip
    const fareIsInTrip =
      props.expandedFareDetails?.fareDetails?.findIndex(
        (fare) => fare.id === fareId
      ) > -1;
    if (
      fareIsInTrip &&
      fareId &&
      !disablePriceFreeze &&
      flightShopType !== FlightShopType.CHFAR_EXERCISE
    ) {
      dispatch(
        fetchSelectedTripPriceFreezeOffer(
          fareId, // fareId
          props.expandedFareDetails?.id, // tripId
          departureDate
        )
      );
    }
  }, [
    selectedFareId,
    departureDate,
    dispatch,
    expandedFlight,
    props.expandedFareDetails?.id,
    disablePriceFreeze,
    flightShopType,
    props.expandedFareDetails?.fareDetails,
  ]);

  const handleNext = useCallback(() => {
    if (setFetchMoreData) {
      setFetchMoreData();
    }
  }, [setFetchMoreData]);

  useEffect(() => {
    setSelectedFareId(expandedFlight);
  }, [expandedFlight]);

  const priceString = useMemo(() => {
    return selectedTripPriceFreezeOffer
      ? formatFiatCurrency(selectedTripPriceFreezeOffer?.price?.fiat, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : undefined;
  }, [formatFiatCurrency, selectedTripPriceFreezeOffer]);

  const showPriceFreeze = useMemo(() => {
    if (disablePriceFreeze) return false;
    // if it's a multiTicket and price freeze hackerfares are hidden
    if (
      hidePriceFreezeHackerFare &&
      fareMultiTicketType !== MultiTicketType.single
    )
      return false;
    if (isOneWay) return true;
    return isInChooseReturnStep;
  }, [
    disablePriceFreeze,
    fareMultiTicketType,
    hidePriceFreezeHackerFare,
    isInChooseReturnStep,
    isOneWay,
  ]);

  const priceFreezePurchaseQueryString = useMemo(() => {
    if (!expandedFlight || !props.expandedFareDetails) return;

    const urlParams: Record<string, string> = {
      tripId: props.expandedFareDetails?.id,
      fareId: selectedFareId ?? expandedFlight,
    };

    // Add passenger query params
    if (adultsCount) {
      urlParams.adultsCount = `${adultsCount}`;
    }
    if (childrenCount) {
      urlParams.childrenCount = `${childrenCount}`;
    }
    if (infantsInSeatCount) {
      urlParams.infantsInSeatCount = `${infantsInSeatCount}`;
    }
    if (infantsOnLapCount) {
      urlParams.infantsOnLapCount = `${infantsOnLapCount}`;
    }

    return new URLSearchParams(urlParams).toString();
  }, [
    expandedFlight,
    props.expandedFareDetails,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    selectedFareId,
  ]);

  const handleDefaultContinue = useCallback(() => {
    const selectedFare = flights.fares[selectedFareId];
    onFareSubmit(
      {
        slice: flights.fareSlices[selectedFare.outbound].slice,
        fares: flights.trips[selectedFare.tripId].fares.map(
          (f) => flights.fares[f]
        ),
      },
      selectedFareId
    );
    setExpandedFlight("");
  }, [flights, selectedFareId, onFareSubmit, setExpandedFlight]);

  const showFlightDetails = useCallback(
    (departure: boolean) => {
      const selectedFare = flights.fares[selectedFareId];
      const slice = flights.fareSlices[selectedFare.outbound].slice;

      if (departure) {
        dispatch(
          setChosenOutgoingSlice({
            outgoingFareId: selectedFareId,
            outgoingSliceId: slice,
            outgoingFareRating: get(
              flights,
              `fareSlices[${selectedFareId}].fareShelf.value`,
              0
            ),
            tripId: get(flights, `fares[${selectedFareId}].tripId`, ""),
            // whenever selecting a different departure flight, reset return ids
            resetReturnIds: true,
          })
        );
      } else {
        dispatch(
          setChosenReturnSlice({
            returnFareId: selectedFareId,
            returnSliceId: slice,
            returnFareRating: get(
              flights,
              `fareSlices[${selectedFareId}].fareShelf.value`,
              0
            ),
            tripId: get(flights, `fares[${selectedFareId}]tripId`, ""),
          })
        );
      }
      history.push(
        `${history.location.pathname}${history.location.search}#${
          departure
            ? HalfSheetHashType.FLIGHT_DETAILS_DEPARTURE
            : HalfSheetHashType.FLIGHT_DETAILS_RETURN
        }`
      );
    },
    [dispatch, flights, history, selectedFareId]
  );

  const handleContinue = useCallback(() => {
    if (showFlightShopModalItinerary) {
      showFlightDetails(!isInChooseReturnStep);
    } else {
      handleDefaultContinue();
    }
  }, [
    handleDefaultContinue,
    isInChooseReturnStep,
    showFlightDetails,
    showFlightShopModalItinerary,
  ]);

  const handlePriceFreezeButtonClick = useCallback(() => {
    trackEvent({
      eventName: PriceFreezeTrackingEvents.FREEZE_PRICE_CLICK,
      properties: {
        price_freeze_entry: PriceFreezeEntryEnum.TRIP_SUMMARY,
      } as FreezePriceClickProperties,
    });

    history.push(
      `${PATH_PRICE_FREEZE_PURCHASE}?${priceFreezePurchaseQueryString}`,
      {
        priceFreezeEntry: PriceFreezeEntryEnum.TRIP_SUMMARY,
        entryButton: EntryButtonEnum.FREEZE_PRICE,
        previousFlight: PreviousFlightEnum.CHOSEN_FLIGHT,
      } as PriceFreezeHistoryState
    );
  }, [history, priceFreezePurchaseQueryString]);

  const indexStep = 3;

  const flightListBanners = useMemo(() => {
    const banners = [];
    if (hasVouchersEnabled && !isLoggedIn) {
      banners.push(<SignupBanner message={t?.("walletBannerSignIn")} />);
    }
    if (!isInChooseReturnStep) {
      if (
        prediction?.priceDropProtection?.PriceDropProtection ===
          PriceDropProtectionEnum.IsEligible &&
        enabledPDP
      ) {
        banners.push(
          <Slot
            id="flight-price-drop-protection-banner"
            className="flight-list-pdp-banner"
            isEligible
            showMore
          />
        );
      }
      banners.push(<Slot id="flight-shop-mobile-ftu-banner" />);
    }
    return banners;
  }, [
    enabledPDP,
    hasVouchersEnabled,
    isInChooseReturnStep,
    isLoggedIn,
    prediction?.priceDropProtection?.PriceDropProtection,
    t,
  ]);

  return (
    <>
      <InfiniteScroll
        dataLength={faresToShow.length}
        next={handleNext}
        hasMore={hasMore}
        loader={
          <div className="loading-flights">
            <B2BSpinner classes={["loading-flights-bunny"]} />
          </div>
        }
      >
        <ul className="infinite-flight-list">
          {faresToShow.map(({ fare, flight }, index) => {
            const flightSliceId = flight.slice;
            const flightSlice = flights?.slices[flightSliceId];
            if (!flightSlice) {
              return null;
            }

            const fareId = fare.example?.fare || fare.id;
            return (
              <Fragment key={`flight-${fareId}`}>
                {index !== 0 && index % indexStep === 0
                  ? flightListBanners[Math.floor(index / indexStep) - 1]
                  : null}
                <li key={`flight-${fareId}`}>
                  <FlightComponent
                    className={`flight-${fareId}`}
                    {...props}
                    slice={flightSlice}
                    index={index}
                    flight={flight}
                    selectedFare={fare}
                    openFlightDetailModal={openFlightDetailModal}
                    setOpenFlightDetailModal={setOpenFlightDetailModal}
                    mobileSelectedFareId={selectedFareId}
                    setMobileSelectedFareId={setSelectedFareId}
                    setExpandedFlight={setExpandedFlight}
                  />
                </li>
              </Fragment>
            );
          })}
        </ul>
      </InfiniteScroll>
      {(getEnvVariables("clientName") === ClientName.UBER ||
        getEnvVariables("clientName") === ClientName.NUBANK ||
        getEnvVariables("clientName") === ClientName.LLOYDS) &&
      expandedFlight &&
      expandedFlight !== "" ? (
        <Slot
          id="flight-shop-cta-section"
          totalPrice={flights?.fares?.[selectedFareId || ""]?.amount.fiat}
          tripCategory={
            isOneWay ? TripCategory.ONE_WAY : TripCategory.ROUND_TRIP
          }
          onClick={handleContinue}
          buttonDisabled={!expandedFareDetails?.fareDetails.length}
          component={
            <div className="flight-shop-continue-button-wrapper">
              <MobileFloatingSection
                primaryButton={{
                  children: !isInChooseReturnStep
                    ? t("selectThisOutbound")
                    : t("selectThisReturn"),
                  className: "flight-shop-continue-button",
                  disabled: Boolean(!expandedFareDetails?.fareDetails.length),
                  onClick: handleContinue,
                  wrapperClassName: "b2b",
                }}
                secondaryButton={
                  showPriceFreeze && selectedTripPriceFreezeOffer
                    ? {
                        children: t("reviewItinerary.priceFreezeButton", {
                          price: priceString,
                        }),
                        onClick: handlePriceFreezeButtonClick,
                        className: "flight-shop-price-freeze-button",
                        wrapperClassName: "b2b",
                      }
                    : null
                }
              />
            </div>
          }
        />
      ) : null}
      {showFlightShopModalItinerary ? (
        <MobileFlightDetailsModalWrapper onClick={handleDefaultContinue} />
      ) : null}
    </>
  );
};

const MobileFlightDetailsModalWrapper = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const history = useHistory();
  const { t } = useI18nContext();
  const [openModal, setOpenModal] = useState<"departure" | "return" | null>(
    null
  );

  const fareDetails = useSelector(getFareDetails);
  const tripDetails = useSelector(getTripDetails);
  const airports = useSelector(getSelectedTripAirports);

  const isOutgoingMixedClass = useMemo(() => {
    return fareDetails ? getIsMixedClass(fareDetails?.slices?.[0]) : false;
  }, [fareDetails]);

  const isReturnMixedClass = useMemo(() => {
    return fareDetails && fareDetails?.slices?.[1]
      ? getIsMixedClass(fareDetails?.slices?.[1])
      : false;
  }, [fareDetails]);

  useEffect(() => {
    if (
      history.location.hash === `#${HalfSheetHashType.FLIGHT_DETAILS_DEPARTURE}`
    ) {
      setOpenModal("departure");
    } else if (
      history.location.hash === `#${HalfSheetHashType.FLIGHT_DETAILS_RETURN}`
    ) {
      setOpenModal("return");
    } else {
      setOpenModal(null);
    }
  }, [history.location.hash]);

  const onModalClose = useCallback(() => {
    history.goBack();
  }, [history]);

  const clearHash = useCallback(() => {
    history.replace(`${history.location.pathname}${history.location.search}`);
  }, [history]);

  return (
    <MobileItineraryDetailsModal
      openModal={!!openModal}
      isDeparture={openModal === "departure"}
      tripDetails={tripDetails}
      fareDetails={fareDetails}
      onClose={onModalClose}
      isMixedCabinClass={
        openModal === "departure" ? isOutgoingMixedClass : isReturnMixedClass
      }
      buttonText={t("continue")}
      airports={airports}
      onClick={() => {
        clearHash();
        onClick();
      }}
    />
  );
};
