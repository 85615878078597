import { FareSliceDetailsV2, FareDetailsV2 } from "@b2bportal/air-shopping-api";
import { IFareData, IFlightFares } from "@b2bportal/core-types";

export const filterWithFareShelfRatings = (
  selectedFareClassFilters: Array<number>,
  fareSliceRating: number
) => {
  const maxShelfRating = Math.max(...selectedFareClassFilters);

  return (
    selectedFareClassFilters.includes(fareSliceRating) ||
    fareSliceRating >= maxShelfRating
  );
};

export const getFareSliceRating = (fareSlice: FareSliceDetailsV2) =>
  typeof fareSlice?.fareShelf?.rating === "number" &&
  fareSlice.fareShelf.rating >= 0
    ? fareSlice.fareShelf.rating
    : -1;

export const getFilteredFareDetails = ({
  fareDetails,
  selectedFareClassFilters,
  sliceIndex,
  selectedFareId,
}: {
  fareDetails: FareDetailsV2[];
  selectedFareClassFilters: Array<number>;
  sliceIndex: number;
  selectedFareId?: string;
}): FareDetailsV2[] => {
  // userSelectedFare, defaults to first fare in list if none selected
  const selectedFare = fareDetails.find((f) => f.id === selectedFareId);

  const selectedFareRating =
    selectedFare?.slices[sliceIndex]?.fareShelf?.rating || -1;
  return fareDetails
    .filter((fare: FareDetailsV2) => {
      const fareSlice = fare.slices[sliceIndex];
      const fareSliceRating = getFareSliceRating(fareSlice);
      // Validate that fare slice has fareShelf defined
      if (fareSlice.fareShelf) {
        if (selectedFareClassFilters.length > 0) {
          // Filters applied
          return filterWithFareShelfRatings(
            selectedFareClassFilters,
            fareSliceRating
          );
        } else {
          // No filters applied
          if (selectedFareRating >= 0) {
            // If there's a selectedFare, show higher fares only
            return fareSliceRating >= selectedFareRating;
          }
        }
      }
      return true;
    })
    .sort((fareA, fareB) => {
      const farePricingsA = fareA.paxPricings;
      const farePricingsB = fareB.paxPricings;
      if (farePricingsA == null && farePricingsB == null) {
        return 0;
      } else if (farePricingsA == null) {
        return 1;
      } else if (farePricingsB == null) {
        return -1;
      } else {
        return (
          farePricingsA[0].pricing.total.fiat.value -
          farePricingsB[0].pricing.total.fiat.value
        );
      }
    });
};

export const getFilteredFares = (
  fares: IFlightFares,
  fareShelfFilter: Array<number>
): IFlightFares =>
  fareShelfFilter?.length == null
    ? structuredClone(fares)
    : Object.fromEntries(
        Object.entries(fares).filter(([, fare]) =>
          fareShelfFilter.includes(fare.fareShelf)
        )
      );

export const getCheapestFilteredFare = (
  fares: IFlightFares,
  fareShelfFilter: Array<number>
): IFareData => {
  const filteredFares = getFilteredFares(fares, fareShelfFilter);

  return Object.values(filteredFares).reduce((cheapest, fare) => {
    return fare.price.fiat.value < cheapest.price.fiat.value ? fare : cheapest;
  });
};
