import {
  ResendEmailRequest,
  ResendEmailResponse,
  emailApi,
  ResendEmailResponseEnum,
} from "@b2bportal/email-api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

const resendEmail = async (
  req: ResendEmailRequest
): Promise<ResendEmailResponse> => {
  try {
    const res = await emailApi(axiosInstance).apiV0EmailResendPost(req);
    if (res.data.ResendEmailResponse === ResendEmailResponseEnum.Failure) {
      throw res.data;
    }

    return res.data;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        location: window.location.pathname,
      },
    });
    throw err;
  }
};

export default resendEmail;
