import { useI18nContext } from "@hopper-b2b/i18n";
import { DisruptionExerciseSelection } from "../DisruptionExerciseSelection";
import { useDispatch, useSelector } from "react-redux";
import { DisruptionExerciseFooter } from "../DisruptionExerciseFooter";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseOptionsCard.module.scss";
import { getDisruptionExerciseProgress } from "../../../../features/exercise";
import {
  DisruptionExerciseProgress,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import { useDeviceType } from "@b2bportal/core-utilities";
import { DisruptionExerciseContainer } from "../DisruptionExerciseContainer";

enum DisruptionExerciseOption {
  Rebook = "rebook",
  Refund = "refund",
}

interface DisruptionExerciseOptionsCardProps {
  onSupportClick: () => void;
}

export const DisruptionExerciseOptionsCard = (
  props: DisruptionExerciseOptionsCardProps
) => {
  const { isDesktopAndUp } = useDeviceType();
  const { t } = useI18nContext();
  const dispatch = useDispatch();
  const disruptionExerciseProgress = useSelector(getDisruptionExerciseProgress);
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseOptionsCard;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const handleSelection = (exerciseOption: DisruptionExerciseOption) => {
    switch (exerciseOption) {
      case DisruptionExerciseOption.Rebook:
        dispatch(
          setDisruptionExerciseProgress(
            DisruptionExerciseProgress.RebookSelected
          )
        );
        return;
      case DisruptionExerciseOption.Refund:
        dispatch(
          setDisruptionExerciseProgress(
            DisruptionExerciseProgress.RefundSelected
          )
        );
        return;
      default:
        return;
    }
  };

  const rows = (
    <>
      <span
        className={cn("rowsTitle")}
        dangerouslySetInnerHTML={{
          __html: t(
            "core-disruption.disruptionUniversalExercise.landingPage.chooseBetween"
          ),
        }}
      />

      <DisruptionExerciseSelection
        content={
          <span className={cn("selectionTitle")}>
            {t(
              "core-disruption.disruptionUniversalExercise.landingPage.rebookOptionTitle"
            )}
          </span>
        }
        isSelected={
          disruptionExerciseProgress ===
          DisruptionExerciseProgress.RebookSelected
        }
        onSelect={() => handleSelection(DisruptionExerciseOption.Rebook)}
        hideRadio={false}
      ></DisruptionExerciseSelection>
      <DisruptionExerciseSelection
        content={
          <span className={cn("selectionTitle")}>
            {t(
              "core-disruption.disruptionUniversalExercise.landingPage.refundOptionTitle"
            )}
          </span>
        }
        isSelected={
          disruptionExerciseProgress ===
          DisruptionExerciseProgress.RefundSelected
        }
        onSelect={() => handleSelection(DisruptionExerciseOption.Refund)}
        hideRadio={false}
      ></DisruptionExerciseSelection>
      {isDesktopAndUp && (
        <DisruptionExerciseFooter
          onSupportClick={props.onSupportClick}
        ></DisruptionExerciseFooter>
      )}
    </>
  );

  return (
    <div className={cn("disruptionOptionsCard")}>
      {<DisruptionExerciseContainer content={rows} />}
    </div>
  );
};

export default DisruptionExerciseOptionsCard;
