import { ButtonWrap } from "@hopper-b2b/ui";
import clsx from "clsx";

interface TravelerSelectRowButtonWrapProps {
  children: JSX.Element;
  onClickSelect: () => void;
  className: string;
  disabled?: boolean;
  isMobile?: boolean;
  isSelected?: boolean;
}

export const TravelerSelectRowButtonWrap = ({
  children,
  onClickSelect,
  className,
  disabled,
  isMobile,
  isSelected,
}: TravelerSelectRowButtonWrapProps): JSX.Element => {
  return (
    <ButtonWrap
      className={clsx("traveler-select-row-root", className, {
        disabled,
        mobile: isMobile,
      })}
      onClick={onClickSelect}
    >
      {children}
    </ButtonWrap>
  );
};
