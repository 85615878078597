import { useDeviceType } from "@b2bportal/core-utilities";
import defaultStyles from "./styles.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";

export interface DisruptionExerciseContainerProps {
  content: JSX.Element | JSX.Element[];
}

export const DisruptionExerciseContainer = (
  props: DisruptionExerciseContainerProps
) => {
  const { isDesktopAndUp } = useDeviceType();
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseContainer;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return !isDesktopAndUp ? (
    <div className={cn("disruptionExerciseContainerMobile")}>
      {props.content}
    </div>
  ) : (
    <div className={cn("disruptionExerciseContainer")}>{props.content}</div>
  );
};

export default DisruptionExerciseContainer;
