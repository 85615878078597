import { useDeviceType } from "@b2bportal/core-utilities";
import defaultStyles from "./DisruptionExerciseHeader.module.scss";
import { Icon } from "@b2bportal/core-ui";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";

export interface DisruptionExerciseHeaderProps {
  title: string;
  subtitle?: string;
  logo?: string;
  onBack: () => void;
  withLogo: boolean;
  rightNavElement?: JSX.Element;
}

export const DisruptionExerciseHeader = (
  props: DisruptionExerciseHeaderProps
) => {
  const { isDesktopAndUp } = useDeviceType();
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseHeader;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const mobileHeader = (
    <div className={cn("disruptionExerciseHeaderContainerMobile")}>
      <div className={cn("contentMobile")}>
        <div className={cn("navComponentContainer")}>
          <Icon
            iconName={IconNameEnum.backIcon}
            onClick={props.onBack}
            className={cn("backIcon")}
          />
        </div>
        <div className={cn("mobileTitleSubtitleContainer")}>
          <span className={cn("titleMobile")}>{props.title}</span>
        </div>
        <div className={cn("navComponentContainer")}>
          {props.rightNavElement ? props.rightNavElement : <div></div>}
        </div>
      </div>
      <hr></hr>
    </div>
  );

  const simpleDisktopHeader = (
    <div className={cn("disruptionExerciseHeaderContainer")}>
      <div className={cn("backIcon")}>
        <Icon iconName={IconNameEnum.backIcon} onClick={props.onBack} />
      </div>
      <span className={cn("title")}>{props.title}</span>
      {props.subtitle && (
        <span className={cn("subtitle")}>{props.subtitle}</span>
      )}
    </div>
  );

  const desktopHeaderWithLogo = (
    <div className={cn("disruptionExerciseHeaderContainer")}>
      <div className={cn("backIcon")}>
        <Icon iconName={IconNameEnum.backIcon} onClick={props.onBack} />
      </div>
      <div className={cn("headerWithLogoContainer")}>
        <Icon
          className={cn("header-image")}
          useCurrentColor={false}
          iconName={IconNameEnum.disruptionProtection}
        />
        <div className={cn("titleContainer")}>
          <span className={cn("title")}>{props.title}</span>
        </div>
      </div>
    </div>
  );

  const desktopHeader = props.withLogo
    ? desktopHeaderWithLogo
    : simpleDisktopHeader;

  return isDesktopAndUp ? desktopHeader : mobileHeader;
};

export default DisruptionExerciseHeader;
