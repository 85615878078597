import { useStyles } from "../context";
import {
  CORE_DISRUPTION_NAME,
  CoreDisruptionComponent,
} from "@b2bportal/core-types";

export const useDisruptionStyles = (
  componentName: keyof typeof CoreDisruptionComponent,
  defaultStyles?: object
) => {
  return useStyles(CORE_DISRUPTION_NAME, componentName, defaultStyles);
};
