import { useUserSource } from "@hopper-b2b/utilities";
import { getLang } from "@hopper-b2b/i18n";
import {
  AxiosInterceptors,
  createRequestLog,
  createResponseErrorLog,
  createResponseLog,
} from "@hopper-b2b/api";
import { useExperiments } from "@hopper-b2b/experiments";
import config from "../../utils/config";

interface IAxiosInterceptors {
  children?: React.ReactNode;
  hopperSessionToken?: string;
  nubankSessionToken?: string;
}

const TENANT = "nubank";

const AxiosInterceptorWrapper = ({
  children,
  hopperSessionToken,
  nubankSessionToken,
}: IAxiosInterceptors) => {
  const { trackingProperties } = useExperiments();
  const userSource = useUserSource();
  const locale = getLang();
  // TODO: Needs to not be hardcoded
  const currency = "BRL";
  const headers = {};
  if (hopperSessionToken) headers["Hopper-Session"] = hopperSessionToken;
  if (nubankSessionToken) headers["Tenant-Session"] = nubankSessionToken;

  return (
    <AxiosInterceptors
      trackingProperties={trackingProperties}
      userSource={userSource}
      tenant={TENANT}
      locale={locale}
      currency={currency}
      isAgentPortal={false}
      requestHeaders={headers}
      version={config.REACT_APP_VERSION}
      logResponse={createResponseLog}
      logRequest={createRequestLog}
      logError={createResponseErrorLog}
    >
      {children}
    </AxiosInterceptors>
  );
};

export default AxiosInterceptorWrapper;
