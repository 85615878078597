import {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";

import { useI18nContext } from "@hopper-b2b/i18n";
import {
  // ContractStateEnum,
  // HotelCfarContract,
  HotelItineraryState,
  LodgingAddressExact,
  MyTripsFilter,
  MyTripsModalTypes,
  TagColors,
} from "@hopper-b2b/types";
import {
  ActionLinks,
  BannerSeverity,
  IActionLink,
  Icon,
  IconName,
  NotificationBanner,
  StatusTag,
} from "@hopper-b2b/ui";
import {
  getGoogleStaticMapSrc,
  getHotelReservationDetails,
  getHotelStayDuration,
  getTwoLineAddresses,
} from "@hopper-b2b/utilities";

import { calculateHotelTotals } from "../../../../../../utils/helpers";
import { getLineItems } from "../../../../utils/helpers";
import {
  addHotelType,
  getPostalCode,
} from "../../../HotelCard/components/DesktopHotelCard/component";
import {
  HotelCancellation,
  HotelCardRoomTypeContent,
} from "../../../HotelCard/components/HotelInfoParts";
import { PremierCollectionBenefits } from "../../../HotelCard/components/HotelInfoParts/component";
import { AddOn, AddOnEnum, AddOnsSummary } from "../AddOnsSummary";
import { ConfirmationSummary } from "../ConfirmationSummary";
import { MobileActionLinks } from "../MobileActionLinks";
import { PaymentSummary } from "../PaymentSummary";
import { SummaryCard } from "../SummaryCard";
import { TravelersSummary } from "../TravelersSummary";
import { MobileHotelItineraryDetailsConnectorProps } from "./container";

import "./styles.scss";
import { InfoBanner } from "@hopper-b2b/wallet";
import { ClientContext } from "../../../../../..//App";

export interface IMobileHotelItineraryDetailsProps
  extends RouteComponentProps,
    MobileHotelItineraryDetailsConnectorProps {}

export const MobileHotelItineraryDetails = ({
  hotel,
  history,
  setOpenModal,
  setSelectedHotel,
  populateTripQueryParams,
  tripsFilter,
}: IMobileHotelItineraryDetailsProps) => {
  const refundPopoverRef = useRef<HTMLButtonElement>(null);
  const [refundPopoverOpen, setRefundPopoverOpen] = useState(false);
  const { t, formatFiatCurrency, language: locale, brand } = useI18nContext();
  const clientContext = useContext(ClientContext);

  const coordinates = useMemo(() => {
    const lat = hotel?.reservation?.lodgingData?.location?.coordinates?.lat;
    const lon = hotel?.reservation?.lodgingData?.location?.coordinates?.lon;
    return `${lat},${lon}`;
  }, [hotel]);

  const resState = useMemo(
    () => hotel.reservation?.state?.ReservationState,
    [hotel.reservation?.state?.ReservationState]
  );
  const isCancelled = useMemo(
    () =>
      [
        HotelItineraryState.Canceled,
        HotelItineraryState.CancelationFailure,
        HotelItineraryState.Canceled,
        "Cancelled",
      ].includes(resState),
    [resState]
  );

  const onOpenModal = useCallback(
    (type: MyTripsModalTypes) =>
      setOpenModal({
        type,
        selectedItinerary: addHotelType(hotel),
      }),
    [hotel, setOpenModal]
  );

  const { reservation, paymentBreakdown } = hotel;
  // const { cfar } = ancillaries;

  const { title, confirmationCode, startDate, endDate, subtitle } =
    getHotelReservationDetails(hotel, locale, brand);

  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;

  const actions: IActionLink[] = [];
  const topActions: IActionLink[] = []; // render at the top of the page

  const closeRefundPopover = () => setRefundPopoverOpen(false);
  const openRefundPopover = () => setRefundPopoverOpen(true);

  if (!isCancelled && !isPastTrips) {
    actions.push({
      content: t("hotelTripsLinks.changeReservation"),
      onClick: () => onOpenModal(MyTripsModalTypes.ChangeHotel),
    });
    actions.push({
      content: t("hotelTripsLinks.cancelReservation"),
      onClick: () => onOpenModal(MyTripsModalTypes.CancelHotel),
    });
  } else if (isCancelled) {
    topActions.push({
      content: (
        <>
          {t("howRefundsWorkTitle")}
          <Icon className="info-icon" name={IconName.InfoCircle} />
        </>
      ),
      linkClassName: "how-refunds-work-link",
      linkRef: refundPopoverRef,
      onClick: openRefundPopover,
    });
  }

  actions.push(
    {
      content: t("tripReviewLinks.resendEmail"),
      onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
    },
    {
      content: t("tripReviewLinks.contactSupport"),
      onClick: () => onOpenModal(MyTripsModalTypes.ContactSupport),
    }
  );

  const { calculatedTripTotal } = hotel
    ? calculateHotelTotals(reservation, paymentBreakdown)
    : {};

  const hotelAddress = useMemo(
    () => reservation.lodgingData?.address as LodgingAddressExact | undefined,
    [reservation.lodgingData?.address]
  );

  const hotelStreet = getTwoLineAddresses(hotelAddress).line1;

  const hotelCancellation = useMemo(
    () => (
      <HotelCancellation
        hotelCfar={
          // hotel.ancillaries.cfar
          undefined
        }
        cancellationPolicy={hotel?.reservation?.cancellationPolicy}
      />
    ),
    [hotel?.reservation?.cancellationPolicy]
  );

  const paymentLineItems = useMemo(() => {
    return getLineItems(t, hotel?.paymentBreakdown) || undefined;
  }, [hotel?.paymentBreakdown, t]);

  const goBack = useCallback(() => {
    history.goBack();
    setSelectedHotel(null);
  }, [history, setSelectedHotel]);

  const openTravelerModal = useCallback(() => {
    onOpenModal(MyTripsModalTypes.TravelersModal);
  }, [onOpenModal]);

  const openPaymentBreakdownModal = useCallback(() => {
    onOpenModal(MyTripsModalTypes.PaymentModal);
  }, [onOpenModal]);

  useEffect(() => {
    if (hotel?.reservation) {
      populateTripQueryParams(history, {
        tripId: hotel?.reservation?.reservationBookingId,
      });
    }
  }, [hotel?.reservation]);

  if (!hotel) {
    return null;
  }

  const showInfoBanner = !paymentLineItems;

  return (
    <Box className="mobile-trip-details">
      <Box className="mobile-trip-details-header">
        <FontAwesomeIcon icon={faChevronLeft} onClick={goBack} />
        <Box className="header-info">
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="caption">
            {t("night", { count: getHotelStayDuration(hotel) })}
          </Typography>
        </Box>
      </Box>
      {isCancelled ? (
        <NotificationBanner
          className="status-banner"
          icon={<Icon name={IconName.ErrorAlert} />}
          label={t("reservationCanceled")}
          severity={BannerSeverity.ERROR}
        />
      ) : null}
      {topActions.length ? <ActionLinks actions={topActions} /> : null}
      <Box
        className={clsx("mobile-trip-card", "mobile-trip-summary-card", {
          "premier-collection": hotel.reservation.isLuxuryCollection,
        })}
      >
        {hotel.reservation.isLuxuryCollection ? (
          <Icon name={IconName.PremierCollectionRibbon} />
        ) : null}

        {isCancelled ? (
          <StatusTag
            className="title-status-tag"
            tagInfo={{
              label: t("cancelled"),
              type: TagColors.RED,
            }}
          />
        ) : null}
        <Typography variant="body1" className="trips-title">
          {title}
        </Typography>
        {subtitle ? (
          <Typography variant="caption" className="trips-subtitle">
            {subtitle}
          </Typography>
        ) : null}
        <Box className="info-container">
          <Typography className="label" variant="caption">
            {t("confirmation")}
          </Typography>
          <Typography
            className={clsx("code", { warning: isCancelled })}
            variant="caption"
          >
            {confirmationCode}
          </Typography>
        </Box>
      </Box>
      {showInfoBanner && (
        <Box className="info-banner">
          <InfoBanner
            icon={clientContext.assets?.infoWhite}
            text={t("infoBanner")}
          />
        </Box>
      )}
      <SummaryCard className="mobile-trip-card mobile-location-details">
        <Box className="mobile-location-details-container">
          <Box className="mobile-hotel-dates">
            <Typography variant="subtitle2" className="date-label">
              {`${t("checkIn")}: `}
            </Typography>
            <Typography variant="subtitle2">{startDate}</Typography>
            <Typography variant="subtitle2">|</Typography>
            <Typography variant="subtitle2" className="date-label">
              {`${t("checkOut")}: `}
            </Typography>
            <Typography variant="subtitle2">{endDate}</Typography>
          </Box>
          <Box className="mobile-hotel-address">
            <Typography variant="body1" className="hotel-address-line">
              {hotelStreet}
            </Typography>
            <Typography variant="caption" className="address-details">
              {`${getPostalCode(reservation)}`}
            </Typography>
            {reservation.lodgingData.phone ? (
              <Typography variant="caption" className="hotel-phone">
                {reservation.lodgingData.phone}
              </Typography>
            ) : null}
          </Box>
          {coordinates ? (
            <img
              src={getGoogleStaticMapSrc(
                coordinates,
                "16",
                "320x320",
                "2",
                true
              )}
              alt=""
            />
          ) : null}
        </Box>
      </SummaryCard>
      <SummaryCard className="mobile-trip-card mobile-trip-room-type">
        <HotelCardRoomTypeContent
          bookedRooms={hotel.reservation.bookedRooms}
          roomInfo={hotel.reservation.roomInfo}
        />
      </SummaryCard>
      {hotel.reservation.isLuxuryCollection ? (
        <PremierCollectionBenefits isMobile />
      ) : null}
      {hotelCancellation ? (
        <SummaryCard className="mobile-trip-card cancellation-summary">
          {hotelCancellation}
        </SummaryCard>
      ) : null}
      <SummaryCard
        className="mobile-trip-card travellers-summary"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={openTravelerModal}
            icon={faChevronRight}
          />
        }
      >
        <TravelersSummary
          label={t("travelerInformationModal.guestInformation")}
          travelers={reservation.guests
            .map((person) => person?.givenName)
            .join(", ")}
        />
      </SummaryCard>
      {/* {hasAddOn ? (
        <SummaryCard className="mobile-trip-card add-ons-summary">
          <AddOnsSummary
            addOns={(() => {
              const addOnItems: AddOn[] = [];
              if (hasCfar) {
                addOnItems.push({
                  expiryDate: cfar?.expired ?? "",
                  expiredTz: cfar?.expiredTz ?? "America/New_York", // TODO: Fall back to EST / EDT for now
                  onClick: () => onOpenModal(MyTripsModalTypes.CfarHotel),
                  isEnabled: !hasCfarExpired(cfar) && !isCancelled,
                  type: AddOnEnum.Cfar,
                });
              }
              return addOnItems;
            })()}
          />
        </SummaryCard>
      ) : null} */}
      <SummaryCard
        className="mobile-payment-summary mobile-trip-card"
        action={
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={openPaymentBreakdownModal}
            icon={faChevronRight}
          />
        }
      >
        <PaymentSummary
          className="mobile-hotel-details-payment-summary"
          tripTotalAmount={formatFiatCurrency(calculatedTripTotal)}
          paymentLineItems={paymentLineItems}
        />
      </SummaryCard>
      <SummaryCard className="mobile-trip-card confirmation-summary">
        <ConfirmationSummary confirmationCode={confirmationCode} />
      </SummaryCard>
      <MobileActionLinks actions={actions} />
      {isCancelled ? (
        <Popover
          anchorEl={refundPopoverRef.current}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          className="how-refunds-work-popover"
          onClose={closeRefundPopover}
          open={refundPopoverOpen}
        >
          <Typography className="subtitle">
            {t("howRefundsWorkSubtitle")}
          </Typography>
        </Popover>
      ) : null}
    </Box>
  );
};
