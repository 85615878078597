import { Typography } from "@material-ui/core";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { ButtonWrap } from "@hopper-b2b/ui";
import { DisplayedPrice } from "@hopper-b2b/wallet";
import { useExperiment } from "@hopper-b2b/experiments";
import { useGetNubankMaxInstallments } from "@hopper-b2b/utilities";
import { ReactComponent as ArrowRight } from "../../assets/client/arrow-right.svg";
import { EXPERIMENTS } from "../../App";
import "./styles.scss";

interface ISharedFlightCtaSectionProps {
  discountedPrice: FiatPrice;
  totalPrice: FiatPrice;
  tripCategory: TripCategory;
  onClick: () => void;
  buttonDisabled?: boolean;
  showCombinedTotal?: boolean;
}

const SharedFlightCtaSection = ({
  discountedPrice,
  totalPrice,
  tripCategory,
  onClick,
  buttonDisabled = false,
  showCombinedTotal = false,
}: ISharedFlightCtaSectionProps) => {
  const { t } = useI18nContext();
  const blackFridayEnabled = useExperiment(EXPERIMENTS.NUBANK_BLACK_FRIDAY);
  const nubankMaxInstallments = useGetNubankMaxInstallments();

  return (
    <div className="fare-details-bottom-wrapper">
      <div className="fare-details-price-summary-wrapper">
        <div className="fare-details-summary-section">
          <div className="description-text">
            {tripCategory === TripCategory.ONE_WAY
              ? t(
                  showCombinedTotal
                    ? "oneWayTripCategoryTotal"
                    : "oneWayPerTraveler"
                )
              : t(
                  showCombinedTotal
                    ? "roundTripCategoryTotal"
                    : "roundTripPerTraveler"
                )}
          </div>
          <div className="price-text">
            <Typography className="content" variant="body1">
              <DisplayedPrice
                discountedCostFiat={discountedPrice}
                totalCostFiat={totalPrice}
              />
            </Typography>
            <div className="installments-badge">
              {/* Black Friday Banner, Remove after 11/12/2024 */}
              {blackFridayEnabled ? (
                <p>
                  {t("upToInterestFreeInstallmentsShort", {
                    count: parseInt(nubankMaxInstallments, 10),
                  })}
                  {" - "}
                  {t("blackFriday.title")}
                </p>
              ) : (
                <p>
                  {t("upToInterestFreeInstallments", {
                    count: parseInt(nubankMaxInstallments, 10),
                  })}
                </p>
              )}
            </div>
          </div>
        </div>
        <ButtonWrap
          className="fare-details-button-section"
          onClick={buttonDisabled ? null : onClick}
        >
          <ArrowRight className={buttonDisabled ? "disabled" : ""} />
        </ButtonWrap>
      </div>
    </div>
  );
};

export default SharedFlightCtaSection;
