import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap } from "@hopper-b2b/ui";

export const PassengerFormMobileTopSaveRightButton = ({
  className,
  onClick,
}: {
  onClick: () => void;
  className: string;
}) => {
  const { t } = useI18nContext();
  return (
    <ButtonWrap className={className} onClick={onClick}>
      {t("travelerFormSaveButton")}
    </ButtonWrap>
  );
};
