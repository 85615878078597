import {
  airDisruptionApi,
  RebookScheduleFulfillResponseEnum,
  CipherText,
  RebookPollFulfillResponseEnum,
  RebookPollFulfillResponse,
  RebookScheduleFulfillRequest,
} from "@b2bportal/air-disruption-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const disruptionRebookFulfillPoll = async (
  req: { contractId: string; token: CipherText },
  index = 0
): Promise<RebookPollFulfillResponse> => {
  const delayTimes = [0, 2000, 4000, 6000, 8000, 10000, 20000, 30000];
  await new Promise((resolve) =>
    setTimeout(resolve, delayTimes[Math.min(index, 7)])
  );
  const response = await airDisruptionApi(
    axios
  ).apiV0DisruptionExerciseRebookFulfillPollPost(req);
  // eslint-disable-next-line no-useless-catch
  try {
    if (
      response.data.RebookPollFulfillResponse ===
      RebookPollFulfillResponseEnum.PollFulfillPending
    ) {
      return disruptionRebookFulfillPoll(req, index++);
    } else if (
      response.data.RebookPollFulfillResponse ===
      RebookPollFulfillResponseEnum.PollFulfillSuccess
    ) {
      return response.data;
    } else {
      throw new Error(
        "disruptionRebookPoll: " + response.data.RebookPollFulfillResponse
      );
    }
  } catch (e) {
    throw e;
  }
};

export const disruptionRebookFulfill = createAsyncThunk<
  RebookPollFulfillResponse,
  RebookScheduleFulfillRequest
>(
  "disruption/exercise/rebook/fulfill/schedule",
  async (request: RebookScheduleFulfillRequest, thunkAPI) => {
    try {
      const res = await airDisruptionApi(
        axios
      ).apiV0DisruptionExerciseRebookFulfillSchedulePost(request);
      if (
        res.data.RebookScheduleFulfillResponse ===
        RebookScheduleFulfillResponseEnum.ScheduleFulfillSuccess
      ) {
        const pollReq = {
          contractId: request.contractId,
          token: res.data.token,
        };
        return disruptionRebookFulfillPoll(pollReq);
      } else {
        throw new Error(
          `disruptionRebookFulfill: ${res.data.RebookScheduleFulfillResponse} ${res.data.reason}`
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
