import {
  IconNameEnum,
  useFintechUiStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreFintechUiComponents,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import horizontalLayoutStyles from "./FintechProductInfoLinksSection.module.scss";
import verticalLayoutStyles from "./FintechProductInfoLinksSection.verticalLayout.module.scss";
import clsx from "clsx";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Icon,
  IframeDialogContent,
} from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FintechProductInfoLinksLayout } from "@b2bportal/core-fintech-ui";
import { useMemo } from "react";

export interface FintechProductInfoLinksSectionProps
  extends PlatformComponentProps {
  howItWorksDialog: JSX.Element;
  viewTermsLink: string;
  layout?: FintechProductInfoLinksLayout;
  showCloseButtonInDialog?: boolean;
  suffixIcon?: { iconName: IconNameEnum; className?: string };
  displayViewTermsAsModal?: boolean;
}

export const FintechProductInfoLinksSection = (
  props: FintechProductInfoLinksSectionProps
) => {
  const COMPONENT_KEY = CoreFintechUiComponents.FintechProductInfoLinksSection;
  const stylesSource = useMemo(() => {
    if (props.layout === FintechProductInfoLinksLayout.Vertical) {
      return verticalLayoutStyles;
    } else {
      return horizontalLayoutStyles;
    }
  }, [props.layout]);
  const styles = useFintechUiStyles(COMPONENT_KEY, stylesSource);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t } = useI18nContext();

  const closeButton = (
    <DialogClose asChild>
      <button className={cn("close-button")}>{t("close.button")}</button>
    </DialogClose>
  );

  return (
    <div className={clsx(block, props.className)}>
      <Dialog>
        <DialogTrigger className={cn("link")}>
          <div className={cn("link-container")}>
            {t("howItWorksDialog.dialogTrigger")}

            {props.suffixIcon != null && (
              <Icon
                iconName={props.suffixIcon.iconName}
                className={clsx(cn("icon"), props.suffixIcon.className)}
              />
            )}
          </div>
        </DialogTrigger>

        <DialogContent showHeaderBottomBorder={false} className={cn("dialog")}>
          {props.howItWorksDialog}
          {props.showCloseButtonInDialog && closeButton}
        </DialogContent>
      </Dialog>

      <div className={cn("separator")} />

      {props.displayViewTermsAsModal ? (
        <Dialog>
          <DialogTrigger className={cn("link")}>
            <div className={cn("link-container")}>
              {t("viewTerms")}
              {props.suffixIcon != null && (
                <Icon
                  iconName={props.suffixIcon.iconName}
                  className={clsx(cn("icon"), props.suffixIcon.className)}
                />
              )}
            </div>
          </DialogTrigger>
          <IframeDialogContent showHeaderBottomBorder={false}>
            <iframe src={props.viewTermsLink}></iframe>
          </IframeDialogContent>
        </Dialog>
      ) : (
        <div className={cn("link-container")}>
          <a
            href={props.viewTermsLink}
            target="_blank"
            rel="noreferrer"
            className={cn("link")}
          >
            {t("viewTerms")}
          </a>
          {props.suffixIcon != null && (
            <Icon
              iconName={props.suffixIcon.iconName}
              className={clsx(cn("icon"), props.suffixIcon.className)}
            />
          )}
        </div>
      )}
    </div>
  );
};
