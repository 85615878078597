import { axiosInstance } from "@hopper-b2b/api";
import {
  ActiveExperimentsRequest,
  GetWalletOffersResponseEnum,
  WalletVouchersResponse,
} from "@hopper-b2b/types";

export const getWalletOffers = async (req: ActiveExperimentsRequest) => {
  try {
    const path = "/api/v0/wallet/vouchers";
    const getOffersResponse = await axiosInstance.put(path, req);

    const offerData: WalletVouchersResponse = getOffersResponse.data;
    if (offerData.GetVouchersResponse === GetWalletOffersResponseEnum.Success) {
      return offerData;
    } else {
      throw Error("invalid wallet response");
    }
  } catch (e) {
    return null;
  }
};
