import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  CallState,
  type SelectedCfarOfferStatus,
  SelectedStatus,
} from "@b2bportal/core-types";

import {
  type AirCfarOffer,
  GetAirCfarOfferResponseEnum,
  type TrackingProperties,
  type AirCfarOfferV2,
  type FareRefundability,
} from "@b2bportal/air-cfar-api";

import { fetchCfarOffers, fetchCfarOffersV2 } from "../thunks";
import { ensureExhaustive } from "@b2bportal/core-utilities";

// State for offer fetching and selection in prebooking funnel
export interface CfarOffersState {
  fetchCfarOffersCallState: CallState;
  cfarOffers: AirCfarOffer[] | AirCfarOfferV2[];
  selectedCfarOffer: SelectedCfarOfferStatus;
  skipCfarTrackingProperties?: TrackingProperties;
  fareRefundability: FareRefundability | undefined;
  fetchCfarOfferTrackingProperties: TrackingProperties | undefined;
}

export const initialCfarOffersState: CfarOffersState = {
  fetchCfarOffersCallState: CallState.NotCalled,
  cfarOffers: [],
  selectedCfarOffer: { status: SelectedStatus.UNSELECTED },
  skipCfarTrackingProperties: undefined,
  fareRefundability: undefined,
  fetchCfarOfferTrackingProperties: undefined,
};

export const cfarOffersSlice = createSlice({
  name: "cfarOffers",
  initialState: initialCfarOffersState,
  reducers: {
    setSelectedCfarOffer: (
      state,
      action: PayloadAction<SelectedCfarOfferStatus>
    ) => {
      state.selectedCfarOffer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCfarOffers.pending, (state, _) => {
        state.fetchCfarOffersCallState = CallState.InProcess;
      })
      .addCase(fetchCfarOffers.rejected, (state, _) => {
        state.fetchCfarOffersCallState = CallState.Failed;
      })
      .addCase(fetchCfarOffers.fulfilled, (state, action) => {
        state.fetchCfarOffersCallState = CallState.Success;
        const response = action.payload;
        const offerResponse = response.GetAirCfarOfferResponse;
        switch (offerResponse) {
          case GetAirCfarOfferResponseEnum.Available:
            state.cfarOffers = response.offers;
            // reset selected offer if the user navigates back and forth
            state.selectedCfarOffer = { status: SelectedStatus.UNSELECTED };
            return;
          case GetAirCfarOfferResponseEnum.NotAvailable:
            state.cfarOffers = [];
            state.skipCfarTrackingProperties = response.trackingProperties;
            // reset selected offer if the user navigates back and forth
            state.selectedCfarOffer = { status: SelectedStatus.UNSELECTED };
            return;
          case GetAirCfarOfferResponseEnum.InvalidOffer:
            state.cfarOffers = [];
            // reset selected offer if the user navigates back and forth
            state.selectedCfarOffer = { status: SelectedStatus.UNSELECTED };
            return;
          default:
            return ensureExhaustive(offerResponse);
        }
      })
      .addCase(fetchCfarOffersV2.pending, (state, _) => {
        state.fetchCfarOffersCallState = CallState.InProcess;
      })
      .addCase(fetchCfarOffersV2.rejected, (state, _) => {
        state.fetchCfarOffersCallState = CallState.Failed;
      })
      .addCase(fetchCfarOffersV2.fulfilled, (state, action) => {
        state.fetchCfarOffersCallState = CallState.Success;
        const response = action.payload;
        state.cfarOffers = response.offers;
        state.fareRefundability = response.fareRefundability;
        state.fetchCfarOfferTrackingProperties = response.trackingProperties;

        state.selectedCfarOffer = { status: SelectedStatus.UNSELECTED };
      });
  },
});

export const cfarOffersActions = cfarOffersSlice.actions;
export const cfarOffersReducer = cfarOffersSlice.reducer;
