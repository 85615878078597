import { Box, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { Person } from "@b2bportal/air-booking-api";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import { getBookingSupportId } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ActionButton,
  Breadcrumbs,
  ContactSupportModalContent,
  DesktopPopupModal,
  GenericModalContent,
  HappyBunny,
  Icon,
  IconName,
  ImportantInfoList,
  LostConnectionBunny,
} from "@hopper-b2b/ui";
import { BookingType, ClientName } from "@hopper-b2b/types";
import { getEnvVariables } from "@hopper-b2b/utilities";

import {
  FlightShopStep,
  flightShopProgressSelector,
} from "../../../shop/reducer";
import { getConfirmPageCopy } from "../../reducer/selectors";
import { submitExchangeReq } from "../../reducer/shop";
import {
  getPassengers,
  getReservationId,
  getSummaryItems,
} from "../../selectors";
import CheckoutBreakdown from "./CheckoutBreakdown";
import NewTripSummary from "./NewTripSummary";
import { setFlightShopProgress } from "../../../shop/actions/actions";

import "./styles.scss";

export interface IConfirmExchangeReqProps {
  backToTrips: () => void;
}

const defaultProps: Partial<IConfirmExchangeReqProps> = {};

const ConfirmExchangeReqStep = (
  props: IConfirmExchangeReqProps
): JSX.Element => {
  const { backToTrips } = props;
  const client = getEnvVariables("clientName") as ClientName;

  const dispatch = useDispatch();
  const { t } = useI18nContext();
  const confirmPageCopy = useSelector(getConfirmPageCopy);
  const passengers = useSelector(getPassengers);
  const bookingId = useSelector(getReservationId);
  const shopStep = useSelector(flightShopProgressSelector);
  const summaryItems = useSelector(getSummaryItems);
  const [numAttempts, setNumAttempts] = useState(0);
  const [supportModalOpen, setSupportModalOpen] = useState(false);

  const isUber = client === ClientName.UBER;

  const submitReq = () => {
    setNumAttempts(numAttempts + 1);
    dispatch(submitExchangeReq(totalCostFiat));
  };

  const onModalClose = () => {
    if (shopStep === FlightShopStep.ExchangeSubmitted) {
      backToTrips();
    } else if (shopStep === FlightShopStep.ExchangeReqFailure) {
      setSupportModalOpen(false);
      dispatch(setFlightShopProgress(FlightShopStep.ExchangeConfirmReq));
    }
  };

  const passengersList = useMemo(() => {
    const { alone, withLapInfants } = passengers;
    const allPassengers: Person[] = alone.map((a) => a.person);

    for (let i = 0; i < withLapInfants.length; i += 1) {
      const { adult, infant } = withLapInfants[i];

      allPassengers.push(adult.person, infant.person);
    }

    return allPassengers;
  }, [passengers]);

  const totalCostFiat = useMemo<FiatPrice>(() => {
    const numPax = passengersList.length || 1;
    let currencyCode = "";
    let currencySymbol = "";
    const total = summaryItems.reduce((acc, item) => {
      ({ currencyCode, currencySymbol } = item.fiatPrice);

      return acc + item.fiatPrice.value;
    }, 0);
    const totalPerPax = total / numPax;

    return { currencyCode, currencySymbol, value: totalPerPax };
  }, [passengersList, summaryItems]);

  const ConfirmReqModalContent = useMemo(() => {
    if (shopStep === FlightShopStep.ExchangeSubmitted) {
      return (
        <GenericModalContent
          actions={
            <ActionButton
              className="exchange-done-btn"
              defaultStyle="h4r-primary"
              message={t("done")}
              onClick={backToTrips}
            />
          }
          className="exchange-req-success-modal"
          image={
            isUber ? (
              <Icon className="success-icon" name={IconName.Checked} />
            ) : (
              <img
                alt="happy bunny"
                className="success-icon"
                src={HappyBunny}
              />
            )
          }
          subtitle={
            <>
              <span>
                {t("exchangeable.submit.success.body1")}
                <b>{t("exchangeable.submit.success.processingTime")}</b>
              </span>
              <br />
              <br />
              <span>{t("exchangeable.submit.success.body2")}</span>
            </>
          }
          title={t("exchangeable.submit.success.title")}
        />
      );
    }

    if (shopStep === FlightShopStep.ExchangeReqFailure) {
      if (supportModalOpen) {
        return (
          <ContactSupportModalContent
            bookingId={bookingId}
            bookingUuid={bookingId}
            bookingType={BookingType.Flight}
            requestType="Exchange"
            getSupportId={getBookingSupportId}
          />
        );
      }

      return (
        <GenericModalContent
          actions={
            <>
              <ActionButton
                className="try-again-btn"
                defaultStyle="h4r-primary"
                message={t("selfServe.tryAgainBtn")}
                onClick={submitReq}
              />
              {numAttempts > 1 && (
                <ActionButton
                  className="contact-support-btn"
                  defaultStyle="h4r-secondary"
                  message={t("selfServe.contactSupport")}
                  onClick={() => setSupportModalOpen(true)}
                />
              )}
            </>
          }
          className="exchange-req-failure-modal"
          image={
            isUber ? (
              <Icon className="failure-icon" name={IconName.ErrorState} />
            ) : (
              <img
                alt="sad bunny"
                className="failure-icon"
                src={LostConnectionBunny}
              />
            )
          }
          subtitle={t("exchangeable.submit.error.subtitle")}
          title={t("exchangeable.submit.error.title")}
        />
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    backToTrips,
    bookingId,
    isUber,
    numAttempts,
    shopStep,
    supportModalOpen,
    t,
  ]);

  return (
    <Box className={clsx("confirm-exchange-req-container", client)}>
      <Breadcrumbs
        showBack
        className="exchange-confirm-breadcrumbs"
        steps={[
          { isSelected: true, key: "submitReq", label: "Submit Request" },
        ]}
      />
      <Box className="header-container">
        {confirmPageCopy?.title ? (
          <Typography className="confirm-header-title" variant="h3">
            {confirmPageCopy.title}
          </Typography>
        ) : (
          <Skeleton />
        )}
        {/* {confirmPageCopy?.body?.map((subtitle: string) => (
          <Typography
            className="confirm-header-subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
            key={subtitle}
          />
        )) ?? <Skeleton />} */}
      </Box>
      <Grid container direction="row" spacing={3}>
        <Grid container item direction="column" spacing={3} xs={8}>
          <Grid item>
            <NewTripSummary />
          </Grid>
          <Grid item>
            <ImportantInfoList
              ordered
              className="hopper-card"
              infoItems={confirmPageCopy.importantInfo}
              title={t("importantInfoTitle")}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <CheckoutBreakdown
            isSubmitting={shopStep === FlightShopStep.ExchangeSubmitting}
            onSubmit={submitReq}
          />
        </Grid>
      </Grid>
      <DesktopPopupModal
        className="submit-request-response-modal"
        contentClassName="exchange-req-response-modal-content"
        invisibleBackdrop={false}
        onClose={onModalClose}
        open={Boolean(ConfirmReqModalContent)}
      >
        {ConfirmReqModalContent}
      </DesktopPopupModal>
    </Box>
  );
};

ConfirmExchangeReqStep.defaultProps = defaultProps;

export default ConfirmExchangeReqStep;
