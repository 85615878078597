import { ButtonWrap, Divider, StatusTag } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  customFormatDateTime,
  dateStringToNewDate,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import {
  FlightInfoDetails,
  IConfirmationNumber,
  MyTripsModalTypes,
  TagInfo,
} from "@hopper-b2b/types";
import { Reservation } from "@b2bportal/lodging-api";
import { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import { ReactComponent as Exchange } from "../../../assets/client/exchange.svg";
import { copyTextFromWebview } from "../../../utils/nubankHandoff";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";

export interface TripDetailsSummaryProps {
  confirmationNumbers: IConfirmationNumber[];
  onClick?: (modalType: MyTripsModalTypes) => void;
  flightInfoDetails?: FlightInfoDetails;
  hotelDetails?: Reservation;
  title: string;
  titleTag?: TagInfo | undefined;
  isMultiAirline?: boolean;
  flight?: BookedFlightItineraryWithDepartureTime;
  disruptionBanner?: JSX.Element;
}

const TripDetailsSummary = ({
  confirmationNumbers,
  onClick,
  flightInfoDetails,
  hotelDetails,
  title,
  titleTag,
  isMultiAirline,
  flight,
  disruptionBanner,
}: TripDetailsSummaryProps) => {
  const { t, brand, language: locale } = useI18nContext();
  const { copy } = useTenantIcons();

  const tripCategory = flightInfoDetails
    ? flightInfoDetails.roundTrip
      ? t("roundTrip")
      : t("oneWay")
    : hotelDetails
    ? `${
        hotelDetails.lodgingData?.city
          ? `${hotelDetails.lodgingData?.city}, `
          : ""
      }${hotelDetails.lodgingData?.country || ""}`
    : null;

  const dates = flightInfoDetails
    ? `${flightInfoDetails?.startDate} ${
        flightInfoDetails?.endDate ? ` - ${flightInfoDetails?.endDate}` : ""
      }`
    : hotelDetails
    ? `${customFormatDateTime(
        dateStringToNewDate(hotelDetails.checkInDate),
        locale,
        DateTimeFormatStyle.ShortMonthDayShortWeekday,
        brand?.customDateTimeFormats?.tripsList
      )} - ${customFormatDateTime(
        dateStringToNewDate(hotelDetails.checkOutDate),
        locale,
        DateTimeFormatStyle.ShortMonthDayShortWeekday,
        brand?.customDateTimeFormats?.tripsList
      )}`
    : null;

  const disruptionAlertBanner = disruptionBanner && (
    <div className="disruption-banner-container">{disruptionBanner}</div>
  );

  return (
    <div className="mobile-trip-details-summary slot">
      <div className="mobile-trip-details-summary-header">
        <div className="mobile-trip-details-locations">{title}</div>
        <div className="mobile-trip-details-dates">{dates}</div>
        <div className="mobile-trip-details-trip-category">{tripCategory}</div>
        {titleTag ? (
          <StatusTag
            className="mobile-trip-details-status"
            tagInfo={titleTag!}
          />
        ) : null}
        {isMultiAirline ? (
          <div className="mobile-trip-details-multi-airline">
            <div className="mobile-trip-details-multi-airline-image-container">
              <Exchange className="mobile-trip-details-multi-airline-image" />
            </div>
            <span className="mobile-trip-details-multi-airline-content">
              {t("multiAirlineTrip")}
            </span>
          </div>
        ) : null}
        {disruptionAlertBanner}
        <Divider className="mobile-trip-details-divider-reservation-numbers" />
      </div>

      <div className="mobile-trip-details-flight-details" onClick={onClick}>
        <div className="mobile-trip-details-confirmation-numbers-container mobile-trip-details-code-container">
          <h3 className="mobile-trip-details-confirmation-title title">
            {t("confirmationNumber")}
          </h3>
          {confirmationNumbers
            ? confirmationNumbers.map((confirmationNumber, i) => {
                return i !== 0 ? (
                  <div
                    className="mobile-trip-details-confirmation-number code"
                    key={`flight-confirmation-numbers-${i}`}
                  >
                    <p className="flight-confirmation-number">
                      {confirmationNumber.locator}
                    </p>
                    {flightInfoDetails ? (
                      <ButtonWrap
                        className="confirmation-copy-container"
                        onClick={(event) => {
                          event.stopPropagation();
                          copyTextFromWebview(confirmationNumber.locator);
                        }}
                        aria-label={t("copy")}
                      >
                        <img src={copy} className="copy-icon" alt="" />
                      </ButtonWrap>
                    ) : null}
                  </div>
                ) : null;
              })
            : flightInfoDetails?.flightNumber}
        </div>

        <Divider className="mobile-trip-details-divider" />

        <div className="mobile-trip-details-confirmation-number mobile-trip-details-code-container">
          <div className="mobile-trip-details-confirmation-content">
            <h3 className="flight-confirmation-label title">{t("nuTravel")}</h3>
            <p className="flight-confirmation-number code">
              {flightInfoDetails?.confirmationCode ||
                hotelDetails?.reservationId}
            </p>
          </div>
          <ButtonWrap
            className="confirmation-copy-container"
            onClick={() => {
              copyTextFromWebview(
                flightInfoDetails?.confirmationCode ||
                  hotelDetails?.reservationId
              );
            }}
            aria-label={t("copy")}
          >
            <img src={copy} className="copy-icon" alt="" />
          </ButtonWrap>
        </div>
      </div>
    </div>
  );
};

export default TripDetailsSummary;
