import dayjs from "dayjs";
import {
  AirlineCode,
  CoreDisruptionComponent,
  ITimeRange,
} from "@b2bportal/core-types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { DisruptionExpandableContainer } from "../DisruptionExpandableContainer";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseShopFilter.module.scss";
import { DisruptionExerciseContainer } from "@b2bportal/core-disruption";
import { DisruptionExerciseCheckbox } from "../DisruptionExerciseCheckbox";
import { DisruptionExerciseTimeWindowSlider } from "../DisruptionExerciseTimeWindowSlider";

export interface DisruptionExerciseShopFilterProps {
  origin: string;
  destination: string;
  stopsOptions: Array<number>;
  selectedStops: Array<number>;
  airlinesOptions: Array<RebookAirlineOptions>;
  selectedAirlines: Array<string>;
  handleStopsSelection: (selected: boolean, numberOfStops: number) => void;
  handleAirlineSelection: (
    selected: boolean,
    airline: RebookAirlineOptions
  ) => void;
  departureTimeRange: ITimeRange;
  arrivalTimeRange: ITimeRange;
  handleDepartureTimeChange: (min: number, max: number) => void;
  handleArrivalTimeChange: (min: number, max: number) => void;
  flightDurationRange: ITimeRange;
  handleFlightDurationChange: (number) => void;
  selectedFlightDuration: number;
  layoverDurationRange: ITimeRange;
  handleLayoverDurationChange: (min: number, max: number) => void;
  selectedLayoverDurationRange: ITimeRange;
}

export interface RebookAirlineOptions {
  label: AirlineCode;
  value: AirlineCode;
}

export const DisruptionExerciseShopFilter = ({
  origin,
  destination,
  stopsOptions,
  selectedStops,
  airlinesOptions,
  selectedAirlines,
  handleStopsSelection,
  handleAirlineSelection,
  departureTimeRange,
  arrivalTimeRange,
  handleDepartureTimeChange,
  handleArrivalTimeChange,
  flightDurationRange,
  handleFlightDurationChange,
  selectedFlightDuration,
  layoverDurationRange,
  handleLayoverDurationChange,
  selectedLayoverDurationRange,
}: DisruptionExerciseShopFilterProps) => {
  const { t, brand } = useI18nContext();
  const TIME_RANGE_MAX = 1439;
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseShopFilter;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const TRANS_PATH =
    "core-disruption.disruptionUniversalExercise.rebook.flightShopPage.filters";

  const airlinesFilter = airlinesOptions.map((airline) => {
    return (
      <DisruptionExerciseCheckbox
        label={airline.label}
        onChange={(selected) => {
          handleAirlineSelection(selected, airline);
        }}
        selected={selectedAirlines.includes(airline.value)}
        key={airline.label}
      />
    );
  });

  const stopsFilters = stopsOptions.map((numberOfStops, index) => {
    const getLable = () => {
      switch (numberOfStops) {
        case 0:
          return t("core-disruption.disruptionUniversalExercise.rebook.direct");
        case 1:
          return t(
            "core-disruption.disruptionUniversalExercise.rebook.stopsSingle",
            {
              stops: numberOfStops,
            }
          );
        default:
          return t("core-disruption.disruptionUniversalExercise.rebook.stops", {
            stops: numberOfStops,
          });
      }
    };

    return (
      <DisruptionExerciseCheckbox
        label={getLable()}
        onChange={(selected) => {
          handleStopsSelection(selected, numberOfStops);
        }}
        selected={selectedStops.includes(numberOfStops)}
        key={index}
      />
    );
  });

  const getDateLabel = (value: number) => {
    return dayjs()
      .hour(0)
      .minute(value)
      .format(brand.timeFormat || "hh:mm A");
  };

  const getHoursString = (value: number) => {
    if (value === 1) {
      return t(
        "core-disruption.disruptionUniversalExercise.rebook.hoursSingular",
        {
          hours: value,
        }
      );
    } else {
      return t("core-disruption.disruptionUniversalExercise.rebook.hours", {
        hours: value,
      });
    }
  };

  return (
    <DisruptionExerciseContainer
      content={
        <div className={cn("rebookShopFilters")}>
          <div className={cn("title")}>{t(`${TRANS_PATH}.title`)}</div>
          <br></br>
          <DisruptionExpandableContainer
            title={
              <span className={cn("sectionTitle")}>
                {t(`${TRANS_PATH}.stops`)}
              </span>
            }
            content={
              <div className={cn("stopsFilters")}>
                <br></br>
                {stopsFilters}
              </div>
            }
          />
          <DisruptionExpandableContainer
            title={
              <span className={cn("sectionTitle")}>
                {t(`${TRANS_PATH}.times`)}
              </span>
            }
            content={
              <div className={cn("slidersContainer")}>
                <br></br>
                <DisruptionExerciseTimeWindowSlider
                  onChange={handleDepartureTimeChange}
                  sliderMax={TIME_RANGE_MAX}
                  type="doubleThumb"
                  timeRange={departureTimeRange}
                  label={t(`${TRANS_PATH}.departureInOrigin`, {
                    origin: origin,
                  })}
                  getLabel={getDateLabel}
                  sliderMin={0}
                />
                <DisruptionExerciseTimeWindowSlider
                  onChange={handleArrivalTimeChange}
                  sliderMax={TIME_RANGE_MAX}
                  type="doubleThumb"
                  timeRange={arrivalTimeRange}
                  label={t(`${TRANS_PATH}.arrivalInDestination`, {
                    destination: destination,
                  })}
                  getLabel={getDateLabel}
                  sliderMin={0}
                />
                <DisruptionExerciseTimeWindowSlider
                  onChange={(min, max) => handleFlightDurationChange(min)}
                  sliderMax={flightDurationRange.max}
                  type="singleThumb"
                  timeRange={{ min: selectedFlightDuration, max: 0 }}
                  label={t(`${TRANS_PATH}.flightDuration`)}
                  getLabel={getHoursString}
                  sliderMin={flightDurationRange.min}
                />
                <DisruptionExerciseTimeWindowSlider
                  onChange={handleLayoverDurationChange}
                  sliderMax={layoverDurationRange.max}
                  type="doubleThumb"
                  timeRange={selectedLayoverDurationRange}
                  label={t(`${TRANS_PATH}.layoverDuration`, {
                    destination: destination,
                  })}
                  getLabel={getHoursString}
                  sliderMin={layoverDurationRange.min}
                />
              </div>
            }
          />
          <DisruptionExpandableContainer
            title={
              <span className={cn("sectionTitle")}>
                {t(`${TRANS_PATH}.airlines`)}
              </span>
            }
            content={
              <div className={cn("airlinesFilter")}>
                <br></br>
                {airlinesFilter}
              </div>
            }
          />
        </div>
      }
    />
  );
};

export default DisruptionExerciseShopFilter;
