import { ConfigTheme, fromBasicConfig } from "@hopper-b2b/portal";
import { ClientName } from "@hopper-b2b/types";
import { images } from "../src/theme/images";
import { styleOverrides } from "../src/overrides";
import translation from "./translation";
import { branding } from "../src/modules/branding";
import { nubankUltravioletaDarkVariables } from "../src/modules/ultravioletaDarkTheme";
import { nubankUltravioletaVariables } from "../src/modules/ultravioletaDefaultTheme";
import { nubankDarkVariables } from "../src/modules/darkTheme";
import { nubankVariables } from "../src/modules/defaultTheme";
import { defaultIcons } from "@b2bportal/core-themes";

import {
  darkModeIconsSvg,
  lightModeIconsSvg,
} from "../src/components/Body/sharedIconsSvg";

export const corePortalConfigFromBasicConfig = (
  darkMode: boolean,
  type: "uv" | "default"
) => {
  const themeVariables = (): Pick<ConfigTheme, "variables" | "icons"> => {
    const icons = {
      ...defaultIcons,
    };
    switch (type) {
      case "uv":
        return darkMode
          ? {
              variables: nubankUltravioletaDarkVariables,
              icons: {
                ...icons,
                ...darkModeIconsSvg,
              },
            }
          : {
              variables: nubankUltravioletaVariables,
              icons: {
                ...icons,
                ...lightModeIconsSvg,
              },
            };
      default:
        return darkMode
          ? {
              variables: nubankDarkVariables,
              icons: {
                ...icons,
                ...darkModeIconsSvg,
              },
            }
          : {
              variables: nubankVariables,
              icons: {
                ...icons,
                ...lightModeIconsSvg,
              },
            };
    }
  };

  return fromBasicConfig({
    clientName: ClientName.NUBANK,
    bootConfig: {},
    theme: {
      ...themeVariables(),
      styleOverrides: styleOverrides,
      images: images,
    },
    featureFlags: {},
    branding: branding,
    translations: translation,
  });
};
