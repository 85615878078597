import { Fare, Flights, Slice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AlgomerchTag,
  FareDetails,
  FlightRatingsEnum,
  FlightShopCardType,
  IFlightGridFares,
  TagType,
  getTags,
} from "@hopper-b2b/types";
import { ButtonWrap, FlightGridRow, Slot, TriangleIcon } from "@hopper-b2b/ui";
import {
  formatInterval,
  getAirlinesCountSegment,
  getPlusDaysOnSlice,
  getPlusDaysOnSliceWithDates,
  getRewardText,
  hasHTSConnectSupport,
  useDeviceTypes,
  useEnableSingleTapSelectFlight,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { matchPath } from "react-router";

import { PATH_EXCHANGE } from "../../../../../../../utils/urlPaths";
import { FareclassOptionFilter } from "../../../../../../search/reducer";
import { IFlightListData } from "../../component";

export enum FlightCardType {
  content = "content",
  skeleton = "skeleton",
}
interface IFlightListInfoBaseProps {
  className?: string;
  type: FlightCardType;
}

export interface IFlightListInfoContentProps extends IFlightListInfoBaseProps {
  selectedFare: any;
  slice: Slice;
  onClick?: (selectedFareRating: number) => void;
  onFareClick?: (fareId: string) => void;
  isExpanded?: boolean;
  type: FlightCardType.content;
  flights: Flights;
  flight: IFlightListData;
  rewardsKey: string;
  fareClassFilter: FareclassOptionFilter;
  onAlgomerchClick: (val: string) => void;
  maxFlightPrice: number;
  isRoundTrip: boolean;
  previewedFareId: string;
  onFareSubmit?: (_sliceId: string, fare: FareDetails) => void;
}

export interface IFlightListInfoSkeletonProps extends IFlightListInfoBaseProps {
  type: FlightCardType.skeleton;
}
const FARE_GRID_TAG_LIMIT = 2;

export const FlightListInfoContent = (props: IFlightListInfoContentProps) => {
  const {
    selectedFare,
    slice,
    isExpanded,
    flight,
    onClick,
    flights,
    rewardsKey,
    fareClassFilter,
    onAlgomerchClick,
    onFareClick,
    maxFlightPrice,
    isRoundTrip,
    previewedFareId,
    onFareSubmit,
  } = props;
  const { matchesMobile, matchesMediumDesktopOnly, matchesLargeDesktop } =
    useDeviceTypes();
  const { t, formatFiatCurrency, brand } = useI18nContext();
  const isHTSConnect = hasHTSConnectSupport();
  const enableSingleTapSelectFlight = useEnableSingleTapSelectFlight();
  const inExchange = matchPath(window.location.pathname, PATH_EXCHANGE);
  const airline = flights.airlines[slice.marketingAirline];
  const rewards = selectedFare.amount.rewards[rewardsKey];
  const rewardsPriceText = rewards && rewardsKey && getRewardText(rewards);
  const additionalAirlinesCount = getAirlinesCountSegment(slice.segments);

  const plusDays = !isHTSConnect
    ? getPlusDaysOnSlice(slice)
    : getPlusDaysOnSliceWithDates(slice.departure, slice.arrival);

  const getFareSliceId = (fare: Fare): string => {
    return fare?.fareSlice || fare?.return || "";
  };

  const fareSlice = flights.fareSlices[getFareSliceId(selectedFare)];
  const tags = [
    fareSlice?.tags.isBest ? AlgomerchTag.BestFlight : null,
    fareSlice?.tags.isCheapest ? AlgomerchTag.Cheapest : null,
    fareSlice?.tags.isBestQuality ? AlgomerchTag.BestQuality : null,
    fareSlice?.tags.isFastest ? AlgomerchTag.Fastest : null,
  ]
    .filter((t): t is AlgomerchTag => t !== null)
    .map((value) => ({ value, type: TagType.Algomerch }));

  const getFareId = (fare: Fare) => {
    return fare.example?.fare || fare?.id;
  };

  const getFlightGridFares = useCallback(
    (flight: IFlightListData) => {
      const fares: IFlightGridFares = {
        basic: null,
        standard: null,
        premium: null,
        enhanced: null,
        luxury: null,
      };
      flight.fares.forEach((fare: Fare) => {
        const fareSlice = flights.fareSlices[getFareSliceId(fare)];
        const fareClass = FlightRatingsEnum[fareSlice.fareShelf.value];
        const existingPrice = fares[fareClass]?.rawPrice;
        const fiatValue = fare.amount?.fiat.value;
        if (
          fiatValue &&
          (fares[fareClass] === null ||
            (existingPrice && existingPrice > fiatValue))
        ) {
          const tags = getTags(fareSlice?.tags);
          // const reward = rewardsKey ? fare.amount?.rewards[rewardsKey] : "";

          const gridFare = {
            fareId: getFareId(fare),
            fareName: fareSlice?.fareBrandName ?? "",
            fareClass: fareClass,
            price: `${fare.amount?.fiat.currencySymbol}${Math.round(
              fiatValue
            )}`,
            rawPrice: fiatValue,
            fiatPrice: fare.amount?.fiat,
            tags,
          };
          // if (reward) gridFare["reward"] = getRewardsString(reward);

          fares[fareClass] = gridFare;
        }
      });
      return fares;
    },
    [flights.fareSlices]
  );

  const gridFares = useMemo(
    () => getFlightGridFares(flight),
    [flight, getFlightGridFares]
  );

  const renderRowDesktopFlightListInfo = () => {
    const { fiat } = selectedFare.amount;
    let currentPriceText = formatFiatCurrency(fiat, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });

    if (inExchange) {
      const { currencySymbol, value } = fiat;

      // exchange shop returns prices as deltas from original flight
      if (value >= 0) currentPriceText = `+${currentPriceText}`;
      else
        currentPriceText = t("exchangeable.shop.minDelta", { currencySymbol });
    }

    return (
      <ButtonWrap
        className={clsx("flight-card-wrapper", {
          "medium-desktop": matchesMediumDesktopOnly,
          "large-desktop": matchesLargeDesktop,
        })}
        onClick={!enableSingleTapSelectFlight ? handleOnClick : null}
      >
        <Slot id="fare-card-header" fareId={getFareId(selectedFare)} />
        <Slot
          id="flight-shop-row"
          airlineCode={airline.code}
          airlineName={airline.displayName}
          arrivalTime={slice.arrival}
          className={clsx("small-flight-shop-row", "b2b")}
          currentPriceText={currentPriceText}
          departureTime={slice.departure}
          destinationCode={slice.destination}
          duration={formatInterval(slice.totalDurationMinutes)}
          segments={slice.segments}
          flight={flight}
          isExpanded={isExpanded}
          key={slice.id}
          layoverString={
            slice.stops === 0
              ? t("stopDetails.nonstop")
              : t("stopDetails.stop", { count: slice.stops })
          }
          onClickTag={(label: string) => onAlgomerchClick(label)}
          originCode={slice.origin}
          tags={tags}
          tagsLimit={FARE_GRID_TAG_LIMIT}
          additionalAirlinesCount={additionalAirlinesCount}
          plusDays={plusDays}
          fareBrandName={fareSlice.fareBrandName}
          isRoundTrip={isRoundTrip}
          onClick={handleOnClick}
          onFareSubmit={onFareSubmit}
        />
        <TriangleIcon
          aria-label="triangle icon"
          className={clsx("expand-flight-row-icon", {
            "is-expanded": isExpanded,
          })}
        />
      </ButtonWrap>
    );
  };

  const handleFareClick = useCallback(
    (fareId: string) => {
      onFareClick && onFareClick(fareId);
    },
    [onFareClick]
  );

  const renderGridDesktopFlightListInfo = () => {
    return (
      // Added button wrap here to match existing behaviour, but in terms of HTML semantics this is incorrect.
      // We should not have interactive element inside other interactive elements.
      // This should be removed and only grid fare elements should have onclick.
      <ButtonWrap onClick={handleOnClick}>
        <FlightGridRow
          key={slice.id}
          isRoundTrip={isRoundTrip}
          className={clsx("flight-grid-row", `row-${slice.id}`)}
          airlineCode={airline.code}
          airlineName={airline.displayName}
          fareClassFilter={fareClassFilter}
          selectedMaxPrice={maxFlightPrice}
          fares={gridFares}
          departureTime={slice.departure}
          arrivalTime={slice.arrival}
          originCode={slice.origin}
          destinationCode={slice.destination}
          duration={formatInterval(slice.totalDurationMinutes)}
          selectedFare={previewedFareId}
          layoverString={
            slice.stops === 0
              ? t("stopDetails.nonstop")
              : t("stopDetails.stop", { count: slice.stops })
          }
          onFareClick={handleFareClick}
          algomerchModalOpen={false}
          onAlgomerchInfoClick={(label: string) => onAlgomerchClick(label)}
          tagsLimit={FARE_GRID_TAG_LIMIT}
          isExpanded={isExpanded}
          fareCardClassName="b2b"
          type="content"
        />
      </ButtonWrap>
    );
  };

  const renderMobileFlightListInfo = () => {
    const { fiat } = selectedFare.amount;
    const airports = slice.segments
      .slice(0, slice.segments.length - 1)
      .map((s) => s.destination);

    const layoverDuration =
      slice.segments.length > 1
        ? formatInterval(slice.segments[0].stopoverDurationMinutes || 0)
        : null;

    const layoverText =
      isExpanded && slice.segments.length > 1
        ? t("layoverWithLocation", {
            duration: formatInterval(
              slice.segments[0].stopoverDurationMinutes || 0
            ),
            location: slice.segments[0].destination,
          }) +
          (slice.segments.length > 2
            ? //Adding space before additional layover string
              ` ${t("additionalLayoverStops", {
                remainingStops: slice.segments.length - 2,
              })}`
            : "")
        : null;

    const plusDaysText = isExpanded && plusDays ? t("flightNextDay") : null;
    let currentPriceText = formatFiatCurrency(fiat, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });

    if (inExchange) {
      const { currencySymbol, value } = fiat;

      // exchange shop returns prices as deltas from original flight
      if (value >= 0) currentPriceText = `+${currentPriceText}`;
      else
        currentPriceText = t("exchangeable.shop.minDelta", {
          currencySymbol,
        });
    }

    const fareMultiTicketType =
      flights.fares[getFareId(selectedFare)].multiTicketType;

    return (
      <ButtonWrap className="flight-card-wrapper" onClick={handleOnClick}>
        {isExpanded ? null : (
          <Slot id="fare-card-header" fareId={getFareId(selectedFare)} />
        )}
        <Slot
          id={"flight-shop-list-mobile-card"}
          duration={formatInterval(slice.totalDurationMinutes)}
          currentPriceText={currentPriceText}
          rewardText={rewardsPriceText}
          originCode={slice.origin}
          destinationCode={slice.destination}
          departureTime={slice.departure}
          arrivalTime={slice.arrival}
          airports={airports}
          brandName={fareSlice.fareBrandName ?? ""}
          primaryCarrier={airline?.code}
          airlineName={airline?.displayName}
          flight={flight}
          tags={tags}
          cardType={brand.flightShopCardType || FlightShopCardType.REGULAR}
          additionalInfo={{
            isExpanded: isExpanded,
            layoverText: layoverText,
            layoverDuration: layoverDuration,
            plusDays: plusDaysText,
          }}
          fiat={fiat}
          fareMultiTicketType={fareMultiTicketType}
          // htsConnect slot use only
          additionalAirlinesCount={additionalAirlinesCount}
          isRoundTrip={isRoundTrip}
          plusDays={isHTSConnect && plusDays}
          isExpanded={isExpanded}
        />
      </ButtonWrap>
    );
  };

  const handleOnClick = useCallback(() => {
    onClick && onClick(fareSlice.fareShelf.value);
    if (!matchesLargeDesktop) {
      onFareClick && onFareClick(getFareId(selectedFare));
    }
  }, [
    fareSlice.fareShelf.value,
    matchesLargeDesktop,
    onClick,
    onFareClick,
    selectedFare,
  ]);

  return (
    <div className={"flight-list-info-root"}>
      {matchesMobile
        ? renderMobileFlightListInfo()
        : matchesMediumDesktopOnly || isHTSConnect
        ? renderRowDesktopFlightListInfo()
        : renderGridDesktopFlightListInfo()}
    </div>
  );
};

export type IFlightListInfoProps =
  | IFlightListInfoContentProps
  | IFlightListInfoSkeletonProps;

export const FlightListInfo = (props: any) => {
  switch (props.type) {
    case "content":
      return <FlightListInfoContent {...props} />;
    case "skeleton":
      return (
        <Box className={"flight-list-info-root"}>
          <FlightGridRow {...props} />
        </Box>
      );
    default:
      return null;
  }
};
