import { useI18nContext } from "@hopper-b2b/i18n";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseRefundSummaryCard.module.scss";
import { Payment, PaymentOpaqueValue } from "@b2bportal/air-disruption-api";
import { DisruptionExerciseContainer } from "../DisruptionExerciseContainer";

export interface DisruptionExerciseRefundSummaryCardProps {
  reimbursementTotalAmount: FiatPrice;
  refundBreakdown: Array<PaymentOpaqueValue> | null;
}
export const DisruptionExerciseRefundSummaryCard = ({
  reimbursementTotalAmount,
  refundBreakdown,
}: DisruptionExerciseRefundSummaryCardProps) => {
  const { t, formatFiatCurrency, formatCurrency } = useI18nContext();
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseRefundSummaryCard;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const getLineItem = (title: string, value: string) => {
    return (
      <div className={cn("lineItemRow")}>
        <span className={cn("lineItemTextTitle")}>{title}</span>
        <span className={cn("lineItemTextValue")}>{value}</span>
      </div>
    );
  };

  const refundBreakdownRows = refundBreakdown?.map((refundLineItem) => {
    switch (refundLineItem.type) {
      case Payment.Card:
        return getLineItem(
          t(
            "core-disruption.disruptionUniversalExercise.refundReviewPage.refundToCard",
            {
              cardDisplay: refundLineItem.value.cardNumberDisplay,
            }
          ),
          formatCurrency(refundLineItem.value.amount.amount, {
            currency: refundLineItem.value.amount.currency,
          })
        );
      case Payment.Wallet:
      case Payment.TravelWalletOffer:
      case Payment.TravelWalletCredit:
        return getLineItem(
          t(
            "core-disruption.disruptionUniversalExercise.refundReviewPage.travelCreditRefund"
          ),
          formatCurrency(refundLineItem.value.amount.amount, {
            currency: refundLineItem.value.amount.currency,
          })
        );
      case Payment.Rewards:
        return getLineItem(
          t(
            "core-disruption.disruptionUniversalExercise.refundReviewPage.rewardsRefund"
          ),
          formatCurrency(refundLineItem.value.amount.fiatValue.amount, {
            currency: refundLineItem.value.amount.fiatValue.currency,
          })
        );
      case Payment.NupayPayment:
        if (refundLineItem.value.PaymentLineItem === "NupayDebit") {
          return getLineItem(
            t(
              "core-disruption.disruptionUniversalExercise.refundReviewPage.accountBalance"
            ),
            formatCurrency(refundLineItem.value.amount.amount, {
              currency: refundLineItem.value.amount.currency,
            })
          );
        } else {
          return getLineItem(
            t(
              "core-disruption.disruptionUniversalExercise.refundReviewPage.creditBalance"
            ),
            formatCurrency(refundLineItem.value.amount.amount, {
              currency: refundLineItem.value.amount.currency,
            })
          );
        }
      default:
        return null;
    }
  });

  const infoRows = (
    <div className={cn("disruption-exercise-refund-review-card")}>
      <div className={cn("disruption-exercise-refund-review-card-header")}>
        <div className={cn("disruption-exercise-refund-review-card-title")}>
          <span>
            {t(
              "core-disruption.disruptionUniversalExercise.refundReviewPage.confirmRefundRequest"
            )}
          </span>
        </div>

        <div className={cn("disruption-exercise-refund-review-card-subtitle")}>
          <span>
            {t(
              "core-disruption.disruptionUniversalExercise.refundReviewPage.youllReceiveARefund"
            )}
          </span>
        </div>

        <hr></hr>
      </div>
    </div>
  );

  const totalRow = (
    <div className={cn("disruption-exercise-refund-review-card")}>
      <div className={cn("disruption-exercise-refund-review-total-refund")}>
        <span
          className={cn("disruption-exercise-refund-review-total-refund-title")}
        >
          {t(
            "core-disruption.disruptionUniversalExercise.refundReviewPage.totalRefund"
          )}
        </span>
        <span
          className={cn("disruption-exercise-refund-review-total-refund-value")}
        >
          {formatFiatCurrency(reimbursementTotalAmount)}
        </span>
      </div>
    </div>
  );

  const content = (
    <div
      className={cn("disruption-exercise-refund-review-container-no-border")}
    >
      {infoRows}
      {refundBreakdownRows?.length !== 0 && (
        <div className={cn("lineItemRowsContainer")}>{refundBreakdownRows}</div>
      )}
      {totalRow}
    </div>
  );

  return (
    <DisruptionExerciseContainer
      content={
        <div
          className={cn(
            "disruption-exercise-refund-review-container-no-border"
          )}
        >
          {content}
        </div>
      }
    ></DisruptionExerciseContainer>
  );
};

export default DisruptionExerciseRefundSummaryCard;
