import { PlatformComponentProps } from "@b2bportal/core-types";
import clsx from "clsx";
import { PropsWithChildren } from "react";

export interface FlightCardWrapperProps extends PlatformComponentProps {
  onClick?: () => void;
}
export const FlightCardWrapper = (
  props: PropsWithChildren<FlightCardWrapperProps>
) => (
  <button className={clsx(props.className)} onClick={props.onClick}>
    {props.children}
  </button>
);
