import { useMemo } from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import {
  LodgingSelectors,
  useCheckoutStateSelector as useSelector,
  WalletSelectors,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap } from "@hopper-b2b/ui";
import { DisplayedPrice } from "@hopper-b2b/wallet";
import { useExperiment } from "@hopper-b2b/experiments";
import {
  roundToTwoDecimals,
  useGetNubankMaxInstallments,
} from "@hopper-b2b/utilities";
import { getRoomPricing } from "../../../modules/lodging-checkout/selectors";
import { ReactComponent as ArrowRight } from "../../../assets/client/arrow-right.svg";
import { EXPERIMENTS } from "../../../App";
import "./styles.scss";

export interface ICheckoutCtaSectionProps {
  onClick: () => void;
  buttonDisabled?: boolean;
  showCombinedTotal?: boolean;
  priceLoading?: boolean;
}

const HotelCheckoutCtaSection = ({
  onClick,
  buttonDisabled = false,
  priceLoading = false,
}: ICheckoutCtaSectionProps) => {
  const { t } = useI18nContext();
  const blackFridayEnabled = useExperiment(EXPERIMENTS.NUBANK_BLACK_FRIDAY);
  const { roomPriceTotalWithTaxesAndFees } = useSelector(getRoomPricing);
  const selectedNights = useSelector(LodgingSelectors.getTotalNights);
  const isOfferApplied = useSelector(WalletSelectors.getIsWalletOfferApplied);
  const hotelPricingBreakdown = useSelector(
    LodgingSelectors.getQuotedLodgingBreakdown
  );
  const hotelPricing = useSelector(
    LodgingSelectors.getQuotedLodgingProductPricingV1
  );
  const nubankMaxInstallments = useGetNubankMaxInstallments();
  const discountedRoomPriceTotalWithTaxesAndFees = useMemo(() => {
    return {
      ...hotelPricingBreakdown?.balance?.fiat,
      value: roundToTwoDecimals(
        hotelPricingBreakdown?.balance?.fiat?.value +
          (hotelPricing?.paymentSchedule?.payLaterTotal?.fiat?.value ?? 0)
      ),
    };
  }, [hotelPricing, hotelPricingBreakdown]);

  return (
    <div className="fare-details-bottom-wrapper">
      <div className="fare-details-price-summary-wrapper">
        <div className="fare-details-summary-section">
          <div className="description-text">
            {t("totalPricingPerNight", { count: selectedNights })}
          </div>
          <div className="price-text">
            <Typography
              className={clsx("content", priceLoading ? "loading" : null)}
              variant="body1"
            >
              <DisplayedPrice
                discountedCostFiat={null}
                totalCostFiat={
                  isOfferApplied
                    ? discountedRoomPriceTotalWithTaxesAndFees
                    : roomPriceTotalWithTaxesAndFees
                }
              />
            </Typography>
            <div className="installments-badge">
              {/* Black Friday Banner, Remove after 11/12/2024 */}
              {blackFridayEnabled ? (
                <p>
                  {t("upToInterestFreeInstallmentsShort", {
                    count: parseInt(nubankMaxInstallments, 10),
                  })}
                  {" - "}
                  {t("blackFriday.title")}
                </p>
              ) : (
                <p>
                  {t("upToInterestFreeInstallments", {
                    count: parseInt(nubankMaxInstallments, 10),
                  })}
                </p>
              )}
            </div>
          </div>
        </div>
        <ButtonWrap
          className="fare-details-button-section"
          onClick={buttonDisabled ? null : onClick}
        >
          <ArrowRight className={buttonDisabled ? "disabled" : ""} />
        </ButtonWrap>
      </div>
    </div>
  );
};

export default HotelCheckoutCtaSection;
