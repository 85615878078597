import { trackEvent } from "@hopper-b2b/api";
import { useExperiments } from "@hopper-b2b/experiments";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  type FareDetails,
  FlightShopType,
  type IFlightListData,
  SELECTED_FLIGHT,
  type TenantCallbacks,
  VIEWED_FLIGHT_LIST,
  VIEWED_FLIGHT_LIST_ERROR,
  VIEWED_SLICE,
  type ViewedSliceProperties,
  getHopperFareRatingName,
} from "@hopper-b2b/types";
import { LoadingScreenWithProgress, Slot } from "@hopper-b2b/ui";
import {
  TenantFlag,
  getEnvVariables,
  tenantFlagsEnabled,
  useApiConfigSelector,
  useDeviceTypes,
  useEnablePriceFreeze,
  useEnablePriceWatch,
  useGetNubankMaxInstallments,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ClientContext } from "../../../App";
import { flightApiConfigStoreKey } from "../../../reducers/types";
import { PATH_FREEZE } from "../../../utils/urlPaths";
import { initialFilterOptions } from "../../search/reducer";
import {
  setFlightShopDefaultSort,
  setRenderLoadingSteps,
} from "../actions/actions";
import {
  useFetchFintechProducts,
  useGoToNextStep,
  useUpdateFlightShopStep,
} from "../hooks";
import { useFetchWalletOffers } from "../hooks/fetchWalletOffers";
import { useFetchFlights } from "../hooks/useFetchFlights";
import {
  FlightShopStep,
  flightShopProgressSelector,
  renderLoadingStepsSelector,
} from "../reducer";
import { getSliceFareDetails } from "../utils/helpers";
import { parseQueryString } from "../utils/parseQueryString";
import { DesktopFlightShop } from "./components/DesktopFlightShop";
import { MobileFlightShop } from "./components/MobileFlightShop";
import type { FlightShopConnectorProps } from "./container";
import "./styles.scss";

export interface IFlightShopProps extends FlightShopConnectorProps {
  tenantCallbacks?: TenantCallbacks;
}

export const FlightShopV3 = ({
  tripSummariesLoading,
  flights,
  fetchTripDetails,
  rewardsKey,
  fareClassFilter,
  isInChooseDepartureStep,
  isInChooseReturnStep,
  origin,
  destination,
  isRoundTrip,
  prediction,
  tripDetailsById,
  departureDate,
  returnDate,
  setChosenReturnSlice,
  setChosenOutgoingSlice,
  hasFlightsError,
  flightList,
  viewedForecastProperties,
  selectedTrip,
  maxFlightPrice,
  hasAppliedFareClassFilter,
  hasAppliedNonFareclassFilter,
  setOpenCalendarModal,
  setAirlineFilter,
  setStopsOption,
  setFlightNumberFilter,
  setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
  setMaxPriceFilter,
  setFareclassOptionFilter,
  setAirportFilter,
  populateFlightBookQueryParams,
  flightShopType,
  fetchTripSummariesForPrediction,
  refreshPrediction,
  tenantCallbacks,
  tripResultEventProperties,
  listWatches,
  tripDetails,
  fetchSelectedTripPriceFreezeOffer,
}: IFlightShopProps) => {
  const { t, brand } = useI18nContext();
  const { experiments } = useExperiments();
  const { setHeader, postEvent, filterExperiments } = useUberBridge();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = parseQueryString(history);

  useEffect(() => {
    if (brand.flightShopDefaultSort) {
      dispatch(setFlightShopDefaultSort(brand.flightShopDefaultSort));
    }
  }, [brand.flightShopDefaultSort, dispatch]);

  // When tenant flight shop navigation prompts flight itinerary modal (i.e. Uber, Nubank), replace history when navigating through flight shop steps
  const replaceOnShopRedirect =
    tenantFlagsEnabled[TenantFlag.FlightMobileShopItineraryModal];

  const { matchesMobile } = useDeviceTypes();

  const clientContext = useContext(ClientContext);

  const updateFlightShopStep = useUpdateFlightShopStep();
  const goToNextStep = useGoToNextStep();
  const fetchFintechProducts = useFetchFintechProducts();
  const fetchWalletOffers = useFetchWalletOffers();

  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);

  const [fareTrips, setFareTrips] = useState([]);

  const flightShopProgress = useSelector(flightShopProgressSelector);
  const renderLoadingSteps = useSelector(renderLoadingStepsSelector);
  const resetRenderLoadingSteps = useCallback(
    (render: boolean) => {
      dispatch(setRenderLoadingSteps(render));
    },
    [dispatch]
  );

  const resetAllFilters = useCallback(() => {
    setAirlineFilter(initialFilterOptions.airlineFilter);
    setStopsOption(initialFilterOptions.stopsOption, apiConfig);
    setFlightNumberFilter(initialFilterOptions.flightNumberFilter);
    setOutboundArrivalTimeRange(initialFilterOptions.outboundArrivalTimeRange);
    setOutboundDepartureTimeRange(
      initialFilterOptions.outboundDepartureTimeRange
    );
    setReturnDepartureTimeRange(initialFilterOptions.returnDepartureTimeRange);
    setReturnArrivalTimeRange(initialFilterOptions.returnArrivalTimeRange);
    setMaxPriceFilter(maxFlightPrice);
    setFareclassOptionFilter({
      basic: false,
      standard: false,
      enhanced: false,
      premium: false,
      luxury: false,
    });
    setAirportFilter(initialFilterOptions.airportFilter);
  }, [
    apiConfig,
    maxFlightPrice,
    setAirlineFilter,
    setAirportFilter,
    setFareclassOptionFilter,
    setFlightNumberFilter,
    setMaxPriceFilter,
    setOutboundArrivalTimeRange,
    setOutboundDepartureTimeRange,
    setReturnArrivalTimeRange,
    setReturnDepartureTimeRange,
    setStopsOption,
  ]);

  const showFlightPriceFreeze = useEnablePriceFreeze();

  const isPriceFreezeEnabled = useMemo(() => {
    return (
      showFlightPriceFreeze && flightShopType !== FlightShopType.CHFAR_EXERCISE
    );
  }, [showFlightPriceFreeze, flightShopType]);

  const isPriceFreezePurchaseShopType = useMemo(() => {
    return flightShopType === FlightShopType.PRICE_FREEZE_PURCHASE;
  }, [flightShopType]);

  const showFlightPriceWatch = useEnablePriceWatch();

  // Ensure the progress is updated when going back
  useEffect(() => {
    if (queryParams.flightShopProgress !== flightShopProgress) {
      updateFlightShopStep(queryParams.flightShopProgress, true);
    }
  }, [
    flightShopProgress,
    queryParams.flightShopProgress,
    updateFlightShopStep,
  ]);

  // Bump back to flight selection if lost flights
  useEffect(() => {
    if (
      (queryParams.flightShopProgress > FlightShopStep.ChooseReturn &&
        !selectedTrip?.tripId) ||
      (queryParams.flightShopProgress === FlightShopStep.ChooseReturn &&
        !selectedTrip?.outgoingFareId)
    ) {
      updateFlightShopStep(FlightShopStep.ChooseDeparture, true);
    }
  }, [
    queryParams.flightShopProgress,
    selectedTrip?.outgoingFareId,
    selectedTrip?.tripId,
    updateFlightShopStep,
  ]);

  useEffect(() => {
    if (showFlightPriceWatch) listWatches();
  }, [listWatches, showFlightPriceWatch]);

  useEffect(() => {
    if (tripSummariesLoading) {
      setHeader({
        title: t("flightSearchLoadingText"),
      });
    }
  }, [setHeader, t, tripSummariesLoading]);

  const fetchFlights = useFetchFlights();
  useEffect(() => {
    const controller = new AbortController();
    // Read query param on intial call and then fetch data.
    if (!flights) fetchFlights(controller);
    return () => {
      // abort fetchflights if we leave this component
      // before it ends loading
      controller.abort();
    };
  }, [flights, history, fetchFlights]);

  useEffect(() => {
    if (!!flights && (isInChooseReturnStep || isInChooseDepartureStep)) {
      if (isInChooseDepartureStep && tenantCallbacks?.onFlightListViewed) {
        tenantCallbacks.onFlightListViewed(tripResultEventProperties);
      }
      // TODO: fix double firing of viewed_flight_list
      trackEvent(
        {
          eventName: VIEWED_FLIGHT_LIST,
          properties: {
            ...viewedForecastProperties,
            is_agent_session: !!clientContext.isAgentPortal,
          },
        },
        apiConfig
      );
      postEvent(VIEWED_FLIGHT_LIST, {
        departure_date: viewedForecastProperties?.departure_date,
        return_date: viewedForecastProperties?.return_date,
        destination: viewedForecastProperties?.destination,
        origin: viewedForecastProperties?.origin,
        slice_direction: viewedForecastProperties?.slice_direction,
        trip_type: viewedForecastProperties?.trip_type,
        experiments: filterExperiments(experiments),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flights, isInChooseDepartureStep, isInChooseReturnStep]);

  useEffect(() => {
    if (hasFlightsError) {
      trackEvent(
        {
          eventName: VIEWED_FLIGHT_LIST_ERROR,
          properties: {
            departure_date: viewedForecastProperties?.departure_date,
            return_date: viewedForecastProperties?.return_date,
            destination: viewedForecastProperties?.destination,
            origin: viewedForecastProperties?.origin,
            slice_direction: viewedForecastProperties?.slice_direction,
            trip_type: viewedForecastProperties?.trip_type,
          },
        },
        apiConfig
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFlightsError]);

  useEffect(() => {
    if (refreshPrediction && isInChooseDepartureStep) {
      fetchTripSummariesForPrediction(history, false);
    }
  }, [
    fetchTripSummariesForPrediction,
    history,
    isInChooseDepartureStep,
    refreshPrediction,
  ]);

  useEffect(() => {
    // Scroll to top on flight lists
    if (isInChooseDepartureStep || isInChooseReturnStep) {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, 0);
    }
  }, [isInChooseDepartureStep, isInChooseReturnStep]);

  const handlePriceFreezeFareSelect = useCallback(() => {
    populateFlightBookQueryParams({
      history,
      pathname: PATH_FREEZE,
      preserveQuery: true,
    });
  }, [history, populateFlightBookQueryParams]);

  const trackSelectedFlight = useCallback(
    (fare_class_rating: number, total_stops: number) => {
      trackEvent(
        {
          eventName: SELECTED_FLIGHT,
          properties: {
            ...viewedForecastProperties,
            fare_class: getHopperFareRatingName(fare_class_rating),
            total_stops: total_stops,
          },
        },
        apiConfig
      );
    },
    [apiConfig, viewedForecastProperties]
  );

  const chooseReturnStep = useCallback(
    (flight: IFlightListData, fareId: string) => {
      const tripDetails =
        tripDetailsById[
          flight.fares.find((fare) => fare.id === fareId)?.tripId
        ];
      const fareDetails: FareDetails | undefined =
        tripDetails?.fareDetails.find((fare) => fare.id === fareId);

      setChosenReturnSlice({
        returnFareId: fareId,
        returnSliceId: flight.slice,
        returnFareRating: fareDetails?.slices[1].fareShelf?.rating,
        tripId:
          tripDetails?.id ||
          flight.fares.find((fare) => fare.id === fareId)?.tripId,
      });

      // fetch fintech offers for return trips
      fetchFintechProducts(tripDetails?.id, fareId);
      fetchWalletOffers(tripDetails?.id, fareId);

      if (isPriceFreezeEnabled && isPriceFreezePurchaseShopType) {
        handlePriceFreezeFareSelect();
      } else {
        goToNextStep(replaceOnShopRedirect);
      }

      if (isPriceFreezeEnabled) {
        fetchSelectedTripPriceFreezeOffer(
          fareId,
          tripDetails?.id,
          departureDate
        );
      }

      trackSelectedFlight(
        fareDetails?.slices?.[1]?.fareShelf?.rating,
        tripDetails?.slices?.[1]?.stops
      );
    },
    [
      tripDetailsById,
      setChosenReturnSlice,
      fetchFintechProducts,
      fetchWalletOffers,
      isPriceFreezeEnabled,
      isPriceFreezePurchaseShopType,
      fetchSelectedTripPriceFreezeOffer,
      departureDate,
      trackSelectedFlight,
      handlePriceFreezeFareSelect,
      goToNextStep,
    ]
  );

  const chooseOutgoingStep = useCallback(
    (flight: IFlightListData, fareId: string) => {
      // TODO: Update any to be of a proper type
      const selectedFareIndex = flight.fares.findIndex(
        (fare: any) => fare.id === fareId || fare?.example?.fare === fareId
      );

      const outgoingFareRating =
        flights!.fareSlices[flights!.fares?.[fareId]?.outbound]?.fareShelf
          .value;
      // TODO: Update or statement to handle a proper type
      const tripId =
        flight.fares[selectedFareIndex]?.tripId ||
        (flight.fares[selectedFareIndex] as any)?.example.trip;

      setChosenOutgoingSlice({
        outgoingFareId: fareId,
        outgoingSliceId: flight.slice,
        outgoingFareRating,
        tripId: tripId || undefined,
        // whenever selecting a different departure flight, reset return ids
        resetReturnIds: true,
      });

      if (isRoundTrip) {
        goToNextStep(replaceOnShopRedirect);
      } else {
        // fetch fintech offers for one-way trips
        fetchFintechProducts(tripId, fareId);
        fetchWalletOffers(tripId, fareId);

        if (isPriceFreezeEnabled && isPriceFreezePurchaseShopType) {
          handlePriceFreezeFareSelect();
        } else {
          goToNextStep(replaceOnShopRedirect);
        }

        if (isPriceFreezeEnabled) {
          fetchSelectedTripPriceFreezeOffer(fareId, tripId, departureDate);
        }
      }

      resetAllFilters();
      trackSelectedFlight(
        outgoingFareRating,
        flights?.slices?.[flight.slice]?.stops
      );

      postEvent(SELECTED_FLIGHT, {
        departure_date: viewedForecastProperties?.departure_date,
        destination: viewedForecastProperties?.destination,
        origin: viewedForecastProperties?.origin,
        return_date: viewedForecastProperties?.return_date,
        slice_direction: viewedForecastProperties?.slice_direction,
        trip_type: viewedForecastProperties?.trip_type,
        fare_class: getHopperFareRatingName(outgoingFareRating),
        total_stops: flights?.slices?.[flight.slice]?.stops,
      });
    },
    [
      departureDate,
      fetchFintechProducts,
      fetchSelectedTripPriceFreezeOffer,
      fetchWalletOffers,
      flights,
      goToNextStep,
      handlePriceFreezeFareSelect,
      isPriceFreezeEnabled,
      isPriceFreezePurchaseShopType,
      isRoundTrip,
      postEvent,
      replaceOnShopRedirect,
      resetAllFilters,
      setChosenOutgoingSlice,
      trackSelectedFlight,
      viewedForecastProperties?.departure_date,
      viewedForecastProperties?.destination,
      viewedForecastProperties?.origin,
      viewedForecastProperties?.return_date,
      viewedForecastProperties?.slice_direction,
      viewedForecastProperties?.trip_type,
    ]
  );

  const handleFareSubmit = useCallback(
    (flight: IFlightListData, fareId: string) => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      if (isInChooseReturnStep) {
        chooseReturnStep(flight, fareId);
      } else {
        chooseOutgoingStep(flight, fareId);
      }
    },
    [chooseOutgoingStep, chooseReturnStep, isInChooseReturnStep]
  );

  const handleFlightSelect = useCallback(
    (fareTrips: any, clickedFareRating: number) => {
      const uniqueTripIds: string[] = Array.from(
        new Set(fareTrips.map((fareTrip: any) => fareTrip.trip))
      );
      uniqueTripIds.forEach((fareTrip: string) => {
        fetchTripDetails(fareTrip);
      });
      setFareTrips(fareTrips);
      const trackingProperties =
        viewedForecastProperties as ViewedSliceProperties;
      const fareClass = getHopperFareRatingName(clickedFareRating);
      if (isInChooseReturnStep) {
        trackingProperties.returnSlice_brand_fareClass = fareClass;
      } else {
        trackingProperties.outboundSlice_brand_fareClass = fareClass;
      }

      trackEvent(
        {
          eventName: VIEWED_SLICE,
          properties: trackingProperties,
        },
        apiConfig
      );
      postEvent(VIEWED_SLICE, {
        departure_date: trackingProperties.departure_date,
        destination: trackingProperties.destination,
        origin: trackingProperties.origin,
        return_date: trackingProperties.return_date,
        slice_direction: trackingProperties.slice_direction,
        trip_type: trackingProperties.trip_type,
      });
    },
    [
      apiConfig,
      fetchTripDetails,
      isInChooseReturnStep,
      postEvent,
      viewedForecastProperties,
    ]
  );

  const expandedFareDetails = useMemo(() => {
    const fetchedAllFareDetails =
      fareTrips.length > 0
        ? fareTrips.reduce((hasFetchedFareTrip: boolean, fareTrip: any) => {
            return hasFetchedFareTrip && !!tripDetailsById[fareTrip.trip];
          }, true)
        : false;

    return fetchedAllFareDetails
      ? getSliceFareDetails(tripDetailsById, fareTrips)
      : null;
  }, [fareTrips, tripDetailsById]);

  const nubankMaxInstallments = useGetNubankMaxInstallments();

  const loadingSteps = useMemo(() => {
    return [
      {
        title: t("flightSearchLoadingText"),
        description: t("flightSearchLoading.step1Description"),
        image: clientContext?.assets?.airplaneFintech,
      },
      {
        title: t("flightSearchLoading.step2Title", {
          installments: nubankMaxInstallments,
        }),
        description: t("flightSearchLoading.step2Description"),
        image: clientContext?.assets?.magnifyCheck,
      },
      {
        title: t("flightSearchLoading.step3Title"),
        description: t("flightSearchLoading.step3Description"),
        image: clientContext?.assets?.calendarLoading,
      },
    ];
  }, [clientContext, t]);

  return (
    <Box className={clsx("flight-shop-root", { mobile: matchesMobile }, "v2")}>
      <Box className="flight-shop-container">
        {renderLoadingSteps ? (
          <Slot
            id="flight-shop-loading-screen"
            loading={tripSummariesLoading}
            averageLoadingTime={
              getEnvVariables("flightSearchLoadingTime") as number
            }
            className="flight-search-loading-popup"
            loadingSteps={loadingSteps}
            onResetRenderLoadingSteps={resetRenderLoadingSteps}
            component={
              <LoadingScreenWithProgress
                className="flight-search-loading-popup"
                loadingSteps={loadingSteps}
                averageLoadingTime={
                  getEnvVariables("flightSearchLoadingTime") as number
                }
                loading={tripSummariesLoading}
                resetRenderLoadingSteps={resetRenderLoadingSteps}
              />
            }
          />
        ) : !matchesMobile ? (
          <DesktopFlightShop
            isRoundTrip={isRoundTrip}
            tripSummariesLoading={!!tripSummariesLoading}
            isPriceFreezeEnabled={isPriceFreezeEnabled}
            origin={origin}
            destination={destination}
            flightsToRender={flightList}
            flights={flights}
            rewardsKey={rewardsKey}
            handleFareSubmit={handleFareSubmit}
            fareClassFilter={fareClassFilter}
            handleFlightSelect={handleFlightSelect}
            expandedFareDetails={expandedFareDetails}
            departureDate={departureDate}
            returnDate={returnDate}
            hasFlightsError={hasFlightsError}
            selectedTrip={selectedTrip}
            maxFlightPrice={maxFlightPrice}
            hasAppliedFareClassFilter={hasAppliedFareClassFilter}
            hasAppliedNonFareclassFilter={hasAppliedNonFareclassFilter}
            setOpenCalendarModal={setOpenCalendarModal}
            prediction={prediction}
          />
        ) : (
          <MobileFlightShop
            tripSummariesLoading={!!tripSummariesLoading}
            isPriceFreezeEnabled={isPriceFreezeEnabled}
            origin={origin}
            flightsToRender={flightList}
            flights={flights}
            rewardsKey={rewardsKey}
            handleFareSubmit={handleFareSubmit}
            fareClassFilter={fareClassFilter}
            handleFlightSelect={handleFlightSelect}
            expandedFareDetails={expandedFareDetails}
            departureDate={departureDate}
            returnDate={returnDate}
            hasFlightsError={hasFlightsError}
            selectedTrip={selectedTrip}
            maxFlightPrice={maxFlightPrice}
            hasAppliedFareClassFilter={hasAppliedFareClassFilter}
            hasAppliedNonFareclassFilter={hasAppliedNonFareclassFilter}
            setOpenCalendarModal={setOpenCalendarModal}
            isRoundTrip={isRoundTrip}
            tripDetails={tripDetails}
          />
        )}
      </Box>
    </Box>
  );
};
