import { useEffect } from "react";
import { useSelector } from "react-redux";

import { ButtonWrap } from "@hopper-b2b/ui";
import {
  getGoogleStaticMapSrc,
  STATIC_MAP_DARK_STYLE,
  useVisibility,
} from "@hopper-b2b/utilities";
import { availabilitySelectors } from "@hopper-b2b/lodging";
import { useI18nContext } from "@hopper-b2b/i18n";

import { ReactComponent as ChevronDarkIcon } from "../../../assets/client/chevron.svg";
import { ReactComponent as ChevronWhiteIcon } from "../../../assets/client/darkMode/chevron-white.svg";
import { useDarkModePreferred } from "../../../utils/colors";
import style from "./styles.module.scss";
import { useExperiment } from "@hopper-b2b/experiments";
import { EXPERIMENTS } from "../../../App";

const getWidth = () =>
  Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - 32; //vw - 2rem(padding)

export const HotelListMapCard = ({ onClick }: { onClick: () => void }) => {
  const { t } = useI18nContext();
  const isDarkMode = useDarkModePreferred();
  const centroid = useSelector(availabilitySelectors.getCentroid);
  const hideHotelMapCardExperiment = useExperiment(
    EXPERIMENTS.RENDER_HOTEL_MAP_ENTRY_POINT
  );

  const ChevronIcon = isDarkMode ? ChevronWhiteIcon : ChevronDarkIcon;

  const [visible, ref] = useVisibility<HTMLButtonElement>();

  useEffect(() => {
    if (!hideHotelMapCardExperiment) {
      const element = document.getElementsByClassName(
        "Availability-view-toggle"
      )[0];
      const elementStyle = (element as HTMLElement)?.style;
      if (elementStyle) {
        if (visible && elementStyle.visibility !== "hidden") {
          elementStyle.visibility = "hidden";
        } else if (!visible && elementStyle.visibility === "hidden") {
          elementStyle.visibility = "";
        }
      }
    }
  }, [hideHotelMapCardExperiment, visible]);

  useEffect(() => {
    if (!hideHotelMapCardExperiment) {
      const element = document.getElementsByClassName(
        "Availability-view-toggle"
      )[0];
      const elementStyle = (element as HTMLElement)?.style;
      elementStyle.visibility = "hidden";
    }
  }, [hideHotelMapCardExperiment]);

  return centroid?.lat && centroid?.lng && !hideHotelMapCardExperiment ? (
    <ButtonWrap
      onClick={onClick}
      className="nubank-hotel-list-map-card"
      ref={ref}
    >
      <img
        className={style.staticMapImage}
        src={getGoogleStaticMapSrc(
          `${centroid.lat},${centroid.lng}`,
          "11", // zoom to show the city name as much as possible
          `${getWidth()}x84`, //fixed height of 84px to match designs
          "1", //scale
          false,
          "red",
          isDarkMode ? STATIC_MAP_DARK_STYLE : ""
        )}
        alt={t("hotelMapCardAlt")}
      />
      <div className={style.hotelMapCardBottom}>
        <p className={style.label}>{t("hotelMapCardLabel")}</p>
        <ChevronIcon
          className={!isDarkMode ? style.darkIcon : style.lightIcon}
        />
      </div>
    </ButtonWrap>
  ) : null;
};
