import { TripSlice } from "@b2bportal/air-shopping-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreFlightsComponent } from "@b2bportal/core-types";
import { FlightDetails } from "@components/flights";
import { Dialog, DialogContent } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import defaultStyles from "./FlightDetailsDialog.module.scss";

export const FlightDetailsDialog = ({
  open,
  onClose,
  onClick,
  flightSlice,
  isOutbound,
}: {
  open: boolean;
  flightSlice?: TripSlice;
  isOutbound: boolean;
  onClose: () => void;
  onClick: () => void;
}) => {
  const COMPONENT_KEY = CoreFlightsComponent.FlightDetailsDialog;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t } = useI18nContext();
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent
        title={t("flightDetails")}
        className={cn("flight-details-dialog")}
        bottomFixedContent={
          <button className={cn("continue-button")} onClick={onClick}>
            {t("continue")}
          </button>
        }
      >
        {flightSlice == null ? null : (
          <FlightDetails flightSlice={flightSlice} isOutgoing={isOutbound} />
        )}
      </DialogContent>
    </Dialog>
  );
};
