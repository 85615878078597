import { DialogProps } from "@material-ui/core";
import clsx from "clsx";
import { useContext, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  BookedFlightItineraryWithDepartureTime,
  PortalItineraryStatus,
} from "@b2bportal/air-booking-api";
import {
  HotelItinerary,
  HotelItinerarySummary,
  ReservationStateEnum,
} from "@b2bportal/lodging-api";
import { getBookingSupportId } from "@hopper-b2b/api";
import { PATH_TRIPS } from "@hopper-b2b/common-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CancelFlightModalContent,
  CancelHotelModalContent,
  ChangeHotelModalContent,
  ExchangeFlightModalContent,
  ResendEmailModalContent,
  ScheduleChangeModalContent,
  UberCancelFlightModalContent,
  UberContactSupport,
} from "@hopper-b2b/self-serve";
import {
  BookingType,
  ClientName,
  Flight,
  HotelItineraryWithType,
  ItineraryEnum,
  ItineraryWithType,
  MyTripsModalTypes,
} from "@hopper-b2b/types";
import {
  ActionLink,
  ContactSupportModalContent,
  DesktopPopupModal,
  IconComponent,
  IconName,
  MobilePopoverCard,
  Slot,
  SweetheartBunny,
} from "@hopper-b2b/ui";
import { getConfirmationNumbers, getEnvVariables } from "@hopper-b2b/utilities";

import { ClientContext } from "../../../../../../App";
import { CfarExercise } from "../../../../../../modules/ancillaries/cfar/CfarExercise";
import { CfarExercise as UberCfarExercise } from "../../../../../../pages/UberTripsList/components/CfarExercise";
import { DisruptionExerciseModal } from "../../../../../../pages/UberTripsList/components/DisruptionExercise";
import { getAirlinesMap, getAirportMap } from "../../../../reducer";
import { TripsStatusTag } from "../TripsStatusTag";
import { CancelFlightModalContent as CancelFlightModalContentV1 } from "./components/CancelFlightModalContent";
import { CheckInFlightModalContent } from "./components/CheckInFlightModalContent";
import { ConfirmationModalContent } from "./components/ConfirmationModalContent";
import { FlightPaymentModalContent } from "./components/FlightPaymentModalContent";
import { HotelPaymentModalContent } from "./components/HotelPaymentModalContent";
import { ItinerarySummaryModalContent } from "./components/ItinerarySummaryModalContent";
import { TravelersModalContent } from "./components/TravelersModalContent";
import { ItinerariesModalConnectorProps } from "./container";

import { ChatPropertiesType } from "@b2bportal/chat-api";
import "./styles.scss";

interface IItinerariesModalProps extends ItinerariesModalConnectorProps {
  isMobile?: boolean;
  onSupportClick?: (
    productId?: string,
    productType?: ChatPropertiesType,
    requestType?: string
  ) => void;
}

const modalClasses = {
  [ItineraryEnum.Flight]: {
    [MyTripsModalTypes.CancelFlight]: "cancel-flight-modal",
    [MyTripsModalTypes.CheckInFlight]: "checkin-flight-modal",
    [MyTripsModalTypes.ConfirmationModal]: "confirmation-modal",
    [MyTripsModalTypes.ContactSupport]: "contact-support-modal",
    [MyTripsModalTypes.ExchangeFlight]: "exchange-flight-modal",
    [MyTripsModalTypes.PaymentModal]: "payment-modal",
    [MyTripsModalTypes.ResendConfirmation]: "resend-confirmation-modal",
    [MyTripsModalTypes.ScheduleChange]: "schedule-change",
    [MyTripsModalTypes.SelfServeCancelFlight]: "self-serve-cancel-flight-modal",
    [MyTripsModalTypes.TravelersModal]: "travelers-modal",
    [MyTripsModalTypes.CfarFlight]: "cfar-flight-exercise-modal",
  },
  [ItineraryEnum.Hotel]: {
    [MyTripsModalTypes.CancelHotel]: "cancel-hotel-modal",
    [MyTripsModalTypes.ChangeHotel]: "change-hotel-modal",
    [MyTripsModalTypes.ContactSupport]: "contact-support-modal",
    [MyTripsModalTypes.PaymentModal]: "hotel-payment-modal",
    [MyTripsModalTypes.ResendConfirmation]: "resend-confirmation-modal",
    [MyTripsModalTypes.TravelersModal]: "travelers-modal",
  },
  // [ItineraryEnum.Car]: {
  //   [MyTripsModalTypes.CancelCar]: "cancel-car-modal",
  //   [MyTripsModalTypes.CarConfirmation]: "car-confirmation-modal",
  //   [MyTripsModalTypes.ChangeCar]: "change-car-modal",
  //   [MyTripsModalTypes.DriversModal]: "drivers-modal",
  //   [MyTripsModalTypes.DropOff]: "location-modal",
  //   [MyTripsModalTypes.PaymentModal]: "payment-modal",
  //   [MyTripsModalTypes.ResendConfirmation]: "resend-confirmation-modal",
  // },
};

export const ItinerariesModal = ({
  isMobile,
  openModal,
  setOpenModal,
  setSelectedFlight,
  setSelectedHotel,
  selectedHotel,
  onSupportClick,
}: IItinerariesModalProps) => {
  const { t } = useI18nContext();
  const { type, selectedItinerary } = openModal;

  const clientContext = useContext(ClientContext);
  // change in switch/case below to set the max-width of a specific modal
  const modalMaxWidthRef = useRef<DialogProps["maxWidth"]>("sm");
  const airlineMap = useSelector(getAirlinesMap);
  const airportMap = useSelector(getAirportMap);

  const [hideXButton, setHideXButton] = useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  const [disableOnClose, setDisableOnClose] = useState(false);

  const clientName = getEnvVariables("clientName");
  const isUber = clientName === ClientName.UBER;
  const isNubank = clientName === ClientName.NUBANK;

  const confirmationNumbers = useMemo(
    () =>
      selectedItinerary?.type === ItineraryEnum.Flight &&
      getConfirmationNumbers({
        flight: selectedItinerary as BookedFlightItineraryWithDepartureTime,
        airlineMap,
      }),
    [selectedItinerary, airlineMap]
  );

  const onClose = (refreshTrips = false) => {
    setHideXButton(false);
    setFullScreen(true);
    setDisableOnClose(false);
    setOpenModal({ type: null, selectedItinerary: null });

    if (refreshTrips === true) {
      let tripId = "";

      switch (selectedItinerary.type) {
        case ItineraryEnum.Flight:
          tripId = selectedItinerary.bookedItinerary.id;
          break;
        case ItineraryEnum.Hotel:
          tripId = selectedItinerary.reservation.reservationId;
          break;
      }

      if (tripId) {
        // reload trips page and go to selected trip
        window.location.href = `/${PATH_TRIPS}/?tripId=${tripId}`;
      }
    }
  };

  const getModalClassName = (): string =>
    modalClasses[selectedItinerary.type][type as string];

  const renderFlightModalContent = (
    flight: BookedFlightItineraryWithDepartureTime
  ) => {
    switch (type) {
      case MyTripsModalTypes.TravelersModal:
        return (
          <TravelersModalContent
            travelers={flight.bookedItinerary.passengers.alone.map(
              (ap) => ap.person
            )}
          />
        );
      case MyTripsModalTypes.PaymentModal:
        return <FlightPaymentModalContent flight={flight} />;
      case MyTripsModalTypes.ConfirmationModal:
        return <ConfirmationModalContent flight={flight} />;
      case MyTripsModalTypes.OutboundItinerary:
        return <ItinerarySummaryModalContent departure flight={flight} />;
      case MyTripsModalTypes.ReturnItinerary:
        return (
          <ItinerarySummaryModalContent flight={flight} departure={false} />
        );
      case MyTripsModalTypes.ScheduleChange:
        modalMaxWidthRef.current = "lg";
        return (
          <ScheduleChangeModalContent
            airlineMap={airlineMap}
            airportMap={airportMap}
            flight={flight}
            onClose={onClose}
          />
        );
      case MyTripsModalTypes.CancelFlight:
        return <CancelFlightModalContentV1 flight={flight} />;
      case MyTripsModalTypes.SelfServeCancelFlight:
        if (isUber || isNubank) {
          return (
            <UberCancelFlightModalContent
              open
              airlines={airlineMap}
              airports={airportMap}
              assets={clientContext.assets}
              flight={flight}
              onClose={onClose}
              setCancellationCompleted={() => ""}
              setOpenModal={setOpenModal}
              isMobile={isMobile}
              confirmationNumbers={confirmationNumbers}
              onSupportClick={onSupportClick}
            />
          );
        }

        return (
          <CancelFlightModalContent
            airlines={airlineMap}
            airports={airportMap}
            flight={flight}
            getStatusTag={(isOutgoing) => (
              <TripsStatusTag
                isOutgoing={isOutgoing}
                airportMap={airportMap}
                className="mobile-status-tag"
                flight={flight}
                scheduleChange={flight.bookedItinerary.scheduleChange}
              />
            )}
            isMobile={isMobile}
            onClose={onClose}
            setOpenModal={setOpenModal}
          />
        );
      case MyTripsModalTypes.ExchangeFlight:
        return (
          <ExchangeFlightModalContent
            flight={flight}
            setOpenModal={setOpenModal}
          />
        );
      case MyTripsModalTypes.CheckInFlight:
        return <CheckInFlightModalContent flight={flight} />;
      case MyTripsModalTypes.ResendConfirmation:
        setHideXButton(true);

        return (
          <ResendEmailModalContent
            errorImage={clientContext.assets?.error}
            isCancelled={flight.status === PortalItineraryStatus.Canceled}
            isMobile={isMobile}
            itinerary={flight as Flight}
            onClose={onClose}
            successImage={clientContext.assets?.checkmarkFilled}
          />
        );
      case MyTripsModalTypes.CfarFlight:
        if (isUber) {
          // if Uber migrates to v1 API, this component can be removed along with this client check
          return <UberCfarExercise flight={flight} onComplete={onClose} />;
        } else
          return (
            <CfarExercise
              flight={flight}
              onComplete={onClose}
              setOpenModal={setOpenModal}
            />
          );
      case MyTripsModalTypes.DelayDisruption:
        return <DisruptionExerciseModal flight={flight} onClose={onClose} />;
      case MyTripsModalTypes.ContactSupport:
      default:
        if (isUber) return <UberContactSupport />;

        modalMaxWidthRef.current = "md";

        return (
          <ContactSupportModalContent
            bookingId={flight.bookedItinerary.id}
            bookingUuid={flight.bookedItinerary.id}
            bookingType={BookingType.Flight}
            className="trip-action-contact-support"
            getSupportId={getBookingSupportId}
            requestType="General"
            icon={<img alt="cs-bunny" src={SweetheartBunny} />}
            onClose={onClose}
          />
        );
    }
  };

  const renderHotelModalContent = (hotel: HotelItinerarySummary) => {
    const { reservationId } = hotel;

    switch (type) {
      case MyTripsModalTypes.CancelHotel:
        return (
          <CancelHotelModalContent
            hotel={hotel as unknown as HotelItineraryWithType}
            isMobile={isMobile}
            setOpenModal={setOpenModal}
            onSupportClick={onSupportClick}
          />
        );
      case MyTripsModalTypes.ChangeHotel:
        modalMaxWidthRef.current = "md";

        return (
          <ChangeHotelModalContent
            hotel={hotel}
            isMobile={isMobile}
            setOpenModal={setOpenModal}
          />
        );
      case MyTripsModalTypes.PaymentModal:
        return <HotelPaymentModalContent hotel={selectedHotel} />;
      case MyTripsModalTypes.ResendConfirmation:
        setHideXButton(true);
        return (
          <ResendEmailModalContent
            errorImage={clientContext.assets?.error}
            isCancelled={
              hotel.state?.ReservationState ===
                ReservationStateEnum.Cancelled ||
              (hotel as unknown as HotelItinerary).reservation?.state //TODO(thilina): Fix type casting
                ?.ReservationState === ReservationStateEnum.Cancelled
            }
            isMobile={isMobile}
            itinerary={hotel as unknown as HotelItineraryWithType}
            onClose={onClose}
            successImage={clientContext.assets?.checkmarkFilled}
          />
        );
      case MyTripsModalTypes.TravelersModal:
        return (
          <TravelersModalContent travelers={selectedHotel.reservation.guests} />
        );
      case MyTripsModalTypes.ContactSupport:
      default:
        if (isUber) return <UberContactSupport />;

        modalMaxWidthRef.current = "md";
        return (
          <ContactSupportModalContent
            bookingId={reservationId}
            bookingUuid={hotel.reservationBookingId}
            bookingType={BookingType.Lodging}
            className="trip-action-contact-support"
            getSupportId={getBookingSupportId}
            requestType="General"
            icon={<img alt="cs-bunny" src={SweetheartBunny} />}
            onClose={onClose}
            onContactSupport={onSupportClick}
          />
        );
    }
  };

  // const renderCarModalContent = (car: CarReservation) => {
  //   // const { dropOff, pickUp } = car.bookResult;
  //
  //   switch (type) {
  //     case MyTripsModalTypes.CarConfirmation:
  //       return <CarConfirmationModalContent car={car} />;
  //     case MyTripsModalTypes.CancelCar:
  //       return <CancelCarModalContent car={car} />;
  //
  //     case MyTripsModalTypes.BasicInfo:
  //       return <CarBasicInfoModalContent car={car} />;
  //     case MyTripsModalTypes.DropOff:
  //       return <LocationCarModalContent location={dropOff} isPickUp={false} />;
  //     case MyTripsModalTypes.PickUp:
  //       return <LocationCarModalContent location={pickUp} isPickUp />;
  //     case MyTripsModalTypes.ChangeCar:
  //       return <ChangeCarlModalContent car={car} />;
  //     case MyTripsModalTypes.DriversModal:
  //       return <CarDriversModalContent car={car} />;
  //     case MyTripsModalTypes.PaymentModal:
  //       return <CarPaymentModalContent car={car} />;
  //     case MyTripsModalTypes.ResendConfirmation:
  //       setHideXButton(true);
  //
  //     return (
  //       <ResendEmailModalContent
  //         isCancelled={car.bookResult.status.State === State.Canceled}
  //         itinerary={car as Car}
  //         onClose={onClose}
  //       />
  //     );
  //     default:
  //       return null;
  //   }
  // };

  const renderModalContent = () => {
    switch ((selectedItinerary as ItineraryWithType).type) {
      case ItineraryEnum.Flight:
        return renderFlightModalContent(
          selectedItinerary as BookedFlightItineraryWithDepartureTime
        );
      case ItineraryEnum.Hotel:
        return renderHotelModalContent(
          selectedItinerary as unknown as HotelItinerarySummary
        );
      // case ItineraryEnum.Car:
      //   return renderCarModalContent(selectedItinerary as CarReservation);
      default:
        return null;
    }
  };

  const ModalContent = useMemo(() => {
    if (selectedItinerary) {
      // reset for next modal
      modalMaxWidthRef.current = "sm";

      return renderModalContent();
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItinerary, type]);

  const renderDesktopModal = () => (
    <DesktopPopupModal
      className={clsx("my-trips-desktop-modal", getModalClassName())}
      hideXButton={hideXButton}
      invisibleBackdrop={false}
      maxWidth={modalMaxWidthRef.current}
      onClose={disableOnClose ? undefined : () => onClose()}
      open={true}
    >
      {ModalContent}
    </DesktopPopupModal>
  );

  const renderMobileModal = () => (
    <MobilePopoverCard
      centered
      fullScreen={fullScreen}
      open
      contentClassName={"my-trips-mobile-popover-content"}
      className={clsx("my-trips-mobile-popover-card b2b", getModalClassName())}
      onClose={disableOnClose ? undefined : () => onClose(false)}
      topRightButton={
        hideXButton ? null : (
          <ActionLink
            className="filter-close-button"
            content={
              <Slot
                id="mytrips-modal-close-icon"
                type={type}
                component={
                  <IconComponent
                    ariaLabel="Close button icon"
                    className={clsx("close-button-icon")}
                    name={IconName.Close}
                  />
                }
              />
            }
            label={t("close")}
            onClick={() => onClose(false)}
          />
        )
      }
      headerElement={
        isNubank && type === MyTripsModalTypes.SelfServeCancelFlight ? (
          <div className="my-trips-header">{t("selfServe.header")}</div>
        ) : null
      }
      topLeftButton={
        isNubank &&
        [
          MyTripsModalTypes.SelfServeCancelFlight,
          MyTripsModalTypes.ResendConfirmation,
          MyTripsModalTypes.CheckInFlight,
        ].includes(type) ? (
          <ActionLink
            className="my-trips-back-button"
            content={
              clientContext?.assets?.back ? (
                <img
                  src={clientContext.assets.back}
                  className="back-arrow"
                  alt={t("back")}
                />
              ) : (
                <IconComponent
                  ariaLabel="back icon"
                  className="back-arrow"
                  name={IconName.ArrowRight}
                />
              )
            }
            label={t("close.button")}
            onClick={() => onClose(false)}
          />
        ) : null
      }
    >
      {ModalContent}
    </MobilePopoverCard>
  );

  if (selectedItinerary && type) {
    if (isMobile) return renderMobileModal();

    return renderDesktopModal();
  }

  return null;
};
