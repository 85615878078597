import { FareDetailsV2 } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useEffect, useMemo, useState } from "react";
import { FareDetails } from "@components/flights";
import { PriceDisplay } from "@components/ui";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  FlightFareSelectionFunction,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import defaultStyles from "./FlightCardFareList.module.scss";
import { getFilteredFareDetails } from "@b2bportal/core-utilities";
import clsx from "clsx";

export interface FlightCardFareListComponentProps
  extends PlatformComponentProps {
  fareIdList?: string[];
  clickedFareId?: string;
  fareDetails: Array<FareDetailsV2>;
  sliceIndex: number;
  fareShelfFilter: number[];
  sliceId: string;
  tripId: string;
  onFareSelect: FlightFareSelectionFunction;
  goToNextStep: () => void;
}
export const FlightCardFareList = ({
  clickedFareId,
  fareDetails,
  sliceIndex,
  fareShelfFilter,
  sliceId,
  tripId,
  onFareSelect,
  goToNextStep,
  className,
  children,
}: FlightCardFareListComponentProps) => {
  const styles = useFlightStyles(
    CoreFlightsComponent.FlightCardFareList,
    defaultStyles
  );

  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightCardFareList
  );

  const { t } = useI18nContext();

  const [selectedFareId, setSelectedFareOption] = useState("");

  const filteredAndSortedFares = useMemo(() => {
    return getFilteredFareDetails({
      fareDetails,
      selectedFareClassFilters: fareShelfFilter,
      sliceIndex,
      selectedFareId: clickedFareId,
    });
  }, [fareDetails, sliceIndex, clickedFareId, fareShelfFilter]);

  const { formatFiatCurrency } = useI18nContext();

  const fares = useMemo(() => {
    return filteredAndSortedFares.map((fare) => {
      const { id, paxPricings } = fare;
      const prices = paxPricings && paxPricings[0].pricing.total;

      const brandName = fare.slices[sliceIndex].fareShelf?.brandName ?? "";
      return (
        <li className={cn("fare-root")} key={`fare-block-${id}`}>
          <div className={cn("fare-info")}>{brandName}</div>
          <button
            className={cn("button")}
            onClick={() => {
              onFareSelect({ sliceId, fareId: id, tripId });
              goToNextStep();
            }}
          >
            {prices && <PriceDisplay prices={prices} />}
          </button>
          <div className={cn("fare-details")}>
            <FareDetails fareSlice={fare.slices[sliceIndex]} />
          </div>
        </li>
      );
    });
  }, [
    filteredAndSortedFares,
    cn,
    tripId,
    formatFiatCurrency,
    goToNextStep,
    onFareSelect,
    sliceId,
    sliceIndex,
  ]);

  useEffect(() => {
    setSelectedFareOption(filteredAndSortedFares[0].id);
    onFareSelect({ sliceId, fareId: filteredAndSortedFares[0].id, tripId });
  }, [onFareSelect, sliceId, tripId, fareShelfFilter.toString()]);

  const selectedFareSlice = useMemo(() => {
    return (
      filteredAndSortedFares.find((f) => f.id === selectedFareId)?.slices[
        sliceIndex
      ] ?? filteredAndSortedFares[0]?.slices[sliceIndex]
    );
  }, [filteredAndSortedFares, selectedFareId, sliceIndex]);

  const fareRadios = useMemo(() => {
    return filteredAndSortedFares.map((fare) => {
      const { id, paxPricings } = fare;
      const prices = paxPricings && paxPricings[0].pricing.total;

      const brandName = fare.slices[sliceIndex].fareShelf?.brandName ?? "";
      const selectedFare = id === selectedFareId;

      return (
        <label
          htmlFor={id}
          key={`fare-radio-${id}`}
          className={cn("fare-radio-button", {
            "selected-fare": selectedFare,
          })}
        >
          <input
            type="radio"
            id={id}
            value={id}
            name={`fare-${sliceId}`}
            checked={selectedFare}
            onChange={() => {
              setSelectedFareOption(id);
              onFareSelect({ sliceId, fareId: id, tripId });
            }}
          />
          <div className={cn("fare-name")}>{brandName}</div>
          <div className={cn("fare-per-person")}>{t("perPerson")}</div>
          <div className={cn("fare-amount")}>
            {prices && <PriceDisplay prices={prices} />}
          </div>
          {selectedFare && (
            <div className={cn("fare-details")}>
              <FareDetails
                fareSlice={selectedFareSlice}
                multiFareNotice={fareDetails[sliceIndex]?.multiTicket}
              />
            </div>
          )}
        </label>
      );
    });
  }, [
    t,
    cn,
    filteredAndSortedFares,
    selectedFareId,
    fareDetails,
    formatFiatCurrency,
    onFareSelect,
    selectedFareSlice,
    sliceId,
    sliceIndex,
    tripId,
  ]);

  return (
    <div className={clsx(block, className)}>
      <ul className={cn("fare-list")}>{fares}</ul>
      <div className={cn("fare-list-radio")} role="radiogroup">
        {fareRadios}
      </div>
      {children}
    </div>
  );
};
