import { FiatPrice } from "@b2bportal/purchase-api";

import { LodgingSelectors, ParentState } from "@hopper-b2b/checkout";
import {
  ILineItemProps,
  ISummaryLineItem,
  SummaryLineItemBaseEnum,
} from "@hopper-b2b/types";
import { LodgingMachineContext } from "../types";
import { State } from "xstate";

type LodgingCheckoutState = State<LodgingMachineContext>;
type LodgingCheckoutStateWithoutValue = Pick<LodgingCheckoutState, "context">;

export const getClientAssets = ({
  context,
}: LodgingCheckoutStateWithoutValue) => context.clientAssets;

export const getLodgingTotalInPrices = (
  state: LodgingCheckoutStateWithoutValue
): { fiat: FiatPrice } | null => {
  const { roomPriceTotalWithTaxesAndFees } = getRoomPricing(state);
  const currency = getCurrencyFromPricingInfo(state);

  if (roomPriceTotalWithTaxesAndFees) {
    const fiatTotal = roomPriceTotalWithTaxesAndFees.value;

    return {
      fiat: {
        ...currency,
        value: fiatTotal < 0 ? 0 : fiatTotal,
      },
    };
  }

  return null;
};

export const getLodgingSummaryLineItems = (
  state: LodgingCheckoutStateWithoutValue
) => {
  const lodgingTotal = getLodgingTotalInPrices(state);

  const lineItems: ISummaryLineItem[] = [];
  if (lodgingTotal) {
    lineItems.push({
      type: SummaryLineItemBaseEnum.TOTAL,
      fiatPrice: lodgingTotal.fiat,
    });
  }

  return lineItems;
};

export const getCurrencyFromPricingInfo = (
  state: LodgingCheckoutStateWithoutValue
) => {
  const shopPricingInfo = LodgingSelectors.getHotelRoomPriceTotal(state);
  return (
    shopPricingInfo && {
      currencyCode: shopPricingInfo.currencyCode,
      currencySymbol: shopPricingInfo.currencySymbol,
    }
  );
};

interface IRoomPricingProps {
  // Per night total
  roomBasePricePerNightTotal: FiatPrice;
  // Per night total including taxes & fees
  roomPricePerNightTotal?: FiatPrice;
  // Total room price
  roomBasePriceTotal: FiatPrice;
  // Total room price including taxes & fees
  roomPriceTotal: FiatPrice;
  roomTaxes: FiatPrice;
  roomFees: FiatPrice;
  roomTaxesAndFeesTotal: FiatPrice;
  roomPriceTotalWithTaxesAndFees: FiatPrice;
  roomPriceTotalNoDiscounts?: FiatPrice;
  roomDueUponCheckInTotal?: FiatPrice;
}

export const getRoomPricing = (
  state: LodgingCheckoutStateWithoutValue
): IRoomPricingProps => {
  const hotelProduct = LodgingSelectors.getQuotedLodgingProduct(state);
  const quotedRoomPricing = getQuotedRoomPricing(state);
  const shoppedRoomPricing = getShoppedRoomPricing(state);
  return hotelProduct ? quotedRoomPricing : shoppedRoomPricing;
};

export const getShoppedRoomPricing = (
  state: LodgingCheckoutStateWithoutValue
): IRoomPricingProps => {
  const hotelRoomPriceNightly =
    LodgingSelectors.getHotelRoomPricePerNightTotal(state);
  const hotelRoomBasePriceNightly =
    LodgingSelectors.getHotelRoomBasePricePerNightTotal(state);
  const hotelRoomBasePriceTotal =
    LodgingSelectors.getHotelRoomBasePriceTotal(state);
  const hotelRoomPriceTotal = LodgingSelectors.getHotelRoomPriceTotal(state);
  const hotelRoomTripTotal = LodgingSelectors.getHotelRoomTripTotal(state);
  const hotelRoomTripTotalNoDiscounts =
    LodgingSelectors.getHotelRoomTripTotalNoDiscounts(state);
  const taxBreakdown = LodgingSelectors.getHotelRoomTaxBreakdown(state);
  const currencyFromPricingInfo = getCurrencyFromPricingInfo(state);
  const taxAmount = Object.keys(taxBreakdown ?? {}).reduce(
    (sum, key) => taxBreakdown[key].fiat.value + sum,
    0
  );

  const hotelRoomDueUponCheckInTotal =
    LodgingSelectors.getHotelRoomFeeBreakdownTotal(state);

  const taxesAndFeesTotal =
    taxAmount +
    (hotelRoomDueUponCheckInTotal ? hotelRoomDueUponCheckInTotal.value : 0);

  return {
    roomPriceTotal: hotelRoomPriceTotal,
    roomPricePerNightTotal: hotelRoomPriceNightly,
    roomDueUponCheckInTotal: hotelRoomDueUponCheckInTotal,
    roomPriceTotalWithTaxesAndFees: hotelRoomTripTotal,
    roomPriceTotalNoDiscounts: hotelRoomTripTotalNoDiscounts,
    roomBasePriceTotal: hotelRoomBasePriceTotal,
    roomBasePricePerNightTotal: hotelRoomBasePriceNightly,
    roomTaxesAndFeesTotal: {
      value: taxesAndFeesTotal,
      ...currencyFromPricingInfo,
    },
  };
};
export const getQuotedRoomPricing = (
  state: LodgingCheckoutStateWithoutValue
): IRoomPricingProps => {
  const pricing = LodgingSelectors.getQuotedLodgingProductPricingV1(state);

  // Separating the fees due at checkin
  // apparently there is a BE limitation in sending these amounts separated
  const taxAmountLessFees =
    pricing?.taxesAndFeesTotal.fiat.value -
    (pricing?.paymentSchedule.payLaterTotal?.fiat.value ?? 0);

  return {
    roomPriceTotal: pricing?.paymentSchedule?.payNowTotal?.fiat,
    roomDueUponCheckInTotal:
      pricing?.paymentSchedule?.payLaterTotalLocalCurrency?.fiat ??
      pricing?.paymentSchedule?.payLaterTotal?.fiat,
    roomPriceTotalWithTaxesAndFees: pricing?.tripTotal?.fiat,
    roomBasePriceTotal: pricing?.subTotal?.fiat,
    roomBasePricePerNightTotal: pricing?.dailyRate?.fiat,
    roomTaxesAndFeesTotal: pricing?.taxesAndFeesTotal.fiat,
  };
};
export interface IHotelLineItemProps extends ILineItemProps {
  type?: "base" | "tax" | "due-upon-check-in" | "due-today";
}

export const getLodgingPriceLineItems = (
  state: LodgingCheckoutStateWithoutValue
): IHotelLineItemProps[] => {
  const { roomBasePriceTotal, roomTaxesAndFeesTotal, roomPriceTotal } =
    getRoomPricing(state);

  if (
    roomBasePriceTotal !== undefined &&
    roomTaxesAndFeesTotal !== undefined &&
    roomPriceTotal !== undefined
  ) {
    const lineItems: IHotelLineItemProps[] = [
      {
        baseAmount: roomBasePriceTotal?.value ?? 0,
        type: "base",
        products: undefined,
      },
      {
        baseAmount: roomTaxesAndFeesTotal?.value ?? 0,
        type: "tax",
        products: undefined,
      },
    ];

    return lineItems;
  }
  return [];
};

export const getOtherLodgingPriceLineItems = (
  state: LodgingCheckoutStateWithoutValue
): IHotelLineItemProps[] => {
  const { roomPriceTotal: dueNow, roomDueUponCheckInTotal: dueAtHotel } =
    getRoomPricing(state);

  const lineItems = [];

  if (!dueAtHotel) {
    return;
  }

  if (dueNow) {
    lineItems.push({
      baseAmount: dueNow?.value ?? 0,
      type: "due-today",
      products: undefined,
    });
  }

  if (dueAtHotel) {
    lineItems.push({
      baseAmount: dueAtHotel?.value ?? 0,
      type: "due-upon-check-in",
      products: undefined,
    });
  }

  return lineItems;
};

export const getQuotedLodgingProductPrice = (
  state: LodgingCheckoutStateWithoutValue
): {
  roomPriceTotal: FiatPrice;
  roomTaxesAndFeesTotal: FiatPrice;
  roomDueUponCheckInTotal?: FiatPrice;
  roomPriceTotalWithTaxesAndFees: FiatPrice;
  roomBasePricePerNightTotal: FiatPrice;
  roomBasePriceTotal: FiatPrice;
} => {
  const quotedProduct = LodgingSelectors.getQuotedLodgingProduct(state);

  const pricing = quotedProduct?.product.value.pricingV1;

  //there is a BE limitation in sending these amounts separated
  const taxAmount =
    pricing?.taxesAndFeesTotal.fiat.value -
    (pricing?.paymentSchedule.payLaterTotal?.fiat.value ?? 0);

  return {
    roomPriceTotal: pricing?.paymentSchedule.payNowTotal.fiat,
    roomDueUponCheckInTotal: pricing?.paymentSchedule.payLaterTotal?.fiat,
    roomPriceTotalWithTaxesAndFees: pricing?.tripTotal.fiat,
    roomBasePriceTotal: pricing?.subTotal.fiat,
    roomBasePricePerNightTotal: pricing?.dailyRate.fiat,
    roomTaxesAndFeesTotal: {
      ...pricing?.taxesAndFeesTotal.fiat,
      value: taxAmount,
    },
  };
};

export const getAdditionalWalletTrackingProperties = (
  state: LodgingCheckoutStateWithoutValue
) => state.context[ParentState.wallet].trackingProperties;
