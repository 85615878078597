import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import { Icon } from "@b2bportal/core-ui";
import clsx from "clsx";
import { Fragment } from "react";
import defaultStyles from "./FlightTime.module.scss";

export interface FlightTimeComponentProps extends PlatformComponentProps {
  departureTime: string;
  arrivalTime: string;
  stops: number;
}

export const FlightTime = ({
  departureTime,
  arrivalTime,
  className,
  children,
  stops,
}: FlightTimeComponentProps) => {
  const COMPNENT_KEY = CoreFlightsComponent.FlightTime;
  const styles = useFlightStyles(COMPNENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPNENT_KEY);

  return (
    <div className={clsx(block, className)}>
      <div className={cn("time", { "departure-time": true })}>
        {departureTime}
      </div>
      <div className={cn("flight-time-icon")}>
        <div className={cn("airplane-icon")}>
          <Icon iconName={IconNameEnum.airplane} />
        </div>
        <div className={cn("circle", { departure: true })} />
        <div className={cn("line")} />
        {Array.from({ length: stops }, (_, i) => i).map((id) => {
          return (
            <Fragment key={id}>
              <div className={cn("circle", { stop: true })} />{" "}
              <div className={cn("line", { stop: true })} />
            </Fragment>
          );
        })}
        <div className={cn("circle", { arrival: true })} />
      </div>
      <div className={clsx(cn("time", { "arrival-time": true }))}>
        {arrivalTime}
      </div>
      {children}
    </div>
  );
};
