import { I18nMarkup } from "@hopper-b2b/i18n";
import styles from "./styles.module.scss";
import { BestPriceGuaranteeBanner } from "../../BestPriceGuaranteeBanner";
import CashBackIcon from "../../../assets/client/cash-back-icon.svg";
import CashBackIconDarkMode from "../../../assets/client/darkMode/cash-back-icon-light.svg";
import { FintechBanner } from "../../FintechBanner";
import { Product } from "@b2bportal/purchase-api";
import { useDarkModePreferred } from "../../../utils/colors";
interface PriceBreakdownAdditionalInfoProps {
  rewardsBack: string;
}

const PriceBreakdownAdditionalInfo = ({
  rewardsBack,
}: PriceBreakdownAdditionalInfoProps) => {
  const isDarkMode = useDarkModePreferred();

  return (
    <div className={styles.PriceBreakdownAdditionalInfo}>
      <BestPriceGuaranteeBanner product={Product.Hotel} />
      <FintechBanner
        fintechIcon={isDarkMode ? CashBackIconDarkMode : CashBackIcon}
        hideBrand
        title={
          <I18nMarkup
            className="default-text"
            tKey={"cashBackOnThisBooking"}
            replacements={{ cashBack: rewardsBack }}
          />
        }
      />
    </div>
  );
};

export default PriceBreakdownAdditionalInfo;
