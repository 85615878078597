import clsx from "clsx";

import { AirlineIconSize, iconNameToSourceMap, iconSizeMap } from "./types";
import defaultStyles from "./styles.module.scss";
import { useMemo } from "react";
import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";

export interface IAirlineIconProps {
  // An airline code must consist of two uppercase letters or digits
  airlineCode: string;
  size?: AirlineIconSize;
  className?: string;
  altText?: string;
}

const iconSizes = ["large", "medium", "small"];
export const AirlineIcon = ({
  size = "large",
  className,
  airlineCode = "PLACEHOLDER",
  altText,
}: IAirlineIconProps) => {
  const styles = useUiStyles(CoreUiComponents.AirlineIcon, defaultStyles);
  const [block] = useModuleBEM(styles, CoreUiComponents.AirlineIcon, [size]);

  const [large, medium, small] = useMemo(
    () =>
      iconSizes.map((s) => {
        return (
          iconNameToSourceMap[`${airlineCode}-${iconSizeMap[s]}`] ||
          iconNameToSourceMap[`PLACEHOLDER-${iconSizeMap[s]}`]
        );
      }),
    [airlineCode]
  );

  return (
    <img
      className={clsx(block, className)}
      alt={altText ?? ""}
      src={small}
      srcSet={`${small} 32w, ${medium} 48w, ${large} 70w`}
      sizes="(max-width: 320px) 32px, (max-width: 375px) 48px, 70px"
    />
  );
};
