import { useI18nContext } from "@hopper-b2b/i18n";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import { DisruptionProtectionDisruptionProtectionPolicyDetails } from "@b2bportal/air-booking-api";

export const useRebookingLimit = () => {
  const { formatFiatCurrency } = useI18nContext();

  const formatCurrency = (value?: FiatPrice) => {
    return formatFiatCurrency(value, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  };

  const rebookingLimit = (
    policyDetails: DisruptionProtectionDisruptionProtectionPolicyDetails
  ) => {
    const rebookingLimit = policyDetails.rebookingLimit;
    if (rebookingLimit) {
      return formatCurrency(rebookingLimit.fiat);
    }
  };

  return { rebookingLimit };
};
