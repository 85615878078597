import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import defaultStyles from "./PageLoadingScreen.module.scss";
import { CoreUiComponents } from "@b2bportal/core-types";
import { Loading } from "@b2bportal/core-ui";
import { PropsWithChildren } from "react";
import clsx from "clsx";

export interface PageLoadingScreenComponentProps {
  className: string;
  isLoading?: boolean;
  backgroundImage?: string;
  text?: string;
}
export const PageLoadingScreen = ({
  isLoading,
  className,
  children,
  text = "",
  backgroundImage = "",
}: PropsWithChildren<PageLoadingScreenComponentProps>) => {
  const styles = useUiStyles(CoreUiComponents.PageLoadingScreen, defaultStyles);
  const [block, cn] = useModuleBEM(styles, CoreUiComponents.PageLoadingScreen);

  return isLoading ? (
    <div className={clsx(block, className)}>
      <div
        className={cn("image")}
        style={{ backgroundImage: `url('${backgroundImage}')` }}
      />
      <div className={cn("icon")}>
        <Loading />
      </div>
      {text && <div className={cn("text")}>{text}</div>}
      {children}
    </div>
  ) : null;
};
