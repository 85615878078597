import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseSummaryCard.module.scss";
import { DisruptionExerciseContainer } from "../DisruptionExerciseContainer";

export interface DisruptionExerciseSummaryCardProps {
  title: string;
  description: string;
}

export const DisruptionExerciseSummaryCard = ({
  title,
  description,
}: DisruptionExerciseSummaryCardProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseSummaryCard;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <DisruptionExerciseContainer
      content={
        <div className={cn("container")}>
          <span className={cn("title")}>{title}</span>
          <span
            className={cn("subtitle")}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
      }
    ></DisruptionExerciseContainer>
  );
};

export default DisruptionExerciseSummaryCard;
