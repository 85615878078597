import { createSlice } from "@reduxjs/toolkit";
import { CallState, ExternalResource } from "@b2bportal/core-types";
import { AirCfarExerciseQuoteResponse } from "@b2bportal/air-cfar-api";

import { fetchQuote, fulfillQuote } from "./thunks";
import { handleAsyncThunk } from "@b2bportal/core-utilities";

export interface CfarExerciseState {
  quoteFetchState: ExternalResource<AirCfarExerciseQuoteResponse, unknown>;
  fulfillQuoteFetchState: ExternalResource<unknown, unknown>;
}

const initialState = Object.freeze({
  quoteFetchState: {
    state: CallState.NotCalled,
  },
  fulfillQuoteFetchState: {
    state: CallState.NotCalled,
  },
} as CfarExerciseState);

export const cfarExerciseSlice = createSlice({
  name: "cfar/exercise",
  initialState: { ...initialState },
  reducers: {
    resetCfarExerciseState: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    handleAsyncThunk(builder, fetchQuote, (state, resource) => {
      state.quoteFetchState = resource;
      state.fulfillQuoteFetchState = { ...initialState.fulfillQuoteFetchState };
    });

    handleAsyncThunk(builder, fulfillQuote, (state, resource) => {
      state.fulfillQuoteFetchState = resource;
    });
  },
});

export const cfarExerciseActions = {
  ...cfarExerciseSlice.actions,
  fetchQuote,
  fulfillQuote,
};

export const cfarExerciseReducer = cfarExerciseSlice.reducer;
