import { ButtonWrap } from "@hopper-b2b/ui";
import styles from "./styles.module.scss";
import { ReactComponent as Arrow } from "../../assets/client/arrow-action.svg";

interface ActionRowItemProps {
  text: string;
  icon?: JSX.Element;
  onClick?: () => void;
}

export const ActionRowItem = ({ text, icon, onClick }: ActionRowItemProps) => {
  return (
    <ButtonWrap className={styles.ActionRowItem} onClick={onClick}>
      <p className="text">{text}</p>
      <div className="icon-wrapper">{icon ? icon : <Arrow />}</div>
    </ButtonWrap>
  );
};
