import { createSelector } from "reselect";
import { FlightSearchFormProps } from "@hopper-b2b/common-search";
import {
  TripCategory,
  IDateBucket,
  SliceStopCountFilter,
  PassengerTypes,
  Passengers,
} from "@hopper-b2b/types";
import { isEqual, toPairs } from "lodash-es";

import { IStoreState } from "../../../../reducers/types";
import { initialFilterOptions, noLCCFareclassOptionFilter } from "../index";

export const getNumTravelers = (state: IStoreState) =>
  state.flightSearch.numTravelers;

export const getTripCategory = (state: IStoreState) =>
  state.flightSearch.tripCategory;

export const getRenderSearchInput = (state: IStoreState) =>
  state.flightSearch.renderSearchInput;

export const getCalendarVisited = (state: IStoreState) =>
  state.flightSearch.calendarVisited;

export const getOpenDatesModal = (state: IStoreState) =>
  state.flightSearch.openDatesModal;

export const getCalendarA11yEnabled = (state: IStoreState) =>
  state.flightSearch.calendarA11yEnabled;

export const getOrigin = (state: IStoreState) => state.flightSearch.origin;

export const getDestination = (state: IStoreState) =>
  state.flightSearch.destination;

export const getOriginType = (state: IStoreState) =>
  state.flightSearch.originType;

export const getDestinationType = (state: IStoreState) =>
  state.flightSearch.destinationType;

export const getOriginCategories = (state: IStoreState) =>
  state.flightSearch.originCategories;

export const getOriginCategoriesLoading = (state: IStoreState) =>
  state.flightSearch.originCategoriesLoading;

export const getDestinationCategories = (state: IStoreState) =>
  state.flightSearch.destinationCategories;

export const getDestinationCategoriesLoading = (state: IStoreState) =>
  state.flightSearch.destinationCategoriesLoading;

export const getDepartureDateBucketsLoading = (state: IStoreState) =>
  state.flightSearch.departureDateBucketsLoading;

export const getMobileSearchProgress = (state: IStoreState) =>
  state.flightSearch.mobileSearchStep;

export const getCurrency = (state: IStoreState) => state.flightSearch.currency;

export const getDepartureDate = (state: IStoreState) =>
  state.flightSearch.departureDate;

export const getReturnDate = (state: IStoreState) =>
  state.flightSearch.returnDate;

export const getIsMissingDate = (state: IStoreState) =>
  state.flightSearch.missingDate;

export const getDepartureMonths = (state: IStoreState) =>
  state.flightSearch.departureMonths;

export const getDepartureDateBuckets = (state: IStoreState) =>
  state.flightSearch.departureDateBuckets;

export const getAwaitingRefetch = (state: IStoreState) =>
  state.flightSearch.awaitingRefetch;

export const getPriceRangeLegends = createSelector(
  [getDepartureDateBuckets],
  (departureDateBuckets) => {
    const legends = departureDateBuckets
      ? departureDateBuckets.map((dateBucket: IDateBucket) => dateBucket.legend)
      : [];
    return legends;
  }
);

export const getPreferences = (state: IStoreState) =>
  state.flightSearch.preferences;

export const getStopsOption = (state: IStoreState) =>
  state.flightSearch.stopsOption;

export const getMaxPriceFilter = (state: IStoreState) =>
  state.flightSearch.maxPriceFilter;

export const getFareclassOptionFilter = (state: IStoreState) =>
  state.flightSearch.fareclassOptionFilter;

export const getOutboundDepartureTimeRange = (state: IStoreState) =>
  state.flightSearch.outboundDepartureTimeRange;

export const getOutboundArrivalTimeRange = (state: IStoreState) =>
  state.flightSearch.outboundArrivalTimeRange;

export const getReturnDepartureTimeRange = (state: IStoreState) =>
  state.flightSearch.returnDepartureTimeRange;

export const getReturnArrivalTimeRange = (state: IStoreState) =>
  state.flightSearch.returnArrivalTimeRange;

export const getAirlineFilter = (state: IStoreState) =>
  state.flightSearch.airlineFilter;

export const getAirportFilter = (state: IStoreState) =>
  state.flightSearch.airportFilter;

export const getFlightNumberFilter = (state: IStoreState) =>
  state.flightSearch.flightNumberFilter;

// Get current filter states
// Note: the max price filter state lives in shop/selectors (it relies on data from the trip summaries page)
export const getHasSetStopsOption = (state: IStoreState) =>
  state.flightSearch.stopsOption !== initialFilterOptions.stopsOption;

export const getStopsOptionFilter = (state: IStoreState) =>
  state.flightSearch.stopsOption;

export const getHasSetAirlineFilter = (state: IStoreState) =>
  state.flightSearch.airlineFilter.length > 0;

export const getHasSetAirportFilter = (state: IStoreState) =>
  state.flightSearch.airportFilter.length > 0;

export const getHasSetFlightNumberFilter = (state: IStoreState) =>
  state.flightSearch.flightNumberFilter.length > 0;

export const getHasSetFareClassFilter = (state: IStoreState) =>
  Object.values(state.flightSearch.fareclassOptionFilter).includes(true);

export const getHasSetDepartureTimeRange = createSelector(
  [getOutboundDepartureTimeRange, getReturnDepartureTimeRange],
  (outboundDepartureTimeRange, returnDepartureTimeRange) => {
    return (
      !isEqual(
        outboundDepartureTimeRange,
        initialFilterOptions.outboundDepartureTimeRange
      ) ||
      !isEqual(
        returnDepartureTimeRange,
        initialFilterOptions.returnDepartureTimeRange
      )
    );
  }
);

export const getHasSetArrivalTimeRange = createSelector(
  [getOutboundArrivalTimeRange, getReturnArrivalTimeRange],
  (outboundArrivalTimeRange, returnArrivalTimeRange) => {
    return (
      !isEqual(
        outboundArrivalTimeRange,
        initialFilterOptions.outboundArrivalTimeRange
      ) ||
      !isEqual(
        returnArrivalTimeRange,
        initialFilterOptions.returnArrivalTimeRange
      )
    );
  }
);

export const hasSelectedDates = createSelector(
  getDepartureDate,
  getReturnDate,
  getTripCategory,
  (departureDate, returnDate, tripCategory): boolean => {
    return (
      (tripCategory === TripCategory.ROUND_TRIP &&
        !!departureDate &&
        !!returnDate) ||
      (tripCategory === TripCategory.ONE_WAY && !!departureDate)
    );
  }
);

export const hasUnsupportedPredictionFilters = createSelector(
  [
    getHasSetDepartureTimeRange,
    getHasSetArrivalTimeRange,
    getHasSetAirlineFilter,
    getHasSetAirportFilter,
    getHasSetFlightNumberFilter,
    getFareclassOptionFilter,
    getStopsOptionFilter,
  ],
  (
    hasSetDepartureTimeRange,
    hasSetArrivalTimeRange,
    hasSetAirlineFilter,
    hasSetAirportFilter,
    hasSetFlightNumberFilter,
    fareclassOptionFilter,
    stopsOption
  ) => {
    const filtersValid =
      isEqual(
        fareclassOptionFilter,
        initialFilterOptions.fareclassOptionFilter
      ) || isEqual(fareclassOptionFilter, noLCCFareclassOptionFilter);
    const stopFilterValid =
      stopsOption === SliceStopCountFilter.NONE ||
      stopsOption === SliceStopCountFilter.ANY_NUMBER ||
      stopsOption === SliceStopCountFilter.DEFAULT;
    return (
      !filtersValid ||
      hasSetDepartureTimeRange ||
      hasSetArrivalTimeRange ||
      hasSetAirlineFilter ||
      hasSetFlightNumberFilter ||
      hasSetAirportFilter ||
      !stopFilterValid
    );
  }
);
export const getActiveFiltersCount = createSelector(
  [
    getStopsOption,
    getFareclassOptionFilter,
    getHasSetDepartureTimeRange,
    getHasSetArrivalTimeRange,
  ],
  (
    stopsOption,
    fareclassOptionFilter,
    hasSetDepartureTimeRange,
    hasSetArrivalTimeRange
  ) => {
    let filtersCount = 0;

    if (stopsOption !== initialFilterOptions.stopsOption) {
      filtersCount += 1;
    }

    if (
      !isEqual(
        fareclassOptionFilter,
        initialFilterOptions.fareclassOptionFilter
      )
    ) {
      filtersCount += 1;
    }

    if (hasSetDepartureTimeRange) {
      filtersCount += 1;
    }

    if (hasSetArrivalTimeRange) {
      filtersCount += 1;
    }

    return filtersCount;
  }
);

export const getAdultsCount = (state: IStoreState) =>
  state.flightSearch.adultsCount;

export const getChildrenCount = (state: IStoreState) =>
  state.flightSearch.childrenCount;

export const getInfantsInSeatCount = (state: IStoreState) =>
  state.flightSearch.infantsInSeatCount;

export const getInfantsOnLapCount = (state: IStoreState) =>
  state.flightSearch.infantsOnLapCount;

export const passengerCountSelector = createSelector(
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  (
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount
  ): Passengers => {
    const passengerObj = {};
    if (adultsCount > 0) passengerObj[PassengerTypes.Adult] = adultsCount;
    if (infantsInSeatCount > 0)
      passengerObj[PassengerTypes.InfantInSeat] = infantsInSeatCount;
    if (infantsOnLapCount > 0)
      passengerObj[PassengerTypes.InfantInLap] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj[PassengerTypes.Child] = childrenCount;
    return passengerObj;
  }
);

export const getPassengersTotal = createSelector(
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  (adultsCount, childrenCount, infantsInSeatCount, infantsOnLapCount) =>
    adultsCount + childrenCount + infantsInSeatCount + infantsOnLapCount
);

export const getSelectedFareShelfRatings = createSelector(
  getFareclassOptionFilter,
  (fareClassFilters) => {
    const filtersToShelfRatingMap = {
      basic: 0,
      standard: 1,
      enhanced: 2,
      premium: 3,
      luxury: 4,
    };
    return toPairs(fareClassFilters).reduce(
      (ratings, [filter, isActive]) =>
        isActive ? [...ratings, filtersToShelfRatingMap[filter]] : ratings,
      [] as Array<number>
    );
  }
);

export const getIsNoLCC = createSelector(
  getFareclassOptionFilter,
  (fareClassFilters) => !fareClassFilters.basic
);

export const viewCalendarEventProperties = createSelector(
  getOrigin,
  getDestination,
  getTripCategory,
  (origin, destination, tripCategory) => ({
    origin: `${origin?.id.code.regionType}/${origin?.id.code.code}`,
    destination: `${destination?.id.code.regionType}/${destination?.id.code.code}`,
    tripCategory,
  })
);

export const getFreezeOffer = (state: IStoreState) =>
  state.flightShop.priceFreezeOffer;

export const getInitialSearchValues = createSelector(
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
  getTripCategory,
  (depDate, dest, origin, retDate, tripCategory) =>
    ({
      departureDate: depDate,
      destination: dest,
      origin,
      returnDate: retDate,
      tripCategory,
    } as unknown as FlightSearchFormProps)
);

export const getFocusedAutocomplete = (state: IStoreState) =>
  state.flightSearch.focusedAutocomplete;
