import { getObjectKeysAsObject, setContextWithKey } from "@checkout/helpers";
import { assign, type DoneInvokeEvent, sendParent } from "xstate";
import type { PartialParentWithTravelerContext } from "./context";
import {
  DeleteTravelerEvent,
  type SelectTravelerEvent,
  type SetCurrentTravelerEvent,
  TravelerEventTypes,
} from "./events";
import { ParentState, TravelerError } from "@checkout/index";
import { isAdult } from "@hopper-b2b/utilities";
import type { ListTravelersResponse } from "@b2bportal/traveler-api/lib/api";

export const actions = {
  selectTraveler: assign(
    (ctx: PartialParentWithTravelerContext, event: SelectTravelerEvent) => {
      const travelerInformationCtx = ctx[ParentState.travelerInformation];
      const { travelerId, singleTravelerWorkflow, onUpdate = false } = event;
      const { selectedTravelerIds, travelers } = travelerInformationCtx;
      const newTravelerInfoCtx = { ...travelerInformationCtx };

      // Unselect the traveler if already exists in the selected list and action is not triggered by update
      if (!onUpdate && selectedTravelerIds.includes(travelerId)) {
        newTravelerInfoCtx.selectedTravelerIds = selectedTravelerIds.filter(
          (id) => id !== travelerId
        );
      } else {
        const newTraveler = travelers.find(
          (traveler) => traveler.id === travelerId
        );
        if (!newTraveler) return ctx;

        newTravelerInfoCtx.selectedTravelerIds = singleTravelerWorkflow
          ? [travelerId] // hotel and car have only one primary traveler
          : [...selectedTravelerIds, travelerId]; // air can have multiple travelers
      }

      return {
        ...ctx,
        [ParentState.travelerInformation]: newTravelerInfoCtx,
      };
    }
  ),

  setTravelers: assign(
    (
      ctx: PartialParentWithTravelerContext,
      event: DoneInvokeEvent<ListTravelersResponse>
    ) => {
      setContextWithKey(
        ctx,
        `${ParentState.travelerInformation}.travelers`,
        event.data.travelers
      );
      return ctx;
    }
  ),

  goPrevious: sendParent(TravelerEventTypes.PREVIOUS),

  handleDeleteTraveler: assign(
    (
      ctx: PartialParentWithTravelerContext,
      event: DoneInvokeEvent<DeleteTravelerEvent>
    ) => {
      const deletedTravelerId = event.data.travelerId;
      const selectedTravelerIds =
        ctx[ParentState.travelerInformation].selectedTravelerIds;
      ctx[ParentState.travelerInformation].selectedTravelerIds =
        selectedTravelerIds.filter((id) => deletedTravelerId !== id);

      const travelers = ctx[ParentState.travelerInformation].travelers;
      ctx[ParentState.travelerInformation].travelers = travelers.filter(
        (traveler) => traveler.id !== deletedTravelerId
      );
      return ctx;
    }
  ),

  setSingleDefaultTraveler: assign((ctx: PartialParentWithTravelerContext) => {
    if (ctx[ParentState.travelerInformation].selectedTravelerIds.length > 0) {
      return ctx;
    }

    if (ctx[ParentState.travelerInformation].travelers.length > 0) {
      ctx[ParentState.travelerInformation].selectedTravelerIds = [
        ctx[ParentState.travelerInformation].travelers[0].id,
      ];
    }
    return ctx;
  }),

  setSingleDefaultAdult: assign((ctx: PartialParentWithTravelerContext) => {
    if (ctx[ParentState.travelerInformation].selectedTravelerIds.length > 0) {
      return ctx;
    }

    if (ctx[ParentState.travelerInformation].travelers.length > 0) {
      const firstAdult = ctx[ParentState.travelerInformation].travelers.find(
        (u) => isAdult(u.dateOfBirth)
      );
      if (firstAdult) {
        ctx[ParentState.travelerInformation].selectedTravelerIds = [
          firstAdult.id,
        ];
      }
    }
    return ctx;
  }),

  setCurrentTraveler: assign(
    (ctx: PartialParentWithTravelerContext, event: SetCurrentTravelerEvent) =>
      setContextWithKey(
        ctx,
        `${ParentState.travelerInformation}.currentUserTraveler`,
        event.traveler
      )
  ),

  setTravelersError: assign(
    (
      ctx: PartialParentWithTravelerContext,
      event: DoneInvokeEvent<TravelerError>
    ) =>
      setContextWithKey(
        ctx,
        `${ParentState.travelerInformation}.error`,
        event.data
      )
  ),

  clearTravelerInformationError: assign(
    (ctx: PartialParentWithTravelerContext, _event) => {
      ctx[ParentState.travelerInformation].error = undefined;
      return ctx;
    }
  ),

  unselectAllTravelers: assign(
    (ctx: PartialParentWithTravelerContext, _event) => {
      ctx[ParentState.travelerInformation].selectedTravelerIds = [];
      return ctx;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
