import { assign, type DoneInvokeEvent } from "xstate";
import type { MatchingProductHotel } from "@b2bportal/price-freeze-api";
import type { HotelPriceFreezeVoucher } from "@b2bportal/core-types";
import {
  ParentState,
  type LodgingContext,
  type HotelsPriceFreezeExerciseContext,
  type AddFrozenProductToExerciseNotAvailableEvent,
} from "@checkout/index";
import { getObjectKeysAsObject, setContextWithKey } from "@checkout/helpers";

export const actions = {
  addPriceFreezeVoucher: assign(
    (
      context: HotelsPriceFreezeExerciseContext,
      event: DoneInvokeEvent<HotelPriceFreezeVoucher>
    ) => {
      return setContextWithKey(
        context,
        `${ParentState.priceFreeze}.priceFreezeVoucher`,
        event.data
      );
    }
  ),

  addFrozenProductToExerciseToLodging: assign(
    (context: LodgingContext, event: DoneInvokeEvent<MatchingProductHotel>) => {
      return setContextWithKey(
        context,
        `${ParentState.lodgingShop}.selectedOpaqueQuoteRequest`,
        event.data.opaqueQuoteRequest
      );
    }
  ),
  addFrozenProductToExerciseAvailable: assign(
    (context: HotelsPriceFreezeExerciseContext) => {
      return setContextWithKey(
        context,
        `${ParentState.priceFreeze}.frozenProductToExerciseAvailable`,
        "Available"
      );
    }
  ),
  addFrozenProductToExerciseNotAvailable: assign(
    (
      context: HotelsPriceFreezeExerciseContext,
      event: AddFrozenProductToExerciseNotAvailableEvent
    ) => {
      if (event.data.FindProductToExerciseResponse === "NotAvailable") {
        return setContextWithKey(
          context,
          `${ParentState.priceFreeze}.frozenProductToExerciseAvailable`,
          "NotAvailable"
        );
      }
      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
