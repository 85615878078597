import { Box, Dialog, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";

import { Person } from "@b2bportal/air-booking-api";
import { trackEvent } from "@hopper-b2b/api";
import {
  EntryButtonEnum,
  FreezePriceClickProperties,
  PreviousFlightEnum,
  PriceFreezeEntryEnum,
  PriceFreezeHistoryState,
  PriceFreezeReviewEntry,
  PriceFreezeTrackingEvents,
} from "@hopper-b2b/fintech";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  FiatPrice,
  HalfSheetHashType,
  PRICE_DROP_VIEWED,
  PriceDropProtectionEnum,
  VIEWED_PRICE_DROP_DETAILS,
  VIEWED_TRIP_SUMMARY,
} from "@hopper-b2b/types";
import {
  ButtonWrap,
  FloatingBox,
  Icon,
  IconName,
  MobilePopoverCard,
  MobilePriceDropProtectionImage,
  PriceDropProtection,
  PriceDropProtectionImage,
  PriceFreeze,
  PriceFreezeImage,
  PriceFreezeImageMobile,
  ReviewItineraryFloatingSection,
  Slot,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  getIsMixedClass,
  getVirtualInterlineLayovers,
  hasHTSConnectSupport,
  useDeviceTypes,
  useEnablePriceFreeze,
  useEnableSingleTapSelectFlight,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { ClientContext } from "../../../../App";
import {
  PATH_FREEZE,
  PATH_PRICE_DROP_PROTECTION,
  PATH_PRICE_FREEZE,
  PATH_PRICE_FREEZE_PURCHASE,
} from "../../../../utils/urlPaths";
import {
  getMaxRefundForPrediction,
  getMonitoringDaysCountForPrediction,
  getPriceFreezeTitleVars,
  useGetPriceDropSubtitle,
} from "../../constants";
import {
  useGoToCheckout,
  useGoToNextStep,
} from "../../hooks/flightShopNavigationHooks";
import { FlightPricingSummarySection } from "../FlightPricingSummarySection";
import { ClosePDPButton } from "./components/ClosePDPButton";
import { DesktopReviewContent } from "./components/DesktopReviewContent";
import { MobileItineraryDetailsModal } from "./components/MobileItineraryDetailsModal";
import { MobileReviewContent } from "./components/MobileReviewContent";
import { FlightShopReviewItineraryConnectorProps } from "./container";
import "./styles.scss";
import { IOpenModal, OpenModalEnum } from "./types/IOpenModal";
import { getRewardsText } from "./utils";

interface IFlightShopReviewItineraryProps
  extends FlightShopReviewItineraryConnectorProps {
  importantInfo?: string[];
  isMobile: boolean;
  onContinue?: () => void;
  onChangeFlight?: (isOutbound: boolean) => void;
  passengers?: Person[];
  priceSummaryChevronIcon?: ReactNode;
  primaryButtonText?: string;
  totalCost?: FiatPrice;
  viewPriceBreakdown?: () => void;
  exchange?: boolean;
}

//TODO: This needs broken up into logical sense, Here be Dragons!
export const FlightShopReviewItinerary = ({
  tripDetailsLoading,
  isOneWay,
  departureDate,
  returnDate,
  tripDetails,
  fareDetails,
  importantInfo,
  isMobile,
  isMultiTicket,
  rewardsKey,
  populateFlightBookQueryParams,
  airports,
  prediction,
  viewedTripSummaryProperties,
  onContinue,
  onChangeFlight,
  passengers,
  priceDropViewedProperties,
  priceFreezeFiat,
  priceFreezeRewards,
  priceFreezeCap,
  priceFreezeOffer,
  priceFreezeDuration,
  priceSummaryChevronIcon,
  primaryButtonText,
  viewedPriceFreezeProperties,
  adultsCount,
  childrenCount,
  infantsInSeatCount,
  infantsOnLapCount,
  selectedTripPriceFreezeOffer,
  totalCost,
  viewPriceBreakdown,
  exchange = false,
}: IFlightShopReviewItineraryProps) => {
  const client = getEnvVariables("clientName");
  const isUberClient = client === ClientName.UBER;
  const isHopperClient = client === ClientName.HOPPER;
  const isNubankClient = client === ClientName.NUBANK;
  const isHTSConnect = hasHTSConnectSupport();
  const isLloydsClient = client === ClientName.LLOYDS;

  const history = useHistory();
  const location = useLocation();
  const { postEvent } = useUberBridge();

  const { matchesMobile } = useDeviceTypes();

  const { t, formatFiatCurrency } = useI18nContext();
  const clientContext = useContext(ClientContext);

  const [openModal, setOpenModal] = useState<IOpenModal>(false);
  const [priceFreezeModalOpen, setPriceFreezeModalOpen] = useState(false);

  const [priceDropPredictionModalOpen, setPriceDropPredictionModalOpen] =
    useState(false);

  const [isOutgoingMixedClass, setIsOutgoingMixedClass] = useState(false);
  const [isReturnMixedClass, setIsReturnMixedClass] = useState(false);

  const getPriceDropSubtitle = useGetPriceDropSubtitle();
  // TODO: refactor pricing & reward as selectors
  const pricing = fareDetails?.paxPricings[0].pricing;
  const totalCostFiat: FiatPrice = useMemo(
    () =>
      totalCost || {
        value:
          (pricing?.baseAmount?.fiat.value || 0) +
          (pricing?.taxAmount?.fiat.value || 0) +
          (pricing?.additionalMargin ? pricing.additionalMargin.fiat.value : 0),
        currencyCode: pricing?.baseAmount.fiat.currencyCode,
        currencySymbol: pricing?.baseAmount.fiat.currencySymbol,
      },
    [
      pricing?.additionalMargin,
      pricing?.baseAmount.fiat.currencyCode,
      pricing?.baseAmount.fiat.currencySymbol,
      pricing?.baseAmount.fiat.value,
      pricing?.taxAmount?.fiat.value,
      totalCost,
    ]
  );

  const showFlightPriceFreeze = useEnablePriceFreeze();
  const enableSingleTapSelectFlight = useEnableSingleTapSelectFlight();

  useEffect(() => {
    // Hide footer on Review Itinerary screen - cannot update HIDDEN_FOOTER_PATHS because we are still in flight shop
    if (isUberClient) {
      const footer = document.querySelector(".generic-footer") as HTMLElement;
      if (footer) footer.style.display = "none";
    }
  }, [isUberClient]);

  useEffect(() => {
    const priceFreezeTrackingProps = {
      ...viewedPriceFreezeProperties,
      frozen_price_total_usd: totalCostFiat.value,
    };
    trackEvent({
      eventName: VIEWED_TRIP_SUMMARY,
      properties: {
        ...viewedTripSummaryProperties,
        ...(showFlightPriceFreeze ? priceFreezeTrackingProps : {}),
        outbound_vi_layovers: getVirtualInterlineLayovers(fareDetails, true),
        return_vi_layovers: getVirtualInterlineLayovers(fareDetails, false),
      },
    });
    postEvent(VIEWED_TRIP_SUMMARY, {
      fare_class: viewedTripSummaryProperties.fare_class,
      destination: viewedTripSummaryProperties.destination,
      origin: viewedTripSummaryProperties.origin,
      return_date: viewedTripSummaryProperties.return_date,
      departure_date: viewedTripSummaryProperties.departure_date,
      price_freeze_shown: showFlightPriceFreeze
        ? priceFreezeTrackingProps.price_freeze_shown
        : false,
      trip_type: viewedTripSummaryProperties.trip_type,
    });
  }, [
    postEvent,
    showFlightPriceFreeze,
    totalCostFiat.value,
    viewedPriceFreezeProperties,
    viewedTripSummaryProperties,
  ]);

  const goToNextStep = useGoToNextStep();
  const goToCheckout = useGoToCheckout();

  // TODO: Consolidate these two continue functions - requires thorough testing throughout all portals
  const uberReviewStepOnContinue = useCallback(() => {
    if (onContinue) {
      onContinue();
    } else if (isMobile) {
      goToNextStep();
    } else {
      goToCheckout();
    }
  }, [goToNextStep, isMobile, goToCheckout, onContinue]);

  const reviewStepOnContinue = useCallback(() => {
    const proceed = isMobile && !isHTSConnect && !(isNubankClient && exchange);
    if (proceed) {
      goToNextStep();
    } else {
      if (onContinue) {
        onContinue();
      } else {
        goToCheckout();
      }
    }
  }, [goToNextStep, isMobile, goToCheckout, onContinue]);

  const priceFreezeOnContinue = useCallback(() => {
    setPriceFreezeModalOpen(false);
    populateFlightBookQueryParams({
      history,
      pathname: PATH_FREEZE,
      preserveQuery: true,
    });
  }, [populateFlightBookQueryParams, history]);

  const priceFreezePurchaseQueryString = useMemo(() => {
    const urlParams: Record<string, string> = {
      tripId: tripDetails.id,
      fareId: fareDetails?.id ?? "",
    };

    // Add passenger query params
    if (adultsCount) {
      urlParams.adultsCount = `${adultsCount}`;
    }
    if (childrenCount) {
      urlParams.childrenCount = `${childrenCount}`;
    }
    if (infantsInSeatCount) {
      urlParams.infantsInSeatCount = `${infantsInSeatCount}`;
    }
    if (infantsOnLapCount) {
      urlParams.infantsOnLapCount = `${infantsOnLapCount}`;
    }

    return new URLSearchParams(urlParams).toString();
  }, [
    tripDetails,
    fareDetails,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
  ]);

  const handlePriceFreezeButtonClick = useCallback(() => {
    trackEvent({
      eventName: PriceFreezeTrackingEvents.FREEZE_PRICE_CLICK,
      properties: {
        price_freeze_entry: PriceFreezeEntryEnum.TRIP_SUMMARY,
      } as FreezePriceClickProperties,
    });

    history.push(
      `${PATH_PRICE_FREEZE_PURCHASE}?${priceFreezePurchaseQueryString}`,
      {
        priceFreezeEntry: PriceFreezeEntryEnum.TRIP_SUMMARY,
        entryButton: EntryButtonEnum.FREEZE_PRICE,
        previousFlight: PreviousFlightEnum.CHOSEN_FLIGHT,
      } as PriceFreezeHistoryState
    );
  }, [history, priceFreezePurchaseQueryString]);

  const isPDPEligible =
    prediction?.priceDropProtection?.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible;

  useEffect(() => {
    if (isPDPEligible && getEnvVariables("clientName") !== ClientName.NUBANK) {
      trackEvent({
        eventName: PRICE_DROP_VIEWED,
        properties: {
          ...priceDropViewedProperties,
          page: "trip_summary",
        },
      });
    }
  }, [isPDPEligible, priceDropViewedProperties]);

  useEffect(() => {
    if (fareDetails) {
      setIsOutgoingMixedClass(getIsMixedClass(fareDetails.slices[0]));
      !isOneWay &&
        setIsReturnMixedClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails, isOneWay]);

  useEffect(() => {
    if (location.hash === `#${HalfSheetHashType.FLIGHT_DETAILS_DEPARTURE}`) {
      setOpenModal(OpenModalEnum.DEPARTURE);
    } else if (
      location.hash === `#${HalfSheetHashType.FLIGHT_DETAILS_RETURN}`
    ) {
      setOpenModal(OpenModalEnum.RETURN);
    } else {
      setOpenModal(false);
    }
  }, [location]);

  const showFlightDetails = useCallback(
    (departure: boolean) => {
      history.push(
        `${location.pathname}${location.search}#${
          departure
            ? HalfSheetHashType.FLIGHT_DETAILS_DEPARTURE
            : HalfSheetHashType.FLIGHT_DETAILS_RETURN
        }`
      );
    },
    [history, location]
  );

  const onModalClose = useCallback(() => {
    getEnvVariables("clientName") === "uber"
      ? history.goBack()
      : setOpenModal(false);
  }, [history]);

  const priceString = useMemo(() => {
    return selectedTripPriceFreezeOffer
      ? formatFiatCurrency(selectedTripPriceFreezeOffer?.price?.fiat, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : undefined;
  }, [formatFiatCurrency, selectedTripPriceFreezeOffer]);

  const rewardText = useMemo(
    () => getRewardsText({ rewardsKey, pricing }),
    [pricing, rewardsKey]
  );

  const priceFreezeTitleVars = useMemo(
    () =>
      getPriceFreezeTitleVars({
        priceFreezeFiat,
        priceFreezeRewards,
        selectedRewardsAccountId: rewardsKey,
        priceFreezeCap,
        duration: priceFreezeDuration,
      }),

    [
      priceFreezeCap,
      priceFreezeDuration,
      priceFreezeFiat,
      priceFreezeRewards,
      rewardsKey,
    ]
  );

  if (!tripDetails || !fareDetails || !fareDetails?.paxPricings) {
    history.push(history.location);
    return null;
  }

  return (
    <>
      <Box
        className={clsx("flight-shop-review-itinerary-root", {
          mobile: isMobile,
        })}
      >
        <Slot id="flight-shop-review-itinerary-root-background" />
        {tripDetailsLoading ? (
          <Box></Box>
        ) : (
          <Grid
            container
            spacing={isMobile ? 0 : 4}
            className="flight-shop-review-itinerary-container"
          >
            <Grid item lg={8} md={12}>
              {isMobile ? (
                <MobileReviewContent
                  importantInfo={importantInfo}
                  isOneWay={isOneWay}
                  departureDate={departureDate}
                  returnDate={returnDate}
                  fareDetails={fareDetails}
                  tripDetails={tripDetails}
                  airports={airports}
                  totalCostFiat={totalCostFiat}
                  isOutgoingMixedClass={isOutgoingMixedClass}
                  isReturnMixedClass={isReturnMixedClass}
                  airplaneDepartIcon={clientContext?.assets?.airplaneDepart}
                  airplaneArriveIcon={clientContext?.assets?.airplaneArrive}
                  cardIcon={clientContext?.assets?.card}
                  onDepartureSummaryRowClick={() => showFlightDetails(true)}
                  onReturnSummaryRowClick={() => showFlightDetails(false)}
                  passengers={passengers}
                  passengersIcon={clientContext?.assets?.passenger}
                  priceSummaryChevronIcon={priceSummaryChevronIcon}
                  setOpenModal={setOpenModal}
                  viewPriceBreakdown={viewPriceBreakdown}
                />
              ) : (
                <DesktopReviewContent
                  expanded={
                    isHTSConnect && !enableSingleTapSelectFlight ? false : true
                  }
                  isMultiTicket={isMultiTicket}
                  isOneWay={isOneWay}
                  departureDate={departureDate}
                  returnDate={returnDate}
                  fareDetails={fareDetails}
                  tripDetails={tripDetails}
                  airports={airports}
                  isOutgoingMixedClass={isOutgoingMixedClass}
                  isReturnMixedClass={isReturnMixedClass}
                  onChangeFlight={onChangeFlight}
                  showChangeFlight={true}
                />
              )}
            </Grid>
            <Grid
              item
              lg={4}
              md={12}
              className={clsx("flight-shop-review-itinerary-bottom-container", {
                mobile: isMobile,
                fixed: isHopperClient || isNubankClient || isLloydsClient,
              })}
            >
              {isUberClient ? (
                <ReviewItineraryFloatingSection
                  priceString={priceString}
                  onContinue={uberReviewStepOnContinue}
                  handlePriceFreezeButtonClicked={handlePriceFreezeButtonClick}
                  imgSrc={clientContext?.assets?.lock}
                  priceFreezeAvailable={
                    showFlightPriceFreeze && !!priceFreezeOffer ? true : false
                  }
                  primaryButtonText={primaryButtonText}
                />
              ) : (
                <FloatingBox>
                  <Box
                    className={clsx(
                      "flight-shop-review-itinerary-bottom-wrapper",
                      {
                        mobile: isMobile,
                      }
                    )}
                  >
                    <Slot
                      id="flight-shop-review-itinerary-policy"
                      fareId={fareDetails.id}
                    />
                    {showFlightPriceFreeze && !!priceFreezeOffer ? (
                      <PriceFreezeReviewEntry
                        tripId={tripDetails.id}
                        fareId={fareDetails.id}
                        onPriceFreezeClick={handlePriceFreezeButtonClick}
                      />
                    ) : null}
                    <Box className="flight-shop-review-itinerary-price-summary-wrapper">
                      <Box className="flight-shop-review-itinerary-summary-section">
                        {prediction && isPDPEligible && (
                          <ButtonWrap
                            className="pdp-modal-link"
                            aria-labelledby="flight-shop-review-itinerary-price-drop-protection-popup"
                            onClick={() => {
                              trackEvent({
                                eventName: VIEWED_PRICE_DROP_DETAILS,
                                properties: {
                                  page: "trip_summary",
                                },
                              });
                              setPriceDropPredictionModalOpen(true);
                            }}
                          >
                            <Typography
                              className="pdp-modal-link-copy"
                              variant="inherit"
                            >
                              {t("pricePrediction.freePriceDropProtection")}
                              <Icon
                                name={IconName.InfoCircle}
                                ariaLabel={t(
                                  "pricePrediction.priceDropAriaLabel"
                                )}
                              />
                            </Typography>
                          </ButtonWrap>
                        )}
                        {prediction && isPDPEligible && isMobile ? (
                          <MobilePopoverCard
                            role="tooltip"
                            id="flight-shop-review-itinerary-price-drop-protection-popup"
                            topRightButton={
                              <ClosePDPButton
                                onClick={() =>
                                  setPriceDropPredictionModalOpen(false)
                                }
                              />
                            }
                            open={priceDropPredictionModalOpen}
                            className="mobile-price-drop-protection-popup"
                            contentClassName="mobile-flight-price-drop-protection-content-wrapper"
                            onClose={() =>
                              setPriceDropPredictionModalOpen(false)
                            }
                          >
                            <PriceDropProtection
                              className="price-drop-protection-card"
                              image={MobilePriceDropProtectionImage}
                              title={t("pdpTitle")}
                              subtitle={getPriceDropSubtitle(
                                getMonitoringDaysCountForPrediction(prediction),
                                getMaxRefundForPrediction(prediction)
                              )}
                              header={t("flightBookPdpHeader")}
                              ctaText={t("readTermsAndConditions")}
                              isMobile={true}
                              mobileTermsConditionCopy={t(
                                "priceDropProtectionBody"
                              )}
                            />
                          </MobilePopoverCard>
                        ) : (
                          prediction &&
                          isPDPEligible && (
                            <Dialog
                              role="tooltip"
                              id="flight-shop-review-itinerary-price-drop-protection-popup"
                              open={priceDropPredictionModalOpen}
                              className="price-drop-protection-popup"
                              onClose={() =>
                                setPriceDropPredictionModalOpen(false)
                              }
                            >
                              <Box className="price-drop-protection-wrapper">
                                <PriceDropProtection
                                  className="price-drop-protection-card"
                                  image={PriceDropProtectionImage}
                                  title={t("pdpTitle")}
                                  onClick={() =>
                                    window.open(
                                      `${PATH_PRICE_DROP_PROTECTION} `,
                                      "_blank"
                                    )
                                  }
                                  onClose={() =>
                                    setPriceDropPredictionModalOpen(false)
                                  }
                                  subtitle={getPriceDropSubtitle(
                                    getMonitoringDaysCountForPrediction(
                                      prediction
                                    ),
                                    getMaxRefundForPrediction(prediction)
                                  )}
                                  header={t("flightBookPdpHeader")}
                                  ctaText={t("readTermsAndConditions")}
                                />
                              </Box>
                            </Dialog>
                          )
                        )}
                        <Slot
                          id="flight-shop-review-itinerary-price-summary-section"
                          hidePrice={isHopperClient && !matchesMobile}
                          fareDetails={fareDetails}
                          isOneWay={isOneWay}
                          onClick={reviewStepOnContinue}
                          component={
                            <FlightPricingSummarySection
                              hidePrice={isHopperClient && !matchesMobile}
                              fareDetails={fareDetails}
                              isOneWay={isOneWay}
                              onClick={reviewStepOnContinue}
                            />
                          }
                        />
                      </Box>
                      <Slot id="non-refundable-banner" />
                    </Box>
                  </Box>
                </FloatingBox>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      {priceFreezeModalOpen && !isMobile && (
        <Dialog
          open={priceFreezeModalOpen}
          className="flight-price-freeze-popup"
          onClose={() => setPriceFreezeModalOpen(false)}
        >
          <Box className="price-freeze-wrapper">
            <PriceFreeze
              header={t("priceFreezeTitles.header")}
              title={t("priceFreezeTitles.title", {
                durationText: priceFreezeTitleVars.durationTextI18nKey
                  ? t(priceFreezeTitleVars.durationTextI18nKey.i18nKey, {
                      count: priceFreezeTitleVars.durationTextI18nKey.count,
                    })
                  : "",
                amount: priceFreezeTitleVars.amount,
                rewards: priceFreezeTitleVars.rewards,
              })}
              increaseText={t("priceFreezeTitles.increaseText", {
                priceString: priceFreezeTitleVars.priceString,
              })}
              decreaseText={t("priceFreezeTitles.decreaseText")}
              similarFlightText={t("priceFreezeTitles.similarFlightText")}
              ctaText={t("priceFreezeTitles.ctaText")}
              onContinue={priceFreezeOnContinue}
              className="price-freeze-card"
              image={PriceFreezeImage}
              onClose={() => setPriceFreezeModalOpen(false)}
              onClick={() => window.open(`${PATH_PRICE_FREEZE}`, "_blank")}
            />
          </Box>
        </Dialog>
      )}
      {priceFreezeModalOpen && isMobile && (
        <MobilePopoverCard
          open={priceFreezeModalOpen}
          className="mobile-price-freeze-popup"
          contentClassName="mobile-price-freeze-content-wrapper"
          onClose={() => setPriceFreezeModalOpen(false)}
          centered={true}
        >
          <Box className="price-freeze-wrapper">
            <PriceFreeze
              header={t("priceFreezeTitles.header")}
              title={t("priceFreezeTitles.title", {
                durationText: priceFreezeTitleVars.durationTextI18nKey
                  ? t(priceFreezeTitleVars.durationTextI18nKey.i18nKey, {
                      count: priceFreezeTitleVars.durationTextI18nKey.count,
                    })
                  : "",
                amount: priceFreezeTitleVars.amount,
                rewards: priceFreezeTitleVars.rewards,
              })}
              increaseText={t("priceFreezeTitles.increaseText", {
                priceString: priceFreezeTitleVars.priceString,
              })}
              decreaseText={t("priceFreezeTitles.decreaseText")}
              similarFlightText={t("priceFreezeTitles.similarFlightText")}
              ctaText={t("priceFreezeTitles.ctaText")}
              onContinue={priceFreezeOnContinue}
              className="price-freeze-card"
              image={PriceFreezeImageMobile}
              onClose={() => setPriceFreezeModalOpen(false)}
              isMobile={true}
              mobileTermsConditionCopy={t("priceFreezeTermsAndConditions")}
            />
          </Box>
        </MobilePopoverCard>
      )}
      {openModal && (
        <MobileItineraryDetailsModal
          openModal={!!openModal}
          isDeparture={openModal === OpenModalEnum.DEPARTURE}
          tripDetails={tripDetails}
          fareDetails={fareDetails}
          onClose={onModalClose}
          isMixedCabinClass={
            openModal === OpenModalEnum.DEPARTURE
              ? isOutgoingMixedClass
              : isReturnMixedClass
          }
          buttonText={t("continue")}
          airports={airports}
        />
      )}
    </>
  );
};
