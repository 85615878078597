import { airBookingApi } from "@b2bportal/air-booking-api";

import {
  AcceptScheduleChangeRequest,
  AcceptScheduleChangeResponse,
} from "@hopper-b2b/types";
import { axiosInstance } from "@hopper-b2b/api";

export const acceptScheduleChange = async (
  req: AcceptScheduleChangeRequest
): Promise<AcceptScheduleChangeResponse> => {
  const res = await airBookingApi(
    axiosInstance
  ).apiV0ItineraryFlightAcceptScheduleChangePost(req);

  if (!res.data.success) {
    throw res.data;
  }

  return res.data;
};
