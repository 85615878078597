import { disruptionRootReducer } from "@b2bportal/core-disruption";
import { configureStore } from "@reduxjs/toolkit";

const coreStore = configureStore({
  reducer: {
    disruption: disruptionRootReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
export default coreStore;
