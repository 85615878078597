import { useI18nContext } from "@hopper-b2b/i18n";
import { useDispatch } from "react-redux";
import { DisruptionProtectionDisruptionProtectionPolicyDetails } from "@b2bportal/air-booking-api";
import { useState, useCallback } from "react";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseNotEligiblePage.module.scss";
import { useDeviceType } from "@b2bportal/core-utilities";
import {
  DisruptionExerciseProgress,
  resetDisruptionExerciseState,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import { DisruptionExerciseLearnMoreContent } from "../../components/DisruptionExerciseLearnMoreContent";
import { DisruptionExerciseFooter } from "../../components/DisruptionExerciseFooter";
import { DisruptionExerciseLearnMoreType } from "../../components/DisruptionExerciseLearnMoreContent/DisruptionExerciseLearnMoreContent";
import { DisruptionExercisePolicyDetails } from "../../components/DisruptionExercisePolicyDetails";
import { DisruptionExercisePage } from "../../components/DisruptionExercisePage";
import { DisruptionExercisePageContent } from "../../components/DisruptionExercisePageContent";
import { DisruptionExerciseSummaryCard } from "../../components/DisruptionExerciseSummaryCard";
import { DisruptionExerciseContainer } from "../../components/DisruptionExerciseContainer";
import { DisruptionExercisePageWidth } from "../../components/DisruptionExercisePage/DisruptionExercisePage";
import { useRebookingLimit } from "../../utils/currencyUtils";

export interface DisruptionExerciseNotEligiblePageProps {
  disruptionProductName: string;
  disruptionProductLogo: string;
  policyDetails: DisruptionProtectionDisruptionProtectionPolicyDetails;
  onContactSupport: () => void;
  termsLink: string;
  displayExternalLinkAsModal: boolean;
}

export const DisruptionExerciseNotEligiblePage = ({
  disruptionProductName,
  disruptionProductLogo,
  policyDetails,
  onContactSupport,
  termsLink,
  displayExternalLinkAsModal,
}: DisruptionExerciseNotEligiblePageProps) => {
  const { t } = useI18nContext();
  const { isDesktopAndUp } = useDeviceType();
  const dispatch = useDispatch();
  const [policyDetailsExpanded, setPolicyDetailsExpanded] = useState(true);
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseNotEligiblePage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const policyDetailsThreshold =
    policyDetails.delayThreshold !== undefined
      ? policyDetails.delayThreshold
      : 180;
  const delayThresholdHours = (policyDetailsThreshold / 60).toString();
  const onExpand = useCallback(() => {
    setPolicyDetailsExpanded(!policyDetailsExpanded);
  }, [setPolicyDetailsExpanded, policyDetailsExpanded]);
  const { rebookingLimit } = useRebookingLimit();
  const hoursString =
    delayThresholdHours === "1"
      ? t("core-disruption.disruptionUniversalExercise.notEligiblePage.hour")
      : t("core-disruption.disruptionUniversalExercise.notEligiblePage.hours");

  const handleGoBack = () => {
    //dispatch(resetDisruptionExerciseState());
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.NotStarted)
    );
  };

  const faqRows = (
    <>
      <span className={cn("faqTitle")}>
        {t(
          "core-disruption.disruptionUniversalExercise.notEligiblePage.faqSection.title"
        )}
      </span>
      <DisruptionExerciseLearnMoreContent
        learnMoreType={DisruptionExerciseLearnMoreType.FAQ}
        contactSupportAction={onContactSupport}
        delayHours={delayThresholdHours}
        numberOfHours={hoursString}
      ></DisruptionExerciseLearnMoreContent>

      {isDesktopAndUp && (
        <DisruptionExerciseFooter
          onSupportClick={onContactSupport}
        ></DisruptionExerciseFooter>
      )}
    </>
  );

  const policyDetailsContent = (
    <DisruptionExercisePolicyDetails
      policyDetails={policyDetails}
      expanded={policyDetailsExpanded}
      onExpand={onExpand}
      termsLink={termsLink}
      displayExternalLinkAsModal={displayExternalLinkAsModal}
    ></DisruptionExercisePolicyDetails>
  );

  return (
    <DisruptionExercisePage
      className={cn("DisruptionExerciseNotEligiblePage")}
      pageWidth={DisruptionExercisePageWidth.Large}
      title={disruptionProductName}
      disruptionProductLogo={disruptionProductLogo}
      onBack={handleGoBack}
      onSupportClick={onContactSupport}
      submitButtonHidden={true}
      termsLink={termsLink}
      content={
        <DisruptionExercisePageContent
          leftContent={[
            <DisruptionExerciseSummaryCard
              title={t(
                "core-disruption.disruptionUniversalExercise.notEligiblePage.title"
              )}
              description={t(
                "core-disruption.disruptionUniversalExercise.notEligiblePage.subtitleWithRebookingLimit",
                {
                  delayHours: delayThresholdHours,
                  numberOfHours: hoursString,
                  rebookLimit: rebookingLimit(policyDetails),
                }
              )}
              key={"exercise-page-left-content"}
            ></DisruptionExerciseSummaryCard>,
            <DisruptionExerciseContainer content={faqRows} />,
          ]}
          rightConent={[
            policyDetailsContent,
            !isDesktopAndUp ? (
              <DisruptionExerciseFooter
                onSupportClick={onContactSupport}
                termsLink={termsLink}
                displayLinkInModal={displayExternalLinkAsModal}
              ></DisruptionExerciseFooter>
            ) : (
              <></>
            ),
          ]}
        />
      }
      delayHours={delayThresholdHours}
      numberOfHours={hoursString}
    ></DisruptionExercisePage>
  );
};

export default DisruptionExerciseNotEligiblePage;
