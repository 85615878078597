import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  airCfarApi,
  AirCfarExercisePollFulfillResponse,
  AirCfarExerciseScheduleFulfillRequest,
  AirCfarExerciseScheduleFulfillResponse,
  AirCfarExerciseScheduleFulfillResponseEnum,
} from "@b2bportal/air-cfar-api";
import { pollRemote, PollStatus } from "./pollRemote";

export const fulfillQuote = createAsyncThunk<
  AirCfarExerciseScheduleFulfillResponse | AirCfarExercisePollFulfillResponse,
  AirCfarExerciseScheduleFulfillRequest
>(
  "cfar/exercise/fulfillQuote",
  async (request: AirCfarExerciseScheduleFulfillRequest, thunkAPI) => {
    const cfarApi = airCfarApi(axios);

    try {
      const fulfillScheduleResponse =
        await cfarApi.apiV1CfarAirExerciseFulfillSchedulePost(request);

      switch (
        fulfillScheduleResponse.data.AirCfarExerciseScheduleFulfillResponse
      ) {
        case AirCfarExerciseScheduleFulfillResponseEnum.ScheduleFulfillFailure:
          return thunkAPI.rejectWithValue(fulfillScheduleResponse.data);
        case AirCfarExerciseScheduleFulfillResponseEnum.ScheduleFulfillSuccess: {
          const sessionId = fulfillScheduleResponse.data.sessionId;
          return pollRemote<AirCfarExercisePollFulfillResponse>({
            poll: () =>
              cfarApi
                .apiV1CfarAirExerciseFulfillPollPost({
                  sessionId,
                })
                .then((res) => res.data),
            getPollStatus: (data) => {
              switch (data.AirCfarExercisePollFulfillResponse) {
                case "PollFulfillPending":
                  return PollStatus.PENDING;
                case "PollFulfillSuccess":
                  return PollStatus.SUCCESS;
                default:
                  return PollStatus.FAILURE;
              }
            },
          });
        }
        default:
          return thunkAPI.rejectWithValue(fulfillScheduleResponse.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
