import { getObjectKeysAsObject } from "@checkout/helpers";
import type { HotelsPriceFreezeExercisePartialMachineContext } from "./types";

export const guards = {
  hasPriceFreezeId: (ctx: HotelsPriceFreezeExercisePartialMachineContext) => {
    return !!ctx.priceFreeze.priceFreezeId;
  },
  frozenProductToExerciseNotAvailable: (
    ctx: HotelsPriceFreezeExercisePartialMachineContext
  ) => {
    return ctx.priceFreeze.frozenProductToExerciseAvailable === "NotAvailable";
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);
