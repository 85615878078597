import { compose, applyMiddleware } from "@reduxjs/toolkit";
import { createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { thunk } from "redux-thunk";
import { cfarMiddlewares } from "@b2bportal/core-flights-cfar";
import { rootSaga } from "../sagas";
import { rootReducer } from "../reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}
const composeEnhancers =
  (process.env["NODE_ENV"] !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

//REDUX SAGA middleware
export const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware, ...cfarMiddlewares))
);

sagaMiddleware.run(rootSaga);

export type TripsState = ReturnType<typeof store.getState>;
