import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import { Slot } from "../Slots";
import "./styles.scss";
import { ReactNode } from "react";

export type ErrorBannerProps = {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  className?: string | ReactNode;
  iconSrc?: IconName;
  primaryOnClick?: () => void;
};

export const ErrorBanner = (props: ErrorBannerProps) => (
  <Slot
    id="error-banner"
    {...props}
    component={<ErrorBannerInternal {...props} />}
  />
);

const ErrorBannerInternal = ({
  title,
  subtitle,
  className,
  iconSrc,
  primaryOnClick,
}: ErrorBannerProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  return (
    <div
      className={clsx(className, "error-banner error-banner-new", {
        mobile: matchesMobile,
      })}
    >
      <div
        className={clsx("error-banner-container", {
          autoHeight: matchesMobile,
        })}
      >
        <IconComponent
          className="error-banner-icon"
          name={iconSrc || IconName.InfoWhiteCircle}
        />
      </div>
      <div className="error-banner-text-container">
        <div className="error-banner-text">
          <div className="error-banner-title">{title}</div>
          {subtitle ? (
            <div className="error-banner-subtitle">{subtitle}</div>
          ) : null}
        </div>
        {primaryOnClick ? (
          <button
            className="error-banner-close-button"
            onClick={primaryOnClick}
          >
            <IconComponent
              ariaLabel={t("close.imageAlt")}
              className={clsx("error-banner-close-icon")}
              name={IconName.Close}
            />
          </button>
        ) : null}
      </div>
    </div>
  );
};
