import { createSelector } from "@reduxjs/toolkit";
import { DisruptionState } from "../../../store/slice";
import {
  getNumberOfPassnegers,
  selectedTripSelector,
} from "@b2bportal/core-flights";
import { DisruptionOffer } from "@b2bportal/air-disruption-api";

export const getDisruptionOffersRequestParams = createSelector(
  (state) => selectedTripSelector(state),
  (state) => getNumberOfPassnegers(state),
  (selectedTrip, numberOfPassengers) => {
    const tripId = selectedTrip.tripId;
    const fareId = selectedTrip.returnFareId
      ? selectedTrip.returnFareId
      : selectedTrip.outgoingFareId;

    if (tripId && fareId) {
      return {
        tripId,
        fareId,
        numberOfPassengers,
      };
    } else {
      return null;
    }
  }
);

export const getFetchDisruptionOffersCallState = (state: DisruptionState) =>
  state.disruption.disruptionOffers.fetchDisruptionOffersCallState;

export const getFetchedDisruptionOffers = (state: DisruptionState) =>
  state.disruption.disruptionOffers.disruptionOffers;

export const getDisruptionOfferToShow = (
  state: DisruptionState
): DisruptionOffer | undefined =>
  state.disruption.disruptionOffers.disruptionOffers?.[0];

export const getFetchedDisruptionOfferQuotes = createSelector(
  getFetchedDisruptionOffers,
  (offers) => {
    return offers.flatMap((offer) => offer.quotes);
  }
);

export const getSelectedDisruptionOffer = (state: DisruptionState) => {
  return state.disruption.disruptionOffers.selectedDisruptionOffer;
};
