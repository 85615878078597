import { TripDetails, DullesAmenitiyType } from "@hopper-b2b/types";
import { ITripDetailsByTripId } from "../reducer";
import { TFunction } from "i18next";
import { ensureExhaustive } from "@b2bportal/core-utilities";

export const getSliceFareDetails = (
  tripDetailsById: ITripDetailsByTripId,
  fareTrips: any
): TripDetails | null => {
  if (!fareTrips.length) return null;
  let sliceDetails: TripDetails | null = null;
  sliceDetails = { ...tripDetailsById[fareTrips[0].trip] } as TripDetails;
  sliceDetails.fareDetails = [];
  fareTrips.forEach((fareTrip: any) => {
    const fareDetailByFareId = tripDetailsById[fareTrip.trip].fareDetails.find(
      (fareDetail) => fareDetail.id === fareTrip.fare
    );
    if (fareDetailByFareId && sliceDetails)
      sliceDetails.fareDetails.push(fareDetailByFareId);
  });
  return sliceDetails as TripDetails;
};

export const localizedAmenityLabel = (
  key: DullesAmenitiyType,
  t: TFunction
): string => {
  // Explicitly use static keys instead of t(`amenitiesCategories.${key}`)
  // to help localization tools extract these keys for translation.
  switch (key) {
    case "seat":
      return t("amenitiesCategories.seat");
    case "aircraft":
      return t("amenitiesCategories.aircraft");
    case "entertainment":
      return t("amenitiesCategories.entertainment");
    case "beverage":
      return t("amenitiesCategories.beverage");
    case "wifi":
      return t("amenitiesCategories.wifi");
    case "layout":
      return t("amenitiesCategories.layout");
    case "power":
      return t("amenitiesCategories.power");
    case "freshFood":
      return t("amenitiesCategories.freshFood");
    default:
      ensureExhaustive(key);
  }
};

export const localizedUtaCategoryLabel = (
  category: string,
  t: TFunction
): string => {
  // Explicitly use static keys instead of t(`amenityUtaCategories.${category}`)
  // to help localization tools extract these keys for translation.
  switch (category) {
    case "advance-change":
      return t("amenityUtaCategories.advance-change");
    case "boarding-priority":
      return t("amenityUtaCategories.boarding-priority");
    case "cancellation":
      return t("amenityUtaCategories.cancellation");
    case "carry-on-allowance":
      return t("amenityUtaCategories.carry-on-allowance");
    case "check-in-priority":
      return t("amenityUtaCategories.check-in-priority");
    case "checked-bag-allowance":
      return t("amenityUtaCategories.checked-bag-allowance");
    case "lounge-access":
      return t("amenityUtaCategories.lounge-access");
    case "same-day-change":
      return t("amenityUtaCategories.same-day-change");
    case "seat-selection":
      return t("amenityUtaCategories.seat-selection");
    case "upgrade-eligibility":
      return t("amenityUtaCategories.upgrade-eligibility");
    case "personal-item":
      return t("amenityUtaCategories.personal-item");
    default:
      console.warn(
        `The UTA category ${category} is not being localized correctly`
      );
      // Default to using a dynamic key as a fallback. This means the category may not be properly localized though.
      return t(`amenityUtaCategories.${category}`);
  }
};
