import clsx from "clsx";
import { ActionLink, Divider, MobilePopoverCard } from "@hopper-b2b/ui";
import { ReactComponent as Close } from "../../assets/client/close.svg";
import styles from "./styles.module.scss";

interface FintechHalfSheetProps {
  isOpen: boolean;
  onClose: () => void;
  icon?: string;
  title: string;
  description: string | JSX.Element;
  additionalInfo?: string | JSX.Element;
  className?: string;
}

const FintechHalfSheet = ({
  isOpen,
  onClose,
  icon,
  title,
  description,
  additionalInfo,
  className,
}: FintechHalfSheetProps) => {
  return (
    <MobilePopoverCard
      open={isOpen}
      className={clsx(styles.FintechHalfSheet, className, "FintechHalfSheet")}
      contentClassName="fintech-half-sheet-modal-content"
      onClose={onClose}
      centered={true}
      topLeftButton={
        <ActionLink
          onClick={onClose}
          content={<Close className="close-icon" />}
        />
      }
    >
      <>
        {icon ? <img src={icon} alt="" /> : null}
        <h2 className="title">{title}</h2>
        <p className="description">{description}</p>
        {additionalInfo ? (
          <>
            <Divider />
            {additionalInfo}
          </>
        ) : null}
      </>
    </MobilePopoverCard>
  );
};

export default FintechHalfSheet;
