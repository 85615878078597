import { TripSegment } from "@b2bportal/air-shopping-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import { AirlineIcon } from "@b2bportal/core-ui";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import { removeTimezone } from "@hopper-b2b/utilities";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import defaultStyles from "./FlightDetails.module.scss";

interface ConnectingFlightInfo {
  plusDays: number;
  formattedDepartureDate: string;
  nextSegment: TripSegment;
}

export interface FlightDetailsSegmentComponentProps
  extends PlatformComponentProps {
  segment: TripSegment;
  nextSegment?: TripSegment;
  cabinClass?: string;
  planeInfo?: string;
}

export const FlightDetailsSegment = ({
  segment,
  nextSegment,
  cabinClass,
  planeInfo,
  className,
  children,
}: FlightDetailsSegmentComponentProps) => {
  const { formatDateTime, DateTimeFormatStyle, formatInterval } =
    useI18nContext();

  const COMPONENT_KEY = CoreFlightsComponent.FlightDetailsSegment;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);

  const { stopoverDurationMinutes, plusDays } = segment;
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY, {
    "has-layover": (stopoverDurationMinutes ?? 0) > 0,
  });

  const { t } = useI18nContext();

  const hasDifferentOperatingAirline =
    segment.operatingAirline?.code !== segment.marketingAirline?.code;

  // note: we need to use startOf('day') to compare the the difference in day because dayjs doesn't give a diff of 1+ day unless it has been 24+ hours past the datetime being compared
  const connectingFlightInfo: ConnectingFlightInfo | undefined = React.useMemo(
    () =>
      nextSegment != null
        ? {
            nextSegment,
            formattedDepartureDate: formatDateTime(
              dayjs(removeTimezone(nextSegment.departureTime)).toDate(),
              DateTimeFormatStyle.MediumDate
            ),
            plusDays: dayjs(removeTimezone(nextSegment.departureTime)) // note: this comparison should work if the departureTime and arrivalTime are the same timezone, which should be as user should be taking their connecting flight where they flew to
              .startOf("day")
              .diff(
                dayjs(removeTimezone(segment.arrivalTime)).startOf("day"),
                "day"
              ),
          }
        : undefined,
    [formatDateTime, DateTimeFormatStyle.MediumDate, nextSegment, segment]
  );

  const formattedArrivalDate = React.useMemo(
    () =>
      formatDateTime(
        dayjs(removeTimezone(segment.arrivalTime)).toDate(),
        DateTimeFormatStyle.MediumDate
      ),
    [formatDateTime, DateTimeFormatStyle.MediumDate, segment]
  );

  return (
    <div
      className={clsx(block, className)}
      key={`trip-segment-${segment.airlineCode}-${segment.flightNumber}`}
    >
      <div className={cn("icon")}>
        <div className={cn("circle")}></div>
        <div className={cn("line")}></div>
        <div className={cn("circle")}></div>
      </div>
      <div className={cn("airport-details")}>
        <Trans
          i18nKey={"flightAirportDetails"}
          values={{
            time: formatDateTime(
              dayjs(removeTimezone(segment.departureTime)).toDate(),
              DateTimeFormatStyle.ShortTime
            ),
            airportName: segment.originName,
            airportCode: segment.originCode,
          }}
          components={{ 1: <span className={cn("time")} /> }}
        />
      </div>
      <div className={cn("travel-time")}>
        {`${t("travelTime")} ${formatInterval(
          dayjs(segment.zonedArrivalTime).diff(
            dayjs(segment.zonedDepartureTime),
            "minutes"
          )
        )}`}
      </div>
      <div className={cn("airport-details")}>
        <Trans
          i18nKey={"flightAirportDetails"}
          values={{
            time: formatDateTime(
              dayjs(removeTimezone(segment.arrivalTime)).toDate(),
              DateTimeFormatStyle.ShortTime
            ),
            airportName: segment.destinationName,
            airportCode: segment.destinationCode,
          }}
          components={{ 1: <span className={cn("time")} /> }}
        />
      </div>
      <div className={cn("flight-info-root")}>
        <div className={cn("flight-info-details")}>
          <AirlineIcon
            className={cn("flight-info-icon")}
            airlineCode={segment.airlineCode}
            altText={segment.airlineName}
          />
          {segment.airlineName} / {segment.airlineCode} {segment.flightNumber}
          {cabinClass ? ` / ${cabinClass}` : null}
          {planeInfo ? ` / ${planeInfo}` : null}
        </div>
        {hasDifferentOperatingAirline ? (
          <div className={cn("operating-airline-info")}>
            {t("operatedBy", {
              airline: segment.operatingAirline.name,
            })}
          </div>
        ) : null}
        {plusDays !== 0 ? (
          <div className={cn("plus-days")}>
            {plusDays > 1 ? (
              // Flight arrives multiple days in the future
              <Trans
                i18nKey={"flightArrivalDifferentDay.flightArrivesPlusDays"}
                values={{
                  plusDays,
                  arrivalDate: formattedArrivalDate,
                }}
                components={{ 1: <span className={cn("notification-date")} /> }}
              />
            ) : plusDays > 0 ? (
              // Flight arrives multiple days after the departure date
              <Trans
                i18nKey={"flightArrivalDifferentDay.flightArrivesNextDay"}
                values={{
                  arrivalDate: formattedArrivalDate,
                }}
                components={{ 1: <span className={cn("notification-date")} /> }}
              />
            ) : (
              // The flight arrives at a previous date, message should only show the arrival date
              <Trans
                i18nKey={"flightArrivalDifferentDay.flightArrivesOnDate"}
                values={{
                  arrivalDate: formattedArrivalDate,
                }}
                components={{ 1: <span className={cn("notification-date")} /> }}
              />
            )}
          </div>
        ) : null}
      </div>
      {stopoverDurationMinutes != null && stopoverDurationMinutes > 0 ? (
        <div className={cn("layover")}>
          {t("layoverWithLocation", {
            duration: formatInterval(stopoverDurationMinutes),
            location: segment.destinationCode,
          })}
        </div>
      ) : null}
      {connectingFlightInfo != null &&
      ((plusDays !== 0 && connectingFlightInfo.plusDays === 0) || // display connecting flight departure message if the current flight is arriving next day and the next connecting flight departs the day the current flight arrives
        connectingFlightInfo.plusDays !== 0) ? ( // or if the connecting flight is +1 or more days from the current flight's arrival
        <div className={cn("connecting-flight")}>
          {connectingFlightInfo.plusDays > 1 ? (
            // Flight departs multiple days in the future
            <Trans
              i18nKey={"connectingFlightDifferentDay.flightDepartsPlusDays"}
              values={{
                plusDays: connectingFlightInfo.plusDays,
                departureDate: connectingFlightInfo.formattedDepartureDate,
              }}
              components={{ 1: <span className={cn("notification-date")} /> }}
            />
          ) : connectingFlightInfo.plusDays > 0 ? (
            // Flight departs multiple days after the departure date
            <Trans
              i18nKey={"connectingFlightDifferentDay.flightDepartsNextDay"}
              values={{
                departureDate: connectingFlightInfo.formattedDepartureDate,
              }}
              components={{ 1: <span className={cn("notification-date")} /> }}
            />
          ) : (
            // The flight departs on current or at a previous date, message should only show the arrival date
            <Trans
              i18nKey={"connectingFlightDifferentDay.flightDepartsOnDate"}
              values={{
                departureDate: connectingFlightInfo.formattedDepartureDate,
              }}
              components={{ 1: <span className={cn("notification-date")} /> }}
            />
          )}
        </div>
      ) : undefined}
      {children}
    </div>
  );
};
