import {
  ActionLink,
  Divider,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import styles from "./styles.module.scss";
import { I18nSpan, useI18nContext } from "@hopper-b2b/i18n";
import { ReactComponent as ShieldWithCheckmark } from "../../assets/client/shield-with-checkmark.svg";
import { ReactComponent as Calendar } from "../../assets/client/calendar-unfilled.svg";
import { ReactComponent as ShieldWithPhone } from "../../assets/client/shield-with-phone.svg";
import { ReactComponent as CloseIcon } from "../../assets/client/close.svg";
import { ActionRowItem } from "../ActionRowItem";
import { useCallback, useState } from "react";
import { openNubankHelp } from "../../utils/nubankHandoff";
import { TravelInsuranceContactSupportModalContent } from "../TravelInsuranceContactSupportModalContent";

const TravelInsurancePopover = ({
  open,
  onClose,
  isRoundTrip,
  expiryDate,
}: {
  open: boolean;
  onClose: () => void;
  isRoundTrip: boolean;
  expiryDate: string;
}) => {
  const { t } = useI18nContext();

  const [contactSupportOpen, setContactSupportOpen] = useState(false);

  const goToFaqHandler = useCallback(() => {
    openNubankHelp();
  }, []);

  const openContactSupportHandler = useCallback(() => {
    setContactSupportOpen(true);
  }, []);

  const closeContactSupportHandler = useCallback(() => {
    setContactSupportOpen(false);
  }, []);

  const listItems = [
    {
      icon: <ShieldWithCheckmark className="icon" />,
      title: t("ultravioletaTravelInsurance.issuePolicyTitle"),
      description: [t("ultravioletaTravelInsurance.issuePolicyDescription")],
    },
    {
      icon: <Calendar className="icon" />,
      title: t("ultravioletaTravelInsurance.validityTitle"),
      description: [
        <I18nSpan
          tKey={"ultravioletaTravelInsurance.validityDescriptionOne"}
          replacements={{ date: expiryDate }}
        />,
        isRoundTrip
          ? t("ultravioletaTravelInsurance.validityDescriptionTwoRoundTrip")
          : t("ultravioletaTravelInsurance.validityDescriptionTwoOneWay"),
      ],
    },
    {
      icon: <ShieldWithPhone className="icon" />,
      title: t("ultravioletaTravelInsurance.activateTitle"),
      description: [
        t("ultravioletaTravelInsurance.activateDescriptionOne"),
        t("ultravioletaTravelInsurance.activateDescriptionTwo"),
      ],
    },
  ];

  return (
    <>
      <MobilePopoverCard
        open={open}
        onClose={onClose}
        fullScreen={true}
        className={styles.TravelInsurancePopover}
        contentClassName={styles.TravelInsurancePopoverContent}
      >
        <div className="top-content">
          <CloseIcon className="close-icon" onClick={onClose} />
          <h1>{t("ultravioletaTravelInsurance.title")}</h1>
          <p>{t("ultravioletaTravelInsurance.myTripsRightToInsuranceEmail")}</p>
        </div>
        <div className="list-items">
          {listItems.map((listItem) => {
            return (
              <div className="list-item">
                {listItem.icon}
                <div className="content">
                  <h3>{listItem.title}</h3>
                  {listItem.description.map((descriptionLine) => (
                    <p className="description">{descriptionLine}</p>
                  ))}
                </div>
              </div>
            );
          })}
          <ActionRowItem
            text={t("ultravioletaTravelInsurance.serviceChannels")}
            onClick={openContactSupportHandler}
          />
          <Divider className={styles.Divider} />
          <h4 className="faq-header">
            {t("ultravioletaTravelInsurance.frequentlyAskedQuestions")}
          </h4>
          <ActionRowItem
            text={t("ultravioletaTravelInsurance.accessFAQ")}
            onClick={goToFaqHandler}
          />
        </div>
      </MobilePopoverCard>

      {/* Customer support modal */}
      <MobilePopoverCard
        open={contactSupportOpen}
        className="contact-support-modal"
        scroll="paper"
        fullWidth
        fullScreen
        topRightButton={
          <ActionLink
            className="introduction-modal-close-button"
            content={
              <IconComponent
                ariaLabel={t("modalClose.ariaLabel")}
                className={"close-button-icon"}
                name={IconName.Close}
              />
            }
            label={t("modalClose.label")}
            onClick={closeContactSupportHandler}
          />
        }
        onClose={closeContactSupportHandler}
      >
        <TravelInsuranceContactSupportModalContent />
      </MobilePopoverCard>
    </>
  );
};

export default TravelInsurancePopover;
