import {
  AirCfarExerciseFailure,
  AirCfarExerciseFailureEnum,
  AirCfarExercisePollFulfillRequest,
  AirCfarExercisePollFulfillResponse,
  AirCfarExercisePollFulfillResponseEnum,
  AirCfarExerciseScheduleFulfillRequest,
  AirCfarExerciseScheduleFulfillResponse,
  AirCfarExerciseScheduleFulfillResponseEnum,
  ScheduleFulfillFailure,
  ScheduleFulfillSuccess,
  TrackingProperties,
  airCfarApi,
} from "@b2bportal/air-cfar-api";
import axios, { AxiosResponse } from "axios";
import { call, delay, put, select } from "redux-saga/effects";
import { actions } from "../../../actions";

import { trackEvent } from "@hopper-b2b/api";
import {
  AirOrchestratorCfarTrackingEvents,
  CfarExerciseProgress,
  CfarTrackingEvents,
} from "@hopper-b2b/types";
import {
  ITrackingProperties,
  ITrackingPropertiesRequest,
  combineTrackingProperties,
} from "@hopper-b2b/utilities";
import {
  getCfarExerciseTrackingProperties,
  getCfarExerciseQuoteTrackingProperties,
  getCfarExerciseTreackingProperties,
} from "../../../reducer";
import { CfarOffersSelectors } from "@b2bportal/core-flights-cfar";

const DELAY_TIMES = [2000, 4000, 6000, 8000, 10000, 20000, 30000, 30000, 30000];

export function* confirmFlightCfarCancellationV1Saga(
  _action: actions.IConfirmFlightCfarCancellation
) {
  try {
    const quoteToken = _action.quoteToken;
    const request: AirCfarExerciseScheduleFulfillRequest = {
      quoteId: quoteToken.value,
    };

    const {
      data: response,
    }: AxiosResponse<AirCfarExerciseScheduleFulfillResponse> = yield airCfarApi(
      axios
    ).apiV1CfarAirExerciseFulfillSchedulePost(request);

    const exerciseSuccessTrackingProps = yield select(
      getCfarExerciseTreackingProperties
    );

    if (
      (response as ScheduleFulfillFailure)
        ?.AirCfarExerciseScheduleFulfillResponse ===
      AirCfarExerciseScheduleFulfillResponseEnum.ScheduleFulfillFailure
    ) {
      yield trackEvent({
        eventName:
          AirOrchestratorCfarTrackingEvents.CFAR_EXERCISE_COMPLETE_FAIL,
        properties: {
          cfar_exercise_fail_reason: (response as ScheduleFulfillFailure)
            .failure,
          ...exerciseSuccessTrackingProps.properties,
        },
        ...exerciseSuccessTrackingProps,
      });
      yield put(actions.setConfirmFlightCfarCancellationCallStateFailed());
      yield put(actions.setConfirmFlightCfarCancellationError());
      return;
    }

    const fulfillmentSessionId = (response as ScheduleFulfillSuccess).sessionId;

    yield put(
      actions.setConfirmFlightCfarCancellationV1Response({
        response,
      })
    );

    yield call(pollFulfillFlightCfarCancellationV1, {
      sessionId: fulfillmentSessionId,
    });
  } catch (e) {
    yield trackEvent({
      eventName: AirOrchestratorCfarTrackingEvents.CFAR_EXERCISE_COMPLETE_FAIL,
      properties: {
        cfar_exercise_fail_reason: AirCfarExerciseFailureEnum.Error,
      },
    });
    yield put(actions.setConfirmFlightCfarCancellationCallStateFailed());
    yield put(actions.setConfirmFlightCfarCancellationError());
  }
}

export function* pollFulfillFlightCfarCancellationV1(
  request: AirCfarExercisePollFulfillRequest
) {
  try {
    let pollFailed = false;
    let index = 0;

    const trackingProps = yield select(
      getCfarExerciseTrackingProperties
    ) as ITrackingProperties;
    const cfarTrackingProps = combineTrackingProperties([trackingProps]);
    const cfarExerciseQuoteTrackingProperties: ITrackingPropertiesRequest =
      yield select(getCfarExerciseQuoteTrackingProperties);

    while (!pollFailed) {
      yield delay(DELAY_TIMES[index]);
      const {
        data: pollResponse,
      }: AxiosResponse<AirCfarExercisePollFulfillResponse> = yield airCfarApi(
        axios
      ).apiV1CfarAirExerciseFulfillPollPost(request);

      switch (pollResponse.AirCfarExercisePollFulfillResponse) {
        case AirCfarExercisePollFulfillResponseEnum.PollFulfillFailure:
          pollFailed = true;
          yield put(
            actions.setPollFulfillFlightCfarCancellationV1Response({
              response: pollResponse,
            })
          );
          yield trackEvent({
            eventName: CfarTrackingEvents.VIEWED_CFAR_EXERCISE_ERROR,
            properties: {},
          });
          yield put(actions.setPollFulfillFlightCfarCancellationV1Error());
          return;
        case AirCfarExercisePollFulfillResponseEnum.PollFulfillPending:
          pollFailed = false;
          yield put(
            actions.setPollFulfillFlightCfarCancellationV1Response({
              response: pollResponse,
            })
          );
          break;
        case AirCfarExercisePollFulfillResponseEnum.PollFulfillSuccess:
          yield put(
            actions.setPollFulfillFlightCfarCancellationV1Response({
              response: pollResponse,
            })
          );
          yield trackEvent({
            eventName: CfarTrackingEvents.CFAR_POLICY_EXERCISED,
            ...cfarTrackingProps,
          });
          yield put(
            actions.setCfarExerciseProgress(CfarExerciseProgress.Completed)
          );

          yield trackEvent({
            eventName: AirOrchestratorCfarTrackingEvents.CFAR_EXERCISE_COMPLETE,
            properties: {
              ...cfarExerciseQuoteTrackingProperties.properties,
            },
            encryptedProperties: [
              ...cfarExerciseQuoteTrackingProperties.encryptedProperties,
            ],
          });
          return;
        default:
          pollFailed = true;
          throw new Error("Cancellation fulfillment failed");
      }
      if (index !== DELAY_TIMES.length - 1) {
        index++;
      }
    }
  } catch (e) {
    yield trackEvent({
      eventName: AirOrchestratorCfarTrackingEvents.CFAR_EXERCISE_COMPLETE_FAIL,
      properties: {
        cfar_exercise_fail_reason: AirCfarExerciseFailureEnum.Error,
      },
    });
    yield put(
      actions.setPollFulfillFlightCfarCancellationV1Response({
        response: {
          failure: {
            reason: "error",
            AirCfarExerciseFailure: "Error",
          } as AirCfarExerciseFailure,
          AirCfarExercisePollFulfillResponse: "PollFulfillFailure",
        } as AirCfarExercisePollFulfillResponse,
      })
    );
    yield put(actions.setPollFulfillFlightCfarCancellationV1Error());
  }
}
