import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import DisruptionExerciseFooter from "../DisruptionExerciseFooter/DisruptionExerciseFooter";
import DisruptionExerciseContainer from "../DisruptionExerciseContainer/component";
import { DisruptionExerciseBulletPoint } from "@b2bportal/core-disruption";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseInformationCard.module.scss";
import { useDeviceType } from "@b2bportal/core-utilities";
import { ExternalLinkContainer } from "../../../SharedComponents/ExternalLinkContainer";

export interface DisruptionExerciseInformationCardProps {
  onSupportClick: () => void;
  onRefundClick?: () => void;
  bulletPoints: {
    title?: string;
    text: string;
    learnMore?: { title: string; onClick: () => void };
    index: number;
  }[];
  inContainer?: boolean;
  termsLink?: string;
  displayExternalLinkAsModal?: boolean;
}

export const DisruptionExerciseInformationCard = ({
  onSupportClick,
  onRefundClick,
  bulletPoints,
  inContainer = true,
  termsLink,
  displayExternalLinkAsModal = true,
}: DisruptionExerciseInformationCardProps) => {
  const { t } = useI18nContext();
  const { isDesktopAndUp } = useDeviceType();
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseInformationCard;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const bulletRows = (
    <div className={cn("bulletsContainer")}>
      {bulletPoints.map((bulletPoint, index) => (
        <DisruptionExerciseBulletPoint
          title={bulletPoint.title}
          text={bulletPoint.text}
          index={index + 1}
          learnMore={bulletPoint.learnMore}
          key={index}
        />
      ))}
    </div>
  );

  const content = (
    <div className={cn("disruptionInformationRoot")}>
      <span
        className={cn("title")}
        dangerouslySetInnerHTML={{
          __html: t(
            "core-disruption.disruptionUniversalExercise.refundReviewPage.importantInformation"
          ),
        }}
      />
      {bulletRows}
      {termsLink != null && (
        <div className={cn("bulletsFooterContainer")}>
          <ExternalLinkContainer
            linkUrl={termsLink}
            displayInModal={displayExternalLinkAsModal}
            content={
              <Trans
                i18nKey={
                  "core-disruption.disruptionUniversalExercise.refundReviewPage.forMoreDetailsAbout"
                }
                components={[
                  <span className={cn("moreDetails")}></span>,
                  <a />,
                ]}
              />
            }
          />
        </div>
      )}
      <DisruptionExerciseFooter
        onSupportClick={onSupportClick}
        onRefundClick={onRefundClick}
        termsLink={termsLink}
      ></DisruptionExerciseFooter>
    </div>
  );

  return inContainer ? (
    <DisruptionExerciseContainer
      content={
        <div
          className={cn("container", {
            mobile: !isDesktopAndUp,
          })}
        >
          {content}
        </div>
      }
    ></DisruptionExerciseContainer>
  ) : (
    content
  );
};

export default DisruptionExerciseInformationCard;
