import { useDeviceType } from "@b2bportal/core-utilities";
import styles from "./DisruptionInfoBanner.module.scss";
import clsx from "clsx";
import { Icon } from "@b2bportal/core-ui";
import { IconNameEnum } from "@b2bportal/core-themes";

export const DisruptionInfoBanner = ({
  icon,
  text,
  className,
}: {
  icon: string;
  text: string | JSX.Element;
  className?: string;
}) => {
  const { isDesktopAndUp } = useDeviceType();
  return (
    <div className={clsx(className, styles.infoBanner)}>
      <div
        className={clsx(styles.iconContainer, {
          [styles.autoHeight]: !isDesktopAndUp,
        })}
      >
        {icon ? (
          <img alt="info-icon" src={icon} className={styles.icon} />
        ) : (
          <Icon className={styles.icon} iconName={IconNameEnum.checkCircle} />
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

export default DisruptionInfoBanner;
