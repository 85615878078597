import {
  AirCfarExerciseRefundPaymentTypeOpaqueValue,
  Prices,
  AirCfarExerciseRefundPaymentType,
} from "@b2bportal/air-cfar-api";
import { I18nMarkup, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { CfarExerciseProgress } from "@hopper-b2b/types";
import { Icon, IconName } from "@hopper-b2b/ui";
import { getEnvVariables, useDeviceTypes } from "@hopper-b2b/utilities";
import { isEmpty, uniq } from "lodash-es";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getSelectedFlight } from "../../../../../pages/TripsList/reducer";
import "./styles.scss";
import clsx from "clsx";

interface CancellationInformationProps {
  cfarExerciseProgress: CfarExerciseProgress;
  refundPrice: Prices | null;
  isFtc: boolean | null;
  airlineNames: string[];
  mobile?: boolean;
  refundPayments: AirCfarExerciseRefundPaymentTypeOpaqueValue[];
}
export const CancellationInformation = ({
  cfarExerciseProgress,
  refundPrice,
  isFtc,
  airlineNames,
  refundPayments,
}: CancellationInformationProps) => {
  const { t, formatFiatCurrency } = useI18nContext();
  const flight = useSelector(getSelectedFlight);
  const refund = refundPrice
    ? formatFiatCurrency(refundPrice?.fiat, { signDisplay: "never" })
    : "——";
  const { matchesMobile } = useDeviceTypes();

  const airlineName = useMemo(() => {
    const uniqueAirlines = uniq(airlineNames);
    if (isEmpty(uniqueAirlines)) {
      return null;
    }

    if (uniqueAirlines.length === 1) {
      return uniqueAirlines[0];
    }

    return null;
  }, [airlineNames]);

  const isFtcText = useMemo(() => {
    return airlineName
      ? t("cfarExercise.confirmation.ftc.details", {
          refundAmount: refund,
          airline: airlineName,
        })
      : t("cfarExercise.confirmation.ftc.detailsNoAirline", {
          refundAmount: refund,
        });
  }, [airlineName, refund, t]);

  const getRefundBreakdown = () => {
    const refundBreakdown: {
      cashSegment?: string;
      rewardsSegment?: string;
      travelCreditSegment?: string;
    } = {
      cashSegment: undefined,
      rewardsSegment: undefined,
      travelCreditSegment: undefined,
    };
    refundPayments.forEach((payment) => {
      if (payment.type === AirCfarExerciseRefundPaymentType.Cash) {
        const fiat = payment.value.amount?.fiat;
        refundBreakdown.cashSegment = formatFiatCurrency(fiat, {
          signDisplay: "never",
        });
      }
      if (payment.type === AirCfarExerciseRefundPaymentType.CommbankRewards) {
        const rewards = payment.value.amount?.rewards;
        const rewardsKey = Object.keys(rewards)[0];
        const rewardsAmount = rewards[rewardsKey].value;
        refundBreakdown.rewardsSegment = `${rewardsAmount} Awards points`;
      }
      if (
        payment.type === AirCfarExerciseRefundPaymentType.TravelWalletCredit
      ) {
        const travelCreditAmount = payment.value.amount?.fiat
          ? formatFiatCurrency(payment.value.amount?.fiat, {
              signDisplay: "never",
            })
          : undefined;
        refundBreakdown.travelCreditSegment = `${travelCreditAmount} in travel credit`;
      }
    });
    const validRefundSegments = [
      refundBreakdown.cashSegment,
      refundBreakdown.rewardsSegment,
      refundBreakdown.travelCreditSegment,
    ].filter((segment) => !!segment);
    switch (validRefundSegments.length) {
      case 3:
        return `${validRefundSegments[0]}, ${validRefundSegments[1]} and ${validRefundSegments[2]}`;
      case 2:
        return `${validRefundSegments[0]} and ${validRefundSegments[1]}`;
      case 1:
        return validRefundSegments[0];
      default:
        return "";
    }
  };

  const descriptionText = useMemo(() => {
    switch (cfarExerciseProgress) {
      case CfarExerciseProgress.Review:
        return isFtc ? (
          isFtcText
        ) : (
          <Trans
            i18nKey="cfarExercise.confirmation.cash.details"
            components={[<b />]}
            values={{
              refundAmount: getRefundBreakdown(),
            }}
          />
        );

      case CfarExerciseProgress.Completed:
        return (
          <I18nMarkup
            tKey={"cfarExercise.completedCancellation"}
            replacements={{ email: flight?.emailAddress || "" }}
          />
        );

      default:
        return (
          <Trans
            i18nKey="cfarExercise.confirmation.cash.details"
            components={[<b />]}
            values={{ refundString: getRefundBreakdown() }}
          />
        );
    }
  }, [cfarExerciseProgress, isFtc, isFtcText, t, refund, flight?.emailAddress]);

  return (
    <div
      className={clsx("cfar-exercise-copy-details", { mobile: matchesMobile })}
    >
      <span>{descriptionText}</span>
    </div>
  );
};
