import { FormLabel, Radio } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { ButtonWrap } from "@hopper-b2b/ui";
import { FormEvent, useCallback } from "react";

export const RadioListItem = <T extends object>({
  isSelected,
  isDisabled,
  value,
  onSelect,
  id,
  label,
}: {
  isSelected: boolean;
  isDisabled?: boolean;
  value: T;
  onSelect: (value: T, id?: string) => void;
  id: string;
  label: React.ReactNode;
}) => {
  const onClickHandler = useCallback(
    (e: FormEvent<HTMLButtonElement> | Event) => {
      e.stopPropagation();
      e.preventDefault();
      onSelect(value, id);
    },
    [id, onSelect, value]
  );

  return (
    <ButtonWrap
      className={clsx(styles.radioListItem, "radio-form", {
        selected: isSelected,
        disabled: isDisabled,
      })}
      onClick={onClickHandler}
    >
      <Radio
        className="radio-button"
        checked={isSelected}
        value={value}
        id={id}
        disabled={isDisabled}
      />
      <FormLabel
        htmlFor={id}
        className={clsx("radio-content-label", { disabled: isDisabled })}
      >
        <p className="label">{label}</p>
      </FormLabel>
    </ButtonWrap>
  );
};
