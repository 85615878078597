import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useEffect } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ReactComponent as Close } from "../../../assets/client/close.svg";
import { ActionLink, MobilePopoverCard } from "@hopper-b2b/ui";

import "./styles.scss";

export interface ISearchModalProps {
  open: boolean;
  onClose: () => void;
  LodgingSearchRoot: JSX.Element;
  title: string;
}

export const SearchModal = ({
  open,
  onClose,
  LodgingSearchRoot,
  title,
}: ISearchModalProps) => {
  const { t } = useI18nContext();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

  return (
    <MobilePopoverCard
      open={open}
      onClose={onClose}
      fullScreen
      className="hotels location-modal-popover"
      contentClassName="location-modal-wrapper"
      paperClassName={
        "nubank-location-modal-calendar-picker location-modal-calendar-picker"
      }
      topRightButton={
        <ActionLink
          className="location-modal-header-close-button"
          onClick={onClose}
          content={<Close aria-label={t("close.imageAlt")} />}
          label={t("close.button")}
        />
      }
    >
      <Box className={clsx("location-modal-root")}>
        <div className="header-center-section">
          <div className="location-modal-header-container">
            <h3 className="location-modal-header">{title}</h3>
          </div>
        </div>
        <Box className="location-search-container">{LodgingSearchRoot}</Box>
      </Box>
    </MobilePopoverCard>
  );
};
