import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import defaultStyles from "./RadioGroup.module.scss";
import { useUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreUiComponents,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import clsx from "clsx";
import type { PropsWithChildren, ReactNode } from "react";

type RadioGroupItemClassNames = Partial<{
  itemContainer: string;
}>;

type RadioLabelProps = PropsWithChildren<{ id: string }>;

type RadioGroupItemProps = {
  key?: string;
  value: string;
  id?: string;
  className?: string;
  label?: ReactNode;
  labelComponent?: React.FC<RadioLabelProps>;
};

type RadioGroupProps = {
  options: RadioGroupItemProps[];
  selectedOption?: string;
  onSelectValue: (value: string) => void;
  classNames?: RadioGroupItemClassNames & {
    container?: string;
  };
} & RadixRadioGroup.RadioGroupProps &
  PlatformComponentProps;

export const RadioGroup = ({
  options,
  disabled,
  selectedOption,
  onSelectValue,
  className,
  classNames = {},
  defaultValue,
  "aria-label": ariaLabel,
}: RadioGroupProps) => {
  const styles = useUiStyles(CoreUiComponents.RadioGroup, defaultStyles);
  const [block] = useModuleBEM(styles, CoreUiComponents.RadioGroup);

  const containerClassNames = clsx(block, className, classNames.container);

  const optionsComponents = options?.map((option) => {
    const elementId = option.key != null ? option.key : option.value;
    const selected = selectedOption === option.value;
    return (
      <RadioItem
        value={option.value}
        id={elementId}
        key={elementId}
        className={classNames?.itemContainer}
        label={option.label}
        labelComponent={option.labelComponent}
        ariaLabel={ariaLabel}
        selected={selected}
      />
    );
  });

  return (
    <RadixRadioGroup.RadioGroup
      className={containerClassNames}
      value={selectedOption}
      onValueChange={onSelectValue}
      defaultValue={defaultValue}
      data-selected={selectedOption}
      disabled={disabled}
    >
      {optionsComponents}
    </RadixRadioGroup.RadioGroup>
  );
};

const DefaultLabel = ({ id, children }: RadioLabelProps) => {
  const styles = useUiStyles(CoreUiComponents.RadioItem, defaultStyles);
  const [, cn] = useModuleBEM(styles, CoreUiComponents.RadioItem);

  return (
    <label className={cn("label")} htmlFor={id}>
      {children}
    </label>
  );
};
export const RadioItem = ({
  value,
  id,
  className,
  label = null,
  labelComponent,
  ariaLabel,
  selected,
}: RadioGroupItemProps & {
  id: string;
  ariaLabel?: string;
  selected: boolean;
}) => {
  const styles = useUiStyles(CoreUiComponents.RadioItem, defaultStyles);
  const [block, cn] = useModuleBEM(styles, CoreUiComponents.RadioItem);

  const containerClassNames = clsx(
    block,
    cn("container", { selected }),
    className
  );
  const Label = labelComponent ?? DefaultLabel;

  return (
    <div className={containerClassNames}>
      <RadixRadioGroup.Item
        className={cn("item")}
        value={value}
        id={id}
        aria-label={ariaLabel}
      >
        <RadixRadioGroup.Indicator className={cn("indicator")} />
      </RadixRadioGroup.Item>
      <Label id={id}>{label}</Label>
    </div>
  );
};
