import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CfarExerciseProgress,
  ClientName,
  FlightSummaryInfo,
  FlightSummaryInfoWithKeys,
  getFlightSummaryInfo,
  getReturnSlice,
  MyTripsModalTypes,
} from "@hopper-b2b/types";
import {
  B2BLoadingPopup,
  ErrorPopup,
  MobileFlightSummaryRowNew,
  Icon,
  IconName,
} from "@hopper-b2b/ui";
import { Box } from "@material-ui/core";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useI18nContext } from "@hopper-b2b/i18n";
import {
  getEnvVariables,
  toggleAdaChat,
  useDeviceTypes,
  SupportContext,
  DAYJS_DATE_FORMAT,
} from "@hopper-b2b/utilities";
import { ClientContext } from "../../../../App";
import {
  discardFlightCfarCancellationQuote,
  resetCfarExerciseState,
  setOpenModal,
} from "../../../TripsList/actions/actions";
import {
  getAirlinesMap,
  getAirportMap,
  getCfarCancellationQuote,
  getCfarCancellationQuoteV1,
  getCfarExerciseProgress,
  getFlightCfarExerciseError,
  getCfarExerciseQuoteError,
  getOpenModal,
} from "../../../TripsList/reducer/selectors";
import { addFlightType } from "../../../TripsList/components/ItineraryList/components/FlightCard/helpers";
import { CfarDetails, CfarProgressCta } from "./components";
import { CfarDetailsV1 } from "./components/CfarDetailsV1";
import "./styles.scss";
import {
  AirCfarExerciseFailureEnum,
  CipherText,
} from "@b2bportal/air-cfar-api/lib/api";
import { getCfarExerciseErrorContent } from "./utils";

export interface ICfarExerciseProps {
  flight: BookedFlightItineraryWithDepartureTime;
  onComplete?: () => void;
  enableTopLeftIcon?: boolean;
}

export const CfarExercise = ({
  flight,
  onComplete,
  enableTopLeftIcon,
}: ICfarExerciseProps) => {
  const clientContext = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const showError = useSelector(getFlightCfarExerciseError);

  const cfarExerciseProgress = useSelector(getCfarExerciseProgress);
  const airportMap = useSelector(getAirportMap);
  const airlineMap = useSelector(getAirlinesMap);
  const cfarQuote = useSelector(getCfarCancellationQuote);
  const cfarQuoteV1 = useSelector(getCfarCancellationQuoteV1);
  const cfarExerciseError = useSelector(getCfarExerciseQuoteError);
  const openModal = useSelector(getOpenModal);
  const { generalSupportChat } = useContext(SupportContext);
  const exerciseErrorContent = useMemo(() => {
    return getCfarExerciseErrorContent({
      cfarExerciseError,
      openModal,
      i18nContext: t,
      generalSupportChat,
    });
  }, [cfarExerciseError, generalSupportChat, openModal, t]);
  const { title, subtitle, primaryButton, secondaryButton } =
    exerciseErrorContent ?? {};

  const [selectedCfarQuoteId, setSelectedCfarQuoteId] = useState(null);
  useEffect(() => {
    if (cfarQuote) {
      setSelectedCfarQuoteId(cfarQuote.quoteId);
    } else if (cfarQuoteV1) {
      setSelectedCfarQuoteId({
        value: cfarQuoteV1.quotes[0]?.quoteId,
      } as CipherText);
    }
  }, [cfarQuote, cfarQuoteV1]);

  const loading =
    cfarExerciseProgress === CfarExerciseProgress.Pending ||
    cfarExerciseProgress === CfarExerciseProgress.Loading;

  useEffect(() => {
    return () => {
      dispatch(discardFlightCfarCancellationQuote());
      dispatch(resetCfarExerciseState());
    };
  }, [dispatch]);

  const departureFlightSummary = useMemo(
    () => getFlightSummaryInfo(true, flight, airportMap, airlineMap),
    [flight, airlineMap, airportMap]
  );
  const returnFlightSummary = useMemo(
    () => getFlightSummaryInfo(false, flight, airportMap, airlineMap),
    [flight, airlineMap, airportMap]
  );

  const mapTitleKeys = ({
    titleKeys,
    ...summary
  }: FlightSummaryInfoWithKeys): FlightSummaryInfo => {
    const title = t("originToDestination", titleKeys);
    return {
      title,
      ...summary,
    };
  };

  const airlineNames = useMemo(
    () =>
      returnFlightSummary
        ? [departureFlightSummary.airlineName, returnFlightSummary.airlineName]
        : [departureFlightSummary.airlineName],
    [departureFlightSummary, returnFlightSummary]
  );

  const loadingMessage = () => {
    switch (cfarExerciseProgress) {
      case CfarExerciseProgress.Loading:
        return t("cfarExercise.loadingMessage");
      case CfarExerciseProgress.Pending:
      default:
        return t("cfarExercise.pendingMessage");
    }
  };

  const onClose = () => {
    dispatch(setOpenModal({ type: null, selectedItinerary: null }));
    dispatch(resetCfarExerciseState());
  };

  const isUber = getEnvVariables("clientName") === ClientName.UBER;

  return (
    <>
      {loading ? (
        <B2BLoadingPopup
          open
          fullScreen={true}
          message={loadingMessage()}
          popupSize="mobile"
        />
      ) : (
        <Box className="mobile-cfar-exercise">
          <Box className="mobile-cfar-exercise--header">
            {cfarExerciseProgress !== CfarExerciseProgress.Completed ? (
              <FontAwesomeIcon
                icon={faChevronLeft as IconProp}
                onClick={() => {
                  onComplete ? onComplete() : history.goBack();
                }}
              />
            ) : (
              <div className="confirmation-header">
                {t("cfarExercise.cancellationConfirmation")}
              </div>
            )}
          </Box>
          {/* Top Section */}
          <div className="cfar-details">
            <div className="cfar-details--title">
              {cfarExerciseProgress === CfarExerciseProgress.Completed
                ? t("cfarExercise.cancellationConfirmedTitle")
                : t("cfarExercise.title")}
            </div>
            {cfarQuote ? (
              <div className="cfar-details--description">
                <CfarDetails
                  cfarExerciseProgress={cfarExerciseProgress}
                  refundPrice={cfarQuote?.cfarRefund}
                  isFtc={cfarQuote.isFtc}
                  airlineNames={airlineNames}
                />
              </div>
            ) : null}
            {cfarQuoteV1 ? (
              <CfarDetailsV1
                cfarExerciseProgress={cfarExerciseProgress}
                selectedQuoteId={selectedCfarQuoteId}
                cfarQuoteV1={cfarQuoteV1}
                airlineNames={airlineNames}
                onSelectQuoteId={setSelectedCfarQuoteId}
              />
            ) : null}
          </div>
          {/* Middle Section */}
          <div className="mobile-trip-details-info">
            <div className="mobile-trip-details-flight-summaries">
              <MobileFlightSummaryRowNew
                flightSummaryInfo={mapTitleKeys(departureFlightSummary)}
                iconSrc={clientContext.assets?.airplaneDepart}
              />
              {getReturnSlice(flight.bookedItinerary) ? (
                <MobileFlightSummaryRowNew
                  flightSummaryInfo={mapTitleKeys(returnFlightSummary)}
                  iconSrc={clientContext.assets?.airplaneArrive}
                />
              ) : null}
            </div>
          </div>
          {/* Bottom Section */}
          <CfarProgressCta
            done={onComplete}
            cfarExerciseProgress={cfarExerciseProgress}
            quoteId={selectedCfarQuoteId}
            disableFloatingButton={getEnvVariables("clientName") !== "uber"}
            useCfarV1={!!cfarQuoteV1 && !cfarQuote}
          />
        </Box>
      )}
      {isUber ? (
        <ErrorPopup
          className={"uber-cfar-error-popup"}
          centered={false}
          fullScreen={matchesMobile}
          open={showError === true}
          title={t("cfarExercise.error.title")}
          subtitle={t("cfarExercise.error.subtitle")}
          primaryButtonText={t("cfarExercise.error.primaryButton")}
          primaryOnClick={() => {
            dispatch(resetCfarExerciseState());
          }}
          secondaryButtonText={t("cfarExercise.error.secondaryButton")}
          secondaryOnClick={() => {
            toggleAdaChat();
            dispatch(resetCfarExerciseState());
          }}
        />
      ) : (
        <ErrorPopup
          reverse={false}
          fullScreen={matchesMobile}
          open={showError === true}
          title={title}
          subtitle={subtitle}
          centered={!matchesMobile}
          onClose={onClose}
          topRightButton={
            <div onClick={() => onClose()}>
              <Icon name={IconName.CloseBlack} />
            </div>
          }
          topLeftButton={
            enableTopLeftIcon &&
            cfarExerciseError === AirCfarExerciseFailureEnum.Void ? (
              <Icon name={IconName.Repayments} />
            ) : undefined
          }
          primaryButtonText={primaryButton?.text}
          primaryOnClick={() => {
            primaryButton?.actions?.forEach((individualAction) =>
              dispatch(individualAction())
            );
            primaryButton?.nonActions?.forEach((nonAction) => nonAction());
          }}
          secondaryButtonText={secondaryButton?.text}
          secondaryOnClick={() => {
            secondaryButton?.actions?.forEach((individualAction) =>
              dispatch(individualAction())
            );
            secondaryButton?.nonActions?.forEach((nonAction) => nonAction());
          }}
          wideButton={cfarExerciseError === AirCfarExerciseFailureEnum.Void}
        />
      )}
    </>
  );
};
