import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ButtonWrap,
  Divider,
  IContactSupportModalContentProps,
} from "@hopper-b2b/ui";
import { DialogContent, DialogTitle } from "@material-ui/core";
import { useCallback } from "react";
import { ReactComponent as ArrowAction } from "../../../assets/client/arrow-action.svg";
import "./styles.scss";

export const ContactSupportModalContent = ({
  onClose: handleCloseContactSupportModal,
  onContactSupport: handleOpenChat,
  hotelCancellation = false,
}: Pick<
  IContactSupportModalContentProps,
  "onClose" | "onContactSupport" | "hotelCancellation"
>) => {
  const { t, brand } = useI18nContext();

  const handleCallSupport = useCallback(() => {
    handleCloseContactSupportModal();
    window.location.href = `tel:${brand.contactSupport.supportNumber}`;
  }, [brand.contactSupport.supportNumber, handleCloseContactSupportModal]);

  const handleCallInternationalSupport = useCallback(() => {
    handleCloseContactSupportModal();
    // International number
    window.location.href = `tel:${brand.contactSupport.intlSupportNumber}`;
  }, [brand.contactSupport.intlSupportNumber, handleCloseContactSupportModal]);

  return (
    <div className="contact-support-modal-content-container">
      <DialogTitle className="contact-support-modal-title" disableTypography>
        <h2 className="title">{t("contactSupport")}</h2>
        {hotelCancellation ? (
          <p className="subtitle">{t("contactSupportChangeOrCancel")}</p>
        ) : null}
        <p className="subtitle">{t("contactSupportMethod")}</p>
      </DialogTitle>
      <DialogContent className="contact-support-modal-content">
        <ButtonWrap className="support-action-row" onClick={handleOpenChat}>
          <p className="support-action-row-title">{t("chatTitle")}</p>
          <div className="support-action-row-right">
            <ArrowAction className="icon" />
          </div>
        </ButtonWrap>
        <Divider />
        <ButtonWrap className="support-action-row" onClick={handleCallSupport}>
          <p className="support-action-row-title">{t("phone")}</p>
          <div className="support-action-row-right">
            <p>{t("callSupportInBrazil")}</p>
            <ArrowAction className="icon" />
          </div>
        </ButtonWrap>
        <Divider />
        <ButtonWrap
          className="support-action-row"
          onClick={handleCallInternationalSupport}
        >
          <p className="support-action-row-title">{t("phone")}</p>
          <div className="support-action-row-right">
            <p>{t("callSupportInternationally")}</p>
            <ArrowAction className="icon" />
          </div>
        </ButtonWrap>
      </DialogContent>
    </div>
  );
};
