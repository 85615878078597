import type { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { DateTimeFormatStyle, useI18nContext } from "@hopper-b2b/i18n";
import { ReactComponent as InsuranceIcon } from "../../../assets/client/uv/insurance.svg";
import { ReactComponent as ChevronDown } from "../../../assets/client/chevron-down.svg";
import styles from "./styles.module.scss";
import { ButtonWrap, Divider } from "@hopper-b2b/ui";
import { useCallback, useMemo, useState } from "react";
import { TravelInsurancePopover } from "../../TravelInsurancePopover";
import dayjs from "dayjs";
import { customFormatDateTime } from "@hopper-b2b/utilities";
import { useExperiment } from "@hopper-b2b/experiments";
import { EXPERIMENTS } from "../../../App";
import type { AirportMap } from "@hopper-b2b/types";

const TripFlightsDetailsInsurance = ({
  flight,
  isRoundTrip,
  airportMap,
}: {
  flight: BookedFlightItineraryWithDepartureTime;
  isRoundTrip: boolean;
  airportMap: AirportMap;
}) => {
  const { t, language, brand } = useI18nContext();

  const [showInsurancePopover, setShowInsurancePopover] = useState(false);

  const openPopoverHandler = useCallback(() => {
    setShowInsurancePopover(true);
  }, []);

  const closePopoverHandler = useCallback(() => {
    setShowInsurancePopover(false);
  }, []);

  // one way flights are only valid for the trip date + 30 days, round trip 60 days
  const insuranceExpiryDate = useMemo(() => {
    const travelDate = dayjs(flight.departureTime);
    const expiryDate = travelDate.add(isRoundTrip ? 60 : 30, "day").toDate();
    return customFormatDateTime(
      expiryDate,
      language,
      DateTimeFormatStyle.ShortDate
    );
  }, [flight.departureTime, isRoundTrip, language]);

  const enableTravelInsurance = useExperiment(
    EXPERIMENTS.NUBANK_TRAVEL_INSURANCE
  );

  // This is very similar to utils/isFlightDomesticToInternational.ts, however becaue we're in trips we need to do different lookups
  const isDomesticToInternational = useMemo(() => {
    // Grab the initial trip slice - regardless of one way or round trip we just need the first slice.
    const travelItineraryInitialSlice =
      flight.bookedItinerary.travelItinerary.slices[0];

    // Get the origin's airport code
    const originLocationAirportCode =
      travelItineraryInitialSlice.segments[0].origin.locationCode;

    // Get the destination's airport code, .length-1 accounts for layovers
    const destinationLocationAirportCode =
      travelItineraryInitialSlice.segments[
        travelItineraryInitialSlice.segments.length - 1
      ].destination.locationCode;

    // Get the origin/destination's country code, which is in the airportMap
    const originAirportCountryCode =
      airportMap[originLocationAirportCode].geography.countryCode;
    const destinationAirportCountryCode =
      airportMap[destinationLocationAirportCode].geography.countryCode;

    // If both airport country codes are the same, it's not an international flight
    if (originAirportCountryCode === destinationAirportCountryCode) {
      return false;
    }
    // We have two separate countries, if the origin airport matches the preferred country code, the flight begins as domestic
    return originAirportCountryCode === brand?.preferredCountryCode;
  }, [airportMap, brand?.preferredCountryCode, flight]);

  return enableTravelInsurance && isDomesticToInternational ? (
    <>
      <Divider className={styles.Divider} />

      <ButtonWrap
        onClick={openPopoverHandler}
        className={styles.TripFlightsDetailsInsurance}
      >
        <div className="insurance-icon">
          <InsuranceIcon />
        </div>

        <div className="content">
          <h4 className="title">{t("ultravioletaTravelInsurance.title")}</h4>
          <p className="description">
            {t("ultravioletaTravelInsurance.findOutHowToActivate")}
          </p>
          <p className="description last">
            {t("ultravioletaTravelInsurance.myTripsRightToInsuranceEmail")}
          </p>
        </div>
        <div className="more">
          <ChevronDown />
        </div>
      </ButtonWrap>
      <TravelInsurancePopover
        open={showInsurancePopover}
        onClose={closePopoverHandler}
        isRoundTrip={isRoundTrip}
        expiryDate={insuranceExpiryDate}
      />
    </>
  ) : null;
};

export default TripFlightsDetailsInsurance;
