import {
  airCfarApi,
  AirCfarExerciseQuoteResponse,
  AirCfarExerciseQuoteResponseEnum,
  QuoteSuccess,
  AirCfarExerciseFailureEnum,
} from "@b2bportal/air-cfar-api";
import { trackEvent } from "@hopper-b2b/api";
import {
  CfarExerciseProgress,
  CfarTrackingEvents,
  AirOrchestratorCfarTrackingEvents,
} from "@hopper-b2b/types";
import {
  combineTrackingProperties,
  ITrackingProperties,
  ITrackingPropertiesRequest,
} from "@hopper-b2b/utilities";
import axios, { AxiosResponse } from "axios";
import { put, select } from "redux-saga/effects";
import { actions } from "../../../actions";
import { getCfarExerciseTrackingProperties } from "../../../reducer";
import { FlightsCfarTrackingEvent } from "@b2bportal/core-types";

export function* fetchFlightCfarCancellationQuoteV1Saga(
  action: actions.IFetchFlightCfarCancellationQuoteV1
) {
  yield put(actions.setCfarExerciseProgress(CfarExerciseProgress.Loading));
  const requestBody = {
    itineraryId: action.itineraryId,
  };
  try {
    const response: AxiosResponse<AirCfarExerciseQuoteResponse> =
      yield airCfarApi(axios).apiV1CfarAirExerciseQuotePost(requestBody);
    const data = response.data;
    if (
      data?.AirCfarExerciseQuoteResponse ===
      AirCfarExerciseQuoteResponseEnum.QuoteFailure
    ) {
      yield trackEvent({
        eventName: AirOrchestratorCfarTrackingEvents.CFAR_EXERCISE_QUOTE_FAIL,
        properties: {
          cfar_exercise_fail_reason: data.failure.AirCfarExerciseFailure,
        },
      });
      yield put(
        actions.setFetchFlightCfarCancellationQuoteError(
          data.failure.AirCfarExerciseFailure
        )
      );
      yield put(actions.setFetchFlightCfarCancellationQuoteCallStateFailed());
    } else {
      yield put(
        actions.setFlightCfarCancellationQuoteV1({
          response: response.data as QuoteSuccess,
        })
      );
      yield put(actions.setCfarExerciseProgress(CfarExerciseProgress.Review));
      yield put(actions.setFetchFlightCfarCancellationQuoteError(undefined));

      const trackingProps = yield select(
        getCfarExerciseTrackingProperties
      ) as ITrackingProperties;
      const cfarTrackingProps = combineTrackingProperties([trackingProps]);
      yield trackEvent({
        eventName: CfarTrackingEvents.ENTER_CFAR_EXERCISE_FLOW,
        ...cfarTrackingProps,
      });

      const trackingProperties: ITrackingProperties = {
        properties: {
          agent_locator:
            action.selectedFlight.bookedItinerary.travelItinerary.locators
              ?.agent.value,
        },
        encryptedProperties: undefined,
      };
      let trackingPropertiesRequest = combineTrackingProperties([
        trackingProperties,
        data.trackingProperties,
      ]);
      const exerciseQuotes = data.quotes;
      if (exerciseQuotes.length > 1) {
        trackEvent({
          eventName: FlightsCfarTrackingEvent.CFAR_EXERCISE_QUOTE_OUTCOMES,
          ...trackingPropertiesRequest,
        });
      }
      const exerciseQuotesTrackingProperties = exerciseQuotes.map(
        (q) => q.trackingProperties
      );
      trackingPropertiesRequest = combineTrackingProperties(
        [...exerciseQuotesTrackingProperties],
        trackingPropertiesRequest.properties,
        trackingPropertiesRequest.encryptedProperties
      );

      yield trackEvent({
        eventName: AirOrchestratorCfarTrackingEvents.CFAR_EXERCISE_QUOTE,
        ...trackingPropertiesRequest,
      });
      yield put(
        actions.setCfarExerciseQuoteTrackingProperties(
          trackingPropertiesRequest
        )
      );
    }
  } catch (e) {
    yield put(actions.setFetchFlightCfarCancellationQuoteCallStateFailed());
    yield put(
      actions.setFetchFlightCfarCancellationQuoteError(
        AirCfarExerciseFailureEnum.Error
      )
    );
    yield trackEvent({
      eventName: AirOrchestratorCfarTrackingEvents.CFAR_EXERCISE_QUOTE_FAIL,
      properties: {
        cfar_exercise_fail_reason: AirCfarExerciseFailureEnum.Error,
      },
    });
  }
}
