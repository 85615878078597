import { combineReducers } from "@reduxjs/toolkit";
import { DisruptionOffer } from "@b2bportal/air-disruption-api";
import {
  DisruptionOffersReducer,
  DisruptionOffersState,
  initialDisruptionOffersState,
} from "../features/offers/store/slice";
import {
  disruptionExerciseReducer,
  DisruptionExerciseState,
  initialDisruptionExerciseState,
} from "../features/exercise/store/slice";

export interface DisruptionState {
  disruption: {
    disruptionOffers: DisruptionOffersState;
    disruptionExerciseState: DisruptionExerciseState;
  };
}

export const initialDisruptionState: DisruptionState = {
  disruption: {
    disruptionOffers: initialDisruptionOffersState,
    disruptionExerciseState: initialDisruptionExerciseState,
  },
};

// State used for `DisruptionV1` machine in libs/checkout
export interface DisruptionStateForCheckout {
  selectedOffer: DisruptionOffer;
}

export const disruptionRootReducer = combineReducers({
  disruptionOffers: DisruptionOffersReducer,
  disruptionExerciseState: disruptionExerciseReducer,
});
