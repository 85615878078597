import { LocationQueryEnum, LocationQuery } from "@b2bportal/air-shopping-api";
import { flightApiConfigStoreKey } from "../../../reducers/types";
import { IStoreState } from "../../../reducers/types";
import { delay, put, select } from "redux-saga/effects";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { fetchLocationAutocomplete } from "@hopper-b2b/api";

export function* fetchOriginCategories(
  action: actions.IFetchDestinationCategories
) {
  yield delay(300);
  try {
    const state: IStoreState = yield select();
    const { flightSearch } = state;
    const apiConfig = state[flightApiConfigStoreKey];
    const requestBody: LocationQuery = {
      LocationQuery: LocationQueryEnum.Label,
      l: action.queryString,
      searchParameters: {
        activeControl: "origin",
        origin: flightSearch?.destination?.id?.code?.code ?? null,
        tripType: flightSearch.tripCategory,
      },
    };
    const { categories } = yield fetchLocationAutocomplete(
      requestBody,
      apiConfig
    );
    yield put(actions.setOriginCategories(categories));
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setOriginCategories([]));
  }
}
