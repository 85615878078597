import dayjs from "dayjs";

import {
  FlightSummaryInfoWithKeys,
  getDepartureSlice,
} from "@hopper-b2b/types";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { pluralize, removeTimezone } from "@hopper-b2b/utilities";
import { Icon, IconName, AirlineIcon } from "@hopper-b2b/ui";

export const FlightTripCard = ({
  flightSummary,
  flight,
  isDeparture,
}: {
  flightSummary: FlightSummaryInfoWithKeys;
  flight: BookedFlightItineraryWithDepartureTime;
  isDeparture: boolean;
}) => {
  const originLocationCode = flightSummary.titleKeys["originLocationCode"];
  const destinationLocationCode =
    flightSummary.titleKeys["destinationLocationCode"];
  const sliceTitle = `${flightSummary.originCity} (${originLocationCode}) to ${flightSummary.destinationCity} (${destinationLocationCode})`;
  const departureNoTimezone = removeTimezone(flightSummary.departure);
  const arrivalNoTimezone = removeTimezone(flightSummary.arrival);
  const departureDay = dayjs(departureNoTimezone).format("ddd DD MMM YY");
  const departureTime = dayjs(departureNoTimezone).format("hh:mm A");
  const arrivalTime = dayjs(arrivalNoTimezone).format("hh:mm A");
  const airline = flightSummary.airlineName;
  const stops =
    flightSummary.stops === 0
      ? "Nonstop"
      : `${flightSummary.stops} ${pluralize(
          flightSummary.stops,
          "stop",
          "stops"
        )}`;
  const duration = dayjs
    .duration(dayjs(flightSummary.arrival).diff(flightSummary.departure))
    .format("H[h] mm[m]");

  const title = `${isDeparture ? "Departing" : "Returning"} - ${departureDay}`;

  const departureSlice = getDepartureSlice(flight.bookedItinerary);
  const fareClass = departureSlice?.fareShelf?.shortBrandName;

  return (
    <div className="cfar-flight-trip-card">
      <div className="cfar-flight-trip-card--header">{sliceTitle}</div>
      <div className="cfar-flight-trip-card--container">
        <div className="cfar-flight-trip-card--title">{title}</div>
        <div className="cfar-flight-trip-card--body">
          <AirlineIcon airlineCode={flightSummary.airlineCode} />
          <div className="cfar-flight-trip-card--itinerary">
            <div className="cfar-flight-trip-card--itinerary-item">
              <span className="bold">{departureTime}</span>
              <span>{originLocationCode}</span>
            </div>
            <div className="cfar-flight-trip-card--itinerary-item">
              <Icon name={IconName.AirplaneGrey} />
              <span style={{ fontWeight: 800, padding: "0px 5px" }}>
                {duration}
              </span>
            </div>
            <div className="cfar-flight-trip-card--itinerary-item">
              <span className="bold">{arrivalTime}</span>
              <span>{destinationLocationCode}</span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <span style={{ fontWeight: 800 }}>{fareClass}</span>
          <span>
            {airline} • {stops}
          </span>
        </div>
      </div>
    </div>
  );
};
