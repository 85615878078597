import { CoreDisruptionComponent } from "@b2bportal/core-types";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseButton.module.scss";
import clsx from "clsx";
import { Icon } from "@b2bportal/core-ui";

export enum DisruptionExerciseButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Clear = "clear",
}

export interface DisruptionExerciseButtonProps {
  title: string;
  onClick: () => void;
  disabled: boolean;
  type?: DisruptionExerciseButtonType;
}

export const DisruptionExerciseButton = ({
  title,
  onClick,
  disabled,
  type = DisruptionExerciseButtonType.Primary,
}: DisruptionExerciseButtonProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseButton;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const typeClass = (type: DisruptionExerciseButtonType) => {
    switch (type) {
      case DisruptionExerciseButtonType.Clear:
        return cn("clear");
      case DisruptionExerciseButtonType.Primary:
        return cn("primary");
      case DisruptionExerciseButtonType.Secondary:
        return cn("secondary");
    }
  };

  const rightArrow =
    type === DisruptionExerciseButtonType.Clear ? (
      <Icon iconName={IconNameEnum.rightArrow} className={cn("arrow")} />
    ) : null;

  const bottomLine =
    type === DisruptionExerciseButtonType.Clear ? <hr></hr> : null;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={clsx(cn("button-container"), typeClass(type))}
    >
      {title}
      {rightArrow}
      {bottomLine}
    </button>
  );
};

export default DisruptionExerciseButton;
