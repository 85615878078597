import { useCallback } from "react";
import { MultiSelectDropdown } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useUberBridge } from "@hopper-b2b/utilities";
import {
  APPLIED_FILTER,
  AppliedFilterTrackingProperties,
} from "@hopper-b2b/types";
import { IAirlineOptions } from "../../../../../../../shop/reducer";
import { AirlineFilterProps } from "./container";

interface IAirlineFilterProps extends AirlineFilterProps {
  allAirlines: IAirlineOptions[];
  showDropdownContentOnly?: boolean;
  trackingProperties?: AppliedFilterTrackingProperties;
}

export const AirlineFilterSelection = (props: IAirlineFilterProps) => {
  const {
    airlineFilter,
    setAirlineFilter,
    allAirlines,
    showDropdownContentOnly,
    trackingProperties,
  } = props;
  const { t } = useI18nContext();
  const { postEvent } = useUberBridge();

  const handleSetAirlineFilter = useCallback(
    (selected: string[]) => {
      postEvent(APPLIED_FILTER, trackingProperties);
      setAirlineFilter(selected);
    },
    [trackingProperties, setAirlineFilter]
  );

  return (
    <MultiSelectDropdown
      currentValue={airlineFilter}
      dropdownLabel={t("searchFilter.airline")}
      options={allAirlines}
      setValue={handleSetAirlineFilter}
      showDropdownContentOnly={showDropdownContentOnly}
      className="b2b-shop-filter"
      popoverClassName="b2b"
    />
  );
};
