import { TenantHotelSteps } from "@tenant/types";
import { FlowProvider, useNavigationFlow } from "../../common";

export enum CoreHotelSteps {
  SEARCH = "SEARCH",
  AVAILABILITY = "AVAILABILITY",
  SHOP = "SHOP",
  ROOM_SELECTION = "ROOM_SELECTION",
  BOOK = "BOOK",
}

const HotelSteps = {
  ...CoreHotelSteps,
  ...TenantHotelSteps,
} as const;
export type HotelSteps = (typeof HotelSteps)[keyof typeof HotelSteps];

export const HotelNavigationFlowProvider = ({ children, navigationFlow }) => {
  return (
    <FlowProvider<HotelSteps> flow={navigationFlow}>{children}</FlowProvider>
  );
};

export const useHotelNavigationFlow = () => {
  const context = useNavigationFlow();
  if (!context) {
    throw new Error("useFlow must be used within a HotelNavigationFlow");
  }
  return context;
};
