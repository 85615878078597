import {
  FareSliceDetailsV2,
  Uta,
  UtaAssessment,
  UtaCategory,
} from "@b2bportal/air-shopping-api";

import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AirRestrictionStatus,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import clsx from "clsx";
import defaultStyles from "./FareDetails.module.scss";
import { useMemo, useState } from "react";
import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreFlightsComponent } from "@b2bportal/core-types";
import { Icon } from "@components/ui";
import { localizedAmenityLabel } from "@b2bportal/core-flights";
import { typedKeys } from "@b2bportal/core-utilities";

const config = {
  MAX_FARE_DETAILS: 4,
};

export const FARE_CLASS_DETAILS_I18N_KEY = {
  0: "fareClass.basicDetails",
  1: "fareClass.standardDetails",
  2: "fareClass.enhancedDetails",
  3: "fareClass.premiumDetails",
  4: "fareClass.luxuryDetails",
};

export interface IRestrictionProps {
  symbol: AirRestrictionStatus;
  description: string;
  key?: string;
}

export interface FareDetailsComponentProps extends PlatformComponentProps {
  fareSlice: FareSliceDetailsV2;
  multiFareNotice?: boolean;
  numberOfFareDetails?: number;
  showAllFareDetails?: boolean;
}

export const FareDetails = ({
  fareSlice,
  multiFareNotice = false,
  numberOfFareDetails,
  showAllFareDetails = false,
  className,
  children,
}: FareDetailsComponentProps) => {
  const { t } = useI18nContext();
  const MAX_FARE_DETAILS = showAllFareDetails
    ? Number.MAX_SAFE_INTEGER
    : numberOfFareDetails != null
    ? numberOfFareDetails
    : config.MAX_FARE_DETAILS;

  const [showAllDetails, setShowAllDetails] = useState(showAllFareDetails);

  const styles = useFlightStyles(
    CoreFlightsComponent.FareListFareDetails,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FareListFareDetails
  );

  const getSymbol = (uta: Uta) => {
    switch (true) {
      case uta.category === UtaCategory.boarding_priority &&
        (uta.assessment === UtaAssessment.fee ||
          uta.assessment === UtaAssessment.restriction):
      case uta.category === UtaCategory.carry_on_allowance &&
        uta.assessment === UtaAssessment.restriction:
      case uta.category === UtaCategory.cancellation &&
        uta.headline === "Non-refundable": // !!! translated strings will not match this copy
        return AirRestrictionStatus.GENERIC;
      case uta.assessment === UtaAssessment.benefit:
        return AirRestrictionStatus.INCLUDED;
      case uta.assessment === UtaAssessment.fee:
        return AirRestrictionStatus.PURCHASABLE;
      case uta.assessment === UtaAssessment.restriction:
        return AirRestrictionStatus.UNAVAILABLE;
      default:
        return AirRestrictionStatus.GENERIC;
    }
  };

  const getAmenitiesSymbol = (amenity) => {
    switch (amenity.exists) {
      case "yes":
        return AirRestrictionStatus.INCLUDED;
      case "no":
        return AirRestrictionStatus.UNAVAILABLE;
      default:
        return AirRestrictionStatus.GENERIC;
    }
  };

  const fareDetailsList = useMemo(() => {
    const list: IRestrictionProps[] = [];
    list.push(
      ...(fareSlice.amenitiesUtas?.utas.utas.map((uta) => {
        const restriction: IRestrictionProps = {
          // TODO: the assessment field should probably be an enum
          // Dulles for Uber is giving us incorrect info for several categories (hack)
          symbol: getSymbol(uta),
          description: `${uta.description}`,
        };

        return restriction;
      }) ?? [])
    );

    if (fareSlice.amenitiesUtas?.amenities) {
      list.push(
        ...typedKeys(fareSlice.amenitiesUtas.amenities).map((key) => {
          const amenity = fareSlice.amenitiesUtas?.amenities?.[key];
          const amenityLabel = localizedAmenityLabel(key, t);
          return {
            symbol: getAmenitiesSymbol(amenity),
            description: `${amenityLabel} - ${amenity?.info.displayText}`,
          };
        })
      );
    }
    return list;
  }, [fareSlice]);

  const fareDetailsIcon: { [k in AirRestrictionStatus]: React.ReactNode } = {
    [AirRestrictionStatus.INCLUDED]: (
      <Icon
        className={cn("icon", { available: true })}
        iconName={IconNameEnum.fareDetailsAvailable}
      />
    ),
    [AirRestrictionStatus.PURCHASABLE]: (
      <Icon
        className={cn("icon", { charge: true })}
        iconName={IconNameEnum.fareDetailsCharge}
      />
    ),
    [AirRestrictionStatus.UNAVAILABLE]: (
      <Icon
        className={cn("icon", { unavailable: true })}
        iconName={IconNameEnum.fareDetailsUnavailable}
      />
    ),
    [AirRestrictionStatus.GENERIC]: (
      <Icon
        className={cn("icon", { generic: true })}
        iconName={IconNameEnum.fareDetailsGeneric}
      />
    ),
    [AirRestrictionStatus.UNKNOWN]: null,
  };

  const fareDetails = useMemo(() => {
    return fareDetailsList.length > 0 ? (
      <>
        {fareDetailsList
          ?.slice(0, showAllDetails ? fareDetailsList.length : MAX_FARE_DETAILS)
          .map((restriction, i) => (
            <li
              className={cn("item")}
              key={`fare-details-${fareSlice.fareDetails}-${i}`}
            >
              {fareDetailsIcon[restriction.symbol]}
              <div>{restriction.description}</div>
            </li>
          ))}
        {fareDetailsList.length > MAX_FARE_DETAILS ? (
          <li key={"fare-details-more"}>
            <button
              onClick={() => {
                setShowAllDetails(!showAllDetails);
              }}
              className={cn("more-details-button")}
            >
              {!showAllDetails
                ? t("moreFareDetails", {
                    number: fareDetailsList.length - MAX_FARE_DETAILS,
                  })
                : t("lessFareDetails")}
            </button>
          </li>
        ) : null}
      </>
    ) : fareSlice.fareShelf == null ? null : (
      <li className={cn("general-detail")}>
        {t(FARE_CLASS_DETAILS_I18N_KEY[fareSlice.fareShelf.rating])}
      </li>
    );
  }, [fareDetailsList, fareSlice, showAllDetails, showAllFareDetails]);

  return (
    <div className={clsx(block, className)}>
      <ul className={cn("list")}>{fareDetails}</ul>
      {/* TODO: tooltip */}
      {multiFareNotice ? (
        <div className={cn("multi-fare-notice")}>
          {t("combinationFlightWarning")}
        </div>
      ) : null}
      {children}
    </div>
  );
};
