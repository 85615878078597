import { CoreDisruptionComponent } from "@b2bportal/core-types";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import defaultStyles from "./styles.module.scss";
import { Dialog, DialogContent, Icon } from "@b2bportal/core-ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  DisruptionExerciseButton,
  DisruptionExerciseButtonType,
} from "@b2bportal/core-disruption";

export interface DisruptionContactSupportProps {
  open: boolean;
  onClose: () => void;
}

export const DisruptionContactSupport = (
  props: DisruptionContactSupportProps
) => {
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionContactSupport;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t, brand } = useI18nContext();
  const { displaySupportNumber, supportNumber } = brand.contactSupport;

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent className={cn("container-root")}>
        <div className={cn("container")}>
          <span className={cn("title")}>
            {t(
              "core-disruption.disruptionUniversalExercise.contactSupportModal.title"
            )}
          </span>
          <span className={cn("subtitle")}>
            {t(
              "core-disruption.disruptionUniversalExercise.contactSupportModal.subtitle"
            )}
          </span>
          <div className={cn("call-box-container")}>
            <Icon iconName={IconNameEnum.info} className={cn("phone-icon")} />
            <div className={cn("call-container")}>
              <span className={cn("call-title")}>
                {t(
                  "core-disruption.disruptionUniversalExercise.contactSupportModal.callUs"
                )}
              </span>
              <span className={cn("call-subtitle")}>
                <a href={`tel:${supportNumber}`}>{displaySupportNumber}</a>
              </span>
            </div>
          </div>
          <DisruptionExerciseButton
            title={t(
              "core-disruption.disruptionUniversalExercise.contactSupportModal.close"
            )}
            onClick={props.onClose}
            type={DisruptionExerciseButtonType.Primary}
            disabled={false}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DisruptionContactSupport;
