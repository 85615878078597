import { FC } from "react";
import { useI18nContext } from "./hooks/useI18nContext";
import { Ii18nMarkup } from "./I18nMarkup";

export const I18nSpan: FC<Ii18nMarkup> = ({
  tKey,
  replacements,
  className,
}: Ii18nMarkup) => {
  const i18n = useI18nContext();
  const text = i18n.t(tKey, {
    interpolation: { escapeValue: false },
    ...replacements,
  });
  return <span className={className}>{text}</span>;
};
