import { TripSlice } from "@b2bportal/air-shopping-api";
import { useI18nCurrency } from "@hopper-b2b/i18n";
import {
  AlgomerchTag,
  FareclassShelfBrandName,
  TagType,
  TripFare,
} from "@hopper-b2b/types";
import {
  formatInterval,
  getRewardText,
  removeTimezone,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { FunctionComponent, ReactNode } from "react";
import { FlightCardWrapper } from "../../component";
import {
  Airline,
  BrandName,
  FlightCardFields,
  FlightDuration,
  FlightLocations,
  FlightTiming,
  PriceDisplay,
  RewardDisplay,
  StopSummary,
} from "../../components/FlightCardFields";

import { FlightCardHeader } from "../../components/FlightCardHeader";
import { FlightTags } from "../../components/FlightTag";
import "./styles.scss";

export interface IB2BFlightCardProps {
  airlineName: string;
  flightInfo: TripSlice;
  fareDetails: TripFare[];
  rewardsKey?: string;
  isReturn?: boolean;
  selectedFareClass?: FareclassShelfBrandName;
  onClickTag?: (label: string) => void;
  onClick?: () => void;
}

export const getFlightCardTypes = ({
  flightInfo,
  fareDetails,
  rewardsKey,
  isReturn,
  selectedFareClass,
}: {
  flightInfo: TripSlice;
  fareDetails: TripFare[];
  rewardsKey?: string;
  isReturn?: boolean;
  selectedFareClass?: FareclassShelfBrandName;
}) => {
  let [displayFare] = fareDetails;

  const getRating = (name: FareclassShelfBrandName) => {
    switch (name) {
      case "basic":
        return 0;
      case "standard":
        return 1;
      case "enhanced":
        return 2;
      case "premium":
        return 3;
      default:
        return 4;
    }
  };

  /*
    In mobile view, if the user has filtered on a fare class, we display the FlightCard with that fare detail.
    We fallback to the default display fare - first element in TripFare[] - if we cannot find that selected fare (race condition prevention).
  */
  if (selectedFareClass) {
    const selectedFare = fareDetails.find((fare) =>
      isReturn
        ? fare.fareShelf?.returning?.rating === getRating(selectedFareClass)
        : fare.fareShelf?.outgoing?.rating === getRating(selectedFareClass)
    );
    if (selectedFare) {
      displayFare = selectedFare;
    }
  }

  const reward = fareDetails[0].amount.accountSpecific[rewardsKey ?? ""];
  const rewardText = reward ? getRewardText({ reward }) : "";

  const segments = flightInfo.segmentDetails;
  const originCode = flightInfo.originCode;
  const destinationCode = flightInfo.destinationCode;
  const departureTime = segments[0].departureTime;
  const arrivalTime = segments[segments.length - 1].arrivalTime;
  const airports = segments
    .slice(0, segments.length - 1)
    .map((s) => s.destinationCode);

  const duration = formatInterval(
    dayjs(flightInfo.arrivalTime).diff(
      dayjs(flightInfo.departureTime),
      "minute",
      true
    )
  );

  const primaryCarrier = flightInfo.segmentDetails[0].airlineCode;
  const brandName =
    isReturn != null
      ? displayFare.fareShelf?.returning?.brandName
      : displayFare.fareShelf?.outgoing.brandName;

  const tags = [
    displayFare.fareScore.isBest ? AlgomerchTag.BestFlight : null,
    displayFare.fareScore.isCheapest ? AlgomerchTag.Cheapest : null,
    displayFare.fareScore.isBestQuality ? AlgomerchTag.BestQuality : null,
    displayFare.fareScore.isFastest ? AlgomerchTag.Fastest : null,
  ]
    .filter((t): t is AlgomerchTag => t !== null)
    .map((value) => ({ value, type: TagType.Algomerch }));

  return {
    duration,
    tags,
    rewardText,
    originCode,
    destinationCode,
    departureTime,
    arrivalTime,
    airports,
    brandName,
    primaryCarrier,
  };
};

export const B2BFlightCard = (props: IB2BFlightCardProps) => {
  const {
    flightInfo,
    fareDetails,
    rewardsKey,
    isReturn,
    selectedFareClass,
    airlineName,
    onClickTag,
    onClick,
  } = props;

  const {
    duration,
    tags,
    rewardText,
    originCode,
    destinationCode,
    departureTime,
    arrivalTime,
    airports,
    brandName,
    primaryCarrier,
  } = getFlightCardTypes({
    flightInfo,
    fareDetails,
    rewardsKey,
    isReturn,
    selectedFareClass,
  });

  const { formatFiatCurrency } = useI18nCurrency();

  return (
    <FlightCardWrapper className="b2b-flight-card" onClick={onClick}>
      <FlightCardHeader>
        <PriceAndRewardDisplay>
          <PriceDisplay
            currentPrice={formatFiatCurrency(fareDetails[0].amount.fiat)}
          />
          {rewardText && (
            <>
              <Typography variant="inherit" className="separator">
                /
              </Typography>
              <RewardDisplay reward={rewardText} />
            </>
          )}
        </PriceAndRewardDisplay>
        {tags.length > 0 && (
          <FlightTags
            tags={tags}
            mergeTags={true}
            onClickTag={onClickTag}
            showFastest
          />
        )}
      </FlightCardHeader>
      <FlightCardFields>
        <Airline code={primaryCarrier} name={airlineName} />
        <FlightTiming
          departureTime={removeTimezone(departureTime)}
          arrivalTime={removeTimezone(arrivalTime)}
        />
        <FlightLocations
          originCode={originCode}
          destinationCode={destinationCode}
        />
        <FlightDuration duration={duration} />
        <BrandName brandName={brandName ?? ""} />
        <StopSummary airports={airports} hideAirports={true} />
      </FlightCardFields>
    </FlightCardWrapper>
  );
};

interface IPriceAndRewardDisplay {
  children: ReactNode;
}

export const PriceAndRewardDisplay: FunctionComponent<
  IPriceAndRewardDisplay
> = ({ children }: IPriceAndRewardDisplay) => (
  <Box className="price-and-reward-section">{children}</Box>
);
