import { useI18nContext } from "@hopper-b2b/i18n";
import type { TripDetails } from "@hopper-b2b/types";
import { isUserTypeCookieUltravioleta } from "../../../utils/colors";
import { ReactComponent as InsuranceActive } from "../../../assets/client/uv/insurance-active.svg";
import "./styles.scss";
import { Divider } from "@hopper-b2b/ui";
import { isFlightDomesticToInternational } from "../../../utils/isFlightDomesticToInternational";
import { useExperiment } from "@hopper-b2b/experiments";
import { EXPERIMENTS } from "../../../App";

const FlightShopReviewInsuranceSection = ({
  tripDetails,
  isOneWay,
}: {
  tripDetails: TripDetails;
  isOneWay: boolean;
}) => {
  const { t, brand } = useI18nContext();

  // TODO(Nate): Update UV detection
  const isUltravioletaUser = isUserTypeCookieUltravioleta();

  const enableTravelInsurance = useExperiment(
    EXPERIMENTS.NUBANK_TRAVEL_INSURANCE
  );

  const isDomesticToInternational = isFlightDomesticToInternational(
    tripDetails,
    brand?.preferredCountryCode
  );

  return isUltravioletaUser &&
    enableTravelInsurance &&
    isDomesticToInternational ? (
    <>
      <Divider className="insurance-divider" />
      <div className="insurance-info">
        <InsuranceActive className="icon" />
        <div className="content">
          <h5 className="subtitle">
            {t("ultravioletaTravelInsurance.noAdditionalCostBenefit")}
          </h5>
          <h4 className="title">{t("ultravioletaTravelInsurance.title")}</h4>
          <p className="description">
            {isOneWay
              ? t("ultravioletaTravelInsurance.oneWayCoverage")
              : t("ultravioletaTravelInsurance.roundTripCoverage")}
          </p>
          <p className="description last">
            {t("ultravioletaTravelInsurance.faqText")}
          </p>
        </div>
      </div>
    </>
  ) : null;
};

export default FlightShopReviewInsuranceSection;
