import { useI18nContext } from "@hopper-b2b/i18n";
import DisruptionExerciseLearnMoreContent, {
  DisruptionExerciseLearnMoreType,
} from "../DisruptionExerciseLearnMoreContent/DisruptionExerciseLearnMoreContent";
import DisruptionExerciseButton, {
  DisruptionExerciseButtonType,
} from "../DisruptionExerciseButton/DisruptionExerciseButton";
import defaultStyles from "./DisruptionExerciseMoreInfo.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";

import { DisruptionExercisePopup } from "../DisruptionExercisePopup";
import { useDeviceType } from "@b2bportal/core-utilities";
export enum DisruptionExerciseMoreInfoType {
  Baggage = "baggage",
  Return = "return",
  MoreHelp = "moreHelp",
}

interface DisruptionExerciseMoreInfoProps {
  onSupportClick?: () => void;
  handleRefundClick?: () => void;
  type: DisruptionExerciseMoreInfoType;
  open: boolean;
  onClose?: () => void;
  rebookingLimit?: string;
  disruptionProductName?: string;
  termsLink: string;
  delayHours: string;
  numberOfHours: string;
}

export const DisruptionExerciseMoreInfo = (
  props: DisruptionExerciseMoreInfoProps
) => {
  const { t } = useI18nContext();
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseMoreInfo;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { isDesktopAndUp } = useDeviceType();

  const title = (type: DisruptionExerciseMoreInfoType) => {
    switch (type) {
      case DisruptionExerciseMoreInfoType.Baggage:
        return t(
          "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBaggage.title"
        );
      case DisruptionExerciseMoreInfoType.Return:
        return t(
          "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBookReturn.title"
        );
      case DisruptionExerciseMoreInfoType.MoreHelp:
        return t(
          "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.moreHelpPopup.title"
        );
    }
  };

  const subtitle = (type: DisruptionExerciseMoreInfoType) => {
    switch (type) {
      case DisruptionExerciseMoreInfoType.Baggage:
        return t(
          "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBaggage.subtitle"
        );
      case DisruptionExerciseMoreInfoType.Return:
        return t(
          "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.learnMoreBookReturn.subtitle"
        );
      case DisruptionExerciseMoreInfoType.MoreHelp:
        return t(
          "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.moreHelpPopup.subtitle"
        );
    }
  };

  const content = (type: DisruptionExerciseMoreInfoType) => {
    switch (type) {
      case DisruptionExerciseMoreInfoType.Baggage:
        return (
          <div>
            <DisruptionExerciseLearnMoreContent
              learnMoreType={DisruptionExerciseLearnMoreType.Baggage}
              termsLink={props.termsLink}
              delayHours={props.delayHours}
              numberOfHours={props.numberOfHours}
            ></DisruptionExerciseLearnMoreContent>
          </div>
        );
      case DisruptionExerciseMoreInfoType.Return:
        return (
          <div>
            <DisruptionExerciseLearnMoreContent
              learnMoreType={DisruptionExerciseLearnMoreType.BookReturn}
              maxPerPax={props.rebookingLimit}
              productName={props.disruptionProductName}
              termsLink={props.termsLink}
              delayHours={props.delayHours}
              numberOfHours={props.numberOfHours}
            ></DisruptionExerciseLearnMoreContent>
          </div>
        );
      case DisruptionExerciseMoreInfoType.MoreHelp:
        return (
          <div
            className={cn("disruptionExerciseActionsRoot", {
              mobile: !isDesktopAndUp,
            })}
          >
            {props.onSupportClick && (
              <DisruptionExerciseButton
                title={t(
                  "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.moreHelpPopup.contactSupportButton"
                )}
                onClick={props.onSupportClick}
                disabled={false}
              ></DisruptionExerciseButton>
            )}
            <br></br>
            {props.handleRefundClick && (
              <DisruptionExerciseButton
                title={t(
                  "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.moreHelpPopup.getRefundButton"
                )}
                onClick={props.handleRefundClick}
                disabled={false}
                type={DisruptionExerciseButtonType.Secondary}
              ></DisruptionExerciseButton>
            )}
          </div>
        );
    }
  };

  const width = (type: DisruptionExerciseMoreInfoType) => {
    switch (type) {
      case DisruptionExerciseMoreInfoType.Baggage:
        return "634px";
      case DisruptionExerciseMoreInfoType.Return:
        return "634px";
      case DisruptionExerciseMoreInfoType.MoreHelp:
        return "552px";
    }
  };

  const headerImage = (type: DisruptionExerciseMoreInfoType) => {
    switch (type) {
      case DisruptionExerciseMoreInfoType.Baggage:
        return IconNameEnum.baggageChecked;
      case DisruptionExerciseMoreInfoType.Return:
        return IconNameEnum.flightOutline;
      case DisruptionExerciseMoreInfoType.MoreHelp:
        return IconNameEnum.tenantLogo;
    }
  };

  const hideFooter = props.type === DisruptionExerciseMoreInfoType.MoreHelp;

  return (
    <DisruptionExercisePopup
      open={props.open}
      onClose={props.onClose}
      title={title(props.type)}
      subtitle={subtitle(props.type)}
      content={content(props.type)}
      headerImage={headerImage(props.type)}
      width={width(props.type)}
      hideFooter={hideFooter}
    ></DisruptionExercisePopup>
  );
};

export default DisruptionExerciseMoreInfo;
