import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  airDisruptionApi,
  DisruptionRebookPlanRequest,
} from "@b2bportal/air-disruption-api";
import {
  DisruptionRebookPlanResponse,
  DisruptionRebookPlanResponseEnum,
} from "@b2bportal/air-disruption-api/lib/api";

export const disruptionRebookPlan = createAsyncThunk<
  DisruptionRebookPlanResponse | undefined,
  DisruptionRebookPlanRequest
>(
  "disruption/exercise/rebook/plan",
  async (request: DisruptionRebookPlanRequest, thunkAPI) => {
    try {
      const res = await airDisruptionApi(
        axios
      ).apiV0DisruptionExerciseRebookPlanPost(request);
      if (
        res.data.DisruptionRebookPlanResponse ===
        DisruptionRebookPlanResponseEnum.RebookPlanSuccess
      ) {
        return res.data;
      } else {
        throw new Error("disruptionRebookPlan: " + res.data.reason);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
