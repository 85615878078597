import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import {
  DisruptionExerciseButton,
  DisruptionExerciseButtonType,
} from "@b2bportal/core-disruption";
import defaultStyles from "./DisruptionExerciseNoFlightsAvailable.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { Icon } from "@components/ui";
export interface DisruptionExerciseNoFlightsAvailableProps {
  headerImage?: string;
  onSupportClick: () => void;
  onRefundClick: () => void;
}

export const DisruptionExerciseNoFlightsAvailable = (
  props: DisruptionExerciseNoFlightsAvailableProps
) => {
  const { t } = useI18nContext();
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseNoFlightsAvailable;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const TRANS_PATH = "core-disruption.disruptionUniversalExercise.rebook";

  return (
    <div className={block}>
      <div className={cn("header-image-container")}>
        <Icon iconName={IconNameEnum.tenantLogo} />
      </div>
      <div className={cn("title")}>
        {t(`${TRANS_PATH}.flightShopPage.noFlightsTitle`)}
      </div>
      <div className={cn("subtitle")}>
        {t(`${TRANS_PATH}.flightShopPage.noFlightsSubtitle`)}
      </div>
      <div className={clsx(cn("disruptionExerciseActionsRoot"), cn("center"))}>
        <DisruptionExerciseButton
          title={t(
            `${TRANS_PATH}.rebookOnboardingPage.moreHelpPopup.contactSupportButton`
          )}
          onClick={props.onSupportClick}
          disabled={false}
        ></DisruptionExerciseButton>
        <br></br>
        <DisruptionExerciseButton
          title={t(
            `${TRANS_PATH}.rebookOnboardingPage.moreHelpPopup.getRefundButton`
          )}
          onClick={props.onRefundClick}
          disabled={false}
          type={DisruptionExerciseButtonType.Secondary}
        ></DisruptionExerciseButton>
      </div>
    </div>
  );
};

export default DisruptionExerciseNoFlightsAvailable;
