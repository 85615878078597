import { Box, Slider, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ChangeEvent, useEffect, useState } from "react";
import { useI18nCurrency } from "@hopper-b2b/i18n";
import { GenericDropdown } from "../GenericDropdown";
import "./styles.scss";

export interface ISliderDropdownProps {
  dropdownLabel?: string;
  maxValue: number;
  value: number;
  setValue: (value: number) => void;
  sliderLabelFormat?: (value: number) => string;
  sliderHeader?: string;
  minValue?: number;
  showDropdownContentOnly?: boolean;
  className?: string;
  popoverClassName?: string;
  showPriceStationary?: boolean;
}

export const SliderDropdown = ({
  dropdownLabel,
  sliderHeader,
  minValue = 0,
  maxValue,
  setValue,
  value = 0,
  sliderLabelFormat,
  showDropdownContentOnly,
  className,
  popoverClassName,
  showPriceStationary = false,
}: ISliderDropdownProps) => {
  const [localValue, setLocalValue] = useState(value);
  const { formatCurrency } = useI18nCurrency();

  const handleChange = (
    _: ChangeEvent<unknown>,
    value: number | number[]
  ): void => {
    if (typeof value == "number") {
      setLocalValue(value);
      setValue(value);
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const renderDropdownContent = () => {
    return (
      <Box className={"slider-dropdown-slider-container"}>
        <Typography className="slider-dropdown-header">
          {sliderHeader}
        </Typography>
        {showPriceStationary ? (
          <p className="slider-dropdown-value-stationary">
            {sliderLabelFormat(localValue)}
          </p>
        ) : null}
        <Slider
          min={minValue}
          max={maxValue}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="on"
          valueLabelFormat={sliderLabelFormat}
          value={localValue}
          onChange={handleChange}
        />
        <div className="price-stationary">
          <p className="left">
            {formatCurrency(minValue, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </p>
          <p className="right">
            {formatCurrency(maxValue, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </p>
        </div>
      </Box>
    );
  };

  return (
    <>
      {!showDropdownContentOnly && (
        <GenericDropdown
          buttonClassName={clsx("b2b-shop-filter", className)}
          popoverClassName={clsx("slider-dropdown-popover", popoverClassName)}
          ariaLabel={dropdownLabel}
          dropdownLabel={dropdownLabel}
          dropdownContent={renderDropdownContent()}
        />
      )}
      {!!showDropdownContentOnly && renderDropdownContent()}
    </>
  );
};
