import { useI18nContext, I18nMarkup, Trans } from "@hopper-b2b/i18n";
import { useState, useCallback, useContext } from "react";
import { FintechBanner } from "../FintechBanner";
import { FintechHalfSheet } from "../FintechHalfSheet";
import BestPriceGuaranteeIcon from "../../assets/client/best-price-guarantee-icon.svg";
import BestPriceGuaranteeIconDarkMode from "../../assets/client/darkMode/best-price-guarantee-icon-light.svg";
import BestPriceGuaranteeIconHalfSheet from "../../assets/client/uv/best-price-guarantee-icon-halfsheet.svg";
import styles from "./styles.module.scss";
import { ButtonWrap, Divider } from "@hopper-b2b/ui";
import { TermsAndConditionsContext } from "../TermsAndConditionsWrapper/component";
import { trackEvent } from "@hopper-b2b/api";
import {
  BestPriceGuaranteeEventProductTypes,
  BestPriceGuaranteeEventProperties,
  BestPriceGuaranteeEvents,
} from "@hopper-b2b/types";
import { Product } from "@b2bportal/purchase-api";
import { useDarkModePreferred } from "../../utils/colors";

export interface BestPriceGuaranteeBannerProps {
  product: Product;
  className?: string;
}
const BestPriceGuaranteeBanner = ({
  product,
  className,
}: BestPriceGuaranteeBannerProps) => {
  const { t } = useI18nContext();
  const { setOpenModal } = useContext(TermsAndConditionsContext);
  const isDarkMode = useDarkModePreferred();

  const [openHalfSheet, setOpenHalfSheet] = useState(false);
  const [openSeeRules, setOpenSeeRules] = useState(false);

  const handleOpenHalfSheet = useCallback(() => {
    trackEvent({
      eventName: BestPriceGuaranteeEvents.viewed_best_price_guarantee,
      properties: {
        [BestPriceGuaranteeEventProperties.product]:
          product === Product.Flight
            ? BestPriceGuaranteeEventProductTypes.Flights
            : BestPriceGuaranteeEventProductTypes.Hotels,
      },
    });
    setOpenHalfSheet(true);
  }, [product]);

  const handleCloseHalfSheet = useCallback(() => {
    setOpenHalfSheet(false);
  }, []);

  const handleCloseSeeRules = useCallback(() => {
    setOpenSeeRules(false);
  }, []);

  return (
    <>
      <FintechBanner
        onClick={handleOpenHalfSheet}
        fintechIcon={
          isDarkMode ? BestPriceGuaranteeIconDarkMode : BestPriceGuaranteeIcon
        }
        hideBrand
        title={
          <I18nMarkup
            className="default-text"
            tKey={"bestPriceGuaranteeTitleBold"}
          />
        }
        className={className}
      />

      <FintechHalfSheet
        isOpen={openHalfSheet}
        onClose={handleCloseHalfSheet}
        icon={BestPriceGuaranteeIconHalfSheet}
        title={t("bestPriceGuaranteeTitle")}
        description={
          <div>
            {t("bestPriceGuaranteeDescription")}{" "}
            <ButtonWrap
              className="nubank-terms-container inline"
              onClick={() => setOpenSeeRules(true)}
            >
              <p>{t("seeRules")}</p>
            </ButtonWrap>
          </div>
        }
        additionalInfo={
          <>
            <p className={styles.submitClaimTitle}>
              {t("bestPriceGuaranteeClaimTitle")}
            </p>
            <p className={styles.submitClaimDescription}>
              {t("bestPriceGuaranteeClaimDescription")}
            </p>
            <Divider />
          </>
        }
      />
      <FintechHalfSheet
        isOpen={openSeeRules}
        onClose={handleCloseSeeRules}
        title={t("bestPriceGuaranteeFaq.title")}
        description={
          <div className="faq">
            {t("bestPriceGuaranteeFaq.introContent")}
            <ul>
              <li>{t("bestPriceGuaranteeFaq.first")}</li>
              <li>{t("bestPriceGuaranteeFaq.second")}</li>
              <li>{t("bestPriceGuaranteeFaq.third")}</li>
              <li>{t("bestPriceGuaranteeFaq.fourth")}</li>
              <li>{t("bestPriceGuaranteeFaq.fifth")}</li>
              <li>{t("bestPriceGuaranteeFaq.sixth")}</li>
            </ul>
            <div className="terms-and-conditions">
              <Trans
                i18nKey={"bestPriceGuaranteeFaq.termsAndConditionsEntry"}
                components={[
                  <ButtonWrap
                    className="terms-link"
                    onClick={() => setOpenModal(true)}
                  />,
                ]}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default BestPriceGuaranteeBanner;
