import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { LodgingSearchRoot } from "@hopper-b2b/lodging/src/modules/search/Search";
import { ButtonWrap, SlotProvider } from "@hopper-b2b/ui";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { useExperiment } from "@hopper-b2b/experiments";
import { ExperimentVariant } from "@hopper-b2b/types";

import { ReactComponent as ArrowBack } from "../../assets/client/arrow-back.svg";
import { PATH_HOME } from "../../utils/urlPaths";
import { ReactComponent as BlackFriday } from "../../assets/client/black-friday-black.svg";
import { ReactComponent as BlackFridayWhite } from "../../assets/client/black-friday-white.svg";
import { useDarkModePreferred } from "../../utils/colors";
import { EXPERIMENTS, FEATURE_FLAGS } from "../../App";
import {
  LodgingSearchMobileGuestModal,
  HotelSearchAutocomplete,
} from "../Slots";
import "./LodgingSearchRoute.scss";
import "../../nubank.hotels.scss";

export const LodgingSearchRoute = () => {
  const navigate = useNavigate();

  const enableLodging: boolean | undefined = useExperiment(
    FEATURE_FLAGS.LODGING,
    ExperimentVariant.Available,
    true
  );

  const showMapAsDefault: boolean | undefined = useExperiment(
    EXPERIMENTS.RENDER_HOTEL_MAP_AS_DEFAULT,
    ExperimentVariant.Available,
    true
  );

  useEffect(() => {
    if (enableLodging === false) {
      navigate(PATH_HOME, { replace: true });
    }
  }, [enableLodging, navigate]);

  const onSearch = useCallback(
    (path: string) => {
      navigate(`${PATH_HOME}${path}`);
    },
    [navigate]
  );

  return (
    <SlotProvider
      slots={{
        "lodging-search-mobile-guest-modal": LodgingSearchMobileGuestModal,
        "hotel-search-autocomplete": HotelSearchAutocomplete,
      }}
    >
      <div className="nubank lodging-search">
        <LodgingSearchRoot
          onSearch={onSearch}
          CalendarScreenHeader={CalendarScreenHeader}
          LocationAndGuestScreenHeader={LocationAndGuestScreenHeader}
          showMapAsDefault={showMapAsDefault}
        />
      </div>
    </SlotProvider>
  );
};

const LocationAndGuestScreenHeader = ({ onBack }: { onBack: () => void }) => {
  const { t } = useI18nContext();
  const darkModeEnabled = useDarkModePreferred();
  const blackFridayEnabled = useExperiment(EXPERIMENTS.NUBANK_BLACK_FRIDAY);
  return (
    <>
      <div className="lodging-search-header">
        <ButtonWrap className="back-button" onClick={onBack}>
          <ArrowBack className="arrow-back-icon" />
        </ButtonWrap>
        <h1>{t("hotels")}</h1>
      </div>
      {/* Black Friday Banner, Remove after 11/12/2024 */}
      {blackFridayEnabled ? (
        <div className="black-friday-banner">
          {darkModeEnabled ? (
            <BlackFridayWhite className="icon" />
          ) : (
            <BlackFriday className="icon" />
          )}
          <I18nMarkup
            className="default-text"
            tKey={"blackFriday.description"}
          />
        </div>
      ) : null}
    </>
  );
};

const CalendarScreenHeader = ({ onBack }: { onBack: () => void }) => {
  const { t } = useI18nContext();
  return (
    <div className="lodging-search-header">
      <ButtonWrap className="back-button" onClick={onBack}>
        <ArrowBack className="arrow-back-icon" />
      </ButtonWrap>
      <h1>{t("mobileSearchChooseDate")}</h1>
    </div>
  );
};

export default LodgingSearchRoute;
