import { useDispatch } from "react-redux";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  Airport,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import {
  Fare,
  Flights,
  FlightsFareSlice,
  Slice,
  Trip,
} from "@b2bportal/air-shopping-api";
import clsx from "clsx";
import {
  DisruptionRebookShopRequest,
  DisruptionRebookShopResponseEnum,
  TripDetailsV2,
  TripSlice,
} from "@b2bportal/air-disruption-api";
import { useDeviceType } from "@b2bportal/core-utilities";
import dayjs from "dayjs";
import {
  AirlineCode,
  AppDispatch,
  CoreDisruptionComponent,
  ITimeRange,
} from "@b2bportal/core-types";
import {
  DisruptionExerciseProgress,
  resetDisruptionExerciseState,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import {
  DisruptionExerciseContainer,
  DisruptionExerciseFooter,
  DisruptionExercisePage,
  DisruptionExercisePageContent,
} from "@b2bportal/core-disruption";
import { DisruptionExercisePageWidth } from "../../components/DisruptionExercisePage/DisruptionExercisePage";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { Dialog, DialogContent, Icon } from "@b2bportal/core-ui";
import { DisruptionExerciseFlightInfoRow } from "../../components/DisruptionExerciseFlightInfoRow";
import defaultStyles from "./DisruptionExerciseRebookFlightShopPage.module.scss";
import { disruptionRebookShop } from "../../../../features/exercise";
import { unwrapResult } from "@reduxjs/toolkit";
import { DisruptionExerciseShopFilter } from "../../components/DisruptionExerciseShopFilter";
import { RebookAirlineOptions } from "../../components/DisruptionExerciseShopFilter/DisruptionExerciseShopFilter";
import { DisruptionExerciseNoFlightsAvailable } from "../../components/DisruptionExerciseNoFlightsAvailable";
import { DisruptionExerciseFlightCard } from "../../components/DisruptionExerciseFlightCard";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { removeTimezone } from "@hopper-b2b/utilities";

export interface DisruptionExerciseRebookFlightShopPageProps {
  disruptedFlight: BookedFlightItineraryWithDepartureTime;
  contractId: string;
  origin: string;
  destination: string;
  departure: string;
  airports: { [key: string]: Airport };
  setShoppedFlight: (flightInfo: {
    selectedFare: Fare;
    selectedTrip: Trip;
    selectedTripSlice: Slice;
    selectedFareSlice: FlightsFareSlice;
    selectedTripDetails?: TripDetailsV2;
  }) => void;
  bookingReturn: boolean;
  termsLink: string;
  delayHours: string;
  hoursString: string;
  onSupportClick: () => void;
  handleRefundClick: () => void;
}

const config = {
  INITIAL_RESULT_SET_SIZE: 20,
  SHOW_MORE_NUM: 20,
  DESKTOP_OFFSET_SCROLL: 150,
  MOBILE_OFFSET_SCROLL: 150,
};

export const DisruptionExerciseRebookFlightShopPage = ({
  disruptedFlight,
  contractId,
  origin,
  destination,
  departure,
  airports,
  setShoppedFlight,
  bookingReturn,
  termsLink,
  delayHours,
  hoursString,
  onSupportClick,
  handleRefundClick,
}: DisruptionExerciseRebookFlightShopPageProps) => {
  const { t } = useI18nContext();
  const { isDesktopAndUp } = useDeviceType();
  const dispatch = useDispatch<AppDispatch>();
  const TRANS_PATH =
    "core-disruption.disruptionUniversalExercise.rebook.flightShopPage";
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseRebookFlightShopPage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const [flights, setFlights] = useState<Flights>();
  const [rebookShopError, setRebookShopError] = useState<boolean>(false);
  const today = dayjs(departure);
  const tomorrow = today.add(1, "day");
  const [showTodayFlights, setShowTodayFlights] = useState<boolean>(true);
  const fareName =
    disruptedFlight.bookedItinerary.travelItinerary.slices[0].segments[0]
      .cabinClassName;
  const numberOfTravelers =
    disruptedFlight.bookedItinerary.passengers.alone.length +
    disruptedFlight.bookedItinerary.passengers.withLapInfants.length;
  const [selectedFlight, setSelectedFlight] = useState<{
    fare: Fare | undefined;
    trip: Trip | undefined;
    tripSlice: Slice | undefined;
    fareSlice: FlightsFareSlice | undefined;
    tripDetails: TripDetailsV2 | undefined;
  }>({
    fare: undefined,
    trip: undefined,
    tripSlice: undefined,
    fareSlice: undefined,
    tripDetails: undefined,
  });
  const [openFlightDetailModal, setOpenFlightDetailModal] =
    useState<boolean>(false);
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hideFloatingButton, setHideFloatingButton] = useState<boolean>(true);
  const [selectedStops, setSelectedStops] = useState<Array<number>>([]);
  const [selectedAirline, setSelectedAirlines] = useState<Array<string>>([]);
  const [selectedFlightDuration, setSelectedFlightDuration] = useState<
    number | null
  >(null);
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false);
  const refs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);
  const [expandedFlightCard, setExpandedFlightCard] = useState<string>();

  const TIME_RANGE_MAX = 1439;
  const initialTimeRange: ITimeRange = {
    min: 0,
    // note: time measured in minutes
    max: TIME_RANGE_MAX,
  };

  const [departureTimeRange, setDepartureTimeRange] =
    useState<ITimeRange>(initialTimeRange);
  const [arrivalTimeRange, setArrivalTimeRange] =
    useState<ITimeRange>(initialTimeRange);
  const [layoverDuration, setLayoverDuration] = useState<ITimeRange>({
    min: 0,
    max: 24,
  });

  const getCityName = (code?: string) => {
    return code ? airports[code]?.cityName || "" : "";
  };

  const handleGoBack = () => {
    if (bookingReturn) {
      dispatch(
        setDisruptionExerciseProgress(DisruptionExerciseProgress.NotStarted)
      );
    } else {
      dispatch(
        setDisruptionExerciseProgress(DisruptionExerciseProgress.RebookReview)
      );
    }
  };

  const handleDateChange = useCallback((isToday: boolean) => {
    setShowTodayFlights(isToday);
    setSelectedIndex(null);
  }, []);

  const handleAirlineSelection = useCallback(
    (selected: boolean, airline: RebookAirlineOptions) => {
      if (selected) {
        const newArray = selectedAirline.slice();
        newArray.push(airline.value);
        setSelectedAirlines(newArray);
      } else {
        const newArray = selectedAirline.filter((element) => {
          return element !== airline.value;
        });
        setSelectedAirlines(newArray);
      }
      handleDateChange(showTodayFlights);
    },
    [selectedAirline, handleDateChange, showTodayFlights]
  );

  const handleStopsSelection = useCallback(
    (selected: boolean, numberOfStops: number) => {
      if (selected) {
        const newArray = selectedStops.slice();
        newArray.push(numberOfStops);
        setSelectedStops(newArray);
      } else {
        const newArray = selectedStops.filter((element) => {
          return element !== numberOfStops;
        });
        setSelectedStops(newArray);
      }
      handleDateChange(showTodayFlights);
    },
    [selectedStops, handleDateChange, showTodayFlights]
  );

  const handleFlightDurationChange = useCallback(
    (maximumDuration: number) => {
      setSelectedFlightDuration(maximumDuration);
    },
    [setSelectedFlightDuration]
  );

  const handleDepartureTimeChange = useCallback((min: number, max: number) => {
    setDepartureTimeRange({ min: min, max: max });
  }, []);

  const handleArrivalTimeChange = useCallback((min: number, max: number) => {
    setArrivalTimeRange({ min: min, max: max });
  }, []);

  const handleLayoverDurationChange = useCallback(
    (min: number, max: number) => {
      setLayoverDuration({ min: min, max: max });
    },
    []
  );

  const handleDismissMobileFilter = useCallback(() => {
    setMobileFilterOpen(false);
  }, []);

  const handleOpenMobileFilter = useCallback(() => {
    if (openFlightDetailModal) {
      setMobileFilterOpen(false);
    } else {
      setMobileFilterOpen(true);
    }
  }, [openFlightDetailModal]);

  const airlinesOptions = () => {
    if (flights) {
      const airlines = new Set<AirlineCode>();
      const allAirlines: RebookAirlineOptions[] = [];
      flights.outbound.forEach((flight) => {
        const flightSlice = flights.slices[flight.slice];
        const airlineCode = flightSlice?.marketingAirline;
        if (!airlines.has(airlineCode)) {
          allAirlines.push({
            value: airlineCode,
            label: flights?.airlines[airlineCode]?.displayName || "",
          });
        }
        airlines.add(airlineCode);
      });
      return allAirlines;
    }
  };

  const stopsOptions = () => {
    if (flights) {
      const stops = new Set<number>();
      const allStops: number[] = [];

      flights.outbound.forEach((flight) => {
        const flightSlice = flights.slices[flight.slice];
        const stopCount = flightSlice.segments.length - 1;
        if (!stops.has(stopCount)) {
          allStops.push(stopCount);
        }
        stops.add(stopCount);
      });
      return allStops.sort((elm1, elm2) => elm1 - elm2);
    }
  };

  const flightDurationRange = () => {
    if (flights) {
      const durations = new Set<number>();
      const allDurations: number[] = [];

      flights.outbound.forEach((flight) => {
        const flightSlice = flights.slices[flight.slice];
        const durationInHours = Math.round(
          flightSlice.totalDurationMinutes / 60
        );
        if (!durations.has(durationInHours)) {
          allDurations.push(durationInHours);
        }
        durations.add(durationInHours);
      });

      return {
        min: Math.min(...allDurations),
        // note: time measured in minutes
        max: Math.max(...allDurations),
      };
    }
  };

  const getLayoverDuration = (flight: Slice) => {
    if (flight.segments.length > 1) {
      return flight.segments
        .map((segment) => {
          return segment.stopoverDurationMinutes
            ? segment.stopoverDurationMinutes
            : 0;
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    } else {
      return 0;
    }
  };

  const getLayoverDurationRange = () => {
    if (flights) {
      const durations = new Set<number>();
      const allDurations: number[] = [];

      flights.outbound.forEach((flight) => {
        const duration = getLayoverDuration(flights.slices[flight.slice]);
        const durationInHours = Math.round(duration / 60);
        if (!durations.has(durationInHours)) {
          allDurations.push(durationInHours);
        }
        durations.add(durationInHours);
      });

      return {
        min: Math.min(...allDurations),
        max: Math.max(...allDurations),
      };
    }
  };

  const fetchDisruptionRebookShop = useCallback(() => {
    if (contractId && origin && destination) {
      setLoading(true);
      const req: DisruptionRebookShopRequest = {
        contractId,
        origin,
        destination,
      };
      dispatch(disruptionRebookShop(req))
        .then((response) => {
          const data = unwrapResult(response);
          setLoading(false);
          if (
            data &&
            data.DisruptionRebookShopResponse ===
              DisruptionRebookShopResponseEnum.RebookShopSuccess
          ) {
            setFlights(data.flights);
          } else {
            setRebookShopError(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          setRebookShopError(true);
        });
    }
  }, [
    setRebookShopError,
    setFlights,
    setLoading,
    contractId,
    origin,
    destination,
    dispatch,
  ]);

  const performTimeRangeFilter = (
    flight: Slice,
    departureTime: ITimeRange,
    arrivalTime: ITimeRange
  ) => {
    const flightDeparture = dayjs(removeTimezone(flight.departure));
    const flightArrival = dayjs(removeTimezone(flight.arrival));

    const flightDepartureMinutes =
      flightDeparture.hour() * 60 + flightDeparture.minute();
    const flightArrivalMinutes =
      flightArrival.hour() * 60 + flightArrival.minute();

    const validDeparture =
      departureTime.max >= flightDepartureMinutes &&
      flightDepartureMinutes >= departureTime.min;
    const validArrival =
      arrivalTime.max >= flightArrivalMinutes &&
      flightArrivalMinutes >= arrivalTime.min;

    return validDeparture && validArrival;
  };

  const performLayoverTimeRangeFilter = useCallback(
    (flight: Slice, layoverRange: ITimeRange) => {
      if (flight.segments.length > 1) {
        const layoverInMinutes = getLayoverDuration(flight);
        const minRangeInMinutes = layoverRange.min * 60;
        const maxRangeInMinutes = layoverRange.max * 60;

        const validLayover =
          maxRangeInMinutes >= layoverInMinutes &&
          layoverInMinutes >= minRangeInMinutes;

        return validLayover;
      } else {
        return true;
      }
    },
    []
  );

  useEffect(() => {
    fetchDisruptionRebookShop();
  }, [fetchDisruptionRebookShop]);

  const flightsToShow = useMemo(() => {
    if (flights) {
      const slices = flights?.outbound;
      return slices
        .filter((flight) => {
          const slice = flights?.slices[flight.slice];
          const departure = dayjs(slice.departure);

          // day filter
          const dayCondition = departure.isSame(
            showTodayFlights ? today : tomorrow,
            "day"
          );

          // stops filter
          const stops = slice.segments.length - 1;
          const stopsCondition =
            selectedStops.length > 0 ? selectedStops.includes(stops) : true;

          // airline filter
          const airline = slice.marketingAirline;
          const airlineCondition =
            selectedAirline.length > 0
              ? selectedAirline.includes(airline)
              : true;

          // departure/arrival time filter
          const timeFilterCondition = performTimeRangeFilter(
            slice,
            departureTimeRange,
            arrivalTimeRange
          );

          // flight duration
          const flightDuration = Math.round(slice.totalDurationMinutes / 60);
          const flightDurationCondition = selectedFlightDuration
            ? flightDuration <= selectedFlightDuration
            : true;

          // layover duration
          const layoverDurationCondition = performLayoverTimeRangeFilter(
            slice,
            layoverDuration
          );

          return (
            dayCondition &&
            stopsCondition &&
            airlineCondition &&
            timeFilterCondition &&
            flightDurationCondition &&
            layoverDurationCondition
          );
        })
        .sort(
          (flightA, flightB) =>
            flights.slices[flightA.slice].totalDurationMinutes -
            flights.slices[flightB.slice].totalDurationMinutes
        );
    } else {
      return [];
    }
  }, [
    selectedAirline,
    flights,
    selectedStops,
    showTodayFlights,
    today,
    tomorrow,
    departureTimeRange,
    arrivalTimeRange,
    selectedFlightDuration,
    performLayoverTimeRangeFilter,
    layoverDuration,
  ]);

  const onContinue = useCallback(() => {
    dispatch(
      setDisruptionExerciseProgress(
        DisruptionExerciseProgress.RebookConfirmation
      )
    );
  }, [dispatch]);

  const onFlightSelect = useCallback(
    (props: {
      fareSliceId: string;
      fareId: string;
      tripId: string;
      tripDetails?: TripDetailsV2;
    }) => {
      if (flights) {
        const fareSlice = flights.fareSlices[props.fareSliceId];
        setSelectedFlight({
          fare: flights.fares[props.fareId],
          trip: flights.trips[props.tripId],
          tripSlice: flights.slices[flights.trips[props.tripId].outbound],
          fareSlice,
          tripDetails: props.tripDetails,
        });
        setShoppedFlight({
          selectedFare: flights.fares[props.fareId],
          selectedTrip: flights.trips[props.tripId],
          selectedTripSlice:
            flights.slices[flights.trips[props.tripId].outbound],
          selectedFareSlice: fareSlice,
          selectedTripDetails: props.tripDetails,
        });

        !isDesktopAndUp ? setHideFloatingButton(false) : onContinue();
      }
    },
    [flights, setShoppedFlight, setSelectedFlight, onContinue, isDesktopAndUp]
  );

  const todayTitle = !isDesktopAndUp
    ? today.format("ddd, MMM D")
    : t(`${TRANS_PATH}.todayButton`, {
        date: today.format("ddd, MMM D"),
      });

  const tomorrowTitle = !isDesktopAndUp
    ? tomorrow.format("ddd, MMM D")
    : t(`${TRANS_PATH}.tomorrowButton`, {
        date: tomorrow.format("ddd, MMM D"),
      });

  const dateButtons = flights?.outbound && (
    <div className={cn("disruptionButtonsDates")}>
      <button
        className={clsx(
          cn("buttonMobile"),
          cn("button"),
          cn("buttonToday"),
          showTodayFlights ? cn("selected") : cn("notSelected")
        )}
        onClick={() => handleDateChange(true)}
      >
        {todayTitle}
      </button>
      <button
        className={clsx(
          cn("buttonMobile"),
          cn("button"),
          cn("buttonTomorrow"),
          showTodayFlights ? cn("notSelected") : cn("selected")
        )}
        onClick={() => handleDateChange(false)}
      >
        {tomorrowTitle}
      </button>
    </div>
  );

  const scrollToView = (ref: React.RefObject<HTMLDivElement>) => {
    setTimeout(() => {
      if (ref.current) {
        const OFFSET = !isDesktopAndUp
          ? config.MOBILE_OFFSET_SCROLL
          : config.DESKTOP_OFFSET_SCROLL;
        const cardTop = ref.current?.getBoundingClientRect().top || 0;
        window.scrollBy({
          top: (cardTop as number) - OFFSET,
          behavior: "smooth",
        });
      }
    }, 200);
  };

  const flightCards = flightsToShow?.map((flight, index) => {
    if (flights) {
      const flightData = {
        sliceId: flight.slice,
        fares: flight.fares,
      };
      const ref = refs.current[index];
      const isSelected = expandedFlightCard === flight.slice;
      return (
        <DisruptionExerciseFlightCard
          flight={flightData}
          isSelected={isSelected}
          onClick={() => {
            if (expandedFlightCard === flight.slice) {
              setExpandedFlightCard("");
            } else {
              setExpandedFlightCard(flight.slice);
              scrollToView(ref);
            }
          }}
          sliceIndex={0}
          onFareSelect={(params: {
            sliceId: string;
            fareId: string;
            tripId: string;
          }) => {
            onFlightSelect({
              fareSliceId: params.sliceId,
              fareId: params.fareId,
              tripId: params.tripId,
              tripDetails: undefined,
            });
          }}
          goToNextStep={onContinue}
          ref={ref}
          flights={flights}
        />
      );
    }
  });

  const flightRows = (
    <div className={cn("disruptionExerciseSelectFlightContent")}>
      {dateButtons}
      <div className={cn("flight-list")}>{flightCards}</div>
    </div>
  );

  const filtersContent = (
    <DisruptionExerciseShopFilter
      origin={getCityName(origin)}
      destination={getCityName(destination)}
      stopsOptions={stopsOptions() ?? []}
      selectedStops={selectedStops}
      airlinesOptions={airlinesOptions() ?? []}
      selectedAirlines={selectedAirline}
      handleAirlineSelection={handleAirlineSelection}
      handleStopsSelection={handleStopsSelection}
      departureTimeRange={departureTimeRange}
      arrivalTimeRange={arrivalTimeRange}
      handleDepartureTimeChange={handleDepartureTimeChange}
      handleArrivalTimeChange={handleArrivalTimeChange}
      flightDurationRange={
        flightDurationRange() ?? {
          min: 0,
          max: 1,
        }
      }
      handleFlightDurationChange={handleFlightDurationChange}
      selectedFlightDuration={selectedFlightDuration ?? 0}
      layoverDurationRange={
        getLayoverDurationRange() ?? {
          min: 0,
          max: 1,
        }
      }
      handleLayoverDurationChange={handleLayoverDurationChange}
      selectedLayoverDurationRange={layoverDuration}
    />
  );

  const filters = !isDesktopAndUp
    ? flights && (
        <Dialog open={mobileFilterOpen} onClose={handleDismissMobileFilter}>
          <DialogContent>
            {
              <div className={cn("shopMobileFiltersContainer")}>
                {filtersContent}
              </div>
            }
          </DialogContent>
        </Dialog>
      )
    : flights && (
        <div className={cn("desktopFiltersContainer")}>{filtersContent}</div>
      );

  return (
    <DisruptionExercisePage
      className={block}
      pageWidth={DisruptionExercisePageWidth.Large}
      title={t(`${TRANS_PATH}.title`)}
      onBack={handleGoBack}
      openErrorModal={rebookShopError}
      onCloseErrorModal={() => {
        setRebookShopError(false);
        dispatch(resetDisruptionExerciseState());
      }}
      onSupportClick={onSupportClick}
      handleRefundClick={handleRefundClick}
      submitButtonHidden={hideFloatingButton}
      submitButtonAction={onContinue}
      submitButtonTitle={t(
        "core-disruption.disruptionUniversalExercise.continue"
      )}
      isLoading={loading}
      loadingMessage={t(`${TRANS_PATH}.loadingMessage`)}
      rightNavElement={
        !isDesktopAndUp ? (
          flights ? (
            <Icon
              iconName={IconNameEnum.filter}
              className={cn("filterIcon")}
              onClick={handleOpenMobileFilter}
            />
          ) : undefined
        ) : undefined
      }
      delayHours={delayHours}
      numberOfHours={hoursString}
      termsLink={termsLink}
      content={
        <DisruptionExercisePageContent
          topContent={
            <DisruptionExerciseFlightInfoRow
              originCode={origin}
              destinationCode={destination}
              numberOfTravelers={numberOfTravelers}
              date={departure}
              fareName={fareName ?? ""}
              airports={airports}
            />
          }
          leftContent={
            <div className={cn("flightListContainer")}>
              {flightsToShow?.length > 0 ? (
                <DisruptionExerciseContainer
                  content={[
                    flightRows,
                    <DisruptionExerciseFooter
                      onSupportClick={onSupportClick}
                      onRefundClick={handleRefundClick}
                    ></DisruptionExerciseFooter>,
                  ]}
                  key={"disruption-rebook-flight-rows"}
                ></DisruptionExerciseContainer>
              ) : (
                <DisruptionExerciseContainer
                  content={[
                    dateButtons ?? <></>,
                    <DisruptionExerciseNoFlightsAvailable
                      onSupportClick={onSupportClick}
                      onRefundClick={handleRefundClick}
                    ></DisruptionExerciseNoFlightsAvailable>,
                  ]}
                  key={"disruption-rebook-no-flights-available"}
                />
              )}
            </div>
          }
          rightConent={filters}
        />
      }
    ></DisruptionExercisePage>
  );
};

export default DisruptionExerciseRebookFlightShopPage;
