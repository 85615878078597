import { useCallback, useState } from "react";
import defaultStyles from "./DisruptionExpandableContainer.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import React from "react";
import { Icon } from "@b2bportal/core-ui";

export interface DisruptionExpandableContainerProps {
  title: JSX.Element;
  content: JSX.Element;
}

export const DisruptionExpandableContainer = (
  props: DisruptionExpandableContainerProps
) => {
  const [expanded, setExpanded] = useState(false);
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExpandableContainer;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const onExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [setExpanded, expanded]);

  const header = (
    <div
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        onExpand();
      }}
      className={cn("expandableHeaderContainer")}
    >
      <div className={cn("titleContainer")}>{props.title}</div>
      <div className={cn("iconContainer")}>
        <Icon
          iconName={
            expanded ? IconNameEnum.chevronUp : IconNameEnum.dropdownArrow
          }
        />
      </div>
    </div>
  );

  const content = expanded ? (
    <>
      {header}
      {props.content}
    </>
  ) : (
    header
  );

  return (
    <div className={cn("expandableContainerWrapper")}>
      {content}
      <hr></hr>
    </div>
  );
};

export default DisruptionExpandableContainer;
