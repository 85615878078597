import { useI18nContext } from "@hopper-b2b/i18n";
import { useDispatch, useSelector } from "react-redux";
import defaultStyles from "./styles.module.scss";
import {
  DisruptionRefundPlanRequest,
  PaymentOpaqueValue,
} from "@b2bportal/air-disruption-api";
import { useDeviceType } from "@b2bportal/core-utilities";
import {
  BookedFlightItineraryWithDepartureTime,
  DisruptionProtectionDisruptionProtectionPolicyDetails,
} from "@b2bportal/air-booking-api";
import { useState, useCallback } from "react";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import { AppDispatch, CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import { getDisruptionRefundPlan } from "../../../../features/exercise/thunks/disruptionRefundPlan";
import {
  DisruptionExerciseProgress,
  resetDisruptionExerciseState,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import { getDisruptionExerciseProgress } from "../../../../features/exercise";
import { unwrapResult } from "@reduxjs/toolkit";
import { DisruptionExercisePolicyDetails } from "../../components/DisruptionExercisePolicyDetails";
import { DisruptionExercisePage } from "../../components/DisruptionExercisePage";
import { DisruptionExercisePageWidth } from "../../components/DisruptionExercisePage/DisruptionExercisePage";
import { DisruptionExercisePageContent } from "../../components/DisruptionExercisePageContent";
import { DisruptionExerciseSummaryCard } from "../../components/DisruptionExerciseSummaryCard";
import { DisruptionExerciseFooter } from "../../components/DisruptionExerciseFooter";
import { DisruptionExerciseOptionsCard } from "../../components/DisruptionExerciseOptionsCard";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useEnableDisruptionExerciseRebook } from "@hopper-b2b/utilities";

export interface DisruptionExerciseLandingPageProps {
  disruptionProductName: string;
  disruptionProductLogo: string;
  disruptedFlight: BookedFlightItineraryWithDepartureTime;
  contractId: string;
  setReimbursementTotalAmount: (amount: FiatPrice) => void;
  setRefundBreakdown: (breakdown: Array<PaymentOpaqueValue>) => void;
  setRefundFulfillmentToken: (token: string) => void;
  policyDetails: DisruptionProtectionDisruptionProtectionPolicyDetails;
  onContactSupport: () => void;
  termsLink: string;
  displayExternalLinkAsModal: boolean;
  delayHours: string;
  hoursString: string;
  rebookingLimit?: string;
}

export const DisruptionExerciseLandingPage = ({
  disruptionProductName,
  disruptionProductLogo,
  disruptedFlight,
  contractId,
  setReimbursementTotalAmount,
  setRefundBreakdown,
  setRefundFulfillmentToken,
  policyDetails,
  onContactSupport,
  termsLink,
  displayExternalLinkAsModal,
  delayHours,
  hoursString,
  rebookingLimit,
}: DisruptionExerciseLandingPageProps) => {
  const { t } = useI18nContext();
  const { isDesktopAndUp } = useDeviceType();
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseLandingPage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [_, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const dispatch = useDispatch<AppDispatch>();
  const disruptionExerciseProgress = useSelector(getDisruptionExerciseProgress);
  const [refundPlanError, setRefundPlanError] = useState<string | null>(null);
  const [policyDetailsExpanded, setPolicyDetailsExpanded] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const shouldEnableCta =
    disruptionExerciseProgress === DisruptionExerciseProgress.RebookSelected ||
    disruptionExerciseProgress === DisruptionExerciseProgress.RefundSelected;
  const enableDisruptionUniversalRebook = useEnableDisruptionExerciseRebook();

  const fetchDisruptionRefundPlan = useCallback(() => {
    if (contractId) {
      const req: DisruptionRefundPlanRequest = {
        contractId,
        itineraryId: disruptedFlight.bookedItinerary.id,
      };

      return dispatch(getDisruptionRefundPlan(req))
        .then((payload) => {
          const response = unwrapResult(payload);
          setLoading(false);
          if (
            response?.DisruptionRefundPlanResponse ===
            "DisruptionRefundPlanSuccess"
          ) {
            setReimbursementTotalAmount(response.reimbursementAmount.fiat);
            if (response.refundBreakdown !== undefined) {
              setRefundBreakdown(response.refundBreakdown.refundLineItems);
            }
            if (response.token !== undefined) {
              setRefundFulfillmentToken(response.token.value);
            }

            dispatch(
              setDisruptionExerciseProgress(
                DisruptionExerciseProgress.RefundReview
              )
            );
          } else {
            handleRefundPlanError("DisruptionRefundPlanFailure");
          }
        })
        .catch((e) => {
          setLoading(false);
          handleRefundPlanError(e);
        });
    }
  }, [
    contractId,
    dispatch,
    disruptedFlight,
    setRefundBreakdown,
    setRefundFulfillmentToken,
    setReimbursementTotalAmount,
  ]);

  const handleRefundPlanError = (error: string) => {
    setRefundPlanError(error);
  };

  const handleCta = useCallback(() => {
    if (
      disruptionExerciseProgress === DisruptionExerciseProgress.RebookSelected
    ) {
      if (enableDisruptionUniversalRebook) {
        dispatch(
          setDisruptionExerciseProgress(DisruptionExerciseProgress.RebookReview)
        );
      } else {
        onContactSupport();
      }
    } else if (
      disruptionExerciseProgress === DisruptionExerciseProgress.RefundSelected
    ) {
      if (disruptedFlight.bookedItinerary.isHopperMor) {
        setLoading(true);
        fetchDisruptionRefundPlan();
      } else {
        onContactSupport();
      }
    }
  }, [
    disruptedFlight,
    disruptionExerciseProgress,
    fetchDisruptionRefundPlan,
    onContactSupport,
    dispatch,
    enableDisruptionUniversalRebook,
  ]);

  const onExpand = useCallback(() => {
    setPolicyDetailsExpanded(!policyDetailsExpanded);
  }, [setPolicyDetailsExpanded, policyDetailsExpanded]);

  const handleGoBack = () => {
    dispatch(resetDisruptionExerciseState());
  };

  const policyDetailsContent = (
    <DisruptionExercisePolicyDetails
      policyDetails={policyDetails}
      expanded={policyDetailsExpanded}
      onExpand={onExpand}
      termsLink={termsLink}
      displayExternalLinkAsModal={displayExternalLinkAsModal}
    ></DisruptionExercisePolicyDetails>
  );

  return (
    <DisruptionExercisePage
      className={cn("DisruptionExerciseLandingPage")}
      pageWidth={DisruptionExercisePageWidth.Large}
      title={disruptionProductName}
      disruptionProductLogo={disruptionProductLogo}
      onBack={handleGoBack}
      openErrorModal={refundPlanError !== null}
      onCloseErrorModal={() => {
        setRefundPlanError(null);
      }}
      onSupportClick={onContactSupport}
      submitButtonTitle={t(
        "core-disruption.disruptionUniversalExercise.continue"
      )}
      submitButtonAction={handleCta}
      submitButtonDisabled={!shouldEnableCta}
      isLoading={loading}
      termsLink={termsLink}
      content={
        <DisruptionExercisePageContent
          leftContent={[
            <DisruptionExerciseSummaryCard
              title={t(
                "core-disruption.disruptionUniversalExercise.landingPage.title"
              )}
              description={t(
                "core-disruption.disruptionUniversalExercise.landingPage.subtitleWithRebookingLimit",
                {
                  rebookLimit: rebookingLimit,
                }
              )}
              key={"landing-page-header-content"}
            ></DisruptionExerciseSummaryCard>,
            <DisruptionExerciseOptionsCard
              onSupportClick={onContactSupport}
              key={"landing-page-options"}
            ></DisruptionExerciseOptionsCard>,
          ]}
          rightConent={[
            policyDetailsContent,
            isDesktopAndUp ? (
              <div></div>
            ) : (
              <DisruptionExerciseFooter
                onSupportClick={onContactSupport}
                termsLink={termsLink}
              ></DisruptionExerciseFooter>
            ),
          ]}
        />
      }
      delayHours={delayHours}
      numberOfHours={hoursString}
    ></DisruptionExercisePage>
  );
};

export default DisruptionExerciseLandingPage;
