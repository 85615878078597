import {
  chatApi,
  ChatPropertiesRequest,
  ChatPropertiesResponse,
  ChatPropertiesType,
} from "@b2bportal/chat-api";
import { AxiosResponse } from "axios";

import { axiosInstance } from "../AxiosInterceptor";

export const fetchChatProperties = async (
  chatPropertiesType: ChatPropertiesType,
  productId: string
) => {
  const request: ChatPropertiesRequest = {
    chatPropertiesType,
    productId,
  };

  try {
    const response: AxiosResponse<ChatPropertiesResponse> = await chatApi(
      axiosInstance
    ).apiV0SupportChatPropertiesPut(request);

    return response.data.chatProperties;
  } catch (err) {
    console.error("Error fetching chat properties", { cause: err });
  }
};
export default fetchChatProperties;
