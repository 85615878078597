import { Radio, RadioGroup } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useId, useRef } from "react";
import { RadioOption } from "@hopper-b2b/types";
import { ButtonWrap, GenericDropdown } from "@hopper-b2b/ui";

export interface IRadioOptions {
  buttonClassName?: string;
  popoverClassName?: string;
  dropdownLabel: string;
  options: RadioOption[];
  selected: RadioOption["value"];
  setOption: (option: string) => void;
  radioLabelPlacement?: "start" | "end";
  showDropdownContentOnly?: boolean;
  groupAriaLabel?: string;
  buttonAriaLabel?: string;
  optionProps?: any;
}

export const RadioDropdown = ({
  buttonClassName,
  popoverClassName,
  options,
  setOption,
  selected,
  dropdownLabel,
  showDropdownContentOnly,
  groupAriaLabel,
  buttonAriaLabel,
  optionProps,
}: IRadioOptions) => {
  const handleChange = useCallback(
    (value: string | number) => {
      setOption(value.toString());
    },
    [setOption]
  );

  const radioRef = useRef<HTMLLabelElement>(null);

  const listId = useId();
  const listButtonId = useId();
  const optionId = useId();

  const renderDropdownContent = () => {
    return (
      <RadioGroup
        className={clsx("radio-group", popoverClassName)}
        value={selected}
        id={listId}
        tabIndex={-1}
        role="radiogroup"
        aria-label={groupAriaLabel}
      >
        {options.map((option) => {
          const itemId = option.value + optionId;
          return (
            <ButtonWrap
              className={clsx("card-option", {
                "selected-option": selected === option.value,
              })}
              key={option.value}
              id={itemId}
              defaultValue={option.value}
              onClick={() => handleChange(option.value)}
              {...optionProps}
            >
              <div id={itemId} className="card-label">
                {option.label}
              </div>
              <Radio
                value={option.value}
                inputProps={{
                  "aria-labelledby": itemId,
                  "aria-selected": selected === option.value,
                }}
                inputRef={selected === option.value ? radioRef : null}
                color="secondary"
              />
            </ButtonWrap>
          );
        })}
      </RadioGroup>
    );
  };

  return (
    <>
      {!showDropdownContentOnly && (
        <GenericDropdown
          buttonId={listButtonId}
          buttonClassName={clsx("b2b-shop-filter", buttonClassName)}
          popoverClassName={clsx("radio-dropdown-popover", popoverClassName)}
          ariaLabel={buttonAriaLabel}
          dropdownLabel={dropdownLabel}
          dropdownContent={renderDropdownContent()}
        />
      )}
      {!!showDropdownContentOnly && renderDropdownContent()}
    </>
  );
};
