import {
  ChangeTypeEnum,
  CoveragePolicyEnum,
  DisruptionOffer,
  DisruptionQuote,
  FiatPrice,
  RedemptionPolicyEnum,
} from "@b2bportal/air-disruption-api";
import {
  FintechProduct,
  FintechProductBullet,
  FintechProductInfoLinksLayout,
  FintechProductInfoLinksPlacement,
  SelectionOfferItemProp,
} from "@b2bportal/core-fintech-ui";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreDisruptionComponent,
  DisruptionOfferDeclined,
  DisruptionOfferQuoteSelected,
  DisruptionOfferQuoteSelectionEnum,
  DisruptionTrackingEvent,
} from "@b2bportal/core-types";
import { convertTimeUnit, TimeUnitEnum } from "@b2bportal/core-utilities";
import { HowItWorksDialog } from "@overrides/disruption";
import { I18nContext, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DisruptionOffersActions } from "../../features/offers/store/slice";
import { DisruptionOffersSelectors } from "../../store";
import defaultStyles from "./DisruptionOfferSection.module.scss";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { trackEvent } from "@hopper-b2b/api";
import React from "react";
import { Dialog, IframeDialogContent } from "@b2bportal/core-ui";

export interface DisruptionOfferComponentProps {
  disruptionOffer: DisruptionOffer;
  onInvalidOffer: () => void;
  optional?: {
    extraBodyLines?: {
      lineType: "disclaimer";
      tKey: string;
      linkUrl?: string;
      components: JSX.Element[];
    }[];
    centerBulletIcons?: boolean;
  };
  optionsSectionUI?: {
    showTitle?: boolean;
  };
  infoLinksUI?: {
    placement: FintechProductInfoLinksPlacement;
    layout: FintechProductInfoLinksLayout;
    suffixIcon?: { iconName: IconNameEnum; className?: string };
  };
  showCloseButtonInDialogs?: boolean;
  bulletLinePrefixIcon?: { iconName: IconNameEnum; className?: string };
  howItWorksDialogBulletLinePrefixIconName?: IconNameEnum;
  radioButtonPosition: "start" | "end";
  /**
   * Whether clicking external links should display the HTML content in <iframe> via Dialog modal, instead of opening the web page in a new tab
   */
  displayExternalLinksAsModal?: boolean;
}

// Selectable disruption offer component
export const DisruptionOfferSection = ({
  disruptionOffer,
  onInvalidOffer,
  optionsSectionUI = {
    showTitle: false,
  },
  infoLinksUI = {
    placement: FintechProductInfoLinksPlacement.BelowOffers,
    layout: FintechProductInfoLinksLayout.Horizontal,
  },
  showCloseButtonInDialogs,
  bulletLinePrefixIcon,
  howItWorksDialogBulletLinePrefixIconName,
  radioButtonPosition,
  displayExternalLinksAsModal,
  optional,
}: DisruptionOfferComponentProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionOfferSection;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const disruptionQuote = useMemo(
    () =>
      disruptionOffer !== undefined && disruptionOffer.quotes !== undefined
        ? disruptionOffer.quotes[0]
        : undefined,
    [disruptionOffer]
  );

  const { t, brand, formatFiatCurrency } = useI18nContext();

  const dispatch = useDispatch();

  const formatCurrency = useCallback(
    (value?: FiatPrice) => {
      return formatFiatCurrency(value, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    },
    [formatFiatCurrency]
  );

  useEffect(() => {
    if (disruptionQuote === undefined) return;

    // For now, we can assume each offer has only one quote for tracking purposes
    trackEvent({
      eventName: DisruptionTrackingEvent.VIEW_DISRUPTION_OFFER,
      properties:
        disruptionOffer.quotes[0]?.trackingProperties.properties ?? [],
      encryptedProperties: [
        disruptionQuote?.trackingProperties.encryptedProperties,
      ].flatMap((encryptedProp) => encryptedProp ?? []),
    });
  }, [disruptionQuote]);

  const selectedOfferQuote = useSelector(
    DisruptionOffersSelectors.getSelectedDisruptionOffer
  );

  const handleSelect = useCallback(
    (selection: DisruptionOfferQuoteSelected | DisruptionOfferDeclined) => {
      dispatch(DisruptionOffersActions.setSelectedDisruptionOffer(selection));
      // For now, we can assume each offer has only one quote for tracking purposes
      trackEvent({
        eventName: DisruptionTrackingEvent.TOGGLE_DISRUPTION_OFFER,
        properties: {
          ...selection.offer.quotes[0]?.trackingProperties.properties,
          disruption_protection_choice:
            selection.selection === DisruptionOfferQuoteSelectionEnum.Selected
              ? 1
              : 0,
          disruption_product_choice: disruptionQuote?.productType,
        },
        encryptedProperties: [
          disruptionQuote?.trackingProperties.encryptedProperties,
        ].flatMap((encryptedProp) => encryptedProp ?? []),
      });
    },
    [dispatch, disruptionQuote]
  );

  const findCoveragesForOffer = (
    offer: DisruptionOffer,
    changeTypes: ChangeTypeEnum[]
  ): DisruptionQuote | undefined => {
    return offer.quotes?.find((_quote) =>
      _quote.coveragePolicies.find(
        (policy) =>
          policy.CoveragePolicy === CoveragePolicyEnum.AirScheduleChange &&
          changeTypes.every((change) =>
            policy.supportedScheduleChanges
              .map((s) => s.ChangeType)
              .includes(change)
          )
      )
    );
  };

  // TODO: remove the static fallback link, and include the link in config of every portal that has disruption integration
  const termsAndConditionsLink =
    brand.urls.disruptionTerms ??
    "https://www.hopper.com/legal/Premium-Disruption-Assistance";

  const benefitsFromAirlineLink =
    brand.urls.benefitsFromAirline ??
    "https://hts.hopper.com/legal/hts-passenger-rights-overview";

  // TODO: simplify/refactor this. I think we might be over-complicating the offer coverages
  const selectionOffers: SelectionOfferItemProp[] = useMemo(() => {
    const items: SelectionOfferItemProp[] = [];

    const fullCoverage = findCoveragesForOffer(disruptionOffer, [
      ChangeTypeEnum.Cancelled,
      ChangeTypeEnum.Delayed,
    ]);

    if (fullCoverage !== undefined) {
      const pricePerPaxLocalized = fullCoverage.pricePerPaxLocalized?.fiat ?? {
        ...fullCoverage.premiumAmountLocalized.fiat,
        value:
          fullCoverage.premiumAmountLocalized.fiat.value / fullCoverage.members,
      };

      items.push({
        title: t("core-disruption.addToTrip"),
        description: (
          <Trans
            i18nKey="core-disruption.costPerTraveler"
            values={{ cost: formatCurrency(pricePerPaxLocalized) }}
            components={[<span className={cn("traveller-cost")} />]}
          />
        ),
        selected:
          selectedOfferQuote?.selection ===
            DisruptionOfferQuoteSelectionEnum.Selected &&
          selectedOfferQuote.quote.quoteId === fullCoverage.quoteId,
        onClick: () =>
          handleSelect({
            offer: disruptionOffer,
            quote: fullCoverage,
            selection: DisruptionOfferQuoteSelectionEnum.Selected,
          }),
        selectedContent: {
          icon: {
            iconName: IconNameEnum.checkFilled,
            className: cn("accepted-icon"),
          },
          children: (
            <span className={cn("selected-content")}>
              <Trans
                i18nKey="core-disruption.addedForPerTraveller"
                values={{
                  cost: formatCurrency(pricePerPaxLocalized),
                }}
                components={[<span className={cn("traveller-cost")} />]}
              />
            </span>
          ),
        },
      });
    }

    const partialCoverage = findCoveragesForOffer(disruptionOffer, [
      ChangeTypeEnum.Delayed,
    ]);

    // Disable partial coverage for now - we pass an array of qoutes (currently only ever one item in array)
    //  and in the past have returned more than one.
    //  Possible in the future that we will return more so building out support
    const supportPartialCoverage = false;

    if (
      supportPartialCoverage &&
      partialCoverage !== undefined &&
      fullCoverage?.quoteId !== partialCoverage.quoteId &&
      fullCoverage != null
    ) {
      const pricePerPaxLocalized = fullCoverage.pricePerPaxLocalized?.fiat ?? {
        ...fullCoverage.premiumAmountLocalized.fiat,
        value:
          fullCoverage.premiumAmountLocalized.fiat.value / fullCoverage.members,
      };

      items.push({
        title: t("flightDelayGuarantee.partialCoverageTitle"),
        description: (
          <Trans i18nKey="core-disruption.partialCoverageDescription" />
        ),
        selected:
          selectedOfferQuote?.selection ===
            DisruptionOfferQuoteSelectionEnum.Selected &&
          selectedOfferQuote.quote.quoteId === partialCoverage.quoteId,
        onClick: () =>
          handleSelect({
            offer: disruptionOffer,
            quote: partialCoverage,
            selection: DisruptionOfferQuoteSelectionEnum.Selected,
          }),
        selectedContent: {
          icon: {
            iconName: IconNameEnum.checkFilled,
            className: cn("accepted-icon"),
          },
          children: (
            <span className={cn("selected-content")}>
              <Trans
                i18nKey="core-disruption.addedForPerTraveller"
                values={{
                  cost: formatCurrency(pricePerPaxLocalized),
                }}
                components={[<span className={cn("traveller-cost")} />]}
              />
            </span>
          ),
        },
      });
    }

    items.push({
      title: t("core-disruption.noThanks"),
      description: <Trans i18nKey="core-disruption.noThanksDescription" />,
      selected:
        selectedOfferQuote?.selection ===
        DisruptionOfferQuoteSelectionEnum.Declined,
      onClick: () =>
        handleSelect({
          offer: disruptionOffer,
          selection: DisruptionOfferQuoteSelectionEnum.Declined,
        }),
      selectedContent: {
        icon: {
          iconName: IconNameEnum.warning,
          className: cn("declined-icon"),
        },
        children: (
          <span className={cn("selected-content")}>
            {t("core-disruption.declined")}
          </span>
        ),
      },
    });
    return items;
  }, [
    t,
    selectedOfferQuote,
    cn,
    disruptionOffer,
    formatCurrency,
    handleSelect,
  ]);

  const delayThreshold: number | undefined = useMemo(() => {
    for (const quote of disruptionOffer.quotes) {
      for (const policy of quote.coveragePolicies) {
        if (policy.CoveragePolicy === CoveragePolicyEnum.AirScheduleChange) {
          for (const supportedScheduleChange of policy.supportedScheduleChanges) {
            if (supportedScheduleChange.ChangeType === ChangeTypeEnum.Delayed) {
              return convertTimeUnit({
                value: supportedScheduleChange.minChangeMinutes,
                from: TimeUnitEnum.MINUTES,
                to: TimeUnitEnum.HOURS,
              }).value;
            }
          }
        }
      }
    }
    return undefined;
  }, [disruptionOffer.quotes]);

  const coverages = useMemo(() => {
    const creditReimbursement = () => {
      for (const quote of disruptionOffer.quotes) {
        for (const policy of quote.redemptionPolicies ?? []) {
          if (
            policy.RedemptionPolicy === RedemptionPolicyEnum.CreditReimbursement
          ) {
            // TODO: remove the temp workaround once backend supports per-pax maxAmount
            const maxPerPaxAmount: FiatPrice = {
              ...policy.maxAmount.fiat,
              value: policy.maxAmount.fiat.value / quote.members,
            };
            return formatCurrency(maxPerPaxAmount);
          }
        }
      }
      return undefined;
    };

    const maxPerPax = () => {
      for (const quote of disruptionOffer.quotes) {
        for (const policy of quote.redemptionPolicies ?? []) {
          if (policy.RedemptionPolicy === RedemptionPolicyEnum.AirRebook) {
            return formatCurrency(policy.maxPerPaxCost?.fiat);
          }
        }
      }
      return undefined;
    };
    const minDelayHoursThreshold = delayThreshold;
    const minDelayHoursThresoldText = t("timeCount.hour", {
      count: delayThreshold,
    });
    const maxAmountRefunded = creditReimbursement();
    const maxAmountCovered = maxPerPax();

    return minDelayHoursThreshold && maxAmountRefunded && maxAmountCovered
      ? {
          minDelayHoursThreshold,
          minDelayHoursThresoldText,
          maxAmountRefunded,
          maxAmountCovered,
        }
      : undefined;
  }, [disruptionOffer.quotes, formatCurrency]);

  const productBody = useMemo(() => {
    if (coverages === undefined || !delayThreshold) return null;

    // This hacky workaround was added for Nubank. We should think about refactoring this when possible
    const optionals =
      optional?.extraBodyLines?.reduce((acc, line) => {
        switch (line.lineType) {
          case "disclaimer":
            acc.push(
              <FintechProductBullet
                className={cn("body-item", ["disclaimer-text"])}
              >
                <Dialog>
                  <Trans
                    i18nKey={line.tKey}
                    values={coverages}
                    components={line.components}
                  />

                  <IframeDialogContent>
                    <iframe src={line.linkUrl} />
                  </IframeDialogContent>
                </Dialog>
              </FintechProductBullet>
            );
        }
        return acc;
      }, [] as Array<JSX.Element>) ?? [];

    const bulletsToRender: JSX.Element[] = [
      <FintechProductBullet className={cn("body-first-item")}>
        {/* TODO: refactor bodyOne copy (currently it's assuming plural and always uses "hours" in copy) */}
        <Trans
          i18nKey="core-disruption.bodyOne"
          values={{ ...coverages, hours: delayThreshold }}
          components={[
            <span className={cn("bullet-bold-text", { subtitle: true })} />,
          ]}
        />
      </FintechProductBullet>,
      ...[
        <Trans
          i18nKey="core-disruption.bodyTwo"
          values={coverages}
          components={[<span className={cn("bullet-bold-text")} />]}
        />,
        <Trans
          i18nKey="core-disruption.bodyThree"
          values={coverages}
          components={[<span className={cn("bullet-bold-text")} />]}
        />,
        <Trans
          i18nKey="core-disruption.bodyFour"
          values={coverages}
          components={[<span className={cn("bullet-bold-text")} />]}
        />,
      ].map((content, index) => (
        <FintechProductBullet
          icon={
            bulletLinePrefixIcon ?? {
              iconName: IconNameEnum.checkBulletPoint,
              className: cn("check-icon"),
            }
          }
          className={cn("body-item", {
            "center-icon": optional?.centerBulletIcons ?? false,
          })}
          key={index}
        >
          {content}
        </FintechProductBullet>
      )),
      ...optionals,
    ];

    return (
      <>
        {bulletsToRender.map((bullet, index) =>
          React.cloneElement(bullet, { key: index })
        )}
      </>
    );
  }, [
    coverages,
    delayThreshold,
    optional?.extraBodyLines,
    optional?.centerBulletIcons,
    cn,
    bulletLinePrefixIcon,
  ]);

  if (!delayThreshold || coverages === undefined) {
    // TODO: handle error logging as it should never reach this point?
    onInvalidOffer();
    return null;
  }

  return (
    <div className={block}>
      <FintechProduct
        title={t("core-disruption.productName")}
        iconName={IconNameEnum.disruptionProtection}
        body={productBody ?? <></>}
        selectionOffers={{
          options: selectionOffers,
        }}
        howItWorksDialog={
          <HowItWorksDialog
            {...coverages}
            viewTermsLink={termsAndConditionsLink}
            benefitsFromAirlineLink={benefitsFromAirlineLink}
            bulletLinePrefixIconName={howItWorksDialogBulletLinePrefixIconName}
            displayExternalLinksAsModal={displayExternalLinksAsModal}
          />
        }
        viewTermsLink={termsAndConditionsLink}
        optionsSectionUI={optionsSectionUI}
        infoLinksUI={{
          ...infoLinksUI,
          displayViewTermsAsModal: displayExternalLinksAsModal,
        }}
        showCloseButtonInDialog={showCloseButtonInDialogs}
        radioButtonPosition={radioButtonPosition}
      />
    </div>
  );
};
