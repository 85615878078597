import { combineReducers, Reducer } from "redux";
import {
  reducer as flightBook,
  initialState as flightBookInitialState,
} from "../modules/book";
import {
  reducer as flightCfar,
  initialState as flightCfarInitialState,
} from "../modules/cfar/reducer";
import {
  reducer as flightChfar,
  initialState as flightChfarInitialState,
} from "../modules/chfar/reducer";
import {
  reducer as flightDisruption,
  initialState as flightDisruptionInitialState,
} from "../modules/disruption/reducer";
import flightExchange, {
  initialState as flightExchangeInitialState,
} from "../modules/exchange/reducer";
import {
  reducer as flightFreeze,
  initialState as flightFreezeInitialState,
} from "../modules/freeze/reducer";
import {
  reducer as flightRewards,
  initialState as flightRewardsInitialState,
} from "../modules/rewards/reducer";
import {
  reducer as flightSearch,
  initialState as flightSearchInitialState,
} from "../modules/search";
import {
  reducer as flightShop,
  initialState as flightShopInitialState,
} from "../modules/shop";
import {
  reducer as flightWallet,
  initialState as flightWalletInitialState,
} from "../modules/wallet";
import {
  apiConfigInitialState,
  getApiConfigReducer as flightApiConfig,
} from "@hopper-b2b/utilities";
import {
  disruptionRootReducer as disruption,
  initialDisruptionState as flightCoreDisruptionInitialState,
} from "@b2bportal/core-disruption";

import { flightApiConfigStoreKey, IStoreState, RESET_STATE } from "./types";

export const appReducer: Reducer = combineReducers({
  flightSearch,
  flightShop,
  flightBook,
  flightRewards,
  flightFreeze,
  flightCfar,
  flightDisruption,
  disruption,
  flightChfar,
  flightApiConfig: flightApiConfig(flightApiConfigStoreKey),
  flightExchange,
  flightWallet,
});

export const rootReducer = (
  state: IStoreState | undefined,
  action: any
): IStoreState => {
  switch (action.type) {
    case RESET_STATE:
      return {
        flightSearch:
          state?.flightSearch ??
          flightSearchInitialState(window?.location.search),
        flightShop: flightShopInitialState,
        flightBook: flightBookInitialState,
        flightRewards: state?.flightRewards ?? flightRewardsInitialState,
        flightFreeze: flightFreezeInitialState,
        flightCfar: flightCfarInitialState,
        flightApiConfig: apiConfigInitialState,
        flightChfar: flightChfarInitialState,
        flightDisruption: flightDisruptionInitialState,
        ...flightCoreDisruptionInitialState,
        flightWallet: flightWalletInitialState,
        flightExchange: flightExchangeInitialState,
      };
    default:
      break;
  }
  return appReducer(state, action);
};
