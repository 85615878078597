import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap } from "@hopper-b2b/ui";
import BackChevron from "../../../assets/client/darkMode/arrow-back-white.svg";
import ListWhite from "../../../assets/client/darkMode/list-white.svg";
import { useRenderHotelMapAsDefaultView } from "@hopper-b2b/utilities";

const LodgingMapBackButton = ({ onClick, className }) => {
  const { t } = useI18nContext();

  const showMapAsDefault = useRenderHotelMapAsDefaultView();

  return (
    <ButtonWrap className={className} onClick={onClick}>
      <img
        src={showMapAsDefault ? ListWhite : BackChevron}
        alt={t("backIconAltText")}
      />
    </ButtonWrap>
  );
};

export default LodgingMapBackButton;
