import { useI18nContext } from "@hopper-b2b/i18n";
import { Airport, FlightItinerarySegment } from "@b2bportal/air-booking-api";
import defaultStyles from "./DisruptionExerciseFlightSliceCard.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import dayjs from "dayjs";
import { DisruptionExerciseSelection } from "@b2bportal/core-disruption";

interface DisruptionExerciseFlightSliceCardProps {
  slice: { segments: Array<FlightItinerarySegment> };
  isSelected: boolean;
  isReturn: boolean;
  onSelect?: () => void;
  airports: { [key: string]: Airport };
}

export const DisruptionExerciseFlightSliceCard = (
  props: DisruptionExerciseFlightSliceCardProps
) => {
  const { t, language } = useI18nContext();
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseFlightSliceCard;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const TRANS_PATH = "core-disruption.disruptionUniversalExercise.rebook";

  const getCityName = (code: string) => {
    return props.airports[code]?.cityName || "";
  };

  const lastSegment = props.slice.segments[props.slice.segments.length - 1];
  const firstSegment = props.slice.segments[0];
  const destinationCode = lastSegment.destination.locationCode;
  const destination = getCityName(destinationCode);
  const date = formatDateTime(
    dayjs(firstSegment.updatedDeparture).toDate(),
    language,
    DateTimeFormatStyle.MediumDate
  );

  const headline = props.isReturn ? (
    <span
      className={cn("regulerText")}
      dangerouslySetInnerHTML={{
        __html: t(`${TRANS_PATH}.returnHeadline`, {
          destination: destination,
          airport: destinationCode,
          date: date,
        }),
      }}
    />
  ) : (
    <span
      className={cn("regulerText")}
      dangerouslySetInnerHTML={{
        __html: t(`${TRANS_PATH}.outboundHeadline`, {
          destination: destination,
          airport: destinationCode,
          date: date,
        }),
      }}
    />
  );

  const time = () => {
    const firstSegmentDepartureTime = formatDateTime(
      dayjs(firstSegment.updatedDeparture).toDate(),
      language,
      DateTimeFormatStyle.ShortTime
    );

    const lastSegmentArrivalTime = formatDateTime(
      dayjs(firstSegment.updatedArrival).toDate(),
      language,
      DateTimeFormatStyle.ShortTime
    );

    const flightTime = t(`${TRANS_PATH}.flightTime`, {
      fromTime: firstSegmentDepartureTime,
      toTime: lastSegmentArrivalTime,
    });

    return (
      <span className={cn("regulerText")}>
        <b>{flightTime}</b>
      </span>
    );
  };

  const duration = () => {
    const hours = dayjs(lastSegment.updatedArrival).diff(
      dayjs(firstSegment.updatedDeparture),
      "hours"
    );
    return (
      <span className={cn("regulerText")}>
        <b>
          {t("core-disruption.disruptionUniversalExercise.hours", {
            numberOfHours: hours,
          })}
        </b>
      </span>
    );
  };

  const stops = () => {
    const numberOfStops = props.slice.segments.length - 1;
    const hasConnections = numberOfStops > 0;
    const stops =
      numberOfStops > 1
        ? t(`${TRANS_PATH}.stops`, {
            stops: numberOfStops,
          })
        : t(`${TRANS_PATH}.stopsSingle`, {
            stops: numberOfStops,
          });

    return hasConnections ? (
      <span className={cn("smallText")}>{stops}</span>
    ) : (
      <span className={cn("smallText")}>{t(`${TRANS_PATH}.direct`)}</span>
    );
  };

  const airline = () => {
    const codes = props.slice.segments
      .map((segment) => {
        return segment.operatingAirline?.code;
      })
      .filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });

    return <span className={cn("smallText")}>{codes}</span>;
  };

  return (
    <DisruptionExerciseSelection
      onSelect={props.onSelect}
      isSelected={props.isSelected}
      hideRadio={false}
      content={
        <div className={cn("disruptionExerciseFlightSliceCardContent")}>
          <div className={cn("topSection")}>{headline}</div>
          <div className={cn("bottomSection")}>
            <div className={cn("column")}>
              {time()}
              {airline()}
            </div>
            <div className={cn("verticalDivider")} />
            <div className={cn("column")}>
              {duration()}
              {stops()}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default DisruptionExerciseFlightSliceCard;
