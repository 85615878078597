import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap } from "@hopper-b2b/ui";
import { DialogTitle, DialogContent, Divider } from "@material-ui/core";

import { ReactComponent as ArrowAction } from "../../assets/client/arrow-action.svg";
import { useCallback } from "react";

// This is a custom contact support modal content, based off of ContactSupportModalContent
// Even though it is "different", the styles and structure are the same
const TravelInsuranceContactSupportModalContent = () => {
  const { t } = useI18nContext();

  // these are phone numbers from Nubank just for insurance
  const whatsAppHandler = useCallback(() => {
    window.location.href = "tel:551142101814";
  }, []);

  const brazilHandler = useCallback(() => {
    window.location.href = "tel:08001045960";
  }, []);

  const latinAmericaHandler = useCallback(() => {
    window.location.href = "tel:551142101814";
  }, []);

  const unitedStatesHandler = useCallback(() => {
    window.location.href = "tel:13054477965";
  }, []);

  const europeHandler = useCallback(() => {
    window.location.href = "tel:34910601933";
  }, []);

  return (
    <div className="contact-support-modal-content-container">
      <DialogTitle className="contact-support-modal-title" disableTypography>
        <h2 className="title">{t("contactSupport")}</h2>
        <p className="subtitle">{t("contactSupportMethod")}</p>
      </DialogTitle>
      <DialogContent className="contact-support-modal-content">
        <ButtonWrap className="support-action-row" onClick={whatsAppHandler}>
          <p className="support-action-row-title">{t("whatsApp")}</p>
          <div className="support-action-row-right">
            <ArrowAction className="icon" />
          </div>
        </ButtonWrap>
        <Divider />
        <ButtonWrap className="support-action-row" onClick={brazilHandler}>
          <p className="support-action-row-title">{t("phone")}</p>
          <div className="support-action-row-right">
            <p>{t("callSupportInBrazil")}</p>
            <ArrowAction className="icon" />
          </div>
        </ButtonWrap>
        <Divider />
        <ButtonWrap
          className="support-action-row"
          onClick={latinAmericaHandler}
        >
          <p className="support-action-row-title">{t("phone")}</p>
          <div className="support-action-row-right">
            <p>{t("latinAmericaAndBrazil")}</p>
            <ArrowAction className="icon" />
          </div>
        </ButtonWrap>
        <Divider />
        <ButtonWrap
          className="support-action-row"
          onClick={unitedStatesHandler}
        >
          <p className="support-action-row-title">{t("phone")}</p>
          <div className="support-action-row-right">
            <p>{t("callFromUnitedStates")}</p>
            <ArrowAction className="icon" />
          </div>
        </ButtonWrap>
        <Divider />
        <ButtonWrap className="support-action-row" onClick={europeHandler}>
          <p className="support-action-row-title">{t("phone")}</p>
          <div className="support-action-row-right">
            <p>{t("callFromEurope")}</p>
            <ArrowAction className="icon" />
          </div>
        </ButtonWrap>
      </DialogContent>
    </div>
  );
};

export default TravelInsuranceContactSupportModalContent;
