import { ISupportContext, SupportContext } from "@hopper-b2b/utilities";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";

import {
  createGeneralSupportConversation,
  createSupportConversationForProduct,
  initializeKustomerChat,
  listenForUnreadCountUpdates,
  loginKustomer,
  openKustomerChat,
  openKustomerConversationById,
  startKustomerChat,
} from "./kustomerChat";

export interface IKustomerConfigurationProps {
  apiKey?: string;
  brandId?: string;
  enabled: boolean;
  hopperUserId?: string;
  kustomerAccessToken?: string;
  tenant: string;
  forceUserLanguage?: string;
}

export interface ISupportContextProvider {
  kustomerConfig: IKustomerConfigurationProps;
  supportContext?: Partial<ISupportContext>;
}

export const SupportContextProvider = ({
  children,
  kustomerConfig,
}: PropsWithChildren<ISupportContextProvider>) => {
  const [queryParams] = useSearchParams();
  const [hopperUserId, setHopperUserId] = useState(kustomerConfig.hopperUserId);
  const [isLoading, setIsLoading] = useState(false);
  const [kustomerAccessToken, setKustomerAccessToken] = useState(
    kustomerConfig.kustomerAccessToken
  );
  const [loaded, setLoaded] = useState(false);
  const [started, setStarted] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const onStartedChat = useCallback(() => {
    setStarted(true);
    listenForUnreadCountUpdates((count) => {
      setUnreadCount(count);
    });
  }, []);

  const onLoadedChat = useCallback(() => {
    setIsLoading(false);
    setLoaded(true);
    if (!started) {
      startKustomerChat(
        {
          brandId: kustomerConfig.brandId,
          hideChatIcon: true,
          hideNewConversationButtonOnHistory: true,
          hideNewConversationButtonOnEnd: true,
          lang: kustomerConfig.forceUserLanguage,
        },
        onStartedChat
      );
    }
  }, [kustomerConfig, onStartedChat, started]);

  useEffect(() => {
    if (!loaded && !isLoading) {
      setIsLoading(true);
      initializeKustomerChat(
        {
          apiKey: kustomerConfig.apiKey,
          enabled: kustomerConfig.enabled,
        },
        onLoadedChat
      );
    }
  }, [isLoading, kustomerConfig, loaded, onLoadedChat]);

  useEffect(() => {
    if (started) {
      if (hopperUserId && kustomerAccessToken) {
        loginKustomer(hopperUserId, kustomerAccessToken);
      }
    }
  }, [hopperUserId, kustomerAccessToken, started]);

  useEffect(() => {
    if (started) {
      const showSupportChat = queryParams.has("chat-support");
      const openConversationId = queryParams.get("openConversation");

      if (openConversationId) {
        openKustomerConversationById(openConversationId);
      } else if (showSupportChat) {
        createGeneralSupportConversation(kustomerConfig.tenant);
      }
    }
  }, [kustomerConfig.tenant, queryParams, started]);

  useEffect(() => {
    if (!hopperUserId && kustomerConfig.hopperUserId) {
      setHopperUserId(kustomerConfig.hopperUserId);
    }

    if (!kustomerAccessToken && kustomerConfig.kustomerAccessToken) {
      setKustomerAccessToken(kustomerConfig.kustomerAccessToken);
    }
  }, [hopperUserId, kustomerAccessToken, kustomerConfig]);

  const supportContext: ISupportContext = {
    setHopperUserId,
    setKustomerAccessToken,
    generalSupportChat: () => {
      createGeneralSupportConversation(kustomerConfig.tenant);
    },
    productSupportChat: async (productId, productType, requestType) => {
      createSupportConversationForProduct(productId, productType, requestType);
    },
    openChatWindow: () => {
      openKustomerChat();
    },
    unreadCount,
  };

  return (
    <SupportContext.Provider value={supportContext}>
      {children}
    </SupportContext.Provider>
  );
};
