import { useI18nContext } from "@hopper-b2b/i18n";
import { ProductType } from "@hopper-b2b/types";
import { ChoiceButton } from "@hopper-b2b/ui";
import { Radio } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles.module.scss";

export const PassengerSelectButton = ({
  onClick,
  isChosen,
  disabled,
  className,
  value,
  isMinor,
  productType,
}: {
  onClick: (e) => void;
  isChosen: boolean;
  disabled: boolean;
  className: string;
  value: string;
  isMinor: boolean;
  productType: ProductType;
}) => {
  const { t } = useI18nContext();

  return productType === ProductType.LODGING ? (
    <Radio
      className={clsx(styles.PassengerSelectButton, className)}
      value={value}
      inputProps={{
        "aria-selected": isChosen,
      }}
      checked={isChosen}
      disabled={isMinor || disabled}
    />
  ) : (
    <ChoiceButton
      onClick={(e) => onClick(e)}
      isChosen={isChosen}
      disabled={!!disabled}
      ariaLabel={`${t("choose")} ${value}`}
      message={t("choose")}
      className={className}
    />
  );
};
