import { useDispatch } from "react-redux";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySegment,
} from "@b2bportal/air-booking-api";
import { useState } from "react";
import { useHistory } from "react-router";
import { TripSlice } from "@b2bportal/air-shopping-api";
import defaultStyles from "./DisruptionExerciseRebookSelectFlightPage.module.scss";
import { RebookPlanSuccess } from "@b2bportal/air-disruption-api";
import {
  DisruptionExerciseProgress,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import {
  DisruptionExerciseContainer,
  DisruptionExerciseFooter,
  DisruptionExercisePage,
  DisruptionExercisePageContent,
} from "@b2bportal/core-disruption";
import { DisruptionExercisePageWidth } from "../../components/DisruptionExercisePage/DisruptionExercisePage";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import DisruptionExerciseFlightSliceCard from "../../components/DisruptionExerciseFlightSliceCard/DisruptionExerciseFlightSliceCard";

export interface DisruptionExerciseRebookSelectFlightPageProps {
  disruptedFlight: BookedFlightItineraryWithDepartureTime;
  planResponse: RebookPlanSuccess;
  setSelectedSlice: (slice: {
    segments: Array<FlightItinerarySegment>;
  }) => void;
  airports: { [key: string]: Airport };
  onFlightSelectContinue: (props: {
    origin: string;
    destination: string;
    departure: string;
    tripSlice?: TripSlice | null;
  }) => void;
  onSupportClick: () => void;
  termsLink: string;
  displayExternalLinkAsModal: boolean;
  delayHours: string;
  hoursString: string;
  handleRefundClick: () => void;
}

export const DisruptionExerciseRebookSelectFlightPage = ({
  disruptedFlight,
  planResponse,
  setSelectedSlice,
  airports,
  onFlightSelectContinue,
  onSupportClick,
  termsLink,
  displayExternalLinkAsModal,
  delayHours,
  hoursString,
  handleRefundClick,
}: DisruptionExerciseRebookSelectFlightPageProps) => {
  const { t } = useI18nContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedSliceIndex, setSelectedSliceIndex] = useState<number | null>(
    null
  );
  const TRANS_PATH =
    "core-disruption.disruptionUniversalExercise.rebook.sliceSelectionPage";
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseRebookSelectFlightPage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const handleCta = () => {
    if (selectedSliceIndex != null) {
      const selectedSlice = planResponse.slices[selectedSliceIndex];
      if (selectedSlice.segments.length === 1) {
        // if 1 segment (no connections) -> search flights
        const segment = selectedSlice.segments[0];

        if (segment) {
          const origin = segment.origin.locationCode;
          const departure =
            segment.updatedDeparture ?? segment.scheduledDeparture;
          const destination = segment.destination.locationCode;

          onFlightSelectContinue({
            origin: origin,
            destination: destination,
            departure: departure,
            tripSlice: null,
          });

          dispatch(
            setDisruptionExerciseProgress(
              DisruptionExerciseProgress.FlightSearch
            )
          );
        }
      } else {
        // if more than 1 segment (connections) -> select segment
        dispatch(
          setDisruptionExerciseProgress(
            DisruptionExerciseProgress.SegmentSelect
          )
        );
      }
    }
  };

  const handleGoBack = () => {
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.RebookReview)
    );
  };

  const rows = planResponse.slices.map((slice, index) => {
    return (
      <DisruptionExerciseFlightSliceCard
        isReturn={index > 0}
        isSelected={selectedSliceIndex === index}
        slice={slice}
        onSelect={() => {
          setSelectedSlice(slice);
          setSelectedSliceIndex(index);
        }}
        airports={airports}
        key={index}
      ></DisruptionExerciseFlightSliceCard>
    );
  });
  const selectableRows = <div className={cn("rowsContainer")}>{rows}</div>;

  return (
    <DisruptionExercisePage
      className={cn("DisruptionExerciseRebookSelectFlightPage")}
      pageWidth={DisruptionExercisePageWidth.Regular}
      title={t(`${TRANS_PATH}.title`)}
      onBack={handleGoBack}
      submitButtonTitle={t(
        "core-disruption.disruptionUniversalExercise.continue"
      )}
      submitButtonAction={handleCta}
      submitButtonDisabled={selectedSliceIndex === null}
      delayHours={delayHours}
      numberOfHours={hoursString}
      termsLink={termsLink}
      content={
        <DisruptionExercisePageContent
          leftContent={
            <DisruptionExerciseContainer
              content={[
                selectableRows,
                <DisruptionExerciseFooter
                  onSupportClick={onSupportClick}
                  onRefundClick={handleRefundClick}
                ></DisruptionExerciseFooter>,
              ]}
            ></DisruptionExerciseContainer>
          }
        />
      }
    ></DisruptionExercisePage>
  );
};

export default DisruptionExerciseRebookSelectFlightPage;
