import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseSelection.module.scss";
import clsx from "clsx";
import { RadioGroup } from "@components/ui";

interface DisruptionExerciseSelectionProps {
  content: JSX.Element;
  isSelected?: boolean;
  onSelect?: () => void;
  hideRadio: boolean;
}

export const DisruptionExerciseSelection = ({
  content,
  isSelected = false,
  onSelect,
  hideRadio = false,
}: DisruptionExerciseSelectionProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseSelection;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const styleClass = isSelected ? cn("selected") : cn("notSelected");

  return (
    <div className={clsx([cn("container"), styleClass])} onClick={onSelect}>
      {!hideRadio && onSelect && (
        <RadioGroup
          options={[{ label: undefined, value: String(isSelected) }]}
          selectedOption={String(true)}
          onSelectValue={onSelect}
        />
      )}
      <div className={cn("selectionContent")}>{content}</div>
    </div>
  );
};

export default DisruptionExerciseSelection;
