import type { TripDetails } from "@hopper-b2b/types";

export const isFlightDomesticToInternational = (
  tripDetails: TripDetails,
  preferredCountryCode: string
) => {
  // Grab the origin airport, based off the origin code
  const originAirport = tripDetails.airports.find(
    (airport) => airport.code === tripDetails.slices[0].originCode
  );
  // Grab the origin airport's country code
  const originAirportCountryCode = originAirport?.geography.countryCode;

  // Grab the destination airport, based off the destination code
  const destinationAirport = tripDetails.airports.find(
    (airport) => airport.code === tripDetails.slices[0].destinationCode
  );
  // Grab the destination airport's country code
  const destinationAirportCountryCode =
    destinationAirport?.geography.countryCode;

  // If both airport country codes are the same, it's not an international flight
  if (originAirportCountryCode === destinationAirportCountryCode) {
    return false;
  }
  // We have two separate countries, if the origin airport matches the preferred country code, the flight begins as domestic
  return originAirportCountryCode === preferredCountryCode;
};
