import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  airDisruptionApi,
  DisruptionRefundPlanRequest,
} from "@b2bportal/air-disruption-api";
import {
  DisruptionRefundPlanResponseEnum,
  DisruptionRefundPlanSuccess,
} from "@b2bportal/air-disruption-api/lib/api";

export const getDisruptionRefundPlan = createAsyncThunk<
  DisruptionRefundPlanSuccess | undefined,
  DisruptionRefundPlanRequest
>(
  "disruption/exercise/refund/plan",
  async (request: DisruptionRefundPlanRequest, thunkAPI) => {
    try {
      const res = await airDisruptionApi(
        axios
      ).apiV0DisruptionExerciseRefundPlanPost(request);
      if (
        res.data.DisruptionRefundPlanResponse ===
        DisruptionRefundPlanResponseEnum.DisruptionRefundPlanSuccess
      ) {
        return res.data;
      } else {
        throw new Error("getDisruptionRefundPlan: " + res.data.reason);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
